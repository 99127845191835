import React, {useState} from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import browserHistory from "../browserHistory";

import {changeColorBrightness, validateEmail} from "../utils/misc";

import { Modal, Paper, TextField, Typography, Divider, Button, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    Clear as CloseIcon
} from '@mui/icons-material';

function getClientMetaData() {
    return {
        location: browserHistory.location,
        userAgent: navigator.userAgent
    };
}


export function FeedbackButton(props) {
    const {
        user,
        onSendClick,
        t
    } = props;
    const classes = makeStyles(theme => ({
        buttonContainer: {
            padding: '5px 12px 5px 12px',
            position: 'fixed',
            bottom: 0,
            left: 0,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.alternate,
            margin: 2,
            '&:hover': {
                backgroundColor: changeColorBrightness(theme.palette.primary.main, 30),
                cursor: 'pointer'
            }
        }
    }))();
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModalOpen = () => setModalOpen(!modalOpen);
    return (
        <React.Fragment>
            <div className={classes.buttonContainer} onClick={toggleModalOpen}>
                <Typography variant={'overline'}>
                    {t('common:feedback')}
                </Typography>
            </div>
            <FeedbackModal
                open={modalOpen}
                user={user}
                onClose={toggleModalOpen}
                onSendClick={onSendClick}
                t={t}
            />

        </React.Fragment>
    )
}

FeedbackButton.propTypes = exact({
    user: PropTypes.object,
    onSendClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
});


function FeedbackModal(props) {
    const {
        user,
        open,
        onSendClick,
        onClose,
        t
    } = props;

    const classes = makeStyles(theme => ({
        modal: {
            padding: 135
        },
        paper: {
            padding: 20
        },
        headerContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        textfield: {
            marginTop: 5,
            marginBottom: 5
        },
        divider: {
            marginBottom: 10
        },
        buttonContainer: {
            textAlign: 'center'
        }
    }))();
    const [email, setEmail] = useState(user && user.email ? user.email : '');
    const [feedbackText, setFeedbackText] = useState('');
    const [emailError, setEmailError] = useState(false);
    const onSubmit = () => {
        if( validateEmail(email)){
            onSendClick(email, feedbackText, getClientMetaData());
            setFeedbackText('');
            onClose();
        } else {
            setEmailError(true)
        }
    };

    return (
        <Modal open={open} className={classes.modal} onClose={onClose}>
            <Paper className={classes.paper}>
                <div className={classes.headerContainer}>
                    <Typography variant={'h6'}>
                        {t('common:feedback')}
                    </Typography>
                    <IconButton onClick={onClose} size="large">
                        <CloseIcon/>
                    </IconButton>
                </div>
                <Divider className={classes.divider}/>

                <TextField
                    error={emailError}
                    helperText={emailError && t('appRoutes:noValidEmail')}
                    fullWidth
                    className={classes.textfield}
                    variant={'outlined'}
                    label={t('common:email')}
                    value={email}
                    onChange={e => {
                        setEmailError(false);
                        setEmail(e.target.value)}
                    }
                />

                <TextField
                    fullWidth
                    multiline
                    className={classes.textfield}
                    rows={8}
                    variant={'outlined'}
                    label={t('appRoutes:feedBackQuestion')}
                    value={feedbackText}
                    onChange={e => setFeedbackText(e.target.value)}
                />

                <div className={classes.buttonContainer}>
                    <Button
                        fullWidth
                        variant={'contained'}
                        color={'primary'}
                        onClick={onSubmit}
                    >
                        {t('common:send')}
                    </Button>
                </div>

            </Paper>
        </Modal>
    );
}

FeedbackModal.defaultProps = {
    user: {}
};
FeedbackModal.propTypes = exact({
    open: PropTypes.bool.isRequired,
    user: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSendClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
});