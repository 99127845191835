import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import LoadingSpinner from "./LoadingSpinner";
import {
    Switch,
    FormControlLabel,
    NativeSelect,
    Paper,
    Divider,
    Typography
} from "@mui/material";
import CatalogFillView from '../components/CatalogFillView';
import CatalogToc from '../components/CatalogComponents/CatalogToc';
import FillCatalogSpeedDial from '../components/CatalogComponents/FillCatalogSpeedDial';
import ActiveUsersAvatars from "../components/CatalogComponents/ActiveUsersAvatars";
import CatalogFillItemOrderView from "../components/CatalogFillItemOrderView";
import TextField from '@mui/material/TextField';
import dayjs from "../utils/dayjsWithLocale";
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";
import {withTranslation} from "react-i18next";
import {AttachFilePanel} from "./AttachFile/AttachFile";
import Grid from "@mui/material/Grid";
import SheetAttendeePicker from "./CatalogComponents/SheetAttendeePicker";


class SheetFillView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resultDate: null,
            displayErrors: true,
            categoryView: true
        }
    }

    onClose = () => {
        this.props.onClose();
    };

    onAnswerItem = (resultId, itemId, values) => {
        this.props.answerItem(resultId, itemId, values);
    };

    onRemoveAnswer = (resultId, itemId) => {
        this.props.removeAnswer(resultId, itemId);
    };

    onTextItemRadioChange = (resultId, itemId, value, positive) => {
        this.props.setItemExample(
            resultId,
            itemId,
            value,
            positive
        )
    };

    scrollToCategory = (categoryId) => {
        const categoryDiv = document.getElementById(categoryId);
        if (!categoryDiv) return;
        window.scrollTo(0, window.scrollY + categoryDiv.getBoundingClientRect().top - 55)
    };

    onChangeResultCreator = (event) => {
        event.preventDefault();
        this.props.changeResultCreator(event.target.value, this.props.result.result_id);
    };

    onResultDateChange = (newDate) => {
        this.setState({resultDate: newDate});
    };

    onResultDateChangeDone = () => {
        if (!this.state.resultDate) return;
        let newDate = dayjs(this.state.resultDate).utc().unix();
        this.props.changeResultDate(this.props.result.result_id, newDate);
    };

    onSetResultCode = (resultCodeId) => {
        this.props.setResultCode(this.props.result.result_id, resultCodeId);
    };

    toggleDisplayErrors = () => {
        this.setState({displayErrors: !this.state.displayErrors});
    };

    onStartCalibration = () => {
        this.props.startCalibrationFromResult(
            this.props.result.result_id,
            this.props.sheet.sheet_id
        );
    };

    showDateChanger = () => {
        if (this.props.publicResult) {
            return false;
        }
        return (
            (this.props.result.user_id === this.props.user.user_id)
            ||
            (this.props.roles[this.props.user.role] > 2)
        )
    };

    onUploadFile = (file) => {
        this.props.attachFile(this.props.result.result_id, file);
    };

    onDownloadFile = (fileId) => {
        this.props.downloadFile(fileId);
    };

    onDeleteFile = (fileId) => {
        this.props.deleteFile(this.props.result.result_id, fileId);
    };

    onChangeAttendees = (attendees) => {
        this.props.changeAttendees(
            this.props.result.result_id,
            attendees.map(u => u.user_id)
        );
    };

    onExportResult = (format) => {
        this.props.exportResult(this.props.result.result_id, format)
    };

    render() {
        const {t, sheet, result, calcTemplate, activeUsers, users} = this.props;
        const props = this.props;
        if (!result || !sheet) {
            return <LoadingSpinner/>
        }
        return (
            <React.Fragment>
                {!this.props.publicResult &&
                <FillCatalogSpeedDial
                    resultCodes={sheet.result_codes}
                    errorAmount={result.errors ? Object.keys(result.errors).length : 0}
                    onDisplayErrorsClick={this.toggleDisplayErrors}
                    onBackClick={this.onClose}
                    onStartCalibrationClick={this.onStartCalibration}
                    onResultCodeSet={this.onSetResultCode}
                    onExportResult={this.onExportResult}
                />
                }

                {this.state.categoryView &&
                <CatalogToc
                    scrollToCategory={this.scrollToCategory}
                    items={sheet.items}
                />
                }

                <ActiveUsersAvatars
                    floating
                    activeUsers={activeUsers}
                />

                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                    <Grid item xs={12} style={{borderBottom: `3px solid ${sheet.color}`}}>
                        <Typography
                            noWrap
                            gutterBottom
                            variant="h5"
                        >
                            {sheet.name}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            label={t('sheetManagement:categoryView')}
                            control={
                                <Switch
                                    color={'primary'}
                                    checked={this.state.categoryView}
                                    onChange={() => this.setState({categoryView: !this.state.categoryView})}
                                />
                            }
                        />
                    </Grid>
                    <Divider/>
                    {!this.props.publicResult &&
                    <React.Fragment>
                        <Grid item xs={3}>
                            {/* maybe we should hide all of this data for guests */}
                            <Typography variant="body1" style={{fontWeight: 'bolder'}}>
                                {t('common:creator')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            { ["LEAD", "STAFF", "ADMIN"].includes(this.props.user.role) ?
                                <NativeSelect
                                    // style={{width: '50%'}}
                                    margin={'none'}
                                    value={result.creatorId}
                                    onChange={event => this.onChangeResultCreator(event)}
                                    name="creator"
                                >
                                    {users.map((user) => {
                                        return <option key={user.user_id} value={user.user_id}>{user.name}</option>
                                    })}
                                </NativeSelect>
                                :
                                <Typography>
                                    {result.creator}
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1" style={{fontWeight: 'bolder'}}>
                                {t('common:date')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            {this.showDateChanger() ?
                                <MobileDateTimePicker
                                    // style={{width: '50%'}}
                                    renderInput={(props) => <TextField variant="standard" {...props} helperText={null}/>}
                                    ampm={false}
                                    margin={'none'}
                                    variant={'inline'}
                                    placeholder={t('common:chooseDate')}
                                    value={this.state.resultDate || result.date}
                                    onChange={this.onResultDateChange}
                                    onClose={this.onResultDateChangeDone}
                                    format={'LLL'}
                                />
                                :
                                <h4>{dayjs.tz(dayjs.utc(result.date)).format('LLL')}</h4>
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1" style={{fontWeight: 'bolder'}}>
                                {t('common:resultCode')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1">
                                {result.result_code ? result.result_code.name : "-"}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1" style={{fontWeight: 'bolder', color: '#8300ff'}}>
                                {t('calcTemplates:totalWeight')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            {calcTemplate && calcTemplate.use_weights &&
                            <Typography variant="body1" style={{color: '#8300ff'}}>
                                {calcTemplate.overall_weight.toFixed(1)}
                            </Typography>
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1" style={{fontWeight: 'bolder', color: '#497993'}}>
                                {t('resultScore')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            {calcTemplate &&
                            <Typography variant="body1" style={{color: '#497993'}}>
                                {
                                    typeof this.props.currentResultScores[sheet.sheet_id] === 'number' ?
                                        this.props.currentResultScores[sheet.sheet_id].toFixed(2) +
                                        (calcTemplate.percentages ? "%" : "")
                                        :
                                        '\u00A0'
                                }
                            </Typography>
                            }
                        </Grid>

                        {sheet.show_attendees &&
                            <Grid item xs={12}>
                                <SheetAttendeePicker
                                    selectedAttendees={result.attendees}
                                    values={this.props.users}
                                    onChange={this.onChangeAttendees}
                                />
                            </Grid>
                        }


                        <div
                            className="col-xs-2 col-md-4"
                            style={{display: 'flex', justifyContent: 'flex-end'}}
                        >
                            <ActiveUsersAvatars activeUsers={activeUsers}/>
                        </div>
                    </React.Fragment>
                    }
                </Grid>
                <Divider style={{marginBottom: '10px'}}/>
                <div className={'row'}>
                    <div className={'col-xs-12'}>
                        <AttachFilePanel
                            files={result.attached_files}
                            onUploadFile={this.onUploadFile}
                            onDownloadFile={this.onDownloadFile}
                            onDeleteFile={this.onDeleteFile}
                        />
                    </div>
                </div>
                <Divider style={{marginBottom: '10px'}}/>

                <div style={{textAlign: 'center'}}>
                    <Paper elevation={1} className="catalog-paper">
                        {this.state.categoryView ?
                            <CatalogFillView
                                sheet={sheet}
                                resultId={result.result_id}
                                result={result.answers}
                                allowedAnswers={this.props.allowedAnswers}
                                itemExamples={result.item_examples}
                                itemScores={
                                    Object.keys(this.props.currentResultScores).length !== 0 ?
                                        this.props.currentResultScores : undefined
                                }
                                resultErrors={result.errors}
                                displayErrors={this.state.displayErrors}
                                itemWeights={calcTemplate && calcTemplate.item_weights}
                                useWeights={calcTemplate && calcTemplate.use_weights}
                                onChange={this.onAnswerItem}
                                onRemoveAnswer={this.onRemoveAnswer}
                                onTextItemRadioChange={this.onTextItemRadioChange}
                                displayPercentages={calcTemplate && calcTemplate.percentages}
                            />
                            :
                            <CatalogFillItemOrderView
                                sheet={sheet}
                                items={sheet.items}
                                resultId={result.result_id}
                                answers={result.answers}
                                itemScores={
                                    Object.keys(this.props.currentResultScores).length !== 0 ?
                                        this.props.currentResultScores : undefined
                                }
                                resultErrors={result.errors}
                                displayErrors={this.state.displayErrors}
                                itemWeights={calcTemplate && calcTemplate.item_weights}
                                useWeights={calcTemplate && calcTemplate.use_weights}
                                onChange={this.onAnswerItem}
                                onRemoveAnswer={this.onRemoveAnswer}
                                onTextItemRadioChange={this.onTextItemRadioChange}
                                itemExamples={result.item_examples}
                                allowedAnswers={this.props.allowedAnswers}
                            />
                        }
                    </Paper>
                </div>
            </React.Fragment>
        );
    }
}

SheetFillView.propTypes = exact({
    t: PropTypes.func,
    i18n: PropTypes.object,
    tReady: PropTypes.bool,
    publicResult: PropTypes.bool,
    sheet: PropTypes.object,
    result: PropTypes.object,
    currentResultScores: PropTypes.object,
    calcTemplate: PropTypes.object,
    activeUsers: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func.isRequired,
    answerItem: PropTypes.func.isRequired,
    removeAnswer: PropTypes.func.isRequired,
    setItemExample: PropTypes.func,
    allowedAnswers: PropTypes.object,
    user: PropTypes.object.isRequired,
    users: PropTypes.array,
    roles: PropTypes.object,
    setResultCode: PropTypes.func.isRequired,
    startCalibrationFromResult: PropTypes.func.isRequired,
    changeResultDate: PropTypes.func.isRequired,
    changeResultCreator: PropTypes.func.isRequired,
    attachFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    changeAttendees: PropTypes.func.isRequired,
    exportResult: PropTypes.func.isRequired
});
export default withTranslation(['desk', 'sheetManagement', 'calcTemplates', 'common'])(SheetFillView);
