import React from 'react';
import PropTypes from 'prop-types';
//import "babel-polyfill";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../../browserHistory';
import * as actionCreators from '../../actions/auth';
import * as dataActions from '../../actions/data';

import AuthContext from '../../contexts/AuthContext';
import NotFound from '../../components/NotFound';
import LoadingSpinner from "../../components/LoadingSpinner";
import {withTranslation} from "react-i18next";
import dayjs from 'utils/dayjsWithLocale';
import {DAYS_BEFORE_USER_EMAIL_ACTIVATION_REQUIRED} from "../../constants";
import ConfirmEmailPanel from "../../components/ConfirmEmailPanel";

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        isAuthenticated: state.auth.isAuthenticated,
        user: state.data.user,
        roles: state.data.roles,
        isFetching: state.data.isFetching,
        loaded: state.data.loaded,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({...dataActions, ...actionCreators}, dispatch);
}


export function requireAuthentication(Component, requiredPrivilege = null, feedbackUnauthorized = true) {
    class AuthenticatedComponent extends React.Component {
        static contextType = AuthContext;
        render() {
            // separate props so translation is not sent to wrapped component
            const {t, tReady, i18n, user, ...restProps} = this.props;
            if (!this.context.loaded){
                return (
                    <React.Fragment>
                        <LoadingSpinner
                            text={t('requiredDataIsBeingLoaded')}
                        />
                    </React.Fragment>
                )
            }

            if (!user){
                return (
                    <React.Fragment>
                        <NotFound unauthorized/>
                        <h4>{t('youAreNotLoggedInMessage')}</h4>
                        <button onClick={this.props.logoutAndRedirect}>{t('toLogin')}</button>
                    </React.Fragment>
                )
            }

            if (requiredPrivilege){
                const userIsAllowed = this.props.roles[user.role] >= requiredPrivilege;
                if (!userIsAllowed && feedbackUnauthorized){
                    return (
                        <React.Fragment>
                            <NotFound unauthorized/>
                            <h4>{t('youDontHavePermission')}</h4>
                        </React.Fragment>
                    )
                }
                // If user is not allowed and should not receive feedback, then just redirect back to main view
                if (!userIsAllowed) {
                    browserHistory.push('/');
                }
            }
            const activationRequired = dayjs().isAfter(
                dayjs(user.creation_date).add(DAYS_BEFORE_USER_EMAIL_ACTIVATION_REQUIRED, 'days')
            );
            if (!user.confirmed_email && activationRequired){
                return (
                    <ConfirmEmailPanel
                        onResendEmailClick={() => this.props.resendConfirmationEmail(this.props.token)}
                    />
                )
            }

            // All checks are done and confirmed, return the actual component
            return <Component {...{user, ...restProps}}/>
        }
    }

    AuthenticatedComponent.propTypes = {
        loginUserSuccess: PropTypes.func,
        isAuthenticated: PropTypes.bool,
    };

    return connect(mapStateToProps, mapDispatchToProps)(withTranslation('actionResponses')(AuthenticatedComponent));
}
