import React from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import * as actionCreators from "../actions/data";
import * as authCreators from "../actions/auth";
import * as socketActions from "../actions/socketActions";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import {sortObjectArrayByName} from "../utils/misc";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        user: state.data.user,
        sheetMetaData: state.data.sheetMetaData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({...actionCreators, ...authCreators, ...socketActions}, dispatch);
}

export function ResultImport(props) {

    const token = useSelector(state => state.auth.token);
    const dispatch = useDispatch();
    const {t} = useTranslation(['importExport']);
    props.fetchSheetMetaData(token);

    return (
        <div>
            <Typography variant={'overline'}>
                {t('resultImportDeadline')}
            </Typography>
            <Divider/>
            <List>
                {sortObjectArrayByName(props.sheetMetaData.filter(s => s.locked), 'order').map(sheet => {
                    return (
                        <ListItem
                            key={sheet.sheet_id}
                            button
                            divider
                            onClick={() => {
                                onSheetClick(sheet.sheet_id)
                            }}
                        >
                            <ListItemText
                                primary={sheet.name}
                                secondary={''}
                            />
                        </ListItem>
                    )
                })}
            </List>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultImport);