import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Portal from '@mui/material/Portal';
import {
    getCategoriesForItemAvatars, getCategoryForItem, getItemView,
    sortCatalogItemsByOrder
} from "./CatalogComponents/CatalogItems";
import {CALIB_FILL_MODE_ITEM_ORDER, PROD_FILL_CATALOG_ITEM_ORDER} from "../constants";
import ItemCategoryAvatars from "./CatalogComponents/ItemCategoryAvatars";


class CatalogFillItemOrderView extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){
        setTimeout(() => this.setState(this.state), 0);
    }

    render(){
        let sortedItems = sortCatalogItemsByOrder(this.props.items);
        return (
            <React.Fragment>
                {sortedItems.map(item => {
                    if (item.isCategory) return null;
                    let categoryAvatars = getCategoriesForItemAvatars(item, sortedItems);
                    let category = getCategoryForItem(item, sortedItems);

                    return (
                        <React.Fragment key={item.id}>
                            {getItemView(
                                item, this.props.sheet, {
                                    viewMode: this.props.viewMode,
                                    onChange: this.props.onChange,
                                    onRemoveAnswer: this.props.onRemoveAnswer,
                                    onTextItemRadioChange: this.props.onTextItemRadioChange,
                                    textItemRadioValue: this.props.itemExamples[item.id],
                                    category: category,
                                    value: this.props.answers[item.id],
                                    itemError: this.props.resultErrors ? this.props.resultErrors[item.id] : null,
                                    displayErrors: this.props.displayErrors,
                                    resultId: this.props.resultId,
                                    itemWeight: this.props.useWeights ? this.props.itemWeights[item.id] : {deactivated: true},
                                    itemScore: this.props.itemScores ? this.props.itemScores[item.id] : null,
                                    showCommentButton: this.props.viewMode === CALIB_FILL_MODE_ITEM_ORDER,
                                    showRadioButtons: this.props.viewMode !== CALIB_FILL_MODE_ITEM_ORDER,
                                    comments: this.props.comments[item.id],
                                    onCommentButtonClick: this.props.onCommentButtonClick,
                                    allowedAnswers: this.props.allowedAnswers && this.props.allowedAnswers[item.id]
                                }
                            )}

                            <Portal container={document.getElementById('itemAvatarContainer' + item.id)}>
                                <ItemCategoryAvatars
                                    categoryAvatars={categoryAvatars}
                                    sheetColor={this.props.sheet.color}
                                />
                            </Portal>
                        </React.Fragment>
                    )
                })}
            </React.Fragment>
        )
    }
}
CatalogFillItemOrderView.defaultProps = {
    viewMode: PROD_FILL_CATALOG_ITEM_ORDER,
    comments: {},
    itemExamples: {},
};
CatalogFillItemOrderView.propTypes = exact({
    sheet: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
    resultId: PropTypes.string.isRequired,
    answers: PropTypes.object,
    itemScores: PropTypes.object,
    resultErrors: PropTypes.object,
    displayErrors: PropTypes.bool,
    itemWeights: PropTypes.object,
    useWeights: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onRemoveAnswer: PropTypes.func.isRequired,
    onTextItemRadioChange: PropTypes.func,
    comments: PropTypes.object,
    itemExamples: PropTypes.object,
    onCommentButtonClick: PropTypes.func,
    viewMode: PropTypes.string.isRequired,
    allowedAnswers: PropTypes.object.isRequired
});
export default CatalogFillItemOrderView;
