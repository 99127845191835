import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import {
    getCategoryChildren,
    getItemView,
    getCurrentLevelItems,
} from "./CatalogComponents/CatalogItems";
import {
    CALIB_FILL_MODE, CALIB_PRESENTATION_MODE,
    PROD_FILL_CATALOG
} from "../constants";
import {requiredIf} from '../utils/misc';

const expansionDetailsStyle = {
    padding: 0,
    display: 'block',
    backgroundColor: '#fff',
    borderRadius: "25px 25px 15px 15px"
};

class CatalogFillView extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div
                id={this.props.subCategory ? null : '__FILL_VIEW_TOP__'}
                style={{textAlign: 'left'}}
            >
                {getCurrentLevelItems(this.props.sheet.items).map((item, index) => {
                    const panelStyle = {
                        margin: 0,
                        backgroundColor: item.color,
                        borderRadius: (this.props.subCategory && index === 0) ?
                            "20px 20px 0px 0px"
                            :
                            null
                    };

                    const detailsStyle = {
                        ...expansionDetailsStyle,
                        border: `2px solid ${item.color}`,
                    };

                    if (item.isCategory) {
                        let categoryChildren = getCategoryChildren(item.path, this.props.sheet.items);
                        return (
                            <React.Fragment key={item.id}>
                                <Accordion
                                    defaultExpanded
                                    id={item.id}
                                    key={item.id}
                                    style={panelStyle}
                                    elevation={this.props.subCategory ? 0 : 3}
                                >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <div className={"row full-width"}>
                                            <div className={"col-xs-3"}>
                                                <Typography>{item.name}</Typography>
                                            </div>

                                            <div className="col-xs-2" style={{display: 'flex', justifyContent: 'end'}}>
                                                <div style={{backgroundColor: '#fff', borderRadius: '10%'}}>
                                                    <Typography variant="body1" style={{margin: 0, color: '#8300ff'}}>
                                                        {
                                                        this.props.useWeights &&
                                                    this.props.itemWeights[item.id] &&
                                                    typeof this.props.itemScores[item.id] === "number" ? "G: "
                                                     + this.props.itemWeights[item.id].weight : "G: \u00A0"}
                                                    </Typography>

                                                    <Typography variant="body1" style={{margin: 0, color: '#497993'}}>
                                                    {typeof this.props.itemScores[item.id] === "number" ?
                                                        "S: " + this.props.itemScores[item.id].toFixed(1) +
                                                        (this.props.displayPercentages ? "%" : "") : "S:"
                                                        + '\u00A0'}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div className={"col-xs-7"}>
                                                <Typography>{item.description}</Typography>
                                            </div>
                                        </div>
                                    </AccordionSummary>

                                    {categoryChildren.length > 0 &&
                                    <AccordionDetails
                                        style={detailsStyle}
                                    >
                                        <CatalogFillView
                                            subCategory
                                            sheet={{
                                                ...this.props.sheet,
                                                items: categoryChildren
                                            }}
                                            resultId={this.props.resultId}
                                            result={this.props.result}
                                            resultErrors={this.props.resultErrors}
                                            displayErrors={this.props.displayErrors}
                                            itemWeights={this.props.itemWeights}
                                            itemScores={this.props.itemScores}
                                            itemExamples={this.props.itemExamples}
                                            onChange={this.props.onChange}
                                            onRemoveAnswer={this.props.onRemoveAnswer}
                                            onTextItemRadioChange={this.props.onTextItemRadioChange}
                                            mode={this.props.mode}
                                            onCommentButtonClick={this.props.onCommentButtonClick}
                                            useWeights={this.props.useWeights}
                                            highlightedItems={this.props.highlightedItems}
                                            comments={this.props.comments}
                                            displayPercentages={this.props.displayPercentages}
                                            allowedAnswers={this.props.allowedAnswers}
                                        />

                                    </AccordionDetails>
                                    }
                                </Accordion>
                            </React.Fragment>
                        )
                    }
                    else {
                        return getItemView(item, this.props.sheet, {
                            viewMode: this.props.mode,
                            onChange: this.props.onChange,
                            onRemoveAnswer: this.props.onRemoveAnswer,
                            onTextItemRadioChange: this.props.onTextItemRadioChange,
                            textItemRadioValue: this.props.itemExamples[item.id],
                            showCommentButton: this.props.mode === CALIB_FILL_MODE || Boolean(this.props.comments[item.id]),
                            showRadioButtons: ![CALIB_FILL_MODE, CALIB_PRESENTATION_MODE].includes(this.props.mode),
                            comments: this.props.comments[item.id],
                            onCommentButtonClick: this.props.onCommentButtonClick,
                            value: this.props.result[item.id],
                            itemError: this.props.resultErrors ? this.props.resultErrors[item.id] : null,
                            displayErrors: this.props.displayErrors,
                            resultId: this.props.resultId,
                            itemWeight: this.props.useWeights ? this.props.itemWeights[item.id] : {deactivated: true},
                            itemScore: this.props.itemScores ? this.props.itemScores[item.id] : null,
                            highlightItem: this.props.highlightedItems.includes(item.id),
                            commentButtonSelected: this.props.selectedItems.includes(`${this.props.resultId}/${item.id}`),
                            displayPercentages: this.props.displayPercentages,
                            allowedAnswers: this.props.allowedAnswers && this.props.allowedAnswers[item.id]
                        })
                    }

                })}
            </div>
        )
    }
}

CatalogFillView.defaultProps = {
    subCategory: false,
    mode: PROD_FILL_CATALOG,
    onCommentButtonClick: () => null,
    result: {},
    displayErrors: false,
    itemWeights: {},
    useWeights: true,
    itemScores: {},
    highlightedItems: [],
    comments: {},
    itemExamples: {},
    selectedItems: [],
    displayPercentages: true
};

CatalogFillView.propTypes = exact({
    sheet: PropTypes.object.isRequired,
    onChange: requiredIf(PropTypes.func, props => props.mode !== CALIB_PRESENTATION_MODE),
    onRemoveAnswer: requiredIf(PropTypes.func, props => props.mode !== CALIB_PRESENTATION_MODE),
    onTextItemRadioChange: PropTypes.func,
    onCommentButtonClick: requiredIf(PropTypes.func, props => props.mode === CALIB_FILL_MODE),
    subCategory: PropTypes.bool,
    mode: PropTypes.string,
    result: PropTypes.object,
    itemScores: PropTypes.object,
    resultId: PropTypes.string,
    resultErrors: PropTypes.object,
    displayErrors: PropTypes.bool,
    itemWeights: PropTypes.object,
    useWeights: PropTypes.bool,
    highlightedItems: PropTypes.array,
    comments: PropTypes.object,
    itemExamples: PropTypes.object,
    selectedItems: PropTypes.arrayOf(PropTypes.string),
    displayPercentages: PropTypes.bool,
    allowedAnswers: PropTypes.object,
});

export default CatalogFillView;
