import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import Fab from '@mui/material/Fab';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemPure from '../ListItemPure';
import ListItemText from '@mui/material/ListItemText';
import TocIcon from '@mui/icons-material/Toc';

import {getParentCategories, getCategoryChildren} from "./CatalogItems";
import Tooltip from 'utils/tooltipWithPlacement';
import {withTranslation} from "react-i18next";

class CatalogToc extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
            categories: [],
        };
    }

    componentDidMount() {
        this.createCategoryList();
    }

    createCategoryList = () => {
        // only works for 1 level of category nesting, make it recursive? will we use it?
        let categories = [];
        let parentCategories = getParentCategories(this.props.items);

        for (let i = 0; i < parentCategories.length; i++) {
            let category = parentCategories[i];
            let subCategories = getCategoryChildren(category.path, this.props.items).filter(
                item => item.isCategory
            );
            categories.push(
                {
                    name: category.name,
                    color: category.color,
                    id: category.id,
                    subCategories: subCategories.map(sC => {
                        return {
                            name: sC.name,
                            id: sC.id,
                            color: sC.color
                        }
                    })
                }
            )
        }
        this.setState({categories: categories})
    };

    toggleOpen = (event) => {
        this.setState({
            open: !this.state.open,
            anchorEl: event ? event.currentTarget : null,
        });
    };

    onItemClick = (categoryId) => {
        this.props.scrollToCategory(categoryId, this.props.sheetIndex);
        this.toggleOpen();
    };

    render() {
        const {t} = this.props;
        return (
            <React.Fragment>
                <Tooltip title={t('tocToolTip')}>
                    <Fab
                        size={'small'}
                        style={{position: 'fixed', zIndex: 6, right: '5%'}}
                        onClick={this.toggleOpen}
                    >
                        <TocIcon/>
                    </Fab>
                </Tooltip>
                <Popover
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    transformOrigin={{vertical: 'top', horizontal: 'left'}}
                    onClose={this.toggleOpen}
                >
                    <Paper style={{padding: 15, textAlign: 'center'}}>
                        <h4 style={{marginTop: 0}}>{t('tocHeading')}</h4>
                        <Divider style={{margin: 0, padding: 0}}/>
                        <List style={{maxHeight: '300px', overflow: 'auto', padding: 0}}>
                            <ListItemPure
                                button
                                divider
                                onClick={this.onItemClick}
                                valueToPass={'__FILL_VIEW_TOP__'}
                            >
                                <ListItemText primary={t('goToTop')}/>
                            </ListItemPure>
                            {this.state.categories.map((category) => {
                                return (
                                    <React.Fragment key={category.id}>
                                        <ListItemPure
                                            button
                                            divider={category.subCategories.length === 0}
                                            key={category.id}
                                            style={{backgroundColor: category.color}}
                                            onClick={this.onItemClick}
                                            valueToPass={category.id}
                                        >
                                            <ListItemText primary={category.name}/>
                                        </ListItemPure>
                                        {category.subCategories.map((subCategory, index) => {
                                            return (
                                                <ListItemPure
                                                    button
                                                    divider={index === category.subCategories.length - 1}
                                                    style={{backgroundColor: subCategory.color}}
                                                    key={subCategory.id}
                                                    onClick={this.onItemClick}
                                                    valueToPass={subCategory.id}
                                                >
                                                    <ListItemText style={{paddingLeft: '24px'}}
                                                                  primary={subCategory.name}/>
                                                </ListItemPure>
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </List>
                    </Paper>
                </Popover>
            </React.Fragment>
        )

    }
}

CatalogToc.defaultProps = {
    sheetIndex: 0,
};

CatalogToc.propTypes = exact({
    scrollToCategory: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    sheetIndex: PropTypes.number,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    tReady: PropTypes.bool.isRequired,
});

export default withTranslation(['sheetManagement'])(CatalogToc);