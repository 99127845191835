export const initialState = {
    pricingInquirySuccess: null,
    pricingInquiryMessage: null,
    joinMailingListSuccess: null,
    projectChanged: false,
    user: null,
    fileUploadSuccess: false,
    users: [],
    associatedUsers: [],
    partners: [],
    sheetTypes: [],
    projects: [],
    roles: [],
    scorecardData: [],
    campaigns: ["Kampagne 1"],
    workbenchCatalogs: [],
    workbenchCatalogRevisions: [],
    workbenchCatalogRevision: null,
    currentWorkbenchCatalog: null,
    newResultId: null,
    sheetMetaData: [],
    resultMetaData: {
        start_date: null,
        end_date: null,
        active_results: [],
        closed_results: []
    },
    historicRawResults: {'2022-01':
            {'Begrüßung des Kunden': {'0': [150, '25%'], '+': [150, '25%'], '++': [300, '50%']},
            'Namentliche Ansprache': {'0': [100, '16.6%'], '+': [350, '50%'], '++': [150, '25%']},
            'Aktives Zuhören': {'0': [132, '10%'], '+': [140, '25%'], '++': [132, '10%']},
            'Worte persönlicher Anerkennung': {'0': [132, '10%'], '+': [140, '25%'], '++': [132, '10%']},
            'Situationsklärung': {'0': [132, '10%'], '+': [140, '25%'], '++': [132, '10%']},
            'Positives und kundenorientiertes Formulieren': {'0': [132, '10%'], '+': [140, '25%'], '++': [132, '10%']},
            'Argumentationstechniken': {'0': [132, '10%'], '+': [188, '25%'], '++': [132, '10%']}
            },
        '2022-02':
            {'Begrüßung des Kunden': {'0': [132, '10%'], '+': [140, '25%'], '++': [132, '10%']},
            'Namentliche Ansprache': {'0': [132, '10%'], '+': [140, '25%'], '++': [132, '10%']},
            'Aktives Zuhören': {'0': [132, '10%'], '+': [140, '25%'], '++': [132, '10%']},
            'Worte persönlicher Anerkennung': {'0': [132, '10%'], '+': [140, '25%'], '++': [132, '10%']},
            'Situationsklärung': {'0': [132, '10%'], '+': [140, '25%'], '++': [132, '10%']},
            'Positives und kundenorientiertes Formulieren': {'0': [132, '10%'], '+': [140, '25%'], '++': [132, '10%']},
            'Argumentationstechniken': {'0': [132, '10%'], '+': [266, '25%'], '++': [132, '10%']}
            }},
    rawResults: {},
    treasures: [
        {
            itemId: "1",
            type: "positive",
            example: "Das ist ein gutes Beispiel",
            partner: []
        },
        {
            itemId: "2",
            type: "negative",
            example: "... und ein schlechtes Beispiel",
            partner: []
        },
    ],
    resultMetaFilters: {
        searchString: "",
        resultCodes: [],
        fromDate: null,
        toDate: null,
        expandedActiveResults: {},
        expandedClosedResults: {},
        scrollY: 0
    },
    toggleBackground: false
};
