import React from 'react';
import browserHistory from '../../browserHistory';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import HomepageFooter from "./HomepageFooter";
import withStyles from '@mui/styles/withStyles';
import './homepage-styles.scss';
import {Typography, Link} from "@mui/material";
import HomepageHeader from "./HomepageHeader";
import {withTranslation} from "react-i18next";


const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'green',
        },
        '& label.Mui-error': {
            color: 'rgba(255,0,0,0.35)',
        },
        '& label.Mui-focused .Mui-error': {
            color: 'rgba(255,0,0,0.35)',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0px',
            '& fieldset': {
                borderColor: 'green',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green'
            },
            '&.Mui-error fieldset': {
                borderColor: 'red'
            },
            '&.Mui-error fieldset .Mui-focused': {
                borderColor: 'red'
            },
        },
    },
})(TextField);

const CssButton = withStyles({
    root: {
        backgroundColor: 'rgb(233,82,41)',
        color: "white",
        '&:disabled': {
            backgroundColor: 'rgba(233,82,41,0.39)',
            color: "white"
        },
        "&:hover": {
            backgroundColor: 'rgb(233,82,41)'
        }
    }
})(Button);


export class LoginView extends React.Component {
    constructor(props) {
        super(props);
        const redirectRoute = '/';
        this.state = {
            userName: '',
            password: '',
            redirectTo: redirectRoute,
        };
    }

    changeValue(e, type) {
        const value = e.target.value.trim();
        const next_state = {};
        next_state[type] = value;
        this.setState(next_state);
    };

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (!this.state.disabled) {
                this.login(e);
            }
        }
    };

    login = (e) => {
        e.preventDefault();
        this.props.loginUser(this.state.userName, this.state.password, this.state.redirectTo);
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <HomepageHeader logoOnly={true}/>
                <div className='homepage-container'>
                    <img src={require('../../images/2.jpg')}
                         alt="Tansparent background"
                         className='homepage-transparent-image'/>
                    <Paper className='homepage-paper'>
                        <form>
                            <div className="text-center" style={{textAlign: 'center'}}>
                                <h1><b>{t('welcomeToOctoscore')}</b></h1>
                                <hr style={{
                                    borderTop: "5px solid rgba(233, 82, 41, 1)",
                                    width: "20%",
                                    marginBottom: '50px'
                                }}/>
                                {!this.props.authenticated ?
                                    <div>
                                        <div className="col-md-12">
                                            <CssTextField
                                                autoComplete="off"
                                                variant="outlined"
                                                id="name"
                                                label={t('common:email')}
                                                placeholder={t('common:email')}
                                                style={{width: '80%'}}
                                                FormHelperTextProps={{style: {textAlign: 'center'}}}
                                                type="username"
                                                margin="normal"
                                                autoFocus={true}
                                                onChange={(e) => this.changeValue(e, 'userName')}
                                                onKeyPress={this._handleKeyPress}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <CssTextField
                                                id="password"
                                                variant="outlined"
                                                label={t('common:password')}
                                                placeholder={t('common:password')}
                                                style={{width: '80%', color: "green"}}
                                                FormHelperTextProps={{style: {textAlign: 'center'}}}
                                                type="password"
                                                margin="normal"
                                                onChange={(e) => this.changeValue(e, 'password')}
                                                onKeyPress={this._handleKeyPress}
                                            />
                                            {this.props.loginError &&
                                            <h4 style={{color: 'red'}}>
                                                {this.props.loginError}
                                            </h4>
                                            }
                                        </div>
                                        <Typography variant={'overline'}>
                                            <Link href={'/forgot-password'}>
                                                {t('passwordForgotten')}
                                            </Link>
                                        </Typography>
                                        <CssButton
                                            variant="contained"
                                            style={{
                                                margin: "24px 0px 16px",
                                                padding: "16px 24px",
                                                width: "80%",
                                                borderRadius: '0px'
                                            }}
                                            onClick={this.login}
                                        >
                                            {t('common:letsGo')}
                                        </CssButton>
                                    </div>
                                    :
                                    <React.Fragment>
                                        <div>
                                            <Button
                                                variant="contained"
                                                style={{marginTop: 50}}
                                                onClick={() => {
                                                    browserHistory.push("/")
                                                }}
                                            >
                                                {t('common:enter')}
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                variant="contained"
                                                style={{marginTop: 50}}
                                                onClick={this.props.logout}
                                            >
                                                {t('changeUser')}
                                            </Button>
                                        </div>

                                    </React.Fragment>

                                }
                                <Typography variant={'overline'}>
                                    {t('homepage:youAreNotRegisteredQuestion')} <Link href={'/register'}>{t('homepage:toRegistration')}</Link>
                                </Typography>
                            </div>
                        </form>
                    </Paper>

                </div>
                <HomepageFooter/>
            </div>
        );
    }
}

export default withTranslation(['homepage', 'common'])(LoginView);
