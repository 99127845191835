import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actionCreators from '../actions/data';
import * as authCreators from '../actions/auth';

import RegisterView from "../components/HomepageComponents/RegisterView";


function mapStateToProps(state) {
    return {
        registerError: state.auth.registerError,
        joinMailingListSuccess: state.data.joinMailingListSuccess,
        registrationEmail: state.auth.registrationEmail,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({...actionCreators, ...authCreators}, dispatch);
}

export class RegisterContainer extends React.Component {
    registerUser = (firstName, lastName, email, password) => {
        this.props.registerUser(firstName, lastName, email, password);
    };

    joinMailingList = (email) => {
        this.props.joinMailingList(email);
    };

    render() {
        return (
            <div>
                <RegisterView
                    registerUser={this.registerUser}
                    registerError={this.props.registerError}
                    joinMailingList={this.joinMailingList}
                    joinMailingListSuccess={this.props.joinMailingListSuccess}
                    registrationEmail={this.props.registrationEmail}
                />
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);