import React from 'react';
import {Tooltip} from "@mui/material";

const TooltipWithPlacement = (props) => {
    const {children, placement, ...otherProps} = props;
    return (
        <Tooltip {...otherProps} placement={placement || 'right'}>
            {children}
        </Tooltip>
    )
};
export default TooltipWithPlacement;
