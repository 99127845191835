let address = window.location.origin;
if (address.includes("3000")) {
    address = address.replace("3000", "5000")
}
export const ITEM_NAME_MAX_LENGTH = 200;
export const SOCKET_ADDRESS = address;
export const TERMS_AND_CONDITIONS_LINK = 'https://octoscore.de/wp-content/uploads/terms.pdf';
export const PRIVACY_POLICY_LINK = 'https://octoscore.de/wp-content/uploads/privacy_policy.pdf';

export const MAX_FILE_UPLOAD_SIZE = 100;
export const DAYS_BEFORE_USER_EMAIL_ACTIVATION_REQUIRED = 2;
let hours = [];
for (let i = 0; i < 24; i++){
    hours.push(`${i}:00`.padStart(5, '0'), `${i}:30`.padStart(5, '0'))
}
export const HOURS = hours;
export const MONTHS = {
    '1': 'Januar',
    '2': 'Februar',
    '3': 'März',
    '4': 'April',
    '5': 'Mai',
    '6': 'Juni',
    '7': 'Juli',
    '8': 'August',
    '9': 'September',
    '10': 'Oktober',
    '11': 'November',
    '12': 'Dezember'
};

export const WEEKDAYS = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
export const DATE_OPTIONS = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
};


export const PRICING_INQUIRY_SUCCESS = 'PRICING_INQUIRY_SUCCESS';
export const PRICING_INQUIRY_FAILURE = 'PRICING_INQUIRE_FAILURE';
export const JOIN_MAILING_LIST_SUCCESS = 'JOIN_MAILING_LIST_SUCCESS';
export const JOIN_MAILING_LIST_FAILURE = 'JOIN_MAILING_LIST_FAILURE';

export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';

export const RESET_DATA_REDUX_STATE = 'RESET_DATA_REDUX_STATE';
export const RESET_SOCKET_REDUX_STATE = 'RESET_SOCKET_REDUX_STATE';

export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGOUT_USER = 'LOGOUT_USER';

export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';

export const BULK_ADD_USERS = 'BULK_ADD_USERS';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const ASSIGN_USER_TO_PROJECT = 'ASSIGN_USER_TO_PROJECT';
export const REMOVE_USER_FROM_PROJECT = 'REMOVE_USER_FROM_PROJECT';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';

export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';
export const FETCH_PROTECTED_DATA_REQUEST = 'FETCH_PROTECTED_DATA_REQUEST';
export const RECEIVE_PROTECTED_DATA = 'RECEIVE_PROTECTED_DATA';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const RECEIVE_USERS_FAILURE = 'RECEIVE_USERS_FAILURE';
export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS';
export const RECEIVE_PROJECT_USERS = 'RECEIVE_PROJECT_USERS';
export const RECEIVE_ASSOCIATED_USERS = 'RECEIVE_ASSOCIATED_USERS';
export const RECEIVE_ROLES = 'RECEIVE_ROLES';
export const RECEIVE_CATALOGS = 'RECEIVE_CATALOGS';
export const PUT_WORKBENCH_CATALOG = 'PUT_WORKBENCH_CATALOG';
export const PUT_RESULT = 'PUT_RESULT';
export const PUT_TREASURE = 'PUT_TREASURE';
export const GET_SCORECARD_DATA = 'GET_SCORECARD_DATA';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CHANGE_PROJECT = 'CHANGE_PROJECT';
export const RENAME_PROJECT = 'RENAME_PROJECT';
export const MODIFY_PROJECT = 'MODIFY_PROJECT';
export const PUT_PROJECT = 'PUT_PROJECT';
export const GET_SHEET_TYPES = 'GET_SHEET_TYPES';
export const GET_SHEET_METADATA = 'GET_SHEET_METADATA';
export const PUT_SHEET_TYPE = 'PUT_SHEET_TYPE';
export const MODIFY_PARTNER = 'MODIFY_PARTNER';
export const RECEIVE_PARTNERS = 'RECEIVE_PARTNERS';
export const RECEIVE_PARTNER = 'RECEIVE_PARTNER';
export const DELETE_PARTNER = 'DELETE_PARTNER';
export const NEW_RESULT_CREATED = 'NEW_RESULT_CREATED';
export const SET_CURRENT_RESULT = 'SET_CURRENT_RESULT';
export const GET_RESULT_METADATA = 'GET_RESULT_METADATA';
export const SAVE_RESULT_META_FILTERS = 'SAVE_RESULT_META_FILTERS';
export const UPDATE_USER_PERSONAL_INFO = 'UPDATE_USER_PERSONAL_INFO';
export const CHANGE_PROJECT_RETENTION_DAYS = 'CHANGE_PROJECT_RETENTION_DAYS';
export const TOGGLE_PROJECT_ATTENDEE_EMAILS = 'TOGGLE_PROJECT_ATTENDEE_EMAILS';

export const ATTACH_FILE_TO_RESULT = 'ATTACH_FILE_TO_RESULT';
export const ATTACH_FILE_TO_CALIBRATION = 'SOCKET/CALIBRATION_FILE_ATTACHED';

export const EDIT_CATEGORIES = 'EDIT_CATEGORIES';
export const EDIT_ORDER = 'ITEM_ORDER';
export const EDIT_CATALOG_MODES = [EDIT_CATEGORIES, EDIT_ORDER];
export const PROD_FILL_CATALOG = 'FILL';
export const PROD_FILL_CATALOG_ITEM_ORDER = 'PROD_FILL_CATALOG_ITEM_ORDER';
export const PROD_PRESENT_CATALOG = 'CATALOG_PRESENTATION';
export const CALIB_OVERVIEW_MODE = 'Overview Mode';
export const CALIB_FILL_MODE = 'CALIB_FILL_MODE';
export const CALIB_FILL_MODE_ITEM_ORDER = 'CALIB_FILL_MODE_ITEM_ORDER';
export const CALIB_PRESENTATION_MODE = 'Presentation Mode';
export const PROD_CATALOG_MODES = [
    PROD_FILL_CATALOG, PROD_PRESENT_CATALOG, PROD_FILL_CATALOG_ITEM_ORDER, CALIB_FILL_MODE, CALIB_PRESENTATION_MODE,
    CALIB_FILL_MODE_ITEM_ORDER
];


export const HIGHLIGHT_COLOR = '#c8cdcf';
export const ERROR_COLOR = '#ff909b';

export const TEXT = 'TEXT';
export const TEXT_ITEM_MAX_LENGTH = 2000;
export const PRODUCT = 'Produkt';
export const RADIO_GROUP = 'RADIO_GROUP';
export const DROP_DOWN = 'DROP_DOWN';
export const SLIDER = 'SLIDER';
export const STANDARD = 'STANDARD';
export const STARS = 'STARS';

export const OPTION_ITEM = 'OPTIONS';
export const MULTISELECT = 'MULTISELECT';
export const DATE_ITEM = 'DATE';
export const INT_ITEM = 'INT';
export const OPTION_TYPES = [RADIO_GROUP, DROP_DOWN/*, SLIDER*/];
export const TOGGLE_BACKGROUND = 'TOGGLE_BACKGROUND';

// SOCKET ACTION TYPES //
export const SOCKET_REQUEST = '/REQUEST';
export const SOCKET_SUCCESS = '/SUCCESS';
export const SOCKET_FAILURE = '/FAILURE';
export const SOCKET_SUBSCRIBE = '/SUBSCRIBE';
export const SOCKET_UNSUBSCRIBE = '/UNSUBSCRIBE';

export const CONNECT_SOCKET = 'SOCKET/CONNECT';
export const DISCONNECT_SOCKET = 'SOCKET/DISCONNECT';

export const JOIN_ROOM = 'SOCKET/JOIN_ROOM';
export const LEAVE_ROOM = 'SOCKET/LEAVE_ROOM';
export const USER_EVENT = 'SOCKET/USER_EVENT';
export const TOKEN_EXPIRED = 'SOCKET/TOKEN_EXPIRED';
export const STATUS_UPDATE = 'SOCKET/STATUS_UPDATE';
export const FILE_DOWNLOAD = 'SOCKET/FILE_DOWNLOAD';
export const RESET_FILE_DOWNLOAD = 'RESET_FILE_DOWNLOAD';

export const GET_SHEETS = 'SOCKET/GET_SHEETS';
export const SET_CURRENT_SHEET = 'SOCKET/SET_CURRENT_SHEET';
export const CREATE_SHEET = 'SOCKET/CREATE_SHEET';
export const DELETE_SHEET = 'SOCKET/DELETE_SHEET';
export const COPY_SHEET = 'SOCKET/COPY_SHEET';
export const TOGGLE_SHEET_IN_PRODUCTION = 'SOCKET/TOGGLE_SHEET_IN_PRODUCTION';
export const CHANGE_SHEET_ORDER = 'SOCKET/CHANGE_SHEET_ORDER';
export const CHANGE_SHEET_NAME = 'SOCKET/CHANGE_SHEET_NAME';
export const CHANGE_SHEET_DESCRIPTION = 'SOCKET/CHANGE_SHEET_DESCRIPTION';
export const CHANGE_SHEET_COLOR = 'SOCKET/CHANGE_SHEET_COLOR';
export const ADD_SHEET_ITEM = 'SOCKET/ADD_SHEET_ITEM';
export const ADD_SHEET_ITEMS = 'SOCKET/ADD_SHEET_ITEMS';
export const MODIFY_SHEET_ITEM = 'SOCKET/MODIFY_SHEET_ITEM';
export const DELETE_SHEET_ITEM = 'SOCKET/DELETE_SHEET_ITEM';
export const CHANGE_SHEET_ITEM_OPTION = 'SOCKET/CHANGE_SHEET_ITEM_OPTION';
export const CHANGE_SHEET_ITEM_CATEGORY = 'SOCKET/CHANGE_SHEET_ITEM_CATEGORY';
export const CHANGE_CATEGORY_ORDER = 'SOCKET/CHANGE_CATEGORY_ORDER';
export const CHANGE_ITEM_ORDER = 'SOCKET/CHANGE_ITEM_ORDER';
export const ADD_OPTION_GROUP = 'SOCKET/ADD_OPTION_GROUP';
export const DELETE_OPTION_GROUP = 'SOCKET/DELETE_OPTION_GROUP';
export const ADD_OPTION_VALUES = 'SOCKET/ADD_OPTION_VALUES';
export const DELETE_OPTION_VALUE = 'SOCKET/DELETE_OPTION_VALUE';
export const CHANGE_OPTION_VALUE_NAME = 'SOCKET/CHANGE_OPTION_VALUE_NAME';
export const CHANGE_OPTION_TYPE = 'SOCKET/CHANGE_OPTION_PRESENTATION';
export const ADD_RESULT_CODE = 'SOCKET/ADD_RESULT_CODE';
export const DELETE_RESULT_CODE = 'SOCKET/DELETE_RESULT_CODE';
export const MODIFY_RESULT_CODE = 'SOCKET/MODIFY_RESULT_CODE';
export const CHANGE_SHEET_SHOW_ATTENDEES = 'SOCKET/CHANGE_SHEET_SHOW_ATTENDEES';
export const CHANGE_SHEET_UPDATE_DATE_ON_COMPLETION = 'SOCKET/CHANGE_SHEET_UPDATE_DATE_ON_COMPLETION';
export const LOCK_SHEET = 'SOCKET/LOCK_SHEET';
export const EXPORT_SHEET_AS_EXCEL = 'SOCKET/EXPORT_SHEET_AS_EXCEL';
export const GET_OPTION_DEPENDENCIES = 'SOCKET/GET_OPTION_DEPENDENCIES';
export const ADD_OPTION_DEPENDENCY = 'SOCKET/ADD_OPTION_DEPENDENCY';
export const DELETE_OPTION_DEPENDENCY = 'SOCKET/DELETE_OPTION_DEPENDENCY';
export const SHEET_ACTIONS = [
    CHANGE_SHEET_NAME, CHANGE_SHEET_DESCRIPTION, CHANGE_SHEET_COLOR, ADD_SHEET_ITEM, ADD_SHEET_ITEMS, MODIFY_SHEET_ITEM,
    DELETE_SHEET_ITEM, CHANGE_SHEET_ITEM_OPTION, CHANGE_SHEET_ITEM_CATEGORY, CHANGE_CATEGORY_ORDER,
    CHANGE_ITEM_ORDER, CHANGE_ITEM_ORDER, ADD_OPTION_GROUP, DELETE_OPTION_GROUP, ADD_OPTION_VALUES,
    DELETE_OPTION_VALUE, CHANGE_OPTION_VALUE_NAME, CHANGE_OPTION_TYPE, ADD_RESULT_CODE,
    DELETE_RESULT_CODE, MODIFY_RESULT_CODE, LOCK_SHEET, EXPORT_SHEET_AS_EXCEL, ADD_OPTION_DEPENDENCY,
    DELETE_OPTION_DEPENDENCY, CHANGE_SHEET_SHOW_ATTENDEES, CHANGE_SHEET_UPDATE_DATE_ON_COMPLETION
];

export const RESULT_UPDATE = 'SOCKET/RESULT_UPDATE';
export const UPDATE_ITEM_RESULT = 'SOCKET/UPDATE_ITEM_RESULT';
export const UPDATE_ITEM_SCORES = 'SOCKET/UPDATE_ITEM_SCORES';
export const SET_ALLOWED_ANSWERS = 'SOCKET/SET_ALLOWED_ANSWERS';
export const UPDATE_ALLOWED_ANSWERS = 'SOCKET/UPDATE_ALLOWED_ANSWERS';
export const DELETE_RESULT_FILE = 'SOCKET/DELETE_RESULT_FILE';
export const CHANGE_RESULT_ATTENDEES = 'SOCKET/CHANGE_RESULT_ATTENDEES';
export const ANSWER_ITEM = 'SOCKET/ANSWER_ITEM';
export const REMOVE_ANSWER = 'SOCKET/REMOVE_ANSWER';
export const DELETE_RESULT = 'SOCKET/DELETE_RESULT';
export const START_CALIBRATION_FROM_RESULT = 'SOCKET/START_CALIBRATION_FROM_RESULT';
export const FETCH_RESULT_SCORES = 'SOCKET/FETCH_RESULT_SCORES';
export const FETCH_ALLOWED_ANSWERS = 'SOCKET/FETCH_ALLOWED_ANSWERS';
export const CHANGE_RESULT_CREATOR = 'SOCKET/CHANGE_RESULT_CREATOR';
export const CHANGE_RESULT_DATE = 'SOCKET/CHANGE_RESULT_DATE';
export const SET_RESULT_CODE = 'SOCKET/SET_RESULT_CODE';
export const TOGGLE_SHEET_PUBLIC = 'SOCKET/TOGGLE_RESULT_PUBLIC';

export const GET_CALC_TEMPLATES = 'SOCKET/GET_CALC_TEMPLATES';
export const GET_DEFAULT_CALC_TEMPLATE = 'SOCKET/GET_DEFAULT_CALC_TEMPLATE';
export const SET_CURRENT_CALC_TEMPLATE = 'SET_CURRENT_CALC_TEMPLATE';
export const RESET_CURRENT_CALC_TEMPLATE = 'RESET_CURRENT_CALC_TEMPLATE';
export const SET_DEFAULT_CALC_TEMPLATE = 'SOCKET/SET_DEFAULT_CALC_TEMPLATE';
export const CREATE_CALC_TEMPLATE = 'SOCKET/CREATE_CALC_TEMPLATE';
export const COPY_CALC_TEMPLATE = 'SOCKET/COPY_CALC_TEMPLATE';
export const DELETE_CALC_TEMPLATE = 'SOCKET/DELETE_CALC_TEMPLATE';
export const LOCK_CALC_TEMPLATE = 'SOCKET/LOCK_CALC_TEMPLATE';
export const WEIGHTS_UPDATED = 'SOCKET/WEIGHTS_UPDATED';


export const CALC_TEMPLATE_META_ACTIONS = [
    SET_DEFAULT_CALC_TEMPLATE, CREATE_CALC_TEMPLATE, COPY_CALC_TEMPLATE,
    DELETE_CALC_TEMPLATE, LOCK_CALC_TEMPLATE, WEIGHTS_UPDATED,
];
export const SET_OPTION_WEIGHT = 'SOCKET/SET_OPTION_WEIGHT';
export const SET_ITEM_WEIGHT = 'SOCKET/SET_ITEM_WEIGHT';
export const CHANGE_CALC_TEMPLATE_PERCENTAGES = 'SOCKET/CHANGE_CALC_TEMPLATE_PERCENTAGES';
export const CHANGE_CALC_TEMPLATE_USE_WEIGHTS = 'SOCKET/CHANGE_CALC_TEMPLATE_USE_WEIGHTS';
export const CHANGE_NOT_ASSESSABLE_REDUCE_WEIGHTS = 'SOCKET/CHANGE_NOT_ASSESSABLE_REDUCE_WEIGHTS'
export const CHANGE_ITEM_ACTIVE = 'SOCKET/CHANGE_ITEM_ACTIVE';
export const CHANGE_OPTION_ACTIVE = 'SOCKET/CHANGE_OPTION_ACTIVE';
export const CHANGE_OPTION_CATEGORY_NULL = 'SOCKET/CHANGE_OPTION_CATEGORY_NULL';
export const CHANGE_OPTION_SHEET_NULL = 'SOCKET/CHANGE_OPTION_SHEET_NULL';
export const SET_SCORE_DISPLAY = 'SOCKET/SET_SCORE_DISPLAY';

export const CALC_TEMPLATE_EDIT_ACTIONS = [
    SET_OPTION_WEIGHT, SET_ITEM_WEIGHT, CHANGE_CALC_TEMPLATE_PERCENTAGES,
    CHANGE_CALC_TEMPLATE_USE_WEIGHTS, CHANGE_ITEM_ACTIVE, CHANGE_OPTION_ACTIVE, SET_SCORE_DISPLAY,
    CHANGE_OPTION_CATEGORY_NULL, CHANGE_OPTION_SHEET_NULL, CHANGE_NOT_ASSESSABLE_REDUCE_WEIGHTS
];

export const BULK_EDIT_SCORE_UPDATE = 'SOCKET/BULK_EDIT_SCORE_UPDATE';

export const FETCH_REPORTABLE_SHEET_METADATA = 'SOCKET/FETCH_REPORTABLE_SHEET_METADATA';
export const FETCH_RESULTS_FOR_SHEET = 'SOCKET/FETCH_RESULTS_FOR_SHEET';

export const FETCH_REPORT_METADATA = 'SOCKET/FETCH_REPORT_METADATA';
export const FETCH_REPORT = 'SOCKET/FETCH_REPORT';
export const CREATE_REPORT_TEMPLATE = 'SOCKET/CREATE_REPORT_TEMPLATE';
export const ADD_REPORT_ITEM = 'SOCKET/ADD_REPORT_ITEM';
export const DELETE_REPORT_ITEM = 'SOCKET/DELETE_REPORT_ITEM';
export const MOVE_REPORT_ITEM = 'SOCKET/MOVE_REPORT_ITEM';

export const REPORT_TEMPLATE_EDIT_ACTIONS = [
    ADD_REPORT_ITEM, DELETE_REPORT_ITEM, MOVE_REPORT_ITEM
];

export const FETCH_CALIBRATION_METADATA = 'SOCKET/FETCH_CALIBRATION_METADATA';
export const FETCH_CALIBRATION = 'SOCKET/FETCH_CALIBRATION';
export const CREATE_NEW_CALIBRATION = 'SOCKET/CREATE_NEW_CALIBRATION';
export const GET_CALIBRATION_RESULT = 'SOCKET/GET_CALIBRATION_RESULT';
export const GET_CALIBRATION_RESULTS = 'SOCKET/GET_CALIBRATION_RESULTS';
export const FETCH_CALIBRATION_TABLE_DATA = 'SOCKET/FETCH_CALIBRATION_TABLE_DATA';
export const SET_CURRENT_CALIBRATION = 'SOCKET/SET_CURRENT_CALIBRATION';
export const ADD_USERS_TO_CALIBRATION = 'SOCKET/ADD_USERS_TO_CALIBRATION';
export const REMOVE_USER_FROM_CALIBRATION = 'SOCKET/REMOVE_USER_FROM_CALIBRATION';
export const CHANGE_CALIBRATION_NAME = 'SOCKET/CHANGE_CALIBRATION_NAME';
export const CHANGE_CALIBRATION_DESCRIPTION = 'SOCKET/CHANGE_CALIBRATION_DESCRIPTION';
export const SET_CALIBRATION_RESULT_CODE = 'SOCKET/SET_CALIBRATION_RESULT_CODE';
export const SET_CALIBRATION_DEADLINE_DATE = 'SOCKET/SET_CALIBRATION_DEADLINE_DATE';
export const SET_CALIBRATION_RESULT_TO_DONE = 'SOCKET/CALIBRATION_RESULT_DONE';
export const CLOSE_CALIBRATION = 'SOCKET/CLOSE_CALIBRATION';
export const SAVE_CALIBRATION_COMMENT = 'SOCKET/SAVE_CALIBRATION_COMMENT';
export const DELETE_CALIBRATION_FILE = 'SOCKET/DELETE_CALIBRATION_FILE';

export const CALIBRATION_ACTIONS = [
    ADD_USERS_TO_CALIBRATION, REMOVE_USER_FROM_CALIBRATION, CHANGE_CALIBRATION_NAME, CHANGE_CALIBRATION_DESCRIPTION,
    SET_CALIBRATION_RESULT_TO_DONE, CLOSE_CALIBRATION, SET_CALIBRATION_RESULT_CODE, SET_CALIBRATION_DEADLINE_DATE,
    ATTACH_FILE_TO_CALIBRATION, DELETE_CALIBRATION_FILE
];


// specific action keys are not really needed for export, will they one day?
export const EXPORT_RAW_REPORT_AS_EXCEL = 'SOCKET/EXPORT_RAW_REPORT_AS_EXCEL';
export const EXPORT_RESULT_COLUMNS_REPORT_AS_EXCEL = 'SOCKET/EXPORT_RESULT_COLUMNS_REPORT_AS_EXCEL';
export const EXPORT_LOCATION_REPORT_AS_EXCEL = 'SOCKET/EXPORT_LOCATION_REPORT_AS_EXCEL';
export const EXPORT_TEXT_REPORT_AS_EXCEL = 'SOCKET/EXPORT_TEXT_REPORT_AS_EXCEL';
export const EXPORT_REPORT_AS_EXCEL = 'SOCKET/EXPORT_REPORT_AS_EXCEL';
export const EXPORT_REPORT_AS_PDF = 'SOCKET/EXPORT_REPORT_AS_PDF';
export const EXPORT_REPORT_AS_POWERPOINT = 'SOCKET/EXPORT_REPORT_AS_POWERPOINT';
export const SET_SELECTED_SHEETS = 'SET_SELECTED_SHEETS';
export const SET_SELECTED_REPORT_TEMPLATE = 'SET_SELECTED_REPORT_TEMPLATE';

export const GET_CATEGORY_SCORE_PROGRESSION = 'SOCKET/GET_CATEGORY_SCORE_PROGRESSION';
export const GET_SHEET_RESULT_REPORT_DATA = 'SOCKET/GET_SHEET_RESULT_REPORT_DATA';
export const GET_REPORT_TEMPLATE_REPORT_DATA = 'SOCKET/GET_REPORT_TEMPLATE_GRAPH_DATA';

export const GET_USER_METRICS = 'SOCKET/GET_USER_METRICS';
export const GET_TABLE_DATA_FOR_ITEM = 'SOCKET/GET_TABLE_DATA_FOR_ITEM';
export const RESET_ITEM_TABLE_DATA = 'RESET_ITEM_TABLE_DATA';

export const GET_SHEET_IMPORT_COMPATIBILITY = 'SOCKET/GET_SHEET_IMPORT_COMPATIBILITY';
export const CONFIRM_IMPORT_EXCEL_RESULTS = 'SOCKET/CONFIRM_IMPORT_EXCEL_RESULTS';

export const SCORE_DISPLAY_MAPPING = { 'PERCENT_WEIGHT': 'Prozentanteil', 'WEIGHT_PRODUCT': 'Weight product',
    'SCORE': 'Score'};

export const CREATE_EXPORT_MARKER = 'SOCKET/CREATE_EXPORT_MARKER';
export const GET_EXPORT_MARKER_METADATA = 'SOCKET/GET_EXPORT_MARKER_METADATA';
export const GET_EXPORT_MARKER_RESULTS = 'SOCKET/GET_EXPORT_MARKER_RESULTS';
export const DOWNLOAD_EXPORT_MARKER_DATA = 'SOCKET/DOWNLOAD_EXPORT_MARKER_DATA';

export const SET_ITEM_EXAMPLE = 'SOCKET/SET_ITEM_EXAMPLE';
export const TOGGLE_ITEM_EXAMPLES_REPORTABLE = 'SOCKET/TOGGLE_ITEM_EXAMPLES_REPORTABLE';
export const DELETE_ITEM_EXAMPLES = 'SOCKET/DELETE_ITEM_EXAMPLES';
export const GET_ITEM_EXAMPLES = 'SOCKET/GET_ITEM_EXAMPLES';

export const RESET_RESULT_REPORTS = 'RESET_RESULT_REPORTS';

export const RESET_CURRENT_CALIBRATION = 'RESET_CURRENT_CALIBRATION';
export const FETCHING_REPORTS_DATA = 'FETCHING_REPORTS_DATA';
export const SET_DISCONNECTED = 'SET_DISCONNECTED';
export const GET_HISTORIC_RAW_RESULTS = 'GET_HISTORIC_RAW_RESULTS';
export const GET_RAW_RESULTS = 'GET_RAW_RESULTS';
export const SET_ITEM_PRESENTATION_MAPPER = 'SET_ITEM_PRESENTATION_MAPPER';
export const CHANGE_ITEM_PRESENTATION_TYPE = 'CHANGE_ITEM_PRESENTATION_TYPE';
export const CHANGE_SHEET_ITEM_INT_MINIMUM_VALUE = 'CHANGE_SHEET_ITEM_INT_MINIMUM_VALUE';
export const CHANGE_SHEET_ITEM_INT_MAXIMUM_VALUE = 'CHANGE_SHEET_ITEM_INT_MAXIMUM_VALUE';
