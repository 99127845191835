import React, {Component} from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import browserHistory from '../../browserHistory';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {withTranslation} from "react-i18next";
import ListItemPure from '../ListItemPure';
import {changeColorBrightness, sortObjectArrayByName} from "../../utils/misc";
import appPaths from '../../appPaths';
import UserPopover from "./UserPopover";
import LanguageContext from "../../contexts/LanguageContext";
import DropDown from "../DropDown";

const headerStyle = {
    position: 'fixed',
    width: '100%',
    top: 0,
    elevation: 10,
    zIndex: 10
};

const drawerStyle = {docked: true};

class Header extends Component {

    static contextType = LanguageContext

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            open: false,
            userPopoverOpen: false,
            anchor: null,
        };
    }

    dispatchNewRoute = (route) => {
        browserHistory.push(route);
        this.setState({
            open: false,
        });
    };

    logout = () => {
        this.props.onLogout();
        this.setState({
            open: false,
        });
    };

    closeDrawer = () => {
        this.setState({
            open: false
        });
    };

    openUserPopover = (event) => {
        this.setState({
            userPopoverOpen: true,
            anchor: event.currentTarget,
        });
    };

    closeUserPopover = () => {
        this.setState({
            userPopoverOpen: false,
            anchor: null
        });
    };

    onChangeProject = (projectId) => {
        this.props.onChangeProject(projectId);
    };

    render() {

        const project = this.props.project;
        const {t} = this.props;
        const {currentLanguage, languages, changeLanguage} = this.context;
        return (
            <header id="header" style={headerStyle}>
                <Drawer open={this.state.open} style={drawerStyle} onClose={this.closeDrawer}>
                    {this.props.user &&
                    appPaths.map((path) => {
                        if (path.privilege > this.props.userPrivilege) {
                            return null
                        }
                        let tName = t(path.name)
                        return (
                            <ListItemPure
                                button
                                type="MenuItem"
                                key={path.name}
                                onClick={this.dispatchNewRoute}
                                valueToPass={path.link}
                            >
                                {tName}
                            </ListItemPure>
                        )
                    })
                    }
                    <ListItemPure
                        button
                        emitEvent
                        type="MenuItem"
                        onClick={this.logout}
                    >
                        {t('logOut')}
                    </ListItemPure>
                </Drawer>
                <AppBar
                    position="static"
                    color={'primary'}
                >
                    <Toolbar variant={'dense'} style={{paddingLeft: 0}}>
                        <IconButton
                            className={this.props.menuButton}
                            style={{
                                color: project ? project.ui_header_color : '#FFF'
                            }}
                            aria-label="Menu"
                            onClick={() => this.setState({open: true})}
                            size="large">
                            <MenuIcon/>
                        </IconButton>
                        <Typography
                            variant="button"
                            style={{
                                color: project ? project.ui_header_color : '#FFF',
                                marginRight: '5px'
                            }}
                        >
                            {t('projectManagement:myCurrentProject')}:
                        </Typography>
                        { this.props.projects &&
                            <DropDown
                                color={project ? project.ui_header_color : '#fff'}
                                disableUnderline={true}
                                value={Object.keys(this.props.project).length === 0 ? "" : this.props.project}
                                values={sortObjectArrayByName(this.props.projects, 'name')}
                                idKey={'project_id'}
                                valueKey={'project_id'}
                                labelKey={'name'}
                                onChange={this.onChangeProject}
                            />
                        }
                        {this.props.user &&
                        <React.Fragment>
                            <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                                <DropDown
                                    color={project && project.ui_header_color ? project.ui_header_color : '#fff'}
                                    selectStyle={{marginTop: '5px'}}
                                    disableUnderline={true}
                                    values={languages ? languages : []}
                                    value={currentLanguage ? currentLanguage : ""}
                                    onChange={changeLanguage}
                                />
                                <Avatar
                                    onClick={this.openUserPopover}
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: this.props.user.color,
                                        border: `4px solid ${changeColorBrightness(this.props.user.color, -30)}`
                                    }}
                                >
                                    {this.props.user.name[0]}
                                </Avatar>
                            </div>

                            <UserPopover
                                open={this.state.userPopoverOpen}
                                anchor={this.state.anchor}
                                onClose={this.closeUserPopover}
                                user={this.props.user}
                                onChangeProject={this.onChangeProject}
                                currentProject={project}
                                onLogoutClick={this.logout}
                            />
                        </React.Fragment>
                        }

                        <Button
                            style={{
                                color: project ? project.ui_header_color : '#FFF'
                            }}
                            onClick={() => this.dispatchNewRoute('/')}
                        >
                            Home
                        </Button>
                    </Toolbar>
                </AppBar>
            </header>
        );
    }
}

Header.defaultProps = {
    project: {},
    user: {},
    userPrivilege: 0,
    projects: []
};

Header.propTypes = exact({
    project: PropTypes.object,
    projects: PropTypes.array,
    user: PropTypes.object,
    userPrivilege: PropTypes.number,
    onChangeProject: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    tReady: PropTypes.bool.isRequired,
});
Header.contextType = LanguageContext;
export default withTranslation(['appRoutes', 'projectManagement', 'common'])(Header);
