import {createReducer} from '../utils/misc';
import {
    RESET_SOCKET_REDUX_STATE,
    JOIN_ROOM,
    LEAVE_ROOM,
    STATUS_UPDATE,
    USER_EVENT,
    SET_CURRENT_SHEET,
    SET_CURRENT_RESULT,
    UPDATE_ITEM_RESULT,
    CHANGE_SHEET_NAME,
    CHANGE_SHEET_DESCRIPTION,
    ADD_SHEET_ITEM,
    ADD_SHEET_ITEMS,
    MODIFY_SHEET_ITEM,
    DELETE_SHEET_ITEM,
    CHANGE_SHEET_ITEM_OPTION,
    CHANGE_SHEET_ITEM_CATEGORY,
    CHANGE_CATEGORY_ORDER,
    CHANGE_ITEM_ORDER,
    ADD_OPTION_GROUP,
    DELETE_OPTION_GROUP,
    ADD_OPTION_VALUES,
    DELETE_OPTION_VALUE,
    CHANGE_OPTION_VALUE_NAME,
    CHANGE_OPTION_TYPE,
    ADD_RESULT_CODE,
    DELETE_RESULT_CODE,
    MODIFY_RESULT_CODE,
    LOCK_SHEET,
    SET_RESULT_CODE,
    SET_CURRENT_CALC_TEMPLATE,
    SET_DEFAULT_CALC_TEMPLATE,
    GET_CALC_TEMPLATES,
    CREATE_CALC_TEMPLATE,
    DELETE_CALC_TEMPLATE,
    SET_ITEM_WEIGHT,
    CHANGE_CALC_TEMPLATE_PERCENTAGES,
    SET_OPTION_WEIGHT,
    CHANGE_CALC_TEMPLATE_USE_WEIGHTS,
    CHANGE_ITEM_ACTIVE,
    CHANGE_OPTION_ACTIVE,
    GET_DEFAULT_CALC_TEMPLATE,
    LOCK_CALC_TEMPLATE,
    UPDATE_ITEM_SCORES,
    GET_SHEETS,
    FETCH_REPORT_METADATA,
    CREATE_REPORT_TEMPLATE,
    FETCH_REPORT,
    ADD_REPORT_ITEM,
    DELETE_REPORT_ITEM,
    MOVE_REPORT_ITEM,
    SET_CURRENT_CALIBRATION,
    FETCH_CALIBRATION_METADATA,
    GET_CALIBRATION_RESULTS,
    FETCH_REPORTABLE_SHEET_METADATA,
    CHANGE_SHEET_COLOR,
    GET_USER_METRICS,
    GET_SHEET_IMPORT_COMPATIBILITY,
    SET_SELECTED_SHEETS,
    SET_SELECTED_REPORT_TEMPLATE,
    FETCH_RESULTS_FOR_SHEET,
    FILE_DOWNLOAD,
    SET_SCORE_DISPLAY,
    GET_SHEET_RESULT_REPORT_DATA,
    GET_EXPORT_MARKER_METADATA,
    GET_EXPORT_MARKER_RESULTS,
    BULK_EDIT_SCORE_UPDATE,
    GET_TABLE_DATA_FOR_ITEM,
    SAVE_CALIBRATION_COMMENT,
    RESET_ITEM_TABLE_DATA,
    SET_ITEM_EXAMPLE,
    GET_ITEM_EXAMPLES,
    DELETE_ITEM_EXAMPLES,
    TOGGLE_ITEM_EXAMPLES_REPORTABLE,
    FETCH_CALIBRATION_TABLE_DATA,
    GET_CATEGORY_SCORE_PROGRESSION,
    RESET_RESULT_REPORTS,
    RESET_FILE_DOWNLOAD,
    RESET_CURRENT_CALIBRATION,
    SET_CALIBRATION_RESULT_TO_DONE,
    FETCHING_REPORTS_DATA,
    CHANGE_RESULT_DATE,
    TOGGLE_SHEET_PUBLIC,
    ADD_OPTION_DEPENDENCY,
    DELETE_OPTION_DEPENDENCY,
    UPDATE_ALLOWED_ANSWERS,
    CHANGE_OPTION_CATEGORY_NULL,
    CHANGE_OPTION_SHEET_NULL,
    GET_OPTION_DEPENDENCIES,
    SET_ALLOWED_ANSWERS,
    ATTACH_FILE_TO_RESULT,
    DELETE_RESULT_FILE,
    ATTACH_FILE_TO_CALIBRATION,
    DELETE_CALIBRATION_FILE,
    CHANGE_SHEET_SHOW_ATTENDEES,
    CHANGE_SHEET_UPDATE_DATE_ON_COMPLETION,
    CHANGE_RESULT_ATTENDEES,
    SET_DISCONNECTED, CHANGE_NOT_ASSESSABLE_REDUCE_WEIGHTS, SET_ITEM_PRESENTATION_MAPPER, CHANGE_ITEM_PRESENTATION_TYPE
} from "../constants";
import {getCategoryChildren} from "../components/CatalogComponents/CatalogItems";

const initialState = {
    projectChangeRequired: null,
    status: {type: 'info', message: ''},
    activeUsers: [],
    currentResult: {item_examples: {}},
    currentResultScores: {},
    currentResultAllowedAnswers: {},
    sheet: null,
    selectedSheets: [],
    calcTemplates: [],
    currentCalcTemplate: null,
    room: null,
    roomOptions: {},
    reportMetaData: [],
    reportsLoading: false,
    currentReport: {},
    calibrationMetaData: [],
    currentCalibration: {},
    calibrationTableData: null,
    reportableSheetMetaData: {
        start_date: null,
        end_date: null,
        sheets: []
    },
    resultReportDates: {
        start_date: null,
        end_date: null,
    },
    sheetResultAmounts: {},
    sheetScores: {
        overall_score: 0,
        scores: []
    },
    sheetScoresByMonth: {
        start_date: null,
        end_date: null,
        scores: []
    },
    userMetrics: [],
    userItemTableData: null,
    resultTableData: {
        columns: {},
        rows: []
    },
    sheetKeyItemsTableData: null,
    sheetCrossSellingTableData: null,
    sheetResults: {
        results: []
    },
    filedownload: {
        done: false
    },
    exportMarkerMetadata: {
        latest_marker: null,
        unmarked_results: 0,
        export_markers: []
    },
    exportMarkerResults: {
        results: [],
        sheet_items: [],
        item_id_assoc: {}
    },
    itemExamples: [],
    optionDependencies: {},
    disconnected: false,
    percentages: true,
    itemPresentationMapper: {}
};

export default createReducer(initialState, {
    [RESET_SOCKET_REDUX_STATE]: () => {
        return {...initialState}
    },
    // GENERAL SOCKET REDUCERS //
    [JOIN_ROOM]: (state, payload) => {
        return {...state, room: payload.room_id, roomOptions: payload.options};
    },
    [LEAVE_ROOM]: (state, payload) => {
        return {...state, room: null, roomOptions: {}};
    },
    [STATUS_UPDATE]: (state, payload) => {
        if (payload.message === "Online!") {
            return {...state, disconnected: false}
        } else {
            return {...state, status: payload}
        }
    },
    [FILE_DOWNLOAD]: (state, success) => {
        return {...state, filedownload: {done: success}}
    },
    [RESET_ITEM_TABLE_DATA]: (state) => {
        return {...state, userItemTableData: null}
    },
    [USER_EVENT]: (state, payload) => {
        return {...state, activeUsers: payload.activeUsers}
    },

    // SHEET ACTION REDUCERS //
    [GET_SHEETS]: (state, payload) => {
        return {...state, selectedSheets: payload}
    },
    [SET_CURRENT_SHEET]: (state, payload) => {
        return {...state, sheet: payload}
    },
    [LOCK_SHEET]: (state, sheetLocked) => {
        return {...state, sheet: {...state.sheet, locked: sheetLocked}}
    },
    [CHANGE_SHEET_NAME]: (state, newName) => {
        return {...state, sheet: {...state.sheet, name: newName}}
    },
    [CHANGE_SHEET_DESCRIPTION]: (state, newDescription) => {
        return {...state, sheet: {...state.sheet, description: newDescription}}
    },
    [CHANGE_SHEET_COLOR]: (state, newColor) => {
        return {...state, sheet: {...state.sheet, color: newColor}}
    },
    [ADD_SHEET_ITEM]: (state, newItemObject) => {
        return {...state, sheet: {...state.sheet, items: [...state.sheet.items, newItemObject]}}
    },
    [ADD_SHEET_ITEMS]: (state, newItems) => {
        return {...state, sheet: {...state.sheet, items: [...state.sheet.items, ...newItems]}}
    },
    [MODIFY_SHEET_ITEM]: (state, newItemObject) => {
        let tempItems = Object.assign([], state.sheet.items);
        tempItems = tempItems.filter(item => item.id !== newItemObject.id);
        tempItems.push(newItemObject);
        return {...state, sheet: {...state.sheet, items: tempItems}}
    },
    [DELETE_SHEET_ITEM]: (state, deletedItemId) => {
        let tempItems = Object.assign([], state.sheet.items);
        let itemsToDelete = [deletedItemId];
        let deletedItem = tempItems.find(item => item.id === deletedItemId);
        if (deletedItem.isCategory) {
            getCategoryChildren(deletedItem.path, tempItems).forEach(item => {
                itemsToDelete.push(item.id)
            });
        }
        tempItems = tempItems.filter(item => !itemsToDelete.includes(item.id));
        return {...state, sheet: {...state.sheet, items: tempItems}}
    },
    [CHANGE_SHEET_ITEM_OPTION]: (state, newItemOption) => {
        let tempItems = Object.assign([], state.sheet.items);
        let item = tempItems.find(item => item.id === newItemOption.item_id);
        item.option = newItemOption.option_group_id;
        return {...state, sheet: {...state.sheet, items: tempItems}}
    },
    [CHANGE_SHEET_ITEM_CATEGORY]: (state, newItems) => {
        return {...state, sheet: {...state.sheet, items: newItems}}
    },
    [CHANGE_CATEGORY_ORDER]: (state, newItems) => {
        return {...state, sheet: {...state.sheet, items: newItems}}
    },
    [CHANGE_ITEM_ORDER]: (state, newItems) => {
        return {...state, sheet: {...state.sheet, items: newItems}}
    },
    [ADD_OPTION_GROUP]: (state, newOptionGroup) => {
        return {...state, sheet: {...state.sheet, options: [...state.sheet.options, newOptionGroup]}}
    },
    [DELETE_OPTION_GROUP]: (state, deletedOptionGroupId) => {
        let tempOptions = Object.assign([], state.sheet.options);
        tempOptions = tempOptions.filter(option => option.option_group_id !== deletedOptionGroupId);
        return {...state, sheet: {...state.sheet, options: tempOptions}}
    },
    [ADD_OPTION_VALUES]: (state, optionGroup) => {
        let tempOptions = Object.assign([], state.sheet.options);
        let foundOptionGroup = tempOptions.find(option => option.option_group_id === optionGroup.option_group_id);
        foundOptionGroup.options = optionGroup.options;
        return {...state, sheet: {...state.sheet, options: tempOptions}}
    },
    [DELETE_OPTION_VALUE]: (state, deletedOptionValue) => {
        let tempOptions = Object.assign([], state.sheet.options);
        let optionGroup = tempOptions.find(option => option.option_group_id === deletedOptionValue.option_group_id);
        optionGroup.options = optionGroup.options.filter(
            optionValue => optionValue.option_id !== deletedOptionValue.deleted_option_value_id
        );
        return {...state, sheet: {...state.sheet, options: tempOptions}}
    },
    [CHANGE_OPTION_VALUE_NAME]: (state, optionValueData) => {
        let tempOptions = Object.assign([], state.sheet.options);
        let optionGroup = tempOptions.find(option => option.option_group_id === optionValueData.option_group_id);
        optionGroup.options.find(
            optionValue => optionValue.option_id === optionValueData.option_value_id
        ).name = optionValueData.new_name;
        return {...state, sheet: {...state.sheet, options: tempOptions}}
    },
    [CHANGE_OPTION_TYPE]: (state, optionGroupData) => {
        let tempOptions = Object.assign([], state.sheet.options);
        let optionGroup = tempOptions.find(
            optionGroup => optionGroup.option_group_id === optionGroupData.option_group_id
        );
        optionGroup.type = optionGroupData.presentation_type;
        return {...state, sheet: {...state.sheet, options: tempOptions}}
    },
    [GET_OPTION_DEPENDENCIES]: (state, optionDependencies) => {
        return {...state, optionDependencies: optionDependencies};
    },
    [ADD_OPTION_DEPENDENCY]: (state, optionDependencyToAdd) => {
        let tempOptionDependencies = Object.assign({}, state.sheet.option_dependencies);
        if (!Object.keys(tempOptionDependencies).includes(optionDependencyToAdd.dependant_item_id)){
            tempOptionDependencies[optionDependencyToAdd.dependant_item_id] = [];
        }
        tempOptionDependencies[optionDependencyToAdd.dependant_item_id].push(optionDependencyToAdd);
        return {...state, sheet: {...state.sheet, option_dependencies: tempOptionDependencies}}
    },
    [DELETE_OPTION_DEPENDENCY]: (state, optionDependencyId) => {
        let tempOptionDependencies = Object.assign({}, state.sheet.option_dependencies);
        Object.entries(tempOptionDependencies).forEach( ([itemId, objectDependencies]) => {
            tempOptionDependencies[itemId] = objectDependencies.filter(oD =>
                oD.option_dependency_id !== optionDependencyId
            );
        });

        return {...state, sheet: {...state.sheet, option_dependencies: tempOptionDependencies}}
    },
    [ADD_RESULT_CODE]: (state, newResultCode) => {
        return {...state, sheet: {...state.sheet, result_codes: [...state.sheet.result_codes, newResultCode]}}
    },
    [DELETE_RESULT_CODE]: (state, resultCodeId) => {
        let tempResultCodes = Object.assign([], state.sheet.result_codes);
        tempResultCodes = tempResultCodes.filter(resultCode => resultCode.result_code_id !== resultCodeId);
        return {...state, sheet: {...state.sheet, result_codes: tempResultCodes}}
    },
    [MODIFY_RESULT_CODE]: (state, newResultCode) => {
        let tempResultCodes = Object.assign([], state.sheet.result_codes);
        tempResultCodes = tempResultCodes.filter(
            resultCode => resultCode.result_code_id !== newResultCode.result_code_id
        );
        tempResultCodes.push(newResultCode);
        return {...state, sheet: {...state.sheet, result_codes: tempResultCodes}}
    },
    [CHANGE_SHEET_SHOW_ATTENDEES]: (state, showAttendees) => {
        return {
            ...state,
            sheet: {
                ...state.sheet,
                show_attendees: showAttendees
            }
        }
    },
    [CHANGE_SHEET_UPDATE_DATE_ON_COMPLETION]: (state, updateDateOnCompletion) => {
        return {
            ...state,
            sheet: {
                ...state.sheet,
                update_date_on_completion: updateDateOnCompletion
            }
        }
    },

    // RESULT CREATION REDUCERS //
    [SET_CURRENT_RESULT]: (state, payload) => {
        if (payload && payload.response) return {...state, currentResult: payload.response};
        return {...state, currentResult: payload}
    },
    [UPDATE_ITEM_RESULT]: (state, payload) => {
        let tempAnswers = Object.assign({}, state.currentResult.answers);
        let tempErrors = Object.assign({}, state.currentResult.errors);
        tempAnswers[payload.item_id] = payload.answer;
        if (payload.item_id in tempErrors) {
            delete tempErrors[payload.item_id];
        }

        return {...state, currentResult: {...state.currentResult, answers: tempAnswers, errors: tempErrors}}
    },
    [UPDATE_ITEM_SCORES]: (state, payload) => {
        return {...state, currentResultScores: payload};
    },
    [SET_ALLOWED_ANSWERS]: (state, payload) => {
        return {...state, currentResultAllowedAnswers: payload};
    },
    [UPDATE_ALLOWED_ANSWERS]: (state, payload) => {
        let tempAllowedAnswers = Object.assign({}, state.currentResultAllowedAnswers, payload);
        return {...state, currentResultAllowedAnswers: tempAllowedAnswers};
    },
    [ATTACH_FILE_TO_RESULT]: (state, newFile) => {
        return {
            ...state,
            currentResult: {
                ...state.currentResult,
                attached_files: [...state.currentResult.attached_files, newFile]
            }
        };
    },
    [DELETE_RESULT_FILE]: (state, deletedFileId) => {
        let tempAttachedFiles = Object.assign([], state.currentResult.attached_files);
        tempAttachedFiles = tempAttachedFiles.filter(file => file.file_id !== deletedFileId);
        return {
            ...state, currentResult: {...state.currentResult, attached_files: tempAttachedFiles}
        }
    },
    [SET_RESULT_CODE + '/ERROR']: (state, payload) => {
        return {...state, currentResult: {...state.currentResult, errors: payload}};
    },
    [TOGGLE_SHEET_PUBLIC]: (state, payload) => {
        return {
            ...state,
            currentResult: {
                ...state.currentResult,
                is_public: payload
            }
        }
    },
    [SET_ITEM_EXAMPLE]: (state, newItemExample) => {
        // for displaying item examples in the result, if there is one.
        let tempResultItemExamples = {};
        if (state.currentResult) {
            tempResultItemExamples = Object.assign({}, state.currentResult.item_examples);
            tempResultItemExamples[newItemExample.item_id] = newItemExample.positive;
        }

        // the rest for the free text management view
        let tempItemExamples = Object.assign([], state.itemExamples);
        // removing the old version of the item example and adding it again
        // or it just does nothing if there is no old version of this one
        let existingItemExample = tempItemExamples.findIndex(ie =>
            ie.item_id === newItemExample.item_id && ie.result_id === newItemExample.result_id
        );
        if (existingItemExample !== -1) {
            tempItemExamples[existingItemExample] = newItemExample;
        } else {
            tempItemExamples.push(newItemExample);
        }

        return {
            ...state,
            itemExamples: tempItemExamples,
            currentResult: state.currentResult ? {
                ...state.currentResult,
                item_examples: tempResultItemExamples
            } : state.currentResult
        }
    },
    [DELETE_ITEM_EXAMPLES]: (state, deletedItemExampleIds) => {
        let tempItemExamples = Object.assign([], state.itemExamples);
        tempItemExamples = tempItemExamples.filter(ie => !deletedItemExampleIds.includes(ie.item_example_id));
        return {
            ...state,
            itemExamples: tempItemExamples
        };
    },
    [TOGGLE_ITEM_EXAMPLES_REPORTABLE]: (state, changedItemExamples) => {
        let tempItemExamples = Object.assign([], state.itemExamples);
        changedItemExamples.forEach(ie => {
            let existingIndex = tempItemExamples.findIndex(eie => eie.item_example_id === ie.item_example_id);
            // if it exists change it, otherwise add it to the list
            // this way the ordering won't change
            if (existingIndex !== -1) {
                tempItemExamples[existingIndex].reportable = ie.reportable
            } else {
                tempItemExamples.push(ie);
            }
        });
        return {
            ...state,
            itemExamples: tempItemExamples
        }
    },
    [CHANGE_RESULT_DATE]: (state, newDate) => {
        return {
            ...state,
            currentResult: {
                ...state.currentResult,
                date: newDate
            }
        }
    },
    [CHANGE_RESULT_ATTENDEES]: (state, newAttendeeIds) => {
        return {
            ...state,
            currentResult: {
                ...state.currentResult,
                attendees: newAttendeeIds
            }
        }
    },

    // CALC TEMPLATES //
    [SET_CURRENT_CALC_TEMPLATE]: (state, calcTemplateId) => {
        let calcTemplate = state.calcTemplates.find(calcTemplate => calcTemplate.id === calcTemplateId);
        return {...state, currentCalcTemplate: calcTemplate}
    },
    [SET_DEFAULT_CALC_TEMPLATE]: (state, payload) => {
        let tempCalcTemplates = Object.assign([], state.calcTemplates);
        let oldDefaultTemplate = tempCalcTemplates.find(calcTemplate => calcTemplate.id === payload.old_default_id)
        if (oldDefaultTemplate) oldDefaultTemplate.default_template = false;
        let newDefaultTemplate = tempCalcTemplates.find(calcTemplate => calcTemplate.id === payload.new_default_id)
        newDefaultTemplate.default_template = true;
        return {...state, calcTemplates: tempCalcTemplates}
    },
    [GET_CALC_TEMPLATES]: (state, calcTemplatesArray) => {
        return {...state, calcTemplates: calcTemplatesArray}
    },
    [GET_DEFAULT_CALC_TEMPLATE]: (state, defaultCalcTemplate) => {
        // TODO - change to this structure in backend instead of doing it here
        // {id: {...object}} instead of [{...object}, ...]
        // Having them as an object is WAY more efficient and reduces re-renders.
        // since the array won't have to be filtered constantly
        // Should really be done for all data structures but that is a task for later
        // maybe use normalizr library for this.
        let itemWeights = {};
        let optionConversions = {};
        if (defaultCalcTemplate) {
            defaultCalcTemplate.item_weights.forEach(itemWeight => {
                itemWeights[itemWeight.item_id] = itemWeight;
            });
            defaultCalcTemplate.option_conversions.forEach(optionConversion => {
                optionConversions[optionConversion.option_id] = optionConversion;
            });
            defaultCalcTemplate.item_weights = itemWeights;
            defaultCalcTemplate.option_conversions = optionConversions;
            return {...state, currentCalcTemplate: defaultCalcTemplate};
        } else {
            return {...state, currentCalcTemplate: null};
        }
    },
    [CREATE_CALC_TEMPLATE]: (state, newCalcTemplate) => {
        return {...state, calcTemplates: [...state.calcTemplates, newCalcTemplate]};
    },
    [DELETE_CALC_TEMPLATE]: (state, deletedCalcTemplateId) => {
        let tempCalcTemplates = Object.assign([], state.calcTemplates);
        tempCalcTemplates = tempCalcTemplates.filter(calcTemplate => calcTemplate.id !== deletedCalcTemplateId);
        return {...state, calcTemplates: tempCalcTemplates};
    },
    [LOCK_CALC_TEMPLATE]: (state, lockedCalcTemplateId) => {
        let tempCalcTemplates = Object.assign([], state.calcTemplates);
        let lockedCalcTemplate = tempCalcTemplates.find(ct => ct.id === lockedCalcTemplateId);
        lockedCalcTemplate.locked = true;
        if (state.currentCalcTemplate) {
            return {
                ...state,
                calcTemplates: tempCalcTemplates,
                currentCalcTemplate: {...state.currentCalcTemplate, locked: true}
            };
        }
        return {...state, calcTemplates: tempCalcTemplates};
    },
    [SET_ITEM_WEIGHT]: (state, payload) => {
        return {
            ...state, currentCalcTemplate: {
                ...state.currentCalcTemplate, item_weights: payload.item_weights,
                overall_weight: payload.overall_weight
            }
        }
    },
    [SET_OPTION_WEIGHT]: (state, newOptionConversion) => {
        let tempOptionConversions = Object.assign([], state.currentCalcTemplate.option_conversions);
        tempOptionConversions = tempOptionConversions.filter(
            optionConversion => optionConversion.option_id !== newOptionConversion.option_id
        );

        return {
            ...state, currentCalcTemplate: {
                ...state.currentCalcTemplate, option_conversions: [
                    ...tempOptionConversions, newOptionConversion
                ]
            }
        }
    },
    [CHANGE_CALC_TEMPLATE_PERCENTAGES]: (state, payload) => {
        return {...state, currentCalcTemplate: {...state.currentCalcTemplate, percentages: payload.percentages}}
    },
    [CHANGE_CALC_TEMPLATE_USE_WEIGHTS]: (state, payload) => {
        return {...state, currentCalcTemplate: {...state.currentCalcTemplate, use_weights: payload.use_weights}}
    },
    [CHANGE_NOT_ASSESSABLE_REDUCE_WEIGHTS]: (state, payload) => {
        return {...state, currentCalcTemplate: {...state.currentCalcTemplate,
                not_assessable_reduce_weights: payload.not_assessable_reduce_weights}}
    },
    [SET_SCORE_DISPLAY]: (state, payload) => {
        return {...state, currentCalcTemplate: {...state.currentCalcTemplate, score_display: payload.score_display}}
    },
    [CHANGE_ITEM_ACTIVE]: (state, payload) => {
        return {
            ...state, currentCalcTemplate: {
                ...state.currentCalcTemplate, item_weights: payload.item_weights,
                overall_weight: payload.overall_weight
            }
        }
    },
    [CHANGE_OPTION_ACTIVE]: (state, newOptionConversion) => {
        let tempOptionConversions = Object.assign([], state.currentCalcTemplate.option_conversions);
        tempOptionConversions = tempOptionConversions.filter(oc => oc.option_id !== newOptionConversion.option_id);
        tempOptionConversions.push(newOptionConversion);
        return {
            ...state, currentCalcTemplate: {
                ...state.currentCalcTemplate, option_conversions: tempOptionConversions
            }
        }
    },
    [CHANGE_OPTION_CATEGORY_NULL]: (state, newOptionConversion) => {
        let tempOptionConversions = Object.assign([], state.currentCalcTemplate.option_conversions);
        tempOptionConversions = tempOptionConversions.filter(oc => oc.option_id !== newOptionConversion.option_id);
        tempOptionConversions.push(newOptionConversion);
        return {
            ...state, currentCalcTemplate: {
                ...state.currentCalcTemplate, option_conversions: tempOptionConversions
            }
        }
    },
    [CHANGE_OPTION_SHEET_NULL]: (state, newOptionConversion) => {
        let tempOptionConversions = Object.assign([], state.currentCalcTemplate.option_conversions);
        tempOptionConversions = tempOptionConversions.filter(oc => oc.option_id !== newOptionConversion.option_id);
        tempOptionConversions.push(newOptionConversion);
        return {
            ...state, currentCalcTemplate: {
                ...state.currentCalcTemplate, option_conversions: tempOptionConversions
            }
        }
    },
    [BULK_EDIT_SCORE_UPDATE]: (state, payload) => {
        let results = Object.assign({}, state.sheetResults);
        let changedResult = results.results.find(result => result.result_id === payload.result_id);
        changedResult.score = payload.score;
        changedResult.answers[payload.sheet_item_id] = payload.answer;
        return {...state, sheetResults: results};
    },


    [FETCH_REPORT_METADATA]: (state, payload) => {
        return {...state, reportMetaData: payload}
    },
    [FETCH_REPORT]: (state, payload) => {
        return {...state, currentReport: payload}
    },
    [FETCH_RESULTS_FOR_SHEET]: (state, payload) => {
        return {
            ...state, sheetResults: {
                start_date: payload.start_date ? payload.start_date : state.sheetResults.start_date,
                end_date: payload.end_date ? payload.end_date : state.sheetResults.end_date,
                average_score: payload.average_score,
                results: payload.results
            }
        }
    },
    [FETCH_REPORTABLE_SHEET_METADATA]: (state, payload) => {
        return {
            ...state,
            reportableSheetMetaData: payload,
            resultReportDates: {
                start_date: payload.start_date,
                end_date: payload.end_date
            }
        }
    },
    [CREATE_REPORT_TEMPLATE]: (state, payload) => {
        return {...state, reportMetaData: [...state.reportMetaData, payload]}
    },
    [ADD_REPORT_ITEM]: (state, payload) => {
        return {...state, currentReport: {...state.currentReport, items: [...state.currentReport.items, payload]}};
    },
    [DELETE_REPORT_ITEM]: (state, deletedItemId) => {
        let tempItems = Object.assign([], state.currentReport.items);
        tempItems = tempItems.filter(item => item.report_item_id !== deletedItemId);
        return {...state, currentReport: {...state.currentReport, items: tempItems}}
    },
    [MOVE_REPORT_ITEM]: (state, newReport) => {
        return {...state, currentReport: {...state.currentReport, items: newReport.items}}
    },

    [FETCH_CALIBRATION_METADATA]: (state, payload) => {
        return {...state, calibrationMetaData: payload}
    },
    [SET_CURRENT_CALIBRATION]: (state, newCalibration) => {
        return {
            ...state,
            currentCalibration: newCalibration,
            projectChangeRequired: newCalibration.project_change_required ? newCalibration.project_id : null
        }
    },
    [SET_CALIBRATION_RESULT_TO_DONE]: (state, calibrationResultId) => {
        // this reducer is only called for the client who just set the result as done
        // the other clients in the same calibration room will get their currentCalibration
        // updated. Need to do that 'manually' here as well.
        let tempCalibration = Object.assign({}, state.currentCalibration);
        let userResultIndex = tempCalibration.results.findIndex(cr => cr.calibration_result_id === calibrationResultId);
        tempCalibration.results[userResultIndex].done = true;
        return {
            ...state,
            currentCalibration: tempCalibration,
            currentResult: {...state.currentResult, done: true}
        }
    },
    [ATTACH_FILE_TO_CALIBRATION]: (state, newFile) => {
        return {
            ...state,
            currentCalibration: {
                ...state.currentCalibration,
                attached_files: [...state.currentCalibration.attached_files, newFile]
            }
        };
    },
    [DELETE_CALIBRATION_FILE]: (state, deletedFileId) => {
        return {
            ...state,
            currentCalibration: {
                ...state.currentCalibration,
                attached_files: state.currentCalibration.attached_files.filter(file => file.file_id !== deletedFileId)
            }
        }
    },
    [GET_CALIBRATION_RESULTS]: (state, calibrationResults) => {
        return {
            ...state, currentCalibration: {
                ...state.currentCalibration, results: calibrationResults, results_fetched: true
            }
        }
    },
    [FETCH_CALIBRATION_TABLE_DATA]: (state, calibrationTableData) => {
        return {...state, calibrationTableData: calibrationTableData};
    },
    [SAVE_CALIBRATION_COMMENT]: (state, newComment) => {
        let tempComments = Object.assign({}, state.currentResult.comments);
        let privateComments = Object.assign({}, tempComments.private);
        let publicComments = Object.assign({}, tempComments.public);

        // remove possibly existing comment to replace with the newly saved one
        if (newComment.public) {
            if (!publicComments[newComment.item_id]) {
                publicComments[newComment.item_id] = []
            }
            // Delete old comment, if present
            publicComments[newComment.item_id]= publicComments[newComment.item_id].filter(
                comment => comment.calibration_comment_id !== newComment.calibration_comment_id)
            publicComments[newComment.item_id].push(newComment)
        } else {
            if (!privateComments[newComment.item_id]) {
                privateComments[newComment.item_id] = []
            }
            // Delete old comment, if present
            privateComments[newComment.item_id] = privateComments[newComment.item_id].filter(
                comment => comment.calibration_comment_id !== newComment.calibration_comment_id)
            privateComments[newComment.item_id].push(newComment)
        }
        tempComments.private = privateComments;
        tempComments.public = publicComments;
        return {
            ...state, currentResult: {
                ...state.currentResult, comments: tempComments
            }
        }
    },

    [SET_SELECTED_SHEETS]: (state, selectedSheets) => {
        return {...state, selectedSheets: selectedSheets}
    },
    [SET_SELECTED_REPORT_TEMPLATE]: (state, selectedReportTemplate) => {
        return {...state, currentReport: selectedReportTemplate, reportsLoading: false}
    },
    [GET_CATEGORY_SCORE_PROGRESSION]: (state, categoryData) => {
        let tempCategoryScores = Object.assign([], state.sheetScoresByMonth.category_scores);
        // do it by index so the graph papers don't switch places due to an order change
        let oldCategoryIndex = tempCategoryScores.findIndex(c => c.name === categoryData.name);
        tempCategoryScores[oldCategoryIndex] = categoryData;
        return {
            ...state,
            sheetScoresByMonth: {
                ...state.sheetScoresByMonth,
                category_scores: tempCategoryScores
            }
        }
    },
    [FETCHING_REPORTS_DATA]: (state) => {
        return {...state, reportsLoading: true}
    },
    [GET_SHEET_RESULT_REPORT_DATA]: (state, payload) => {
        return {
            ...state,
            resultReportDates: {
                start_date: state.resultReportDates.start_date ? state.resultReportDates.start_date : payload.start_date,
                end_date: state.resultReportDates.end_date ? state.resultReportDates.end_date : payload.end_date,
            },
            resultTableData: payload.table_data,
            sheetKeyItemsTableData: payload.key_items_table_data,
            sheetKeyItemsMonthlyTableData: payload.monthly_key_items_table_data,
            sheetCrossSellingTableData: payload.cross_selling_table_data ? payload.cross_selling_table_data : state.sheetCrossSellingTableData,
            sheetResultAmounts: payload.amounts,
            sheetScores: payload.scores,
            sheetScoresByMonth: payload.monthly_scores ? payload.monthly_scores : state.sheetScoresByMonth,
            reportsLoading: false,
            percentages: payload.percentages
        }
    },
    [GET_USER_METRICS]: (state, userMetrics) => {
        return {...state, userMetrics: userMetrics}
    },
    [GET_TABLE_DATA_FOR_ITEM]: (state, tableData) => {
        return {...state, userItemTableData: tableData}
    },

    [GET_SHEET_IMPORT_COMPATIBILITY]: (state, payload) => {
        return {...state, excelImportData: payload}
    },

    [GET_EXPORT_MARKER_METADATA]: (state, payload) => {
        return {...state, exportMarkerMetadata: payload}
    },
    [GET_EXPORT_MARKER_RESULTS]: (state, payload) => {
        return {...state, exportMarkerResults: payload}
    },

    [GET_ITEM_EXAMPLES]: (state, payload) => {
        return {...state, itemExamples: payload}
    },

    [RESET_FILE_DOWNLOAD]: (state) => {
        return {
            ...state,
            filedownload: {
                done: false
            }
        }
    },
    [RESET_RESULT_REPORTS]: (state) => {
        return {
            ...state,
            reportableSheetMetaData: {
                start_date: null,
                end_date: null,
                sheets: []
            },
            resultReportDates: {
                start_date: null,
                end_date: null,
            },
            sheetResultAmounts: {},
            sheetScores: {
                overall_score: 0,
                scores: []
            },
            sheetScoresByMonth: {
                start_date: null,
                end_date: null,
                scores: []
            },
            sheetKeyItemsTableData: null,
            sheetKeyItemsMonthlyTableData: null,
            sheetCrossSellingData: null
        }
    },
    [RESET_CURRENT_CALIBRATION]: (state) => {
        return {
            ...state,
            currentCalibration: null
        }
    },
    [SET_DISCONNECTED]: (state, payload) => {
        return {
            ...state, disconnected: payload
        }
    },
    [SET_ITEM_PRESENTATION_MAPPER]: (state, payload) => {
        return {
            ...state, itemPresentationMapper: payload
        }
    },
    [CHANGE_ITEM_PRESENTATION_TYPE]: (state, payload) => {
        let tempItems = Object.assign([], state.sheet.items);
        tempItems = tempItems.filter(item => item.id !== payload.id);
        tempItems.push(payload);
        return {...state, sheet: {...state.sheet, items: tempItems}}
    }
});
