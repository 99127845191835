import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../../browserHistory';
import * as actionCreators from '../../actions/auth';
import {validate_token} from "../../utils/http_functions";

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}


export function requireNoAuthentication(Component, redirectUrlOnAuth='/') {

    class notAuthenticatedComponent extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                loaded: false,
            };
        }

        componentDidMount() {
            this.checkAuth();
        }

        componentDidUpdate(prevProps){
            if (prevProps.isAuthenticated !== this.props.isAuthenticated){
                this.checkAuth(this.props);
            }
        }

        checkAuth(props = this.props) {
            if (props.isAuthenticated) {
                    this.setState({
                        loaded: true,
                    });
            } else {
                const token = localStorage.getItem('token');
                if (token) {
                    validate_token(token)
                        .then(res => {
                            if (res.status === 200) {
                                this.props.loginUserSuccess(token);
                                browserHistory.push(redirectUrlOnAuth);

                            } else {
                                this.setState({
                                    loaded: true,
                                });
                            }
                        })
                        .catch(err => {
                            // Token exists but backend does not like it, prob because expiry
                            // logout and redirect to login
                            this.props.logoutAndRedirect();
                            this.setState({
                                loaded: true,
                            });
                        });
                } else {
                    this.setState({
                        loaded: true,
                    });
                }
            }
        }

        render() {
            return (
                <div>
                    {this.state.loaded
                        ? <Component {...this.props} />
                        : null
                    }
                </div>
            );

        }
    }

    notAuthenticatedComponent.propTypes = {
        loginUserSuccess: PropTypes.func,
        isAuthenticated: PropTypes.bool,
    };

    return connect(mapStateToProps, mapDispatchToProps)(notAuthenticatedComponent);

}
