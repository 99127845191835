import React from 'react';
import TemplateAdminIcon from '@mui/icons-material/InsertDriveFile';
import DeskIcon from '@mui/icons-material/BorderColor';
import BulkEditIcon from '@mui/icons-material/Dashboard';
import ReportTemplatesIcon from '@mui/icons-material/Assignment';
import ReportsIcon from '@mui/icons-material/TrendingUp';
import CalibrationIcon from '@mui/icons-material/ThumbsUpDown';
import TreasuresIcon from '@mui/icons-material/FormatQuote';
import ProjectManagementIcon from '@mui/icons-material/Apps';
//import SplitTestIcon from '@mui/icons-material/ViewQuilt';
import SettingsIcon from '@mui/icons-material/SettingsApplicationsRounded';
import UserManagementIcon from '@mui/icons-material/Group';
import ExportFormatsIcon from '@mui/icons-material/Assessment';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ExportMarkerIcon from '@mui/icons-material/Restore';
import DashboardIcon from '@mui/icons-material/VerticalSplit';
import SystemMonitorIcon from '@mui/icons-material/Speed';

// This object contains all the app paths, the home view and sidebar uses this to map everything dynamically
// The privilege field is the minimum level of privilege the user must have for the link to be shown.
// They're defined in the backend, as of right now it's:
// ADMIN: 5
// LEAD: 4
// STAFF: 3
// CONTRACTOR: 2
// USER: 1

const appPaths = [
    {
        name: 'templateAdmin',
        link: '/template-admin',
        privilege: 2,
        icon: <TemplateAdminIcon className="homepaper-icon"/>
    },
    {
        name: 'desk',
        link: '/desk',
        privilege: 1,
        icon: <DeskIcon className="homepaper-icon"/>,
    },
    {
        name: 'calibration',
        link: '/calibration',
        privilege: 1,
        icon: <CalibrationIcon className="homepaper-icon"/>
    },
    {
        name: 'yourDashboard',
        link: '/your-dashboard',
        privilege: 1,
        icon: <DashboardIcon className="homepaper-icon"/>
    },
    {
        name: 'bulkEdit',
        link: '/bulk-edit',
        privilege: 4,
        icon: <BulkEditIcon className="homepaper-icon"/>
    },
    {
        name: 'calcTemplates',
        link: '/calc-templates',
        privilege: 4,
        icon: <ExportFormatsIcon className="homepaper-icon"/>
    },
    {
        name: 'reports',
        link: '/reports',
        privilege: 2,
        icon: <ReportsIcon className="homepaper-icon"/>
    },
    {
        name: 'reportTemplates',
        link: '/report-templates',
        privilege: 5,
        icon: <ReportTemplatesIcon className="homepaper-icon"/>
    },
    {
        name: 'freeTextManagement',
        link: '/free-text',
        privilege: 4,
        icon: <TreasuresIcon className="homepaper-icon"/>
    },
    {
        name: 'projectManagement',
        link: '/project-management',
        privilege: null,
        icon: <ProjectManagementIcon className="homepaper-icon"/>
    },
    /*
    {
        name: 'Split view test',
        link: '/split-test',
        privilege: 5,
        icon: <SplitTestIcon className="homepaper-icon"/>
    },
    */
    {
        name: 'userManagement',
        link: '/user-admin',
        privilege: 5,
        icon: <UserManagementIcon className="homepaper-icon"/>
    },
    {
        name: 'settings',
        link: '/settings',
        privilege: 1,
        icon: <SettingsIcon className="homepaper-icon"/>
    },
    {
        name: 'importExport',
        link: '/import-export',
        privilege: 4,
        icon: <ImportExportIcon className="homepaper-icon"/>
    },
    {
        name: 'exportMarkers',
        link: '/export-markers',
        privilege: 4,
        icon: <ExportMarkerIcon className="homepaper-icon"/>
    },
    {
        name: 'dashboard',
        link: '/dashboard',
        privilege: 5,
        icon: <DashboardIcon className="homepaper-icon"/>
    },
    {
        name: 'systemMonitor',
        link: '/system-monitor',
        privilege: 5,
        icon: <SystemMonitorIcon className="homepaper-icon"/>
    }
];
export default appPaths;
