import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import {
    Card,
    Typography,
    Button,
    Divider
} from "@mui/material";
import {useTranslation} from "react-i18next";


const cardStyle = {
    padding: 20,

}

export default function ConfirmEmailPanel(props){
    const {
        onResendEmailClick
    } = props;
    const { t } = useTranslation('actionResponses');
    return (
        <Card style={cardStyle}>
            <Typography variant={"overline"}>
                {t('youNeedToConfirmYourEmail')}
            </Typography>
            <Divider/>
            <Typography variant={'body1'}>
                {t('confirmYourEmailText')}
            </Typography>
            <Button
                color={'primary'}
                variant={'outlined'}
                onClick={onResendEmailClick}
            >
                {t('resendEmail')}
            </Button>
        </Card>
    )
}
ConfirmEmailPanel.propTypes = exact({
    onResendEmailClick: PropTypes.func.isRequired
})