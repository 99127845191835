import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {requiredIf} from '../../utils/misc';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import {RADIO_GROUP, DROP_DOWN, ITEM_NAME_MAX_LENGTH} from '../../constants';
import Radio from '@mui/material/Radio';
import Tooltip from 'utils/tooltipWithPlacement';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {sortCatalogItemsByOrder} from "./CatalogItems";
import {withTranslation} from "react-i18next";
import {Autocomplete} from '@mui/material';
import Checkbox from "@mui/material/Checkbox";


class OptionItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemName: "",
            currentName: this.props.itemName ? this.props.itemName : "",
            itemOption: this.props.itemOption,
            radioGroupValue: "",
        };
    };

    componentDidUpdate() {
        if (this.props.itemName !== this.state.itemName && this.props.itemName !== null) {
            let name = this.props.itemName;
            this.setState({
                itemName: name,
                currentName: name
            });
        }
    }

    onDropDownChange = (event, newValue) => {
        this.setState({dropDownValue: newValue})
    };

    onRadioGroupChange = (value) => {
        this.setState({radioGroupValue: value})
    };

    onItemNameChange = (event) => {
        this.setState({currentName: event.target.value})
    };

    onNameChange = () => {
        if (this.props.itemName === this.state.currentName || this.props.currentName === null) return;
        this.props.onItemLabelChange(this.props.itemId, {name: this.state.currentName});
    };

    withTooltip = (radioElement, description, disabled) => {
        if (!description || disabled) return radioElement;
        const descriptionNode = <div style={{whiteSpace: 'pre-line'}}>{description}</div>;
        return (
            <Tooltip title={descriptionNode}>
                {radioElement}
            </Tooltip>
        );
    };

    getOptionField = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                {this.props.itemOption.type === RADIO_GROUP &&
                    <React.Fragment>
                        {sortCatalogItemsByOrder(this.props.itemOption.options).map((value) => {
                            if (this.props.multiselect) {
                                return (
                                    <FormControlLabel
                                        value={value}
                                        control={this.withTooltip(<Checkbox/>,
                                            this.props.optionDescriptions[value.option_id],
                                            this.props.optionOverrides[value.option_id])}
                                        label={value.name}
                                        disabled={
                                            this.props.optionOverrides[value.option_id] ?
                                                this.props.optionOverrides[value.option_id].disabled
                                                :
                                                false
                                        }
                                        labelPlacement="end"
                                    />);
                            } else {
                                return (
                                    <FormControlLabel
                                        key={value.option_id}
                                        label={value.name}
                                        value={value.name}
                                        disabled={
                                            this.props.optionOverrides[value.option_id] ?
                                                this.props.optionOverrides[value.option_id].disabled
                                                :
                                                false
                                        }
                                        control={this.withTooltip(
                                            <Radio
                                                color="primary"
                                                key={value.name + "radio"}
                                                value={value.name}
                                                checked={value.name === this.state.radioGroupValue}
                                                onChange={() => {
                                                    this.setState({radioGroupValue: value.name})
                                                }}
                                            />,
                                            this.props.optionDescriptions[value.option_id],
                                            this.props.optionOverrides[value.option_id]
                                        )}
                                    />
                                );
                            }
                        })}
                    </React.Fragment>
                }
                {this.props.itemOption.type === DROP_DOWN &&
                    <Autocomplete
                        id={this.props.itemId}
                        disabled={this.props.disabled}
                        fullWidth
                        noOptionsText={t('common:noHit')}
                        renderInput={(params) => (
                            <TextField
                                variant="standard"
                                {...params}
                                placeholder={t('common:choice')}
                                label={" "}
                            />
                        )}
                        options={
                            sortCatalogItemsByOrder(this.props.itemOption.options).map(value => {
                                return value.name
                            })
                        }
                        multiple={this.props.multiselect}
                    />
                }
            </React.Fragment>
        )
    };

    getRootClassName = () => {
        if ((!this.props.disabled || this.props.showMenuButton)) {
            if (this.props.category) return "row col-xs-10";
            return "row col-xs-11";
        }
        return "row col-xs-12";
    };


    render() {
        const {t} = this.props;
        const nameError = this.state.currentName && this.state.currentName.length >= ITEM_NAME_MAX_LENGTH;
        return (
            <div className="row small-gutters display-flex"
                 style={{alignItems: 'center', width: '100%', paddingBottom: '0'}}>
                {(!this.props.disabled || this.props.showMenuButton) &&
                    <div className="col-xs-1" style={{display: 'flex', alignItems: 'center'}}>
                        <IconButton
                            style={{marginLeft: '-12px'}}
                            onClick={(event) => this.props.onMenuButtonClick(event, this.props.itemId)}
                            size="large">
                            <MoreVertIcon/>
                        </IconButton>
                    </div>
                }
                {this.props.category &&
                    <div
                        className="col-xs-1"
                        id={'itemAvatarContainer' + this.props.itemId}
                        style={{display: 'inline-flex'}}
                    >

                    </div>
                }
                <div className={this.getRootClassName()}>
                    <div className="col-sm-6 col-xs-12">
                        <TextField
                            variant="standard"
                            error={nameError ? nameError : false}
                            label={nameError ? t('fieldNameError') : t('fieldName')}
                            helperText={nameError ? t('fieldNameTooLong', {maxLength: ITEM_NAME_MAX_LENGTH}) : null}
                            fullWidth
                            disabled={this.props.disabled}
                            value={this.state.currentName}
                            onChange={this.onItemNameChange}
                            onBlur={this.onNameChange}
                            InputProps={this.props.itemRequired.length > 0 ?
                                {
                                    endAdornment:
                                        <InputAdornment
                                            disableTypography
                                            position='end'
                                            style={{color: 'red'}}
                                        >
                                            *
                                        </InputAdornment>
                                }
                                :
                                null
                            }
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12" style={{display: 'flow-root'}}>
                        {this.getOptionField()}
                    </div>
                </div>
            </div>
        );
    }
}

OptionItem.defaultProps = {
    showMenuButton: false,
    disabled: false,
    optionOverrides: {},
    optionDescriptions: {},
    itemRequired: [],
};

OptionItem.propTypes = exact({
    itemId: PropTypes.string.isRequired,
    itemName: PropTypes.string,
    itemRequired: PropTypes.array,
    itemOption: PropTypes.object.isRequired,
    multiselect: PropTypes.bool.isRequired,
    optionOverrides: PropTypes.object,
    optionDescriptions: PropTypes.object,
    onPresentationTypeChange: PropTypes.func.isRequired,
    onItemLabelChange: PropTypes.func.isRequired,
    onMenuButtonClick: requiredIf(PropTypes.func, props => props.showMenuButton),
    showMenuButton: PropTypes.bool,
    disabled: PropTypes.bool,
    category: PropTypes.object,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    tReady: PropTypes.bool.isRequired,
});
export default withTranslation(['sheetManagement', 'common'])(OptionItem);
