import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actionCreators from '../actions/data';
import * as authCreators from '../actions/auth';

import LoginView from "../components/HomepageComponents/LoginView";


function mapStateToProps(state) {
    return {
        loginError: state.auth.loginError,
        authenticated: state.auth.isAuthenticated
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({...actionCreators, ...authCreators}, dispatch);
}

export class LoginContainer extends React.Component {
    render() {
        return (
            <div>
                <LoginView
                    loginUser={this.props.loginUser}
                    loginError={this.props.loginError}
                />
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);