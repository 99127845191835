import browserHistory from '../browserHistory';

import {
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGIN_USER_REQUEST,
    LOGOUT_USER,
    REGISTER_USER_FAILURE,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    RESET_DATA_REDUX_STATE,
    RESET_SOCKET_REDUX_STATE, STATUS_UPDATE, CREATE_USER, DELETE_USER, FILE_UPLOAD_SUCCESS, BULK_ADD_USERS
} from '../constants/index';

import {parseJSON} from '../utils/misc';
import {
    get_token,
    validate_token,
    create_user,
    delete_user,
    change_user_password,
    reset_user_password,
    register_user,
    verify_reset_password_token,
    request_password_reset,
    change_password,
    resend_confirmation_email, attach_file_to_result, bulk_add_users
} from '../utils/http_functions';
import i18n from '../i18n';
const t = i18n.getFixedT(null, 'actionResponses');


export function requestPasswordReset(email){
    return (dispatch) => {
        return request_password_reset(email)
    }
}

export function verifyResetPasswordToken(token){
    return (dispatch) => {
        return verify_reset_password_token(token)
    }
}

export function changePassword(verificationToken, newPassword){
    let json = {
        verification_token: verificationToken,
        new_password: newPassword
    };
    return (dispatch) => {
        return change_password(json)
    }

}

export function isTokenValid(token) {
    return (dispatch) => {
        validate_token(token).then(parseJSON).then(response => {
            if (response.token) {
                dispatch({
                    type: LOGIN_USER_SUCCESS,
                    payload: {token}
                })
            }
        })
    }
}

export function loginUserSuccess(token) {
    localStorage.setItem('token', token);
    return {
        type: LOGIN_USER_SUCCESS,
        payload: {
            token,
        },
    };
}


export function loginUserFailure(error) {
    let incoming = error.response.data;
    if (incoming.token && incoming.error === 'LICENSE_EXPIRED') {
        return (dispatch) => {
            // Token is valid but login license is expired
            // Treat this like a login success but redirect user to the settings
            // This way we can still associate the client with the User
            // when they decide to get a license
            dispatch(loginUserSuccess(incoming.token));
            dispatch({
                type: STATUS_UPDATE,
                payload: {'type': 'info', 'message': t('licenseExpired')}
            });
            browserHistory.push('/settings');
        }
    }

    localStorage.removeItem('token');
    return {
        type: LOGIN_USER_FAILURE,
        payload: {
            status: error.response.status,
            statusText: error.response.data.error,
            errorMessage: incoming.message
        },
    };
}

export function loginUserRequest() {
    return {
        type: LOGIN_USER_REQUEST,
    };
}

export function logout() {
    localStorage.clear();
    return (dispatch) => {
        dispatch({type: LOGOUT_USER});
        dispatch({type: RESET_DATA_REDUX_STATE});
        dispatch({type: RESET_SOCKET_REDUX_STATE});
    }
}

export function logoutAndRedirect() {
    return (dispatch) => {
        dispatch(logout());
        browserHistory.push('/login');
    };
}

export function redirectToRoute(route) {
    return () => {
        browserHistory.push(route);
    };
}

export function loginUser(name, password) {
    return function (dispatch) {
        dispatch(loginUserRequest());
        return get_token(name, password)
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(loginUserSuccess(response.token));
                    browserHistory.push('/');
                } catch (e) {
                    alert(e);
                    dispatch(loginUserFailure({
                        response: {
                            status: 403,
                            statusText: 'Invalid token',
                        },
                    }));
                }
            })
            .catch(error => {
                dispatch(loginUserFailure(error));
            });
    };
}

export function registerUser(firstName, lastName, email, password) {
    return (dispatch) => {
        register_user(firstName, lastName, email, password).then(parseJSON).then(response => {
            dispatch(
                loginUser(email, password)
            );
            dispatch({
                type: REGISTER_USER_SUCCESS,
                payload: {response},
            });
        }).catch(err => dispatch(registerUserFailure(err)))
    }
}


export function registerUserRequest() {
    return {
        type: REGISTER_USER_REQUEST,
    };
}

export function registerUserSuccess(response) {
    return {
        type: REGISTER_USER_SUCCESS,
        payload: {
            ...response,
        },
    };
}

export function registerUserFailure(response) {
    localStorage.removeItem('token');
    if (response.hasOwnProperty('response')) response = response.response.data;
    return {
        type: REGISTER_USER_FAILURE,
        payload: {
            ...response
        },
    };
}

export function resendConfirmationEmail(token){
    return function(dispatch){
        return resend_confirmation_email(token).then(() => {
            dispatch({
                type: STATUS_UPDATE,
                payload: {
                    type: 'success',
                    message: t('confirmationEmailSent')
                }
            })
        })
    }
}

export function bulkAddUsers(projectId, file, token){
    return (dispatch) => {
        bulk_add_users(projectId, file, token).then(response => {
            if (response.status === 200){
                dispatch({
                    type: FILE_UPLOAD_SUCCESS
                });
                dispatch({
                    type: BULK_ADD_USERS,
                    payload: response.data
                })
            }
        });
    }
}

export function createUser(projectId, firstName, lastName, email, password, role, token) {
    return function (dispatch) {
        return create_user(projectId, firstName, lastName, email, password, role, token)
            .then((resp) => dispatch({
                type: CREATE_USER,
                payload: resp
            }))
            .catch(error => {
                let errorMsg = error;
                if (error.response && error.response.data){
                    errorMsg = error.response.data.message;
                }

                dispatch({
                    type: STATUS_UPDATE,
                    payload: {
                        type: 'error',
                        message: t('errorCreatingUser') + ": " + errorMsg
                    }
                });
            });
    };
}

export function deleteUser(id, token) {
    return function (dispatch) {
        return delete_user(id, token)
            .then(() => dispatch({
                type: DELETE_USER,
                payload: id
            }));

    }
}

export function resetUserPassword(userId, token) {
    return function (dispatch) {
        return reset_user_password(userId, token)

    }
}

export function changeUserPassword(userId, newPassword, token) {
    let json = {
        user_id: userId,
        new_password: newPassword
    };
    return function (dispatch) {
        return change_user_password(json, token)
    }

}
