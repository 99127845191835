import {lazy} from 'react';
import {withRouter} from 'react-router-dom';

import loadableComponent from './containers/ContainerWrappers/LoadableComponent';
import {DetermineAuth} from './containers/ContainerWrappers/DetermineAuth';
import {requireAuthentication} from './containers/ContainerWrappers/AuthenticatedComponent';
import {requireNoAuthentication} from './containers/ContainerWrappers/notAuthenticatedComponent';

// To increase speed code splitting is used.
// Code for non-essential components will be sent to client only when needed using the LoadableComponent HOC
// This reduces the main.js size considerably, which as of writing this is 2.5 mb (!!!) and would take upwards to 20s
// to load on a simulated slow connection.


import App from './containers/App/';
import AppRoutesContainer from './containers/AppRoutesContainer';
import LoginContainer from './containers/LoginContainer';
import RegisterContainer from './containers/RegisterContainer';
import ConfirmationComponent from "./components/HomepageComponents/ConfirmationComponent";
import RedisDashboardView from "./components/RedisDashboardView/RedisDashboardView";
import {GuestSurveyRedirect} from "./containers/GuestSurveyContainer";
import {YourDashboard} from "./containers/YourDashboard";
import ResultImportContainer from "./containers/ResultImportContainer";

const TemplateAdmin = loadableComponent(lazy(() => import ('./containers/TemplateAdmin')));
const Desk = loadableComponent(lazy(() => import ('./containers/Desk')));
const CalcTemplates = loadableComponent(lazy(() => import ('./containers/CalcTemplates')));
const FillCatalogContainer = loadableComponent(lazy(() => import ('./containers/FillCatalogContainer')));
const WorkbenchCatalogContainer = loadableComponent(lazy(() => import ('./containers/WorkbenchCatalogContainer')));
const ReportsContainer = loadableComponent(lazy(() => import ('./containers/ReportsContainer')));
const ReportTemplateOverviewContainer = loadableComponent(lazy(() => import ('./containers/ReportTemplateOverviewContainer')));
const ReportTemplateEditContainer = loadableComponent(lazy(() => import ('./containers/ReportTemplateEditContainer')));
const FreeTextManagement = loadableComponent(lazy(() => import ('./containers/FreeTextManagement')));
const CalibrationMetaContainer = loadableComponent(lazy (() => import ('./containers/CalibrationMetaContainer')));
const CalibrationOverviewContainer = loadableComponent(lazy(() => import ('./containers/CalibrationOverviewContainer')));
const CalibrationFillContainer = loadableComponent(lazy(() => import ('./containers/CalibrationFillContainer')));
const CalibrationPresentationContainer = loadableComponent(lazy(() => import ('./containers/CalibrationPresentationContainer')));
const BulkEditMetaContainer = loadableComponent(lazy(() => import ('./containers/BulkEditMetaContainer')));
const BulkEditContainer = loadableComponent(lazy(() => import ('./containers/BulkEditContainer')));
const UserAdmin = loadableComponent(lazy(() => import ('./containers/UserAdmin')));
const Settings = loadableComponent(lazy(() => import ('./containers/Settings')));
const SplitView = loadableComponent(lazy(() => import ('./components/SplitView')));
const DashboardContainer = loadableComponent(lazy(() => import ('./containers/DashboardContainer')));
const ImportExportContainer = loadableComponent(lazy(() => import ('./containers/ImportExportContainer')));
const ResetPasswordContainer = loadableComponent(lazy(() => import ('./containers/ResetPasswordContainer')));
const InviteResetPasswordContainer = loadableComponent(lazy(() => import ('./containers/InviteResetPasswordContainer')));
const ForgotPasswordContainer = loadableComponent(lazy(() => import ('./containers/ForgotPasswordContainer')));
const ExportMarkerContainer = loadableComponent(lazy(() => import ('./containers/ExportMarkerContainer')));
const SystemMonitorContainer = loadableComponent(lazy(() => import ('./containers/SystemMonitorContainer')));
const ProjectManagementContainer = loadableComponent(lazy(() => import ('./containers/ProjectManagementContainer')));
const GuestSurveyContainer = loadableComponent(lazy(() => import ('./containers/GuestSurveyContainer')));
const NotFound = loadableComponent(lazy(() => import ('./components/NotFound')));

const roles = {
    ADMIN: 5,
    LEAD: 4,
    STAFF: 3,
    CONTRACTOR: 2,
    USER: 1,
};

export const routeConfig = [
    {
        path: "/login",
        exact: true,
        component: withRouter(requireNoAuthentication(LoginContainer))
    },
    {
        path: '/register',
        exact: true,
        component: withRouter(requireNoAuthentication(RegisterContainer))
    },
    {
        path: '/confirmation',
        exact: true,
        component: withRouter(requireNoAuthentication(ConfirmationComponent, '/?email_confirmed=true'))
    },
    {
        path: "/invite/reset-password/:token",
        exact: true,
        component: withRouter(requireNoAuthentication(InviteResetPasswordContainer))
    },
    {
        path: "/forgot-password",
        exact: true,
        component: withRouter(requireNoAuthentication(ForgotPasswordContainer))
    },
    {
        path: "/survey/:sheet_id",
        exact: true,
        component: withRouter(GuestSurveyRedirect)
    },
    {
        path: "/survey/:sheet_id/:result_id",
        exact: true,
        component: withRouter(requireNoAuthentication(GuestSurveyContainer))
    },
    {
        component: withRouter(App),
        routes: [
            {
                path: "/",
                exact: true,
                component: withRouter(requireAuthentication(AppRoutesContainer, roles.USER))
            },
            {
                path: "/template-admin",
                exact: true,
                component: withRouter(requireAuthentication(TemplateAdmin, roles.CONTRACTOR)),
            },
            {
                path: "/template-admin/:sheet_id",
                component: withRouter(requireAuthentication(WorkbenchCatalogContainer, roles.CONTRACTOR))
            },
            {
                path: "/desk",
                exact: true,
                component: withRouter(requireAuthentication(Desk))
            },
            {
                path: "/desk/:result_id",
                component: withRouter(requireAuthentication(FillCatalogContainer, roles.USER))
            },
            {
                path: "/calc-templates",
                component: withRouter(requireAuthentication(CalcTemplates, roles.LEAD))
            },
            {
                path: "/report-templates",
                exact: true,
                component: withRouter(requireAuthentication(ReportTemplateOverviewContainer, roles.ADMIN))
            },
            {
                path: "/report-templates/:report_id",
                component: withRouter(requireAuthentication(ReportTemplateEditContainer, roles.ADMIN))
            },
            {
                path: "/reports",
                component: withRouter(requireAuthentication(ReportsContainer, roles.STAFF))
            },
            {
                path: "/bulk-edit",
                exact: true,
                component: withRouter(requireAuthentication(BulkEditMetaContainer, roles.LEAD))
            },
            {
                path: "/bulk-edit/:sheet_id/:end_month?",
                component: withRouter(requireAuthentication(BulkEditContainer, roles.LEAD))
            },
            {
                path: "/calibration",
                exact: true,
                component: withRouter(requireAuthentication(CalibrationMetaContainer, roles.USER))
            },
            {
                path: "/calibration/:calibration_id",
                exact: true,
                component: withRouter(requireAuthentication(CalibrationOverviewContainer, roles.USER))
            },
            {
                path: "/calibration/fill/:calibration_id",
                component: withRouter(requireAuthentication(CalibrationFillContainer, roles.USER))
            },
            {
                path: "/calibration/presentation/:calibration_id",
                component: withRouter(requireAuthentication(CalibrationPresentationContainer, roles.USER))
            },
            {
                path: "/free-text",
                component: withRouter(requireAuthentication(FreeTextManagement, roles.LEAD))
            },
            {
                path: "/user-admin",
                component: withRouter(requireAuthentication(UserAdmin, roles.ADMIN))
            },
            {
                path: "/settings",
                component: withRouter(requireAuthentication(Settings, roles.USER))
            },
            {
                path: "/split-test",
                component: withRouter(requireAuthentication(SplitView, roles.ADMIN))
            },
            {
                path: "/import-export",
                component: withRouter(requireAuthentication(ImportExportContainer, roles.LEAD))
            },
            {
                path: "/dashboard",
                component: withRouter(requireAuthentication(DashboardContainer, roles.ADMIN))
            },
            {
                path: "/reset-password/:user_id",
                exact: true,
                component: withRouter(requireAuthentication(ResetPasswordContainer, roles.USER))
            },
            {
                path: "/reset-password/:user_id/:force",
                exact: true,
                component: withRouter(requireAuthentication(ResetPasswordContainer, roles.USER), {force: true})
            },
            {
                path: "/export-markers",
                component: withRouter(requireAuthentication(ExportMarkerContainer, roles.LEAD))
            },
            {
                path: "/system-monitor",
                component: withRouter(requireAuthentication(SystemMonitorContainer, roles.ADMIN))
            },
            {
                path: "/rq",
                exact: true,
                component: withRouter(requireAuthentication(RedisDashboardView, roles.ADMIN))
            },
            {
                path: "/project-management",
                exact: true,
                component: withRouter(requireAuthentication(ProjectManagementContainer, null, false))
            },
            {
                path: "/your-dashboard",
                exact: true,
                component: withRouter(requireAuthentication(YourDashboard, null, false))
            },
            {
                path: "/result-import",
                component: withRouter(requireAuthentication(ResultImportContainer, roles.LEAD))
            },
            {
                path: "/project-management/create-project",
                exact: true,
                component: withRouter(
                    requireAuthentication(
                        ProjectManagementContainer,
                        null,
                        false
                    ), {
                        createProject: true
                    })
            },
            {
                path: "*",
                component: withRouter(DetermineAuth(NotFound))
            }
        ]
    }
];
