import React from 'react';
import browserHistory from '../../browserHistory';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import HomepageFooter from "./HomepageFooter";
import withStyles from '@mui/styles/withStyles';
import './homepage-styles.scss';
import {validateUserLoginInfo} from "../../utils/misc";
import {Checkbox, FormControlLabel, Link, Typography} from "@mui/material";
import HomepageHeader from "./HomepageHeader";
import {Trans, withTranslation} from "react-i18next";
import {PRIVACY_POLICY_LINK, TERMS_AND_CONDITIONS_LINK} from "../../constants";


export const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'green',
        },
        '& label.Mui-error': {
            color: 'rgba(255,0,0,0.35)',
        },
        '& label.Mui-focused .Mui-error': {
            color: 'rgba(255,0,0,0.35)',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0px',
            '& fieldset': {
                borderColor: 'green',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green'
            },
            '&.Mui-error fieldset': {
                borderColor: 'red'
            },
            '&.Mui-error fieldset .Mui-focused': {
                borderColor: 'red'
            },
        },
    },
})(TextField);

export const CssButton = withStyles({
    root: {
        backgroundColor: 'rgb(233,82,41)',
        color: "white",
        '&:disabled': {
            backgroundColor: 'rgba(233,82,41,0.39)',
            color: "white"
        },
        "&:hover": {
            backgroundColor: 'rgb(233,82,41)'
        }
    }
})(Button);

class RegisterView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            password: '',
            email: '',
            registerAttempt: false,
            fieldsAreValid: false,
            fieldErrors: {},
            mailingListModalOpen: false,
            termsAgreed: false,
            privacyAgreed: false,
        };
    }

    onFieldChange(e, type) {
        const value = e.target.value.trim();
        const next_state = {};
        next_state[type] = value;
        if (this.state.registerAttempt) {
            this.setState(next_state, () => {
                this.updateFieldErrors();
            });
        } else this.setState(next_state)
    }

    onFieldKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.onRegisterClick();
        }
    };

    updateFieldErrors = () => {
        let [fieldsAreValid, fieldErrors] = validateUserLoginInfo(
            this.state.firstName,
            this.state.lastName,
            this.state.email,
            this.state.password
        );
        if (!this.state.termsAgreed) {
            fieldsAreValid = false;
            fieldErrors['terms'] = true;
        }
        if (!this.state.privacyAgreed) {
            fieldsAreValid = false;
            fieldErrors['privacyPolicy'] = true;
        }
        this.setState({
            fieldsAreValid: fieldsAreValid,
            fieldErrors: fieldErrors
        });
    };

    onRegisterClick = () => {
        this.setState({
            registerAttempt: true,
        });
        let [fieldsAreValid, fieldErrors] = validateUserLoginInfo(
            this.state.firstName,
            this.state.lastName,
            this.state.email,
            this.state.password
        );
        if (!this.state.termsAgreed) {
            fieldsAreValid = false;
            fieldErrors['terms'] = true;
        }
        if (!this.state.privacyAgreed) {
            fieldsAreValid = false;
            fieldErrors['privacyPolicy'] = true;
        }
        if (!fieldsAreValid) {
            this.setState({
                fieldsAreValid: fieldsAreValid,
                fieldErrors: fieldErrors
            });
            return
        }

        this.props.registerUser(
            this.state.firstName,
            this.state.lastName,
            this.state.email,
            this.state.password
        )
    };

    onCheckboxChange(which) {
        const cb = () => {
            if (this.state.registerAttempt){
                this.updateFieldErrors();
            }
        };
        if (which === 'TERMS'){
            this.setState({
                termsAgreed: !this.state.termsAgreed
            }, cb)
        }
        if (which === 'PRIVACYPOLICY'){
            this.setState({
                privacyAgreed: !this.state.privacyAgreed
            }, cb)
        }
    }

    render() {
        const [fieldsAreValid, fieldErrors] = [this.state.fieldsAreValid, this.state.fieldErrors];
        const { t } = this.props;
        return (
            <React.Fragment>
                <HomepageHeader logoOnly={true}/>
                <div className='homepage-container'>
                    <img
                        className='homepage-transparent-image'
                        src={require('../../images/2.jpg')}
                        alt="Transparent background"
                    />
                    <Paper className='homepage-paper'>
                        <form>
                            <div className="text-center" style={{textAlign: 'center'}}>
                                <React.Fragment>
                                    <h1><b>{t('registerToOctoscore')}</b></h1>
                                    <hr style={{
                                        borderTop: "5px solid rgba(233, 82, 41, 1)",
                                        width: "20%",
                                        marginBottom: '50px'
                                    }}/>
                                    {this.props.authenticated &&
                                    <React.Fragment>
                                        <div>
                                            <Button
                                                variant="contained"
                                                style={{marginTop: 50}}
                                                onClick={() => {
                                                    browserHistory.push("/")
                                                }}
                                            >
                                                {t('common:access')}
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                variant="contained"
                                                style={{marginTop: 50}}
                                                onClick={this.props.logout}
                                            >
                                                {t('changeUser')}
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                    }

                                    {!this.props.authenticated &&
                                    <div>
                                        <div className="col-md-12">
                                            <CssTextField
                                                fullWidth
                                                error={Boolean(fieldErrors['firstName'])}
                                                autoComplete="off"
                                                variant="outlined"
                                                id="firstName"
                                                label={t('common:firstName')}
                                                placeholder={t('common:firstName')}
                                                helperText={!fieldsAreValid && fieldErrors['firstName']}
                                                FormHelperTextProps={{style: {textAlign: 'center'}}}
                                                className={this.props.textField}
                                                margin="normal"
                                                onKeyPress={this.onFieldKeyPress}
                                                onChange={(e) => this.onFieldChange(e, 'firstName')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <CssTextField
                                                fullWidth
                                                error={Boolean(fieldErrors['lastName'])}
                                                autoComplete="off"
                                                variant="outlined"
                                                id="name"
                                                label={t('common:lastName')}
                                                placeholder={t('common:lastName')}
                                                helperText={!fieldsAreValid && fieldErrors['lastName']}
                                                FormHelperTextProps={{style: {textAlign: 'center'}}}
                                                className={this.props.textField}
                                                margin="normal"
                                                onKeyPress={this.onFieldKeyPress}
                                                onChange={(e) => this.onFieldChange(e, 'lastName')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <CssTextField
                                                fullWidth
                                                error={Boolean(fieldErrors['email']) || Boolean(this.props.registerError)}
                                                autoComplete="off"
                                                variant="outlined"
                                                id="email"
                                                label={t('common:email')}
                                                placeholder={t('common:email')}
                                                helperText={this.props.registerError ? this.props.registerError :
                                                    !fieldsAreValid && fieldErrors['email']}
                                                FormHelperTextProps={{style: {textAlign: 'center'}}}
                                                className={this.props.textField}
                                                type="email"
                                                margin="normal"
                                                onKeyPress={this.onFieldKeyPress}
                                                onChange={(e) => this.onFieldChange(e, 'email')}
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <CssTextField
                                                fullWidth
                                                id="password"
                                                variant="outlined"
                                                label={t('common:password')}
                                                placeholder={t('common:password')}
                                                className={this.props.textField}
                                                style={{color: "green"}}
                                                onKeyPress={this.onFieldKeyPress}
                                                onChange={(e) => this.onFieldChange(e, 'password')}
                                                error={Boolean(fieldErrors['password'])}
                                                helperText={!fieldsAreValid && fieldErrors['password']}
                                                FormHelperTextProps={{style: {textAlign: 'center'}}}
                                                type="password"
                                                margin="normal"
                                            />
                                            {this.props.statusText &&
                                            <h5 style={{color: [403, 500].includes(this.props.status) ? 'red' : 'green'}}>{this.props.statusText}</h5>
                                            }
                                        </div>

                                        <div className="col-md-12" style={{textAlign: 'start'}}>
                                            <FormControlLabel
                                                checked={this.state.termsAgreed}
                                                onChange={() => this.onCheckboxChange('TERMS')}
                                                control={<Checkbox/>}
                                                label={
                                                    <Typography
                                                        variant={"body2"}
                                                        style={(this.state.registerAttempt && fieldErrors['terms']) ?
                                                            {color: 'red'} : null
                                                        }
                                                    >
                                                        <Trans i18nKey={'termsAndConditionsText'}>
                                                            I have read and agree to the Octoscore
                                                            <Link href={TERMS_AND_CONDITIONS_LINK} target="_blank">terms and conditions.</Link>
                                                        </Trans>
                                                         *
                                                    </Typography>
                                                }
                                            />
                                        </div>
                                        <div className="col-md-12" style={{textAlign: 'start'}}>
                                            <FormControlLabel
                                                checked={this.state.privacyAgreed}
                                                onChange={() => this.onCheckboxChange('PRIVACYPOLICY')}
                                                control={<Checkbox/>}
                                                label={
                                                    <Typography
                                                        variant={"body2"}
                                                        style={(this.state.registerAttempt && fieldErrors['privacyPolicy']) ?
                                                            {color: 'red'} : null
                                                        }
                                                    >
                                                        <Trans i18nKey={'privacyPolicyText'}>
                                                            I have read and agree to the Octoscore
                                                            <Link href={PRIVACY_POLICY_LINK} target="_blank">privacy policy</Link>
                                                        </Trans>
                                                         *
                                                    </Typography>
                                                }
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <CssButton
                                                fullWidth
                                                variant="contained"
                                                disabled={this.state.registerAttempt && !this.state.fieldsAreValid && !this.props.registerError}
                                                style={{
                                                    margin: "24px 0px 16px",
                                                    padding: "16px 24px",
                                                    borderRadius: '0px'
                                                }}
                                                onClick={this.onRegisterClick}
                                            >
                                                {t('common:register')}
                                            </CssButton>
                                        </div>
                                        <Typography variant={'overline'}>
                                            {t('youAlreadyHaveAnAccountQuestion')} <Link href={'/login'}>{t('goToLogin')}</Link>
                                        </Typography>
                                    </div>
                                    }
                                </React.Fragment>
                            </div>
                        </form>
                    </Paper>

                </div>
                <HomepageFooter/>
            </React.Fragment>
        );
    }
}

export default withTranslation(['homepage', 'common'])(RegisterView);
