import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import Avatar from '@mui/material/Avatar';
import Tooltip from 'utils/tooltipWithPlacement';

import {changeColorBrightness} from "../../utils/misc";
import DescriptionPopover from "./DescriptionPopover";

const defaultAvatarStyle = {marginLeft: '5px'};
const floatingAvatarStyle = {
    ...defaultAvatarStyle,
    marginBottom: '5px',
    boxShadow: '0px 3px 7px grey'
};
const defaultDivStyle = {display: 'inherit', justifyContent: 'inherit'};
const floatingDivStyle = {
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: '5%',
    left: '0%',
    zIndex: 6
};


class ActiveUsersAvatars extends React.Component {
    render(){
        if (this.props.activeUsers.length <= 1){
            return null;
        }

       return (
            <div style={this.props.floating ? floatingDivStyle : defaultDivStyle}>
                {this.props.activeUsers.map((user, index) => {
                    let avatarStyle = this.props.floating ? floatingAvatarStyle : defaultAvatarStyle;
                    avatarStyle = {
                        ...avatarStyle,
                        backgroundColor: user.color,
                        border: `4px solid ${changeColorBrightness(user.color, -30)}`
                    };

                    let label = "";
                    let userInitials = user.name.split(" ");
                    for (let i = 0; i < userInitials.length; i++){
                        if (i > 2) break;
                        label += userInitials[i][0]
                    }

                    return (
                        <DescriptionPopover key={user.name + index} description={user.name}>
                            <Tooltip title={user.name}>
                                <Avatar style={avatarStyle}>{label}</Avatar>
                            </Tooltip>
                        </DescriptionPopover>
                    )
                })}
            </div>
        )
    }
}

ActiveUsersAvatars.defaultProps = {
    floating: false,
};

ActiveUsersAvatars.propTypes = exact({
    activeUsers: PropTypes.array.isRequired,
    floating: PropTypes.bool,
});

export default ActiveUsersAvatars;