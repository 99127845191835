import i18n from "i18next";
import {initReactI18next} from "react-i18next";
// do we want to use these plugins? check
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonEN from './locales/en/common.json';
import homepageEN from './locales/en/homepage.json'
import appRoutesEN from './locales/en/appRoutes.json'
import bulkEditEN from './locales/en/bulkEdit.json';
import calcTemplatesEN from './locales/en/calcTemplates.json';
import sheetManagementEN from './locales/en/sheetManagement.json';
import calibrationEN from './locales/en/calibration.json';
import exportMarkersEN from './locales/en/exportMarkers.json';
import freeTextManagementEN from './locales/en/freeTextManagement.json';
import dashboardEN from './locales/en/dashboard.json';
import datesEN from './locales/en/dates.json';
import reportsEN from './locales/en/reports.json';
import settingsEN from './locales/en/settings.json';
import projectManagementEN from './locales/en/projectManagement.json';
import userAdminEN from './locales/en/userAdmin.json';
import templateAdminEN from './locales/en/templateAdmin.json';
import deskEN from './locales/en/desk.json';
import reportTemplatesEN from './locales/en/reportTemplates.json';
import actionResponsesEN from './locales/en/actionResponses.json';
import statusMessagesEN from './locales/en/statusMessages.json';
import yourDashboardEN from './locales/en/yourDashboard.json';
import importExportEN from './locales/en/importExport.json';


import commonDE from './locales/de/common.json';
import homepageDE from './locales/de/homepage.json'
import appRoutesDE from './locales/de/appRoutes.json'
import bulkEditDE from './locales/de/bulkEdit.json';
import calcTemplatesDE from './locales/de/calcTemplates.json';
import sheetManagementDE from './locales/de/sheetManagement.json';
import calibrationDE from './locales/de/calibration.json';
import exportMarkersDE from './locales/de/exportMarkers.json';
import freeTextManagementDE from './locales/de/freeTextManagement.json';
import dashboardDE from './locales/de/dashboard.json';
import datesDE from './locales/de/dates.json';
import reportsDE from './locales/de/reports.json';
import settingsDE from './locales/de/settings.json';
import projectManagementDE from './locales/de/projectManagement.json';
import userAdminDE from './locales/de/userAdmin.json';
import templateAdminDE from './locales/de/templateAdmin.json';
import deskDE from './locales/de/desk.json';
import reportTemplatesDE from './locales/de/reportTemplates.json';
import actionResponsesDE from './locales/de/actionResponses.json';
import statusMessagesDE from './locales/de/statusMessages.json';
import yourDashboardDE from './locales/de/yourDashboard.json';
import importExportDE from './locales/de/importExport.json';

import {setInterceptorLocaleHeader} from "./actions/interceptors";
import {LANGUAGES} from "./contexts/LanguageContext";
import {changeDayjsLocale} from "./utils/dayjsWithLocale";

// the translations
const resources = {
    en: {
        common: commonEN,
        homepage: homepageEN,
        appRoutes: appRoutesEN,
        bulkEdit: bulkEditEN,
        sheetManagement: sheetManagementEN,
        calcTemplates: calcTemplatesEN,
        calibration: calibrationEN,
        exportMarkers: exportMarkersEN,
        freeTextManagement: freeTextManagementEN,
        dashboard: dashboardEN,
        dates: datesEN,
        reports: reportsEN,
        settings: settingsEN,
        projectManagement: projectManagementEN,
        userAdmin: userAdminEN,
        templateAdmin: templateAdminEN,
        desk: deskEN,
        reportTemplates: reportTemplatesEN,
        actionResponses: actionResponsesEN,
        statusMessages: statusMessagesEN,
        yourDashboard: yourDashboardEN,
        importExport: importExportEN
    },
    de: {
        common: commonDE,
        homepage: homepageDE,
        appRoutes: appRoutesDE,
        bulkEdit: bulkEditDE,
        sheetManagement: sheetManagementDE,
        calcTemplates: calcTemplatesDE,
        calibration: calibrationDE,
        exportMarkers: exportMarkersDE,
        freeTextManagement: freeTextManagementDE,
        dashboard: dashboardDE,
        dates: datesDE,
        reports: reportsDE,
        settings: settingsDE,
        projectManagement: projectManagementDE,
        userAdmin: userAdminDE,
        templateAdmin: templateAdminDE,
        desk: deskDE,
        reportTemplates: reportTemplatesDE,
        actionResponses: actionResponsesDE,
        statusMessages: statusMessagesDE,
        yourDashboard: yourDashboardDE,
        importExport: importExportDE
    }
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        load: 'languageOnly',
        supportedLngs: ['en', 'de'],
        fallbackLng: 'en',
        lowerCaseLng: true,
        defaultNS: ['homepage'],
        debug: true,
        interpolation: {
            escapeValue: false,
        }
    });
setInterceptorLocaleHeader(i18n.language, () => (i18n.language));
export default i18n;

export function changeLanguage(newLanguage, callback = null) {

    if (!LANGUAGES.some(language => language.value === newLanguage)) {
        return false;
    }
    i18n.changeLanguage(newLanguage, callback && callback);
    setInterceptorLocaleHeader(newLanguage, () => (i18n.language));
    changeDayjsLocale(newLanguage);

}
