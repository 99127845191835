import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Paper, Typography, Divider, Fab, Button, Badge } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
    PersonAdd as RegisterIcon,
    Done as DoneIcon
} from '@mui/icons-material';
import SheetFillView from "../SheetFillView";
import {withTranslation} from "react-i18next";
import backgroundImage from '../../images/2.jpg';
import {AnimatedCheckmark} from "../HomepageComponents/ConfirmationComponent";
import browserHistory from "../../browserHistory";

const theme = {
    containerDiv: {
        padding: 80,
    },
    paper: {
        padding: 20
    },
    bgimage: {
          display: 'block',
          position: 'absolute',
          opacity: 0.015,
          left: 0,
          top: 0,
          width: '100vw',
          height: '100vh',
          objectFit: 'cover',
    },
    fab: {
        position: 'fixed',
        bottom: '5%',
        right: '5%'
    },
    registerButton: {

    },
    checkmarkContainer: {
        margin: 20
    }
};

function ResultInvalidPanel(props){
    const { classes, t } = props;
    return (
        <div className={classes.containerDiv}>
            <Paper className={classes.paper}>
                <Typography variant={'h5'}>
                    {t('invalidResultPanelHeadline')}
                </Typography>
                <Divider/>
                <Typography variant={'body1'}>
                    {t('invalidResultPanelBody')}
                </Typography>
            </Paper>
        </div>
    )
}
ResultInvalidPanel.propTypes = exact({
    classes: PropTypes.object,
    t: PropTypes.func
});

function ResultFinishedPanel(props){
    const { classes, t } = props;
    return (
        <div className={classes.containerDiv}>
            <Paper className={classes.paper}>
                <Typography variant={'h5'}>
                    {t('resultFinishedPanelHeadline')}
                </Typography>
                <Divider/>
                <div className={classes.checkmarkContainer}>
                    <AnimatedCheckmark/>
                </div>

                <Typography variant={'body1'}>
                    {t('resultFinishedPanelBody')}
                </Typography>
                <Button
                    variant={'outlined'}
                    fullWidth
                    startIcon={<RegisterIcon/>}
                    onClick={() => browserHistory.push('/register')}
                >
                    {t('common:register')}
                </Button>
            </Paper>
        </div>
    )
}
ResultFinishedPanel.propTypes = exact({
    classes: PropTypes.object,
    t: PropTypes.func
});


const Container = (props) => {
    const classes = makeStyles(theme)();
    return (
        <React.Fragment>
            <img alt='background' src={backgroundImage} className={classes.bgimage}/>
            <div className={classes.containerDiv}>
                {props.children}
            </div>
        </React.Fragment>
    )
};

class GuestSurveyView extends React.Component {
    render(){
        const { classes, t } = this.props;
        if (!this.props.result || this.props.resultInvalid){
            return (
                <Container>
                    <ResultInvalidPanel
                        classes={classes}
                        t={t}
                    />
                </Container>
            )
        }

        if (this.props.resultFinished){
            return (
                <Container>
                    <ResultFinishedPanel
                        classes={classes}
                        t={t}
                    />
                </Container>
            )
        }

        return (
            <Container>
                <SheetFillView
                    publicResult={true}
                    sheet={this.props.sheet}
                    result={this.props.result}
                    currentResultScores={this.props.currentResultScores}
                    calcTemplate={this.props.currentCalcTemplate}
                    activeUsers={this.props.activeUsers}
                    onClose={this.props.onClose}
                    answerItem={this.props.answerItem}
                    setItemExample={this.props.setItemExample}
                />
                    <Fab
                        color={'primary'}
                        className={classes.fab}
                        onClick={this.props.finishResult}
                    >
                        <Badge
                            color={'error'}
                            badgeContent={this.props.result.errors ? Object.keys(this.props.result.errors).length : null}
                        >
                                <DoneIcon/>
                        </Badge>
                    </Fab>
            </Container>
        )
    }
}
GuestSurveyView.propTypes = exact({
    resultInvalid: PropTypes.bool,
    classes: PropTypes.object,
    i18n: PropTypes.object,
    t: PropTypes.func,
    tReady: PropTypes.bool,
    resultFinished: PropTypes.bool,
    sheet: PropTypes.object,
    result: PropTypes.object,
    currentResultScores: PropTypes.object,
    calcTemplate: PropTypes.object,
    activeUsers: PropTypes.array,
    onClose: PropTypes.func,
    answerItem: PropTypes.func,
    setItemExample: PropTypes.func,
    finishResult: PropTypes.func
});

export default withStyles(theme)(withTranslation(['templateAdmin', 'common'])(GuestSurveyView));
