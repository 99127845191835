import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import {RADIO_GROUP, DROP_DOWN} from '../../constants';
import Badge from '@mui/material/Badge';
import Tooltip from 'utils/tooltipWithPlacement';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import DescriptionPopover from "./DescriptionPopover";
import {sortCatalogItemsByOrder} from "./CatalogItems";
import {withTranslation} from "react-i18next";
import {Autocomplete} from '@mui/material';
import {TextField} from "@mui/material";
import {Clear as DeleteIcon} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";

const itemNameStyle = {marginLeft: '1%', textAlign: 'left', hyphens: 'auto'};
const commentButtonSelectedStyle = {backgroundColor: '#cecece'};


class OptionItemProduction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : "",
            inputValue: this.props.value ? this.props.value : ""
        };
    }

    onChange = (event, newValue, reason) => {
        if (reason === 'removeOption') {
            if (typeof this.props.value === 'string') {
                this.props.onChange(this.props.resultId, this.props.itemId, this.props.value);
            } else {
                let valueToRemove = this.props.value.filter(value => newValue.indexOf(value) === -1)
                if (valueToRemove.length > 0) {
                    valueToRemove = valueToRemove[0]
                    this.props.onChange(this.props.resultId, this.props.itemId, valueToRemove);
                }
                return;
            }
        }
        if (this.props.disabled) {
            return
        }
        if (reason === 'clear') {
            if (this.props.multiselect) {
                this.props.onRemoveAnswer(this.props.resultId, this.props.itemId);
            } else {
                this.setState({value: "", inputValue: ""});
                this.props.onRemoveAnswer(this.props.resultId, this.props.itemId);
            }
        } else if (this.props.multiselect) {
            this.setState({value: newValue, inputValue: newValue});
            this.props.onChange(this.props.resultId, this.props.itemId, newValue[newValue.length - 1]);
        } else if (newValue && this.props.onChange) {
            this.setState({value: newValue, inputValue: newValue});
            this.props.onChange(this.props.resultId, this.props.itemId, newValue);
        }
    };

    onRadioGroupChange = (newValue) => {
        if (this.props.onChange && !this.props.disabled) {
            this.setState({value: newValue});
            this.props.onChange(this.props.resultId, this.props.itemId, newValue);
        }
    };

    onInputChange = (event, newValue) => {
        this.setState({inputValue: newValue});
    };

    withTooltip = (radioElement, description) => {
        if (!description) return radioElement;
        return (
            <Tooltip placement="right" title={description}>
                {radioElement}
            </Tooltip>
        )
    };

    getOptions = () => {
        let options = this.props.itemOption.options;
        if (this.props.allowedAnswers) {
            options = options.filter(option => this.props.allowedAnswers.includes(option.option_id));
        }
        return sortCatalogItemsByOrder(options);
    };

    render() {
        const {t} = this.props;
        let id = this.props.itemId;
        let value = this.props.value ? this.props.value : undefined
        if (this.props.value && this.props.multiselect) {
            if (typeof value === 'string') {
                value = [value]
            }
        }
        if (this.props.resultRefInId) {
            id = `${this.props.resultId}/${this.props.itemId}`;
        }

        return (
            <div id={id} className="row small-gutters display-flex" style={{alignItems: 'center'}}>
                <div className="col-sm-2 col-xs-12">
                    <Tooltip
                        title={this.props.itemDescription !== null ?
                            this.props.itemDescription : ""} placement="right"
                    >
                        <h4 style={itemNameStyle}>{this.props.itemName}
                            {this.props.itemRequired.length > 0 &&
                                <span style={{color: 'red'}}> *</span>
                            }
                        </h4>
                    </Tooltip>
                </div>
                {this.props.avatarContainer &&
                    <div
                        className={'col-sm-2 col-xs-12'}
                        id={'itemAvatarContainer' + this.props.itemId}
                        style={{display: 'inline-flex'}}
                    >
                    </div>
                }
                <div className={`col-sm-${this.props.avatarContainer ? '1' : '3'}`}
                     style={{display: 'flex', justifyContent: 'end'}}>
                    <div style={{marginRight: '20px'}}>
                        <h5 style={{
                            margin: 0,
                            color: '#8300ff'
                        }}>{typeof this.props.itemScore === 'number' && this.props.itemWeight
                        && !this.props.itemWeight.deactivated ? "G: " + this.props.itemWeight.weight : '\u00A0'}</h5>

                        <h5 style={{margin: 0, color: '#497993'}}>{typeof this.props.itemScore === 'number' ?
                            "S: " + this.props.itemScore.toString() + (this.props.displayPercentages ? "%" : "") : '\u00A0'}
                        </h5>
                    </div>
                </div>
                <div
                    className={"col-sm-7 col-xs-12"}
                    style={{textAlign: 'left'}}
                >
                    {this.props.itemOption.type === RADIO_GROUP &&
                        <React.Fragment>
                            {this.getOptions().map((value) => {
                                if (this.props.multiselect) {
                                    return (
                                        <FormControlLabel
                                            key={value.option_id}
                                            value={value.name}
                                            control={this.withTooltip(<Checkbox
                                                    checked={this.props.value && this.props.value.includes(value.name)}
                                                    onChange={(event) => {
                                                        event.preventDefault();
                                                        this.onRadioGroupChange(value.name);
                                                    }} />,
                                                this.props.optionDescriptions[value.option_id],
                                                this.props.optionOverrides[value.option_id])}
                                            label={value.name}
                                            disabled={
                                                this.props.optionOverrides[value.option_id] ?
                                                    this.props.optionOverrides[value.option_id].disabled
                                                    :
                                                    false
                                            }
                                            labelPlacement="end"
                                        />);
                                } else {

                                    return (
                                        <FormControlLabel
                                            key={value.option_id}
                                            label={value.name}
                                            value={value.name}
                                            control={this.withTooltip(
                                                <Radio
                                                    color="primary"
                                                    key={value.name + "radio"}
                                                    checked={value.name === this.props.value}
                                                    value={value.name}
                                                    disabled={
                                                        this.props.optionOverrides[value.option_id] ?
                                                            this.props.optionOverrides[value.option_id].disabled
                                                            :
                                                            false
                                                    }
                                                    onChange={(event) => {
                                                        event.preventDefault();
                                                        this.onRadioGroupChange(value.name);
                                                    }}
                                                />,
                                                this.props.optionDescriptions[value.option_id]
                                            )}
                                        />
                                    )
                                }
                            })
                            }
                            {this.props.value &&
                                <IconButton
                                    onClick={() => {
                                        this.props.onRemoveAnswer(this.props.resultId, this.props.itemId)
                                    }}
                                    size="large">
                                    <DeleteIcon/>
                                </IconButton>
                            }
                        </React.Fragment>
                    }
                    {this.props.itemOption.type === DROP_DOWN &&
                        <Autocomplete
                            id={this.props.itemId}
                            fullWidth
                            noOptionsText={t('common:noHit')}
                            disabled={this.props.disabled}
                            onChange={this.onChange}
                            renderInput={(params) => (
                                <TextField
                                    variant="standard"
                                    {...params}
                                    placeholder={t('common:choice')}
                                />
                            )}
                            value={value}
                            inputValue={ this.props.multiselect ? undefined : this.state.inputValue && this.state.inputValue}
                            onInputChange={this.onInputChange}
                            options={this.getOptions().map(value => {
                                return value.name
                            })}
                            multiple={this.props.multiselect}
                        />
                    }
                </div>
                {this.props.showCommentButton &&
                    <div className="col-xs-1">
                        <Badge
                            variant={'dot'}
                            color={'primary'}
                            overlap="circular"
                            badgeContent={this.props.comments.length}
                        >
                            <IconButton
                                style={this.props.commentButtonSelected ? commentButtonSelectedStyle : null}
                                onClick={(event) => {
                                    this.props.onCommentButtonClick(event, id)
                                }}
                                size="large">
                                <ModeCommentIcon/>
                            </IconButton>
                        </Badge>
                    </div>
                }
            </div>
        );
    }
}

OptionItemProduction.defaultProps = {
    optionOverrides: {},
    optionDescriptions: {},
    showCommentButton: false,
    disabled: false,
    itemWeight: null,
    itemScore: null,
    itemRequired: [],
    comments: [],
    avatarContainer: false,
    commentButtonSelected: false,
    resultRefInId: false,
    displayPercentages: true,
    value: null
};

OptionItemProduction.propTypes = exact({
    resultId: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    itemName: PropTypes.string.isRequired,
    itemDescription: PropTypes.string,
    itemRequired: PropTypes.array,
    itemOption: PropTypes.object.isRequired,
    multiselect: PropTypes.bool.isRequired,
    optionOverrides: PropTypes.object,
    optionDescriptions: PropTypes.object,
    onChange: PropTypes.func,
    onRemoveAnswer: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    showCommentButton: PropTypes.bool,
    onCommentButtonClick: PropTypes.func,
    itemWeight: PropTypes.object,
    itemScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    comments: PropTypes.arrayOf(PropTypes.object),
    avatarContainer: PropTypes.bool,
    commentButtonSelected: PropTypes.bool,
    resultRefInId: PropTypes.bool,
    t: PropTypes.func,
    i18n: PropTypes.object,
    tReady: PropTypes.bool,
    displayPercentages: PropTypes.bool,
    allowedAnswers: PropTypes.array,
});

export default withTranslation(['sheetManagement', 'common'])(OptionItemProduction);
