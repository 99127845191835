/* eslint max-len: 0, no-param-reassign: 0 */
//import { UserAuthWrapper } from 'redux-auth-wrapper';
import NotFound from '../components/NotFound';
import dayjs from './dayjsWithLocale';
import i18n from '../i18n';

// export const VisibleOnlyAdmin = (Roles, Component) => UserAuthWrapper({
//         authSelector: state => state.data,
//         wrapperDisplayName: 'VisibleOnlyAdmin',
//         predicate: data => Roles.includes(data.user.currentProject.role),
//         FailureComponent: NotFound
// })(Component);

export function createConstants(...constants) {
    return constants.reduce((acc, constant) => {
        acc[constant] = constant;
        return acc;
    }, {});
}

export function createReducer(initialState, reducerMap) {
    return (state = initialState, action) => {
        const reducer = reducerMap[action.type];


        return reducer
            ? reducer(state, action.payload)
            : state;
    };
}


export function parseJSON(response) {
    return response.data;
}


export function validateUserPersonalInfo(firstName, lastName, email){
    let errors = {};
    if (!firstName|| !lastName || !email){
        if (!firstName) errors['firstName'] = i18n.t('settings:firstNameShouldNotBeEmpty');
        if (!lastName) errors['lastName'] = i18n.t('settings:lastNameShouldNotBeEmpty');
        if (!email) errors['email'] = i18n.t("settings:emailShouldNotBeEmpty");
    }
    if (!validateEmail(email)){
        errors['email'] = i18n.t('settings:emailShouldNotBeEmpty');
    }
    if (Object.keys(errors).length){
        return [false, errors]
    }
    return [true, errors];
}

export function validateUserPassword(password, passwordRepeat=null){
    let errors = {};
    if (!password){
        errors['password'] = i18n.t('settings:passwordShouldNotBeEmpty');
    }
    if (password.length < 6){
        errors['password'] = i18n.t('settings:passwordNotLongEnough');
    }
    if (passwordRepeat !== null){
        if (password !== passwordRepeat){
            errors['password'] = i18n.t('settings:passwordsNotTheSame')
        }
    }
    if (Object.keys(errors).length){
        return [false, errors];
    }
    return [true, errors];
}


export function validateUserLoginInfo(firstName, lastName, email, password){
    let [personalInfoValid, personalInfoErrors] = validateUserPersonalInfo(firstName, lastName, email);
    let [passwordValid, passwordErrors] = validateUserPassword(password);
    if (personalInfoValid && passwordValid){
        return [true, {}];
    }
    return [false, {...personalInfoErrors, ...passwordErrors}];
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function validateDate(inputDate) {
    let isValid = false;
    let t = inputDate.match(/^(\d{4})-(\d{2})-(\d{2})$/);

    if (t !== null) {
        let y = +t[1], m = +t[2], d = +t[3];
        let date = new Date(y, m - 1, d);

        isValid = (date.getFullYear() === y && date.getMonth() === m - 1) ;
    }
    return isValid;
}

export function requiredIf(type, condition) {
  return function(props, propName, componentName) {
    if (typeof type !== "function") {
      return new Error(
        'Invalid react-required-if prop type supplied to ' + componentName + '. Validation failed.'
      );
    }

    if (typeof condition !== "function") {
      return new Error(
        'Invalid react-required-if condition supplied to ' + componentName + '. Validation failed.'
      );
    }

    let test = condition(props) ? type.isRequired : type;
    return test.apply(this, arguments);
  };
}

export function changeColorBrightness(color, amount){
    let usePound = false;
    if (color[0] === "#") {
        color = color.slice(1);
        usePound = true;
    }

    let num = parseInt(color,16);

    let r = (num >> 16) + amount;

    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amount;

    if (b > 255) b = 255;
    else if  (b < 0) b = 0;

    let g = (num & 0x0000FF) + amount;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}


export function getDateRange(startDate, endDate){
    let dateArray = [];
    startDate = dayjs.utc(startDate).set('hour', 0).set('minute', 0).set('second', 1);
    endDate = dayjs.utc(endDate).set('hour', 23).set('minute', 59).set('second', 59);
    let loopDate = startDate.clone();
    while (loopDate.isBefore(endDate)){
        dateArray.push(loopDate.toDate());
        loopDate = loopDate.add(1, 'day');
    }
    return dateArray;
}

export function getMonthRange(startDate, endDate){
    let dateArray = [];
    startDate = dayjs(startDate).startOf('month');
    endDate = dayjs(endDate).endOf('month');
    let currentDate = startDate.clone();
    while (currentDate.isBefore(endDate)){
        dateArray.push(currentDate.clone());
        currentDate = currentDate.clone().add(1, 'month');
    }
    return dateArray;
}

export function sortObjArrayByDate(array, dateKey){
    return array.sort((a, b) => {
        let aDate = new Date(a[dateKey]);
        let bDate = new Date(b[dateKey]);
        return aDate - bDate;
    })
}

export function sortObjectArrayByName(array, keyName) {
    return array.sort( (a, b) => {
        let aName = a[keyName];
        let bName = b[keyName];
        if (aName < bName) {
            return -1;
        }
        if (aName > bName) {
            return 1;
        }
        return 0;
    })
}
