import React from 'react';
import browserHistory from '../../browserHistory';
import Paper from '@mui/material/Paper';

const cardStyle = {
    padding: 20,
    margin: 30,
    textAlign: 'center',
    display: 'inline-block',
    cursor: 'pointer'
};

export default class HomePaper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            elevation: 1,
        }
    }

    shouldComponentUpdate(newProps, newState){
        // this.props.symbol gets re-referenced by parent every render for some reason
        // Only elevation changes makes a difference for this component.
        return (newState.elevation !== this.state.elevation);
    }

    onMouseOver = () => {
        this.setState({elevation: 5})
    };

    onMouseOut = () => {
        this.setState({elevation: 1})
    };

    onClick = (event) => {
      event.preventDefault();
      browserHistory.push(this.props.browserAddress);
    };

    render() {
        return (
            <Paper
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                elevation={this.state.elevation}
                style={cardStyle}
                onClick={this.onClick}
            >
                <h3>{this.props.headline}</h3>
                {this.props.symbol}
            </Paper>
        )
    }
}