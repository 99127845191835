import {AppBar, Button, ButtonGroup, ListItemText, Menu, MenuItem} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExitToApp from "@mui/icons-material/ExitToApp";
import PersonAdd from "@mui/icons-material/PersonAdd";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from '@mui/icons-material/Language';
import withStyles from '@mui/styles/withStyles';
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import browserHistory from '../../browserHistory';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import {withTranslation} from "react-i18next";
import Logo from "../../images/Logo Octoscore.svg";


function HideOnScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({target: window ? window() : undefined});

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:hover': {
            backgroundColor: '#E95229',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: '#fff',
            },
        },
    },
}))(MenuItem);

const NavBarButton = withStyles(theme => ({
    root: {
        nozBorderRadius: '0px',
        webkitBorderEadius: '0px',
        borderRadius: '0px',
        color: theme.palette.getContrastText('#fefefe'),
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#E95229',
            color: '#fff'
        },
    },
}))(Button);


class HomepageHeader extends React.Component {

    constructor() {
        super();
        this.state = {
            anchorEl: null,
            isMobile: false,
            drawerOpen: false,
            sublistOpen: true,
        };
    }

    onLoginClick = () => {
        if (this.props.authenticated) {
            browserHistory.push('/')
        } else browserHistory.push('/login');
    };

    openFeatureMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    closeFeatureMenu = () => {
        this.setState({anchorEl: null});
    };

    throttledHandleWindowResize = () => {
        this.setState({isMobile: window.innerWidth < 760});
    };

    componentDidMount() {
        window.addEventListener('resize', this.throttledHandleWindowResize);
        this.throttledHandleWindowResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.throttledHandleWindowResize);
    }

    toggleDrawer = (event, open) => {
        this.setState({drawerOpen: open});
    };

    toggleSublist = () => {
        this.setState({sublistOpen: !this.state.sublistOpen});
    };

    render() {
        const {i18n, t} = this.props;
        const changeLanguage = (lang) => {
            i18n.changeLanguage(lang);
        };

        return (
            <HideOnScroll {...this.props}>
                <AppBar style={{backgroundColor: '#fefefe'}}>
                    <Toolbar>
                        <a href={"https://octoscore.de"}>
                            <img
                                src={Logo}
                                style={{maxWidth: '200px'}}
                                alt={'logoimg'}
                            />
                        </a>
                        {!this.props.logoOnly && this.state.isMobile &&
                        <IconButton
                            style={{color: '#E95229', marginLeft: 'auto'}}
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={event => this.toggleDrawer(event, true)}
                            size="large">
                            <MenuIcon/>
                        </IconButton>}
                        <Drawer anchor="right" open={this.state.drawerOpen}
                                onClose={event => this.toggleDrawer(event, false)}>
                            <List component="nav" style={{width: '100%'}}>
                                <StyledMenuItem onClick={event => {
                                    this.toggleSublist()
                                }}>
                                    <ListItemText primary="Features"/>
                                    {this.state.sublistOpen ? <ExpandLess/> : <ExpandMore/>}
                                </StyledMenuItem>
                                <Collapse in={this.state.sublistOpen} timeout="auto" unmountOnExit>
                                    <List disablePadding>
                                        <StyledMenuItem button>
                                            <ListItemText primary={t('sheets')} onClick={event => {
                                                this.props.scrollToElement(this.props.sheetRef);
                                                this.setState({drawerOpen: false})
                                            }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('desk')}
                                                          onClick={event => {
                                                              this.props.scrollToElement(this.props.deskRef);
                                                              this.setState({drawerOpen: false})
                                                          }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('calibration')}
                                                          onClick={event => {
                                                              this.props.scrollToElement(this.props.calibrationRef);
                                                              this.setState({drawerOpen: false})
                                                          }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('reports')} onClick={
                                                event => {
                                                    this.props.scrollToElement(this.props.reportsRef);
                                                    this.setState({drawerOpen: false})
                                                }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('calcTemplates')}
                                                          onClick={event => {
                                                              this.props.scrollToElement(this.props.calcTemplatesRef);
                                                              this.setState({drawerOpen: false})
                                                          }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('freeText')}
                                                          onClick={event => {
                                                              this.props.scrollToElement(this.props.freeTextRef);
                                                              this.setState({drawerOpen: false})
                                                          }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('bulkEdit')}
                                                          onClick={event => {
                                                              this.props.scrollToElement(this.props.bulkEditRef);
                                                              this.setState({drawerOpen: false})
                                                          }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('projectManagement')}
                                                          onClick={event => {
                                                              this.props.scrollToElement(this.props.projectManagementRef);
                                                              this.setState({drawerOpen: false})
                                                          }}/>
                                        </StyledMenuItem>
                                    </List>
                                </Collapse>
                                <Divider/>
                                <StyledMenuItem>
                                    <ListItemText primary={t('roadmap')}
                                                  onClick={event => {
                                                      browserHistory.push('/roadmap')
                                                  }}/>
                                </StyledMenuItem>
                                <StyledMenuItem>
                                    <ListItemText primary={t('pricing')}
                                                  onClick={event => {
                                                      browserHistory.push('/pricing')
                                                  }}/>
                                </StyledMenuItem>
                                <Divider/>
                                <StyledMenuItem>
                                    <ListItemIcon>
                                        <ExitToApp/>
                                    </ListItemIcon>
                                    <ListItemText primary={this.props.authenticated ? t('access') : t('login')}
                                                  onClick={() => {
                                                      this.onLoginClick()
                                                  }}/>
                                </StyledMenuItem>
                                {!this.props.authenticated &&
                                <StyledMenuItem>
                                    <ListItemIcon>
                                        <PersonAdd/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={t('register')}
                                        onClick={() => browserHistory.push('/register')}
                                    />
                                </StyledMenuItem>
                                }
                            </List>
                        </Drawer>
                        {this.props.logoOnly || this.state.isMobile ? null :
                            <React.Fragment>
                                <ButtonGroup variant="text" style={{marginLeft: 'auto'}}>
                                    <NavBarButton endIcon={<ExpandMoreIcon/>} onClick={this.openFeatureMenu}>
                                        {t('features')}
                                    </NavBarButton>
                                    <StyledMenu
                                        id="customized-menu"
                                        variant="menu"
                                        anchorEl={this.state.anchorEl}
                                        open={Boolean(this.state.anchorEl)}
                                        onClose={this.closeFeatureMenu}
                                    >
                                        <StyledMenuItem>
                                            <ListItemText primary={t('sheets')} onClick={event => {
                                                this.props.scrollToElement(this.props.sheetRef);
                                                this.setState({anchorEl: null})
                                            }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('desk')}
                                                          onClick={event => {
                                                              this.props.scrollToElement(this.props.deskRef);
                                                              this.setState({anchorEl: null})
                                                          }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('calibration')}
                                                          onClick={event => {
                                                              this.props.scrollToElement(this.props.calibrationRef);
                                                              this.setState({anchorEl: null})
                                                          }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('reports')} onClick={
                                                event => {
                                                    this.props.scrollToElement(this.props.reportsRef);
                                                    this.setState({anchorEl: null})
                                                }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('calcTemplates')}
                                                          onClick={event => {
                                                              this.props.scrollToElement(this.props.calcTemplatesRef);
                                                              this.setState({anchorEl: null})
                                                          }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('freeText')}
                                                          onClick={event => {
                                                              this.props.scrollToElement(this.props.freeTextRef);
                                                              this.setState({anchorEl: null})
                                                          }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('bulkEdit')}
                                                          onClick={event => {
                                                              this.props.scrollToElement(this.props.bulkEditRef);
                                                              this.setState({anchorEl: null})
                                                          }}/>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItemText primary={t('projectManagement')}
                                                          onClick={event => {
                                                              this.props.scrollToElement(this.props.projectManagementRef);
                                                              this.setState({anchorEl: null})
                                                          }}/>
                                        </StyledMenuItem>
                                    </StyledMenu>
                                    <NavBarButton>
                                        {t('roadmap')}
                                    </NavBarButton>
                                    <NavBarButton onClick={event => {
                                                      browserHistory.push('/pricing')
                                                  }}>
                                        {t('pricing')}
                                    </NavBarButton>
                                </ButtonGroup>
                            </React.Fragment>
                        }
                        {this.props.logoOnly || this.state.isMobile ? null :
                            <ButtonGroup variant="text" style={{marginLeft: 'auto'}}>
                                <NavBarButton startIcon={<ExitToApp/>} onClick={this.onLoginClick}>
                                    {this.props.authenticated ? t('access') : t('login')}
                                </NavBarButton>
                                {!this.props.authenticated &&
                                <NavBarButton
                                    startIcon={<PersonAdd/>}
                                    onClick={() => browserHistory.push('/register')}
                                >
                                    {t('register')}
                                </NavBarButton>
                                }
                                <NavBarButton
                                    startIcon={<LanguageIcon/>}
                                    onClick={() => {
                                        if (i18n.language === "en"){
                                            changeLanguage('de');
                                        }
                                        else {
                                            changeLanguage('en');
                                        }
                                    }}
                                />
                            </ButtonGroup>
                        }
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
        );
    }
}
export default withTranslation(['common'])(HomepageHeader);
