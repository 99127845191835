import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import H5AudioPlayer from "react-h5-audio-player";
import 'react-h5-audio-player/lib/styles.css';
import {useTranslation} from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    IconButton,
    Typography,
    CircularProgress,
    useMediaQuery
} from "@mui/material";
import AttachIcon from "@mui/icons-material/AttachFile";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/GetApp"
import DeleteIcon from "@mui/icons-material/Delete"

import DescriptionPopover from "../CatalogComponents/DescriptionPopover";
import {getDialogConfirmation} from "../Dialog";
import {MAX_FILE_UPLOAD_SIZE} from "../../constants";
import {requiredIf} from "../../utils/misc";
import dayjs from "../../utils/dayjsWithLocale";


export function isMediaFile(file) {
    return ['wav', 'mp3', 'ogg'].includes(file.extension);
}

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    list: {
        width: '100%'
    },
    secondaryAction: {
        display: 'flex',
        alignContent: 'flex-end',
        alignItems: 'center',
        justifyContent: 'end',
    },
    secondaryActionMedia: {
        display: 'flex',
        alignContent: 'flex-end',
        alignItems: 'center',
        justifyContent: 'end',
        width: '50%'
    },
    rhapOverwrites: {
        background: 'none',
        margin: '5px',
        padding: 0,
        boxShadow: 'none',
        width: '100%'
    },
    fileUpload: {
        border: '1px solid #ccc',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '6px 12px',
        cursor: 'pointer'
    },
    fileUploadInput: {
        display: 'none'
    },
    fileListItemRoot: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '8px 16px 8px 16px',
        borderBottom: '1px solid #e0e0e0',
    },
    dataItem: {
        backgroundColor: '#fff',
        transition: '0.3s',
        '&:hover': {
            backgroundColor: '#e0e0e0'
        }
    },
    fileListItemRow: {
        width: '100%',
        alignItems: 'center'
    },
    fileListItemSecondary: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    disabled: {
        textDecoration: "line-through",
        fontStyle: "italic",
        color: "#909090"
    }
}));

export function AttachFilePanel(props) {
    const {
        files,
        onUploadFile,
        onDownloadFile,
        onDeleteFile,
        readOnly
    } = props;
    const {t} = useTranslation('common');

    return (
        <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                {t('attachFiles')}
            </AccordionSummary>

            <AccordionDetails>
                <AttachedFilesList
                    files={files}
                    onUploadFile={onUploadFile}
                    onDownloadFile={onDownloadFile}
                    onDeleteFile={onDeleteFile}
                    readOnly={readOnly}
                />
            </AccordionDetails>
        </Accordion>
    )
}

AttachFilePanel.defaultProps = {
    readOnly: false
};

AttachFilePanel.propTypes = exact({
    files: PropTypes.arrayOf(PropTypes.object),
    onUploadFile: requiredIf(PropTypes.func, props => !props.readOnly),
    onDownloadFile: requiredIf(PropTypes.func, props => !props.readOnly),
    onDeleteFile: requiredIf(PropTypes.func, props => !props.readOnly),
    readOnly: PropTypes.bool.isRequired
});

function AttachedFilesList(props) {
    const classes = useStyles();
    const {files, onUploadFile, onDownloadFile, onDeleteFile, readOnly} = props;
    const [popoverState, setPopoverState] = useState({
        popoverOpen: false,
        popoverAnchor: null,
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const closePopover = () => {
        setSelectedFile(null);
        setPopoverState({popoverOpen: false});
    }
    const onMenuClick = (event, fileId) => {
        let file = files.find(file => file.file_id === fileId);
        setSelectedFile(file);
        setPopoverState({
            popoverOpen: true,
            popoverAnchor: event.currentTarget
        });
    };

    // Wraps delete/upload handler so we can display a loading spinner after user request
    const requestHandler = (fn) => {
        return (...args) => {
            setLoading(true);
            return fn(...args);
        }
    };
    // If files changed, we can assume that the request was completed and hide spinner, and reset file selection
    useEffect(() => {
        setLoading(false);
    }, [files]);

    return (
        <React.Fragment>
            {!readOnly &&
                <React.Fragment>
                    <FileMenuPopover
                        open={popoverState.popoverOpen}
                        anchor={popoverState.popoverAnchor}
                        file={selectedFile}
                        onDownloadClick={onDownloadFile}
                        onDeleteClick={requestHandler(onDeleteFile)}
                        onClose={closePopover}
                    />

                </React.Fragment>
            }
            <List className={classes.list}>
                {!readOnly &&
                   <div style={{display: 'flex'}}>
                        <AttachFileListItem
                            onUploadFile={requestHandler(onUploadFile)}
                            loading={loading}
                        />
                        {loading &&
                            <CircularProgress/>
                        }
                   </div>
                }
                {files.map(file => {
                    return (
                        <FileListItem
                            key={file.file_id}
                            file={file}
                            onClick={onDownloadFile}
                            onMenuClick={onMenuClick}
                            readOnly={readOnly}
                        />
                    )
                })}
            </List>
        </React.Fragment>
    )
}

AttachedFilesList.propTypes = exact({
    files: PropTypes.arrayOf(PropTypes.object),
    onUploadFile: requiredIf(PropTypes.func, props => !props.readOnly),
    onDownloadFile: requiredIf(PropTypes.func, props => !props.readOnly),
    onDeleteFile: requiredIf(PropTypes.func, props => !props.readOnly),
    readOnly: PropTypes.bool.isRequired
});

function FileMenuPopover(props) {
    const {t} = useTranslation('common');
    const {
        open,
        anchor,
        anchorOrigin = {horizontal: 'left', vertical: 'bottom'},
        transformOrigin = {horizontal: 'left', vertical: 'top'},
        file,
        onClose,
        onDownloadClick,
        onDeleteClick
    } = props;

    const downloadClick = () => {
        onDownloadClick(file.file_id);
        onClose();
    };
    const deleteClick = () => {
        getDialogConfirmation(t('areYouSure'), '', t('yes'), t('no')).then(confirmed => {
            if (confirmed) {
                onDeleteClick(file.file_id);
                onClose();
            }
        });
    };

    return (
        <Popover
            open={open}
            anchorEl={anchor}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            onClose={onClose}
        >
            <ListItem
                button
                divider
                onClick={downloadClick}
            >
                <ListItemIcon>
                    <DownloadIcon/>
                </ListItemIcon>
                <ListItemText primary={t('download')}/>
            </ListItem>

            <ListItem
                button
                divider
                onClick={deleteClick}
            >
                <ListItemIcon>
                    <DeleteIcon/>
                </ListItemIcon>
                <ListItemText primary={t('delete')}/>
            </ListItem>
        </Popover>
    )
}

FileMenuPopover.propTypes = exact({
    open: PropTypes.bool.isRequired,
    anchor: PropTypes.object,
    anchorOrigin: PropTypes.object,
    transformOrigin: PropTypes.object,
    file: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onDownloadClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired
});

function FileListItem(props) {
    const classes = useStyles();
    const {
        file,
        onClick,
        onMenuClick,
        readOnly
    } = props;
    const openMenu = (event) => {
        event.stopPropagation();
        onMenuClick(event, file.file_id);
    };
    const { t } = useTranslation('common');
    const onClickWrap = () => onClick(file.file_id);
    const isMedia = isMediaFile(file);
    const isTiny = useMediaQuery('(max-width: 600px)');
    const isDisabled = Boolean(file.deletion_date);
    return (
        <div
            className={`${classes.fileListItemRoot} ${!isMedia && !isDisabled ? classes.dataItem : ''}`}
            onClick={(isMedia || readOnly || isDisabled) ? null : onClickWrap}
        >
            <div className={`${classes.fileListItemRow} row display-flex`}>
                <div className={'col-md-6 col-sm-12 col-xs-12'}>
                    {isDisabled ?
                        <DescriptionPopover description={t('fileDeletedAtDate', {date: dayjs(file.deletion_date).format('LLL')})}>
                            <Typography className={classes.disabled}>
                                {file.name}
                            </Typography>
                        </DescriptionPopover>
                        :
                        <Typography>
                            {file.name}
                        </Typography>
                    }
                </div>
                <div className={`${classes.fileListItemSecondary} col-md-6 col-sm-12 col-xs-12`}>
                    {(isMedia && !isDisabled) &&
                        <H5AudioPlayer
                            src={file.url}
                            className={classes.rhapOverwrites}
                            layout={isTiny ? 'stacked' : 'horizontal'}
                            customAdditionalControls={[]}
                            autoPlayAfterSrcChange={false}
                        />
                    }
                    {(!readOnly && !isDisabled) &&
                        <IconButton onClick={openMenu} size="large">
                            <MoreVertIcon/>
                        </IconButton>
                    }
                </div>
            </div>
        </div>
    );
}

FileListItem.propTypes = exact({
    file: PropTypes.object.isRequired,
    onClick: requiredIf(PropTypes.func, props => !props.readOnly),
    onMenuClick: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired
});

export function AttachFileListItem(props) {
    const classes = useStyles();
    const {t} = useTranslation('common');
    const {
        onUploadFile,
        loading
    } = props;
    const [isUploading, setIsUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState('');

    const onChange = (event) => {
        // should we allow multiple files to be uploaded in one go?
        let file = event.target.files[0];
        let sizeMb = file.size / Math.pow(1024, 2);
        setSelectedFile(event.target.files[0]);

        if (sizeMb > MAX_FILE_UPLOAD_SIZE) {
            setError(t('fileMayNotExceedXmb', {mbLimit: MAX_FILE_UPLOAD_SIZE}));
            return
        } else if (Boolean(error)) {
            setError('');
        }
        setIsUploading(true);
        onUploadFile(event.target.files[0]);
    };
    // props.loading is flipped when upload is done
    // this resets the selected item once the upload is done
    useEffect(() => {
        if (isUploading && !loading){
            setSelectedFile(null);
            setIsUploading(false);
        }
    }, [loading]);

    return (
        <ListItem
            divider
        >
            <label htmlFor={'file-upload'} className={classes.fileUpload}>
                <AttachIcon/> {t('attachFile')}
            </label>
            <input
                className={classes.fileUploadInput}
                type={'file'}
                id={'file-upload'}
                onChange={onChange}
            />
            {selectedFile &&
                <ListItemText
                    inset
                    primary={selectedFile.name}
                    secondary={Boolean(error) && error}
                    secondaryTypographyProps={{color: 'error'}}
                />
            }
        </ListItem>
    )
}

AttachFileListItem.propTypes = exact({
    onUploadFile: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
});

