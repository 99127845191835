import {
    RECEIVE_PROTECTED_DATA,
    RECEIVE_USERS,
    RECEIVE_PROJECTS,
    RECEIVE_ROLES,
    RECEIVE_USERS_FAILURE,
    GET_SCORECARD_DATA,
    CHANGE_PROJECT,
    RENAME_PROJECT,
    PUT_PROJECT,
    GET_SHEET_TYPES,
    PUT_SHEET_TYPE,
    SET_CURRENT_SHEET,
    RECEIVE_PARTNERS,
    RECEIVE_PARTNER,
    GET_SHEET_METADATA,
    RECEIVE_PROJECT_USERS,
    ASSIGN_USER_TO_PROJECT,
    JOIN_MAILING_LIST_SUCCESS,
    JOIN_MAILING_LIST_FAILURE,
    UPDATE_USER_PERSONAL_INFO,
    REMOVE_USER_FROM_PROJECT,
    STATUS_UPDATE,
    PRICING_INQUIRY_SUCCESS,
    PRICING_INQUIRY_FAILURE,
    SET_USER_LANGUAGE,
    CHANGE_PROJECT_RETENTION_DAYS,
    TOGGLE_BACKGROUND,
    CREATE_USER,
    RECEIVE_ASSOCIATED_USERS,
    GET_SHEET_RESULT_REPORT_DATA,
    SOCKET_REQUEST,
    GET_HISTORIC_RAW_RESULTS, GET_RAW_RESULTS, TOGGLE_PROJECT_ATTENDEE_EMAILS
} from '../constants/index';
import {parseJSON} from '../utils/misc';
import {
    data_about_user,
    getUsers,
    getProjects,
    getRoles,
    getScorecardData,
    changeCurrentProject,
    put_project,
    get_sheet_types,
    put_sheet_type,
    get_partners,
    delete_partner,
    get_workbench_sheet,
    get_sheet_metadata,
    create_new_result,
    get_result_metadata,
    get_guest_result,
    get_result,
    assign_user_to_project,
    create_project,
    modify_project,
    create_partner,
    add_partner_to_project,
    remove_partner_from_project,
    modify_partner,
    change_user_color,
    upload_file,
    generate_result_scores,
    getProjectUsers,
    join_mailing_list,
    change_user_personal_info,
    remove_user_from_project,
    invite_user_to_project,
    send_pricing_inquiry,
    send_feedback,
    set_user_language,
    create_guest_result,
    attach_file_to_result,
    attach_file_to_calibration,
    change_project_retention_days,
    getAssociatedUsers,
    get_historic_raw_results,
    get_raw_results,
    toggle_project_attendee_emails
}
    from '../utils/http_functions';
import {logoutAndRedirect} from './auth';
import {
    CREATE_PROJECT, DELETE_PARTNER, FILE_UPLOAD_SUCCESS, GET_RESULT_METADATA, MODIFY_PARTNER, MODIFY_PROJECT,
    SAVE_RESULT_META_FILTERS, SET_CURRENT_RESULT, RESET_DATA_REDUX_STATE, NEW_RESULT_CREATED
} from "../constants";
import browserHistory from "../browserHistory";
import i18n, {changeLanguage} from '../i18n';
import {get_sheet_result_report_data} from "../utils/socket_functions";
import {fetchingReportsData} from "./socketActions";
const t = i18n.getFixedT(null, 'actionResponses');


export function setUserLanguage(userId, language, token){
    let json = {
        user_id: userId,
        language
    };
    return (dispatch) => {
        set_user_language(json, token).then(parseJSON).then(resp => {
            dispatch({
                type: SET_USER_LANGUAGE,
                payload: language
            })
        }).catch(err => {
            console.error('set user language backend err:', err);
        })
    }

}


export function sendPricingInquiry(name, email, staffAmount, salesText){
    let json = {
        name: name,
        email: email,
        staff_amount: staffAmount,
        sales_text: salesText
    };
    return (dispatch) => {
        send_pricing_inquiry(json).then(parseJSON).then(resp => {
            dispatch({
                type: PRICING_INQUIRY_SUCCESS,
                payload: resp
            })
        }).catch(err => {
            dispatch({
                type: PRICING_INQUIRY_FAILURE,
                payload: err
            })
        })
    }
}

export function sendFeedback(email, feedback, clientMetadata, username){
    let json = {
        email: email,
        feedback: feedback,
        client_metadata: clientMetadata,
        username: username
    };
    return (dispatch) => {
        send_feedback(json).then(resp => {
            dispatch({
                type: STATUS_UPDATE,
                payload: {type: 'success', message: t('thanksForYourFeedback')}
            })
        }).catch(err => {
            dispatch({
                type: STATUS_UPDATE,
                payload: {type: 'error', message: t('feedbackError')}
            })
        })
    }
}

export function joinMailingList(email){
    return (dispatch) => {
        join_mailing_list(email).then(resp => {
            dispatch({
                type: JOIN_MAILING_LIST_SUCCESS,
                payload: resp
            })
        }).catch(err => {
            dispatch({
                type: JOIN_MAILING_LIST_FAILURE,
                payload: err
            })
        })
    }
}

export function resetDataReduxState(){
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: RESET_DATA_REDUX_STATE
            });
            resolve(true);
        })

    }
}

export function downloadFile(filename, token){
    // is this gross?
    // seems to be the best way to download files without having to process the binary data
    // in js, which takes aaaaages.
    let address = window.location.origin;
    if (address.includes('3000')){
        address = address.replace('3000', '5000');
    }
    address = `${address}/api/download_file/${filename}`;

    let iframe = document.createElement('iframe');
    iframe.setAttribute('id', `downloadFileIframe_${filename}`);
    iframe.setAttribute('src', address);
    // adding it will trigger file download
    document.body.appendChild(iframe);
    // remove it as we don't need it anymore after the popup trigger
    // using a timeout as just calling it normally will not trigger the download window
    setTimeout(() => {document.body.removeChild(iframe)}, 1000);

    // todo is there a way to actually check if it completed successfully?
    return true;
}

export function uploadFile(file, token){
    return (dispatch) => {
        upload_file(file, token).then(response => {
            if (response.status === 200){
                dispatch({
                    type: FILE_UPLOAD_SUCCESS
                })
            }
        });
    }
}

export function attachFileToResult(resultId, file, token){
    return (dispatch) => {
        attach_file_to_result(resultId, file, token).then(response => {
            if (response.status === 200){
                dispatch({
                    type: FILE_UPLOAD_SUCCESS
                })
            }
        });
    }
}

export function attachFileToCalibration(calibrationId, file, token){
    return (dispatch) => {
        attach_file_to_calibration(calibrationId, file, token).then(response => {
            if (response.status === 200){
                dispatch({
                    type: FILE_UPLOAD_SUCCESS
                })
            }
        });
    }
}

export function fetchVitalData(token){
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let failed = false;
            let rolesDone = false;
            let userDataDone = false;

            fetchUserData(dispatch, token)
                .then(userDataDone = true)
                .catch(err => failed = true);
            fetchRoles(dispatch, token)
                .then(rolesDone = true)
                .catch(err => failed = true);

            if (failed) {
                reject('Something failed')
            }
            if (rolesDone && userDataDone){
                resolve(true)
            }
        });
    }
}

function fetchUserData(dispatch, token){
    return new Promise((resolve, reject) => {
        data_about_user(token)
            .then(parseJSON)
            .then(user => {
                changeLanguage(user.language);
                dispatch({
                    type: RECEIVE_PROTECTED_DATA,
                    payload: user
                });
            })
            .catch(error => {
                if (error.status === 401){
                    dispatch(logoutAndRedirect(error))
                }
                reject(error);
            })
            .then(resolve(true))
    })
}

function fetchRoles(dispatch, token){
    return new Promise((resolve, reject) => {
        getRoles(token).then(parseJSON).then(response => {
            dispatch({
                type: RECEIVE_ROLES,
                payload: {response}
            })
        })
            .catch(err => reject(err))
            .then(resolve(true))
    })
}

export function fetchUsers(token) {
    return (dispatch) => {
        getUsers(token).then(parseJSON).then(response => {
            dispatch({
                type: RECEIVE_USERS,
                payload: {response}
            })
        }).catch(error => {
            console.log(error);
            dispatch({
                type: RECEIVE_USERS_FAILURE,
                payload: {
                    status: error.status,
                    statusText: error.statusText,
                }
            });
        })
    }
}

export function fetchProjects(token) {
    return (dispatch) => {
        getProjects(token).then(parseJSON).then(response => {
            dispatch({
                type: RECEIVE_PROJECTS,
                payload: {response}
            })
        })
    }
}

export function fetchProjectUsers(projectId, token){
    return (dispatch) => {
        getProjectUsers(projectId, token).then(parseJSON).then(response => {
            dispatch({
                type: RECEIVE_PROJECT_USERS,
                payload: response
            })
        })
    }
}

export function fetchAssociatedUsers(userId, token){
    return (dispatch) => {
        getAssociatedUsers(userId, token).then(parseJSON).then(response => {
            dispatch({
                type: RECEIVE_ASSOCIATED_USERS,
                payload: response
            })
        })
    }
}

export function fetchWorkbenchSheet(id, token) {
    return (dispatch) => {
        get_workbench_sheet(id, token).then(parseJSON).then(response => {
            dispatch(setCurrentWorkbenchCatalog(response))
        })
    }
}

export function fetchScorecardData(project) {
    return (dispatch) => {
        getScorecardData(project).then(parseJSON).then(response => {
            dispatch({
                type: GET_SCORECARD_DATA,
                payload: {response}
            })
        })
    }
}

export function changeProject(projectId, token) {
    return (dispatch) => {
        changeCurrentProject(projectId, token).then(parseJSON).then(response => {
            dispatch({
                type: CHANGE_PROJECT,
                payload: {response}
            });
            dispatch(getSheetType(projectId, token))
        })
    }
}

export function putProject(project, token) {
    return (dispatch) => {
        put_project(project, token).then(parseJSON).then(response => {
            dispatch({
                type: project.new_name ? RENAME_PROJECT : PUT_PROJECT,
                payload: {response: response, oldProject: project},
            })
        })
    }
}

export function createProject(projectName, setToCurrentProject, token, isFirstProject=false){
    let json = {
        project_name: projectName,
        set_to_current_project: setToCurrentProject
    };
    return (dispatch) => {
        create_project(json, token).then(parseJSON).then(response => {
            dispatch({
                type: CREATE_PROJECT,
                payload: response
            });
            if (isFirstProject){
                browserHistory.push('/?welcome=true');
            } else browserHistory.push('/project-management');
        })
    }
}

export function modifyProject(projectId, projectModifications, token){
    let json = {project_id: projectId, project_changes: projectModifications};
    return (dispatch) => {
        modify_project(json, token).then(parseJSON).then(response => {
            dispatch({
                type: MODIFY_PROJECT,
                payload: response
            });
            dispatch(fetchProjectUsers(projectId, token));
        });
    }
}

export function fetchSheetTypes(token) {
    return (dispatch) => {
        data_about_user(token).then(parseJSON).then(response => {
            dispatch(getSheetType(response.currentProject.project.name, token))
        })
    }
}

export function fetchSheetMetaData(token, creators = null, fromDate = null, toDate = null) {
    let json = {
        creators: creators,
        from_date: fromDate,
        to_date: toDate,
    };
    return (dispatch) => {
        get_sheet_metadata(json, token).then(parseJSON).then(response => {
            dispatch({
                type: GET_SHEET_METADATA,
                payload: {response}
            })
        })
    }
}

function getSheetType(projectName, token) {
    return dispatch => {
        get_sheet_types(projectName, token).then(parseJSON).then(response => {
            dispatch({
                type: GET_SHEET_TYPES,
                payload: {response}
            })
        })
    }
}

export function putSheetType(projectName, name, token) {
    return (dispatch) => {
        put_sheet_type(projectName, name, token).then(parseJSON).then(response => {
            dispatch({
                type: PUT_SHEET_TYPE,
                payload: {response}
            })
        })
    }
}

export function setCurrentWorkbenchCatalog(catalog) {
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_SHEET,
            payload: catalog
        })
    }
}

export function addPartnerToProject(partnerId, projectId, token) {
    return (dispatch) => {
        add_partner_to_project(partnerId, projectId, token).then(parseJSON).then(response => {
            dispatch({
                type: MODIFY_PROJECT,
                payload: response.project
            });
            dispatch({
                type: MODIFY_PARTNER,
                payload: response.partner,
            });
        });
    }
}

export function removePartnerFromProject(partnerId, projectId, token){
    return (dispatch) => {
        remove_partner_from_project(partnerId, projectId, token).then(parseJSON).then(response => {
            dispatch({
                type: MODIFY_PROJECT,
                payload: response.project
            });
            dispatch({
                type: MODIFY_PARTNER,
                payload: response.partner
            });
        });
    }
}

export function createPartner(partnerName, token){
    return (dispatch) => {
        create_partner(partnerName, token).then(parseJSON).then(response => {
            dispatch({
                type: RECEIVE_PARTNER,
                payload: response
            })
        })
    }
}

export function modifyPartner(partnerId, partnerChanges, token){
    let json = {
        partner_id: partnerId,
        partner_changes: partnerChanges
    };
    return (dispatch) => {
        modify_partner(json, token).then(parseJSON).then(response => {
            dispatch({
                type: MODIFY_PARTNER,
                payload: response,
            });
        });
        fetchProjects(token);
    }
}

export function deletePartner(partnerId, token){
    return (dispatch) => {
        delete_partner(partnerId, token).then(parseJSON).then(response => {
            dispatch({
                type: DELETE_PARTNER,
                payload: response
            })
        })
    }
}

export function fetchPartners(token) {
    return (dispatch) => {
        get_partners(token).then(parseJSON).then(response => {
            dispatch({
                type: RECEIVE_PARTNERS,
                payload: response
            })
        })
    }
}

export function createNewResult(sheetId, token) {
    return (dispatch) => {
        create_new_result(sheetId, token).then(parseJSON).then(response => {
            dispatch({
                type: NEW_RESULT_CREATED,
                payload: response
            })
        })
    }
}

export function fetchResultMetaData(resultCodeIds, fromDate, toDate, token) {
    let json = {
        result_code_ids: resultCodeIds,
        from_date: fromDate ? fromDate : null,
        to_date: toDate ? toDate : null,
    };
    return (dispatch) => {
        get_result_metadata(json, token).then(parseJSON).then(response => {
            dispatch({
                type: GET_RESULT_METADATA,
                payload: {response}
            })
        })
    }
}

export function createGuestResult(sheetId, token=null){
    return (dispatch) => {
        return create_guest_result(sheetId, token).then(parseJSON)
    }
}

export function fetchGuestResult(resultId, token) {
    return (dispatch) => {
        get_guest_result(resultId, token).then(parseJSON).then(response => {
            dispatch({
                type: SET_CURRENT_RESULT,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: SET_CURRENT_RESULT,
                payload: null
            })
        })
    }
}

export function fetchResult(resultId, token) {
    return (dispatch) => {
        get_result(resultId, token).then(parseJSON).then(response => {
            dispatch({
                type: SET_CURRENT_RESULT,
                payload: response
            })
        })
    }
}

export function assignUserToProject(userId, projectId, role, token){
    let json = {
        user_id: userId,
        project_id: projectId,
        role: role
    };
    return (dispatch) => {
        assign_user_to_project(json, token).then(parseJSON)
            .then(() => dispatch({
                type: ASSIGN_USER_TO_PROJECT,
                payload: json
            })
        )
    }
}

export function inviteUserToProject(inviteeEmail, userId, role, token) {
    let json = {
        email: inviteeEmail,
        role: role
    };
    return (dispatch) => {
        invite_user_to_project(json, token).then(parseJSON)
            .then(resp => {
                dispatch({
                    type: CREATE_USER,
                    payload: resp
                })
                dispatch({
                    type: STATUS_UPDATE,
                    payload: {
                        message: t('inviteUserToProjectSuccess'),
                        type: 'success'
                    }
                })
                dispatch(fetchAssociatedUsers(userId, token));
            })
            .catch(err => {
                dispatch({
                    type: STATUS_UPDATE,
                    payload: {
                        message: t('genericError', {error: err.response ? err.response.data.message : err}),
                        type: 'error'
                    }
                })
            })
    }
}

export function removeUserFromProject(userId, projectId, token){
    let json = {
        user_id: userId,
        project_id: projectId
    };
    return (dispatch) => {
        remove_user_from_project(json, token).then(parseJSON)
            .then(() => dispatch({
                type: REMOVE_USER_FROM_PROJECT,
                payload: json
            }))
    }
}

export function changeUserColor(userId, newColor, token){
    let json = {
        user_id: userId,
        color: newColor
    };
    return (dispatch) => {
        change_user_color(json, token).then(parseJSON).then(() => dispatch(fetchUsers(token)))
    }
}

export function changeUserPersonalInfo(userId, firstName, lastName, email, token){
    return (dispatch) => {
        change_user_personal_info(userId, firstName, lastName, email, token).then(parseJSON).then(resp => {
            dispatch({
                type: UPDATE_USER_PERSONAL_INFO,
                payload: {
                    user_id: userId,
                    first_name: firstName,
                    last_name: lastName,
                    name: `${firstName} ${lastName}`,
                    email: email
                }
            })
        })
    }
}

export function changeProjectRetentionDays(projectId, newRetentionDays, token){
    return (dispatch) => {
        change_project_retention_days(projectId, newRetentionDays, token).then(parseJSON).then(resp => {
            dispatch({
                type: CHANGE_PROJECT_RETENTION_DAYS,
                payload: resp.new_retention_days
            });
        });
    }
}

export function toggleProjectAttendeeEmails(projectId, enabled, token){
    return (dispatch) => {
        toggle_project_attendee_emails(projectId, enabled, token).then(parseJSON).then(resp => {
            dispatch({
                type: TOGGLE_PROJECT_ATTENDEE_EMAILS,
                payload: resp.enabled
            });
        });
    }
}

export function saveResultMetaFilters(searchString, resultCodeNames, fromDate, toDate, expandedActiveResults,
                                      expandedClosedResults, scrollY){
    let filters = {
        searchString: searchString,
        resultCodes: resultCodeNames,
        fromDate: fromDate,
        toDate: toDate,
        expandedActiveResults: expandedActiveResults,
        expandedClosedResults: expandedClosedResults,
        scrollY: scrollY
    };
    return (dispatch) => {
        dispatch({
            type: SAVE_RESULT_META_FILTERS,
            payload: filters
        })
    }
}

export function generateResultScores(token) {
    return (dispatch) => {
        generate_result_scores(token)
    }
}

export function toggleBackground(on) {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_BACKGROUND,
            payload: on
        })
    }
}

export function getHistoricRawResults(sheetIds, toDate = null, filterItems = null, token){
    let json = {
        sheet_ids: sheetIds,
        to_date: toDate,
        filter_items: filterItems,
    };
    return (dispatch) => {
        get_historic_raw_results(json, token).then(parseJSON).then(response => {
            dispatch({
            type: GET_HISTORIC_RAW_RESULTS,
            payload: response
            });
        });
    }
}

export function getRawResults(sheetIds, fromDate=null, toDate = null, filterItems = null, token){
    let json = {
        sheet_ids: sheetIds,
        from_date: fromDate,
        to_date: toDate,
        filter_items: filterItems,
    };
    return (dispatch) => {
        get_raw_results(json, token).then(parseJSON).then(response => {
            dispatch({
            type: GET_RAW_RESULTS,
            payload: response
            });
        });
    }
}
