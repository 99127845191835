import {
    join_room,
    leave_room,
    join_room_unauthenticated,
    leave_room_unauthenticated,
    change_sheet_name,
    change_sheet_description,
    add_sheet_item,
    modify_sheet_item,
    delete_sheet_item,
    change_sheet_item_option,
    change_sheet_item_category,
    change_category_order,
    change_item_order,
    add_option_group,
    delete_option_group,
    add_option_values,
    delete_option_value,
    change_option_value_name,
    change_option_type,
    lock_sheet,
    add_result_code,
    delete_result_code,
    modify_result_code,
    answer_item,
    set_result_code,
    finish_guest_result,
    get_calc_templates,
    set_default_calc_template,
    create_calc_template,
    copy_calc_template,
    delete_calc_template,
    set_option_weight,
    set_item_weight,
    change_calc_template_percentages,
    change_calc_template_use_weights,
    change_item_active,
    change_option_active,
    get_default_calc_template,
    lock_calc_template,
    get_sheets,
    fetch_result_scores,
    fetch_report_metadata,
    create_report_template,
    fetch_report,
    add_report_item,
    delete_report_item,
    move_report_item,
    create_new_calibration,
    fetch_calibration_metadata,
    fetch_calibration,
    add_users_to_calibration,
    get_report_template_report_data,
    get_calibration_result,
    remove_user_from_calibration,
    change_calibration_name,
    change_calibration_description,
    set_calibration_result_to_done,
    close_calibration,
    get_calibration_results,
    save_calibration_comment,
    fetch_reportable_sheet_metadata,
    change_sheet_color,
    start_calibration_from_result,
    get_user_metrics,
    export_sheet_as_excel,
    get_sheet_import_compatibility,
    create_results_from_excel_sheet,
    get_sheet_result_report_data,
    fetch_results_for_sheet,
    export_report_as_excel,
    change_result_creator,
    delete_result,
    set_score_display,
    export_text_report_as_excel,
    export_location_report_as_excel,
    export_raw_report_as_excel,
    create_export_marker,
    get_export_marker_metadata,
    get_export_marker_results,
    download_export_marker_data,
    export_result_columns_report_as_excel,
    get_table_data_for_item,
    export_report_as_powerpoint,
    set_item_example,
    get_item_examples,
    toggle_item_examples_reportable,
    delete_item_examples,
    fetch_calibration_table_data,
    export_report_as_pdf,
    change_sheet_order,
    create_sheet,
    copy_sheet,
    toggle_sheet_in_production,
    get_category_score_progression,
    set_calibration_result_code,
    set_calibration_deadline_date,
    change_result_date,
    toggle_sheet_public,
    add_option_dependency,
    delete_option_dependency,
    fetch_allowed_answers,
    change_option_category_null,
    change_option_sheet_null,
    get_option_dependencies,
    delete_result_file,
    delete_calibration_file,
    remove_answer,
    change_sheet_show_attendees,
    change_result_attendees,
    add_sheet_items,
    delete_sheet,
    change_not_assessable_reduce_weights,
    change_sheet_update_date_on_completion,
    get_item_presentation_mapper,
    change_sheet_item_presentation_type, on_change_sheet_item_int_minimum_value, on_change_sheet_item_int_maximum_value,
    export_result
} from "../utils/socket_functions";

import {
    RESET_SOCKET_REDUX_STATE,
    SOCKET_REQUEST,
    SOCKET_SUCCESS,
    SOCKET_SUBSCRIBE,
    SOCKET_UNSUBSCRIBE,
    CONNECT_SOCKET,
    DISCONNECT_SOCKET,
    JOIN_ROOM,
    LEAVE_ROOM,
    USER_EVENT,
    STATUS_UPDATE,
    CHANGE_SHEET_NAME,
    CHANGE_SHEET_DESCRIPTION,
    ADD_SHEET_ITEM,
    MODIFY_SHEET_ITEM,
    DELETE_SHEET_ITEM,
    CHANGE_SHEET_ITEM_OPTION,
    CHANGE_SHEET_ITEM_CATEGORY,
    CHANGE_CATEGORY_ORDER,
    CHANGE_ITEM_ORDER,
    ADD_OPTION_GROUP,
    DELETE_OPTION_GROUP,
    ADD_OPTION_VALUES,
    DELETE_OPTION_VALUE,
    CHANGE_OPTION_VALUE_NAME,
    CHANGE_OPTION_TYPE,
    ADD_RESULT_CODE,
    DELETE_RESULT_CODE,
    MODIFY_RESULT_CODE,
    LOCK_SHEET,
    RESULT_UPDATE,
    UPDATE_ITEM_RESULT,
    ANSWER_ITEM,
    SET_RESULT_CODE,
    SHEET_ACTIONS,
    SET_CURRENT_CALC_TEMPLATE,
    RESET_CURRENT_CALC_TEMPLATE,
    GET_CALC_TEMPLATES,
    SET_DEFAULT_CALC_TEMPLATE,
    CREATE_CALC_TEMPLATE,
    COPY_CALC_TEMPLATE,
    DELETE_CALC_TEMPLATE,
    SET_OPTION_WEIGHT,
    SET_ITEM_WEIGHT,
    CALC_TEMPLATE_META_ACTIONS,
    CALC_TEMPLATE_EDIT_ACTIONS,
    CHANGE_CALC_TEMPLATE_PERCENTAGES,
    CHANGE_CALC_TEMPLATE_USE_WEIGHTS,
    CHANGE_ITEM_ACTIVE,
    CHANGE_OPTION_ACTIVE,
    GET_DEFAULT_CALC_TEMPLATE,
    LOCK_CALC_TEMPLATE,
    UPDATE_ITEM_SCORES,
    FETCH_RESULT_SCORES,
    GET_SHEETS,
    FETCH_REPORT_METADATA,
    CREATE_REPORT_TEMPLATE,
    FETCH_REPORT,
    ADD_REPORT_ITEM,
    REPORT_TEMPLATE_EDIT_ACTIONS,
    DELETE_REPORT_ITEM,
    MOVE_REPORT_ITEM,
    CREATE_NEW_CALIBRATION,
    SET_CURRENT_CALIBRATION,
    FETCH_CALIBRATION_METADATA,
    FETCH_CALIBRATION,
    SET_CURRENT_RESULT,
    ADD_USERS_TO_CALIBRATION,
    GET_CALIBRATION_RESULT,
    REMOVE_USER_FROM_CALIBRATION,
    CALIBRATION_ACTIONS,
    CHANGE_CALIBRATION_NAME,
    CHANGE_CALIBRATION_DESCRIPTION,
    SET_CALIBRATION_RESULT_TO_DONE,
    CLOSE_CALIBRATION,
    GET_CALIBRATION_RESULTS,
    SAVE_CALIBRATION_COMMENT,
    FETCH_REPORTABLE_SHEET_METADATA,
    CHANGE_SHEET_COLOR,
    START_CALIBRATION_FROM_RESULT,
    GET_USER_METRICS,
    EXPORT_SHEET_AS_EXCEL,
    GET_SHEET_IMPORT_COMPATIBILITY,
    CONFIRM_IMPORT_EXCEL_RESULTS,
    GET_SHEET_RESULT_REPORT_DATA,
    GET_REPORT_TEMPLATE_REPORT_DATA,
    SET_SELECTED_SHEETS,
    SET_SELECTED_REPORT_TEMPLATE,
    FETCH_RESULTS_FOR_SHEET,
    EXPORT_REPORT_AS_EXCEL,
    CHANGE_RESULT_CREATOR,
    FILE_DOWNLOAD,
    DELETE_RESULT,
    SET_SCORE_DISPLAY,
    EXPORT_TEXT_REPORT_AS_EXCEL,
    EXPORT_LOCATION_REPORT_AS_EXCEL,
    EXPORT_RAW_REPORT_AS_EXCEL,
    CREATE_EXPORT_MARKER,
    GET_EXPORT_MARKER_METADATA,
    GET_EXPORT_MARKER_RESULTS,
    DOWNLOAD_EXPORT_MARKER_DATA,
    EXPORT_RESULT_COLUMNS_REPORT_AS_EXCEL,
    BULK_EDIT_SCORE_UPDATE,
    GET_TABLE_DATA_FOR_ITEM,
    RESET_ITEM_TABLE_DATA,
    EXPORT_REPORT_AS_POWERPOINT,
    SET_ITEM_EXAMPLE,
    GET_ITEM_EXAMPLES,
    TOGGLE_ITEM_EXAMPLES_REPORTABLE,
    DELETE_ITEM_EXAMPLES,
    FETCH_CALIBRATION_TABLE_DATA,
    EXPORT_REPORT_AS_PDF,
    CHANGE_SHEET_ORDER,
    CREATE_SHEET,
    COPY_SHEET,
    TOGGLE_SHEET_IN_PRODUCTION,
    GET_CATEGORY_SCORE_PROGRESSION,
    RESET_RESULT_REPORTS,
    RESET_FILE_DOWNLOAD,
    RESET_CURRENT_CALIBRATION,
    FETCHING_REPORTS_DATA,
    SET_CALIBRATION_RESULT_CODE,
    SET_CALIBRATION_DEADLINE_DATE,
    CHANGE_RESULT_DATE,
    TOGGLE_SHEET_PUBLIC,
    ADD_OPTION_DEPENDENCY,
    DELETE_OPTION_DEPENDENCY,
    UPDATE_ALLOWED_ANSWERS,
    CHANGE_OPTION_CATEGORY_NULL,
    CHANGE_OPTION_SHEET_NULL,
    GET_OPTION_DEPENDENCIES,
    SET_ALLOWED_ANSWERS,
    ATTACH_FILE_TO_RESULT,
    DELETE_RESULT_FILE,
    DELETE_CALIBRATION_FILE,
    ATTACH_FILE_TO_CALIBRATION,
    REMOVE_ANSWER,
    CHANGE_SHEET_SHOW_ATTENDEES,
    CHANGE_RESULT_ATTENDEES,
    SET_DISCONNECTED,
    ADD_SHEET_ITEMS,
    TOKEN_EXPIRED,
    DELETE_SHEET,
    CHANGE_NOT_ASSESSABLE_REDUCE_WEIGHTS,
    CHANGE_SHEET_UPDATE_DATE_ON_COMPLETION,
    SET_ITEM_PRESENTATION_MAPPER,
    CHANGE_ITEM_PRESENTATION_TYPE,
    CHANGE_SHEET_ITEM_INT_MINIMUM_VALUE,
    CHANGE_SHEET_ITEM_INT_MAXIMUM_VALUE, MULTISELECT
} from "../constants";
import {downloadFile} from "./data";
import {logoutAndRedirect} from "./auth";


export function resetSocketReduxState() {
    return (dispatch) => {
        dispatch({
            type: RESET_SOCKET_REDUX_STATE
        })
    }
}

export function connectSocket(token) {
    return (dispatch) => {
        let connectionPromise = null;
        dispatch({
            type: CONNECT_SOCKET,
            transport: 'socket',
            uncontrolled: true,
            promise: socket => connectionPromise = socket.connect(token),
        });
        return connectionPromise;
    }
}

export function connectSocketUnauthenticated() {
    return (dispatch) => {
        let connectionPromise = null;
        dispatch({
            type: CONNECT_SOCKET,
            transport: 'socket',
            uncontrolled: true,
            promise: socket => connectionPromise = socket.connectUnauthenticated(),
        });
        return connectionPromise;
    }
}

export function disconnectSocket() {
    return (dispatch) => {
        dispatch({
            type: DISCONNECT_SOCKET,
            transport: 'socket',
            promise: socket => socket.disconnect(),
        })
    }
}

export function socketEmit(address, data = null, cb = null) {
    if (!cb) cb = () => null;
    return (dispatch) => {
        dispatch({
            type: 'SOCKET_EMIT',
            transport: 'socket',
            promise: socket => socket.emit(address, data).then(cb)
        })
    }
}

export function joinRoom(userName, roomId, options = {getUserEvents: true}) {
    let json = {
        user: userName,
        room_id: roomId,
        get_user_events: options.getUserEvents
    };
    return (dispatch) => {
        dispatch({
            type: JOIN_ROOM + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => join_room(socket, json).then(response => {
                dispatch({
                    type: JOIN_ROOM,
                    payload: response
                })
            }),
        });
    }
}

export function leaveRoom(userName, roomId) {
    return (dispatch) => {
        let json = {user: userName, room_id: roomId};
        dispatch({
            type: LEAVE_ROOM + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => leave_room(socket, json).then(response => {
                dispatch({
                    type: LEAVE_ROOM
                })
            }),
        });
    }
}

export function joinRoomUnauthenticated(userName, roomId, options = {getUserEvents: true}) {
    let json = {
        user: userName,
        room_id: roomId,
        get_user_events: options.getUserEvents
    };
    return (dispatch) => {
        dispatch({
            type: JOIN_ROOM + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => join_room_unauthenticated(socket, json).then(response => {
                dispatch({
                    type: JOIN_ROOM,
                    payload: response
                })
            }),
        });
    }
}

export function leaveRoomUnauthenticated(userName, roomId) {
    return (dispatch) => {
        let json = {user: userName, room_id: roomId};
        dispatch({
            type: LEAVE_ROOM + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => leave_room_unauthenticated(socket, json).then(response => {
                dispatch({
                    type: LEAVE_ROOM
                })
            }),
        });
    }
}

export function subscribeTo(event, callbackFunc) {
    return (dispatch) => {
        dispatch({
            type: `socket/${event}${SOCKET_SUBSCRIBE}`.toUpperCase(),
            transport: 'socket',
            promise: socket => socket.on(event, (response) => callbackFunc(response, dispatch))
        })
    }
}

// If the func argument is passed only that specific "event => handler" instance is unsubscribed
// Otherwise ALL handlers on event are removed
export function unsubscribeFrom(event, func = null) {
    return (dispatch) => {
        dispatch({
            type: `socket/${event}/${SOCKET_UNSUBSCRIBE}`.toUpperCase(),
            transport: 'socket',
            promise: socket => func ? socket.off(event, func) : socket.off(event)
        })
    }
}

export function subscribeToUserEvents() {
    return (dispatch) => {
        dispatch({
            type: USER_EVENT + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on('user_event', (data) => {
                dispatch({
                    type: USER_EVENT,
                    payload: data,
                })
            }),
        })
    }
}

export function unsubscribeFromUserEvents() {
    return (dispatch) => {
        dispatch({
            type: USER_EVENT + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off('user_event')
        })
    }
}

export function subscribeToTokenExpired() {
    return (dispatch) => {
        dispatch({
            type: TOKEN_EXPIRED + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on('token_expired', () => {
                dispatch(logoutAndRedirect())
            }),
        })
    }
}

export function unsubscribeFromTokenExpired() {
    return (dispatch) => {
        dispatch({
            type: TOKEN_EXPIRED + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off('token_expired')
        })
    }
}

export function subscribeToStatusUpdates() {
    return (dispatch) => {
        dispatch({
            type: STATUS_UPDATE + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on('status', (status) => {
                dispatch({
                    type: STATUS_UPDATE,
                    payload: {...status}
                })
            }),
        })
    }
}

export function unsubscribeFromStatusUpdates() {
    return (dispatch) => {
        dispatch({
            type: STATUS_UPDATE + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off('status')
        })
    }
}

export function statusUpdate(type, message) {
    return (dispatch) => {
        dispatch({
            type: STATUS_UPDATE,
            payload: {'type': type, 'message': message}
        })
    }
}


export function subscribeToFileDownload() {
    return (dispatch) => {
        dispatch({
            type: FILE_DOWNLOAD + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on('socket/file_download_ready', (filename) => {
                let success = downloadFile(filename);
                dispatch({
                    type: FILE_DOWNLOAD,
                    payload: success
                });
            }),
        });
    }
}

export function subscribeToSheetUpdates() {
    return (dispatch) => {
        SHEET_ACTIONS.map(action => {
            return (
                dispatch({
                    type: action + SOCKET_SUBSCRIBE,
                    transport: 'socket',
                    promise: socket => socket.on(action.toLowerCase() + '/success', (payload) => {
                        dispatch({
                            type: action,
                            payload: payload
                        })
                    })
                })
            )
        })
    }
}

export function unsubscribeFromSheetUpdates() {
    return (dispatch) => {
        SHEET_ACTIONS.map(action => {
            return (
                dispatch({
                    type: action + SOCKET_UNSUBSCRIBE,
                    transport: 'socket',
                    promise: socket => socket.off(action.toLowerCase() + '/success'),
                })
            )
        })
    }
}

export function getSheets(sheetIds) {
    return (dispatch) => {
        dispatch({
            type: GET_SHEETS + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_sheets(socket, sheetIds).then(response => {
                dispatch({
                    type: GET_SHEETS,
                    payload: response
                })
            }),
        })
    }
}

export function createSheet(sheetName) {
    return (dispatch) => {
        dispatch({
            type: CREATE_SHEET + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => create_sheet(socket, sheetName)
        })
    }
}

export function deleteSheet(sheetId) {
    return (dispatch) => {
        dispatch({
            type: DELETE_SHEET + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => delete_sheet(socket, sheetId)
        })
    }
}

export function copySheet(newSheetName, sheetId) {
    let json = {
        new_sheet_name: newSheetName,
        sheet_id: sheetId
    };
    return (dispatch) => {
        dispatch({
            type: COPY_SHEET + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => copy_sheet(socket, json)
        })
    }

}

export function toggleSheetInProduction(sheetId) {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_SHEET_IN_PRODUCTION + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => toggle_sheet_in_production(socket, sheetId)
        })
    }
}

export function changeSheetOrder(sheetId, newOrder) {
    let json = {
        sheet_id: sheetId,
        new_order: newOrder
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_SHEET_ORDER + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_sheet_order(socket, json),
        });
    }
}

export function changeSheetName(sheetId, name) {
    let json = {
        sheet_id: sheetId,
        new_name: name
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_SHEET_NAME + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_sheet_name(socket, json),
        });
    }
}

export function changeSheetDescription(sheetId, description) {
    let json = {
        sheet_id: sheetId,
        new_description: description
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_SHEET_DESCRIPTION + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_sheet_description(socket, json),
        });
    }
}

export function changeSheetColor(sheetId, color) {
    let json = {
        sheet_id: sheetId,
        new_color: color
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_SHEET_COLOR + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_sheet_color(socket, json),
        });
    }
}

export function addSheetItem(sheetId, itemOption, categoryPath, isCategory, itemObject) {
    return (dispatch) => {
        let json = {}
        if (itemOption === 'MULTISELECT') {
            json = {
                sheet_id: sheetId,
                path: categoryPath,
                is_category: isCategory,
                item_type: 'OPTIONS',
                multiselect: true
            };
        } else {
            json = {
                sheet_id: sheetId,
                path: categoryPath,
                is_category: isCategory,
                item_type: itemOption
            };
        }
        if (itemObject) json['item_object'] = itemObject;

        dispatch({
            type: ADD_SHEET_ITEM + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => add_sheet_item(socket, json),
        });
    }
}

export function addSheetItems(sheetId, numberOfItems, itemType, categoryPath) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            number_of_items: numberOfItems,
            item_type: itemType === 'MULTISELECT' ? 'OPTIONS' : itemType,
            path: categoryPath,
            multiselect: itemType === 'MULTISELECT'
        };
        dispatch({
            type: ADD_SHEET_ITEMS + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => add_sheet_items(socket, json)
        });
    }
}

export function modifySheetItem(sheetId, itemId, itemChanges) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            item_id: itemId,
            item_changes: itemChanges
        };
        dispatch({
            type: MODIFY_SHEET_ITEM + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => modify_sheet_item(socket, json),
        });
    }
}

export function deleteSheetItem(sheetId, itemId) {
    let json = {
        sheet_id: sheetId,
        item_id: itemId
    };
    return (dispatch) => {
        dispatch({
            type: DELETE_SHEET_ITEM + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => delete_sheet_item(socket, json),
        });
    }
}

export function changeSheetItemOption(sheetId, itemId, newOptionId) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            item_id: itemId,
            new_option_group_id: newOptionId
        };
        dispatch({
            type: CHANGE_SHEET_ITEM_OPTION + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_sheet_item_option(socket, json),
        });
    }
}

export function changeSheetItemCategory(sheetId, itemId, newCategoryId) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            item_id: itemId,
            new_category_id: newCategoryId
        };
        dispatch({
            type: CHANGE_SHEET_ITEM_CATEGORY + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_sheet_item_category(socket, json),
        });
    }
}

export function changeCategoryOrder(sheetId, categoryPath, newListOrder) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            category_path: categoryPath,
            new_category_order: newListOrder
        };
        dispatch({
            type: CHANGE_CATEGORY_ORDER + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_category_order(socket, json),
        });
    }
}

export function changeItemOrder(sheetId, newListOrder) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            new_order: newListOrder
        };
        dispatch({
            type: CHANGE_ITEM_ORDER + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_item_order(socket, json),
        });
    }
}

export function addOptionGroup(sheetId, optionGroupName) {
    let json = {
        sheet_id: sheetId,
        option_group_name: optionGroupName
    };
    return (dispatch) => {
        dispatch({
            type: ADD_OPTION_GROUP + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => add_option_group(socket, json),
        });
    }
}

export function deleteOptionGroup(sheetId, optionGroupId) {
    let json = {
        sheet_id: sheetId,
        option_group_id: optionGroupId
    };
    return (dispatch) => {
        dispatch({
            type: DELETE_OPTION_GROUP + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => delete_option_group(socket, json),
        });
    }
}

export function addOptionValues(sheetId, optionGroupId, optionValueNames) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            option_group_id: optionGroupId,
            option_value_names: optionValueNames
        };
        dispatch({
            type: ADD_OPTION_VALUES + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => add_option_values(socket, json),
        });
    }
}

export function deleteOptionValue(sheetId, optionId, optionGroupId) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            option_id: optionId,
            option_group_id: optionGroupId
        };
        dispatch({
            type: DELETE_OPTION_VALUE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => delete_option_value(socket, json),
        });
    }
}

export function changeOptionValueName(sheetId, optionId, optionGroupId, newName) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            option_id: optionId,
            option_group_id: optionGroupId,
            new_name: newName
        };
        dispatch({
            type: CHANGE_OPTION_VALUE_NAME + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_option_value_name(socket, json),
        });
    }
}

export function changeOptionType(sheetId, optionGroupId, newOptionType) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            option_group_id: optionGroupId,
            option_presentation: newOptionType
        };
        dispatch({
            type: CHANGE_OPTION_TYPE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_option_type(socket, json),
        });
    }
}

export function getOptionDependencies(sheetId) {
    return (dispatch) => {
        dispatch({
            type: GET_OPTION_DEPENDENCIES + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_option_dependencies(socket, sheetId).then(resp => {
                dispatch({
                    type: GET_OPTION_DEPENDENCIES,
                    payload: resp
                })
            })
        })
    }
}

export function addOptionDependency(sheetId, itemId, masterItemId, masterOptionGroupId, optionId, textLimit) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            master_item_id: masterItemId,
            dependant_item_id: itemId,
            master_option_id: masterOptionGroupId,
            dependant_option_id: optionId,
            text_limit: textLimit
        };

        dispatch({
            type: ADD_OPTION_DEPENDENCY + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => add_option_dependency(socket, json),
        });
    }
}

export function deleteOptionDependency(sheetId, optionDependencyId) {
    return (dispatch) => {
        let json = {
            'sheet_id': sheetId,
            'option_dependency_id': optionDependencyId
        };

        dispatch({
            type: DELETE_OPTION_DEPENDENCY + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => delete_option_dependency(socket, json),
        });
    }
}

export function addResultCode(sheetId, resultCodeName) {
    let json = {
        sheet_id: sheetId,
        result_code_name: resultCodeName
    };
    return (dispatch) => {
        dispatch({
            type: ADD_RESULT_CODE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => add_result_code(socket, json),
        });
    }
}

export function deleteResultCode(sheetId, resultCodeId) {
    let json = {
        sheet_id: sheetId,
        result_code_id: resultCodeId
    };
    return (dispatch) => {
        dispatch({
            type: DELETE_RESULT_CODE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => delete_result_code(socket, json),
        });
    }
}

export function changeResultCodeName(sheetId, resultCodeId, newName) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            result_code_id: resultCodeId,
            result_code_modifications: {
                new_name: newName
            }
        };
        dispatch({
            type: MODIFY_RESULT_CODE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => modify_result_code(socket, json),
        });
    }
}

export function changeResultCodeColor(sheetId, resultCodeId, newColor) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            result_code_id: resultCodeId,
            result_code_modifications: {
                new_color: newColor
            }
        };
        dispatch({
            type: MODIFY_RESULT_CODE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => modify_result_code(socket, json),
        });
    }
}

export function changeResultCodeReportable(sheetId, resultCodeId, reportable) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            result_code_id: resultCodeId,
            result_code_modifications: {
                reportable: reportable
            }
        };
        dispatch({
            type: MODIFY_RESULT_CODE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => modify_result_code(socket, json)
        })
    }
}

export function changeShowAttendees(sheetId, showAttendees) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            show_attendees: showAttendees
        };
        dispatch({
            type: CHANGE_SHEET_SHOW_ATTENDEES + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_sheet_show_attendees(socket, json)
        });
    }
}

export function changeUpdateDateOnCompletion(sheetId, updateDateOnCompletion) {
    return (dispatch) => {
        let json = {
            sheet_id: sheetId,
            update_date_on_completion: updateDateOnCompletion
        };
        dispatch({
            type: CHANGE_SHEET_UPDATE_DATE_ON_COMPLETION + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_sheet_update_date_on_completion(socket, json)
        });
    }
}


export function lockSheet(sheetId) {
    return (dispatch) => {
        dispatch({
            type: LOCK_SHEET + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => lock_sheet(socket, sheetId),
        });
    }
}

export function exportSheetAsExcel(sheetId) {
    return (dispatch) => {
        dispatch({
            type: EXPORT_SHEET_AS_EXCEL + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => export_sheet_as_excel(socket, sheetId),
        });
    }
}

export function subscribeToResultUpdates() {
    return (dispatch) => {
        dispatch({
            type: RESULT_UPDATE + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on('result_updated', (newResult) => {
                dispatch({
                    type: UPDATE_ITEM_RESULT,
                    payload: {...newResult}
                })
            })
        });
        dispatch({
            type: SET_RESULT_CODE + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on('result_code_updated', (newResult) => {
                dispatch({
                    type: SET_CURRENT_RESULT,
                    payload: newResult
                })
            })
        });
        dispatch({
            type: UPDATE_ITEM_SCORES + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on('update_item_scores', (newScores) => {
                dispatch({
                    type: UPDATE_ITEM_SCORES,
                    payload: {...newScores}
                })
            })
        });
        dispatch({
            type: UPDATE_ALLOWED_ANSWERS + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on('update_allowed_answers', (newAnswers) => {
                dispatch({
                    type: UPDATE_ALLOWED_ANSWERS,
                    payload: {...newAnswers}
                })
            })
        });
        dispatch({
            type: ATTACH_FILE_TO_RESULT + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on('result_file_attached', (newFile) => {
                dispatch({
                    type: ATTACH_FILE_TO_RESULT,
                    payload: newFile
                })
            })
        });
        dispatch({
            type: DELETE_RESULT_FILE + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on('result_file_deleted', (deletedFileId) => {
                dispatch({
                    type: DELETE_RESULT_FILE,
                    payload: deletedFileId
                })
            })
        });
        dispatch({
            type: CHANGE_RESULT_ATTENDEES + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on('result_attendees_changed', (newAttendeeIds) => {
                dispatch({
                    type: CHANGE_RESULT_ATTENDEES,
                    payload: newAttendeeIds
                })
            })
        });
    }
}

export function unsubscribeFromResultUpdates() {
    return (dispatch) => {
        dispatch({
            type: RESULT_UPDATE + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off('result_updated')
        });
        dispatch({
            type: UPDATE_ITEM_SCORES + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off('update_item_scores')
        });
        dispatch({
            type: SET_RESULT_CODE + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off('result_code_updated')
        });
        dispatch({
            type: UPDATE_ALLOWED_ANSWERS + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off('update_allowed_answers')
        });
        dispatch({
            type: ATTACH_FILE_TO_RESULT + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off('result_file_attached')
        });
        dispatch({
            type: DELETE_RESULT_FILE + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off('result_file_deleted')
        });
        dispatch({
            type: CHANGE_RESULT_ATTENDEES + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off('result_attendees_changed')
        })
    }
}

export function subscribeToItemExampleUpdates() {
    return (dispatch) => {
        dispatch({
            type: SET_ITEM_EXAMPLE + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on((SET_ITEM_EXAMPLE + SOCKET_SUCCESS).toLowerCase(), (response) => {
                dispatch({
                    type: SET_ITEM_EXAMPLE,
                    payload: response
                })
            })
        });
        dispatch({
            type: DELETE_ITEM_EXAMPLES + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on((DELETE_ITEM_EXAMPLES + SOCKET_SUCCESS).toLowerCase(), (response) => {
                dispatch({
                    type: DELETE_ITEM_EXAMPLES,
                    payload: response
                })
            })
        });
        dispatch({
            type: TOGGLE_ITEM_EXAMPLES_REPORTABLE + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on((TOGGLE_ITEM_EXAMPLES_REPORTABLE + SOCKET_SUCCESS).toLowerCase(), (response) => {
                dispatch({
                    type: TOGGLE_ITEM_EXAMPLES_REPORTABLE,
                    payload: response
                })
            }),
        });
    }
}

export function unsubscribeFromItemExampleUpdates() {
    return (dispatch) => {
        dispatch({
            type: SET_ITEM_EXAMPLE + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off((SET_ITEM_EXAMPLE + SOCKET_SUCCESS).toLowerCase())
        });
        dispatch({
            type: DELETE_ITEM_EXAMPLES + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off((DELETE_ITEM_EXAMPLES + SOCKET_SUCCESS).toLowerCase())
        });
        dispatch({
            type: TOGGLE_ITEM_EXAMPLES_REPORTABLE + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off((TOGGLE_ITEM_EXAMPLES_REPORTABLE + SOCKET_SUCCESS).toLowerCase())
        });
    }
}

export function deleteResultFile(resultId, fileId) {
    let json = {
        result_id: resultId,
        file_id: fileId
    };
    return (dispatch) => {
        dispatch({
            type: DELETE_RESULT_FILE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => delete_result_file(socket, json),
        })
    }
}

export function changeResultAttendees(resultId, attendeeIds) {
    let json = {
        result_id: resultId,
        attendee_ids: attendeeIds
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_RESULT_ATTENDEES + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_result_attendees(socket, json)
        })
    }
}

export function answerItem(json) {
    return (dispatch) => {
        dispatch({
            type: ANSWER_ITEM + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => answer_item(socket, json),
        });
    }
}

export function removeAnswer(resultId, itemId) {
    let json = {
        result_id: resultId,
        item_id: itemId
    };
    return (dispatch) => {
        dispatch({
            type: REMOVE_ANSWER + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => remove_answer(socket, json)
        })
    }
}

export function deleteResult(resultId) {
    return (dispatch) => {
        dispatch({
            type: DELETE_RESULT + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => delete_result(socket, resultId)
        });
    }
}

export function startCalibrationFromResult(resultId, sheetId) {
    let json = {
        result_id: resultId,
        sheet_id: sheetId
    };
    return (dispatch) => {
        dispatch({
            type: START_CALIBRATION_FROM_RESULT + SOCKET_REQUEST,
            transport: 'socket',
            subscribeTo: socket => socket.on('socket/set_result_code/error', (response) => {
                dispatch({
                    type: SET_RESULT_CODE + '/ERROR',
                    payload: response,
                })
            }),
            promise: socket => start_calibration_from_result(socket, json),
        })
    }
}


export function fetchResultScores(resultId) {
    return (dispatch) => {
        dispatch({
            type: FETCH_RESULT_SCORES + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => fetch_result_scores(socket, resultId),
        });
    }
}

export function fetchAllowedAnswers(resultId) {
    return (dispatch) => {
        dispatch({
            type: FETCH_RESULT_SCORES + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => fetch_allowed_answers(socket, resultId).then(resp => {
                dispatch({
                    type: SET_ALLOWED_ANSWERS,
                    payload: resp
                })
            }),
        });
    }
}

export function changeResultCreator(newCreatorId, resultId) {
    let json = {
        new_creator_id: newCreatorId,
        result_id: resultId
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_RESULT_CREATOR + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_result_creator(socket, json),
        })
    }
}

export function changeResultDate(resultId, newDate) {
    let json = {
        result_id: resultId,
        new_date: newDate
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_RESULT_DATE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_result_date(socket, json).then(resp => {
                dispatch({
                    type: CHANGE_RESULT_DATE,
                    payload: resp
                })
            })
        })
    }
}


export function setResultCode(resultId, resultCodeId, verifyAnswers = true) {
    return (dispatch) => {
        let json = {
            result_id: resultId,
            result_code_id: resultCodeId,
            verify_answers: verifyAnswers
        };
        dispatch({
            type: SET_RESULT_CODE + SOCKET_REQUEST,
            transport: 'socket',
            subscribeTo: socket => socket.on('socket/set_result_code/error', (response) => {
                dispatch({
                    type: SET_RESULT_CODE + '/ERROR',
                    payload: response,
                })
            }),
            promise: socket => set_result_code(socket, json),
        });
    }
}


export function finishGuestResult(resultId, verifyAnswers = true) {
    return (dispatch) => {
        let json = {
            result_id: resultId,
            verify_answers: verifyAnswers
        };
        return new Promise((resolve, reject) => {
            dispatch({
                type: SET_RESULT_CODE + SOCKET_REQUEST,
                transport: 'socket',
                promise: socket => finish_guest_result(socket, json).then(resp => {
                    if (resp !== true) {
                        reject(resp);
                        dispatch({
                            type: SET_RESULT_CODE + '/ERROR',
                            payload: resp
                        });
                    } else {
                        resolve(true);
                    }
                })
            })
        });
    }
}

export function exportResult(resultId, format){
    let json = {
        result_id: resultId,
        export_format: format
    };
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: 'SOMETHING' + SOCKET_REQUEST,
                transport: 'socket',
                promise: socket => export_result(socket, json)
            })
        })
    }
}

export function toggleSheetPublic(sheetId, enabled, resultCodeId = null) {
    let json = {
        sheet_id: sheetId,
        enabled: enabled,
        result_code_id: resultCodeId
    };
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: TOGGLE_SHEET_PUBLIC + SOCKET_REQUEST,
                transport: 'socket',
                promise: socket => toggle_sheet_public(socket, json).then(resp => {
                    if (resp) {
                        resolve(true)
                    } else {
                        reject(resp);
                    }
                }).catch(err => reject(err))
            });
        });
    }
}

export function subscribeToCalcTemplateMetaUpdates() {
    return (dispatch) => {
        CALC_TEMPLATE_META_ACTIONS.map(action => {
            return (
                dispatch({
                    type: action + SOCKET_SUBSCRIBE,
                    transport: 'socket',
                    promise: socket => socket.on(action.toLowerCase() + '/success', (payload) => {
                        dispatch({
                            type: action,
                            payload: payload
                        })
                    })
                })
            )
        })
    }
}

export function unsubscribeFromCalcTemplateMetaUpdates() {
    return (dispatch) => {
        CALC_TEMPLATE_META_ACTIONS.map(action => {
            return (
                dispatch({
                    type: action + SOCKET_UNSUBSCRIBE,
                    transport: 'socket',
                    promise: socket => socket.off(action.toLowerCase() + '/success'),
                })
            )
        })
    }
}

export function fetchCalcTemplates(sheetId) {
    return (dispatch) => {
        dispatch({
            type: GET_CALC_TEMPLATES + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_calc_templates(socket, sheetId).then(response => {
                dispatch({
                    type: GET_CALC_TEMPLATES,
                    payload: response
                })
            }),
        });
    }
}

export function fetchDefaultCalcTemplate(resultId) {
    return (dispatch) => {
        dispatch({
            type: GET_DEFAULT_CALC_TEMPLATE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_default_calc_template(socket, resultId).then(response => {
                dispatch({
                    type: GET_DEFAULT_CALC_TEMPLATE,
                    payload: response
                })
            }),
        })
    }
}

export function setCurrentCalcTemplate(calcTemplateId) {
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_CALC_TEMPLATE,
            payload: calcTemplateId
        })
    }
}

export function resetCurrentCalcTemplate() {
    return (dispatch) => {
        dispatch({
            type: RESET_CURRENT_CALC_TEMPLATE,
        })
    }
}

export function setDefaultCalcTemplate(calcTemplateId, sheetId) {
    let json = {
        calc_template_id: calcTemplateId,
        sheet_id: sheetId
    };
    return (dispatch) => {
        dispatch({
            type: SET_DEFAULT_CALC_TEMPLATE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => set_default_calc_template(socket, json),
        });
    }
}

export function createCalcTemplate(name, sheetId, userName) {
    let json = {
        name: name,
        sheet_id: sheetId,
        user_name: userName
    };
    return (dispatch) => {
        dispatch({
            type: CREATE_CALC_TEMPLATE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => create_calc_template(socket, json),
        });
    }
}

export function copyCalcTemplate(sheetId, name, calcTemplateId, userName) {
    let json = {
        sheet_id: sheetId,
        name: name,
        calc_template_id: calcTemplateId,
        user_name: userName
    };
    return (dispatch) => {
        dispatch({
            type: COPY_CALC_TEMPLATE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => copy_calc_template(socket, json),
        })
    }
}

export function deleteCalcTemplate(sheetId, calcTemplateId) {
    let json = {
        sheet_id: sheetId,
        calc_template_id: calcTemplateId
    };
    return (dispatch) => {
        dispatch({
            type: DELETE_CALC_TEMPLATE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => delete_calc_template(socket, json),
        })
    }
}

export function lockCalcTemplate(sheetId, calcTemplateId) {
    let json = {
        sheet_id: sheetId,
        calc_template_id: calcTemplateId
    };
    return (dispatch) => {
        dispatch({
            type: LOCK_CALC_TEMPLATE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => lock_calc_template(socket, json),
        });
    }
}

export function subscribeToCalcTemplateEditUpdates() {
    return (dispatch) => {
        CALC_TEMPLATE_EDIT_ACTIONS.map(action => {
            return (
                dispatch({
                    type: action + SOCKET_SUBSCRIBE,
                    transport: 'socket',
                    promise: socket => socket.on(action.toLowerCase() + '/success', (payload) => {
                        dispatch({
                            type: action,
                            payload: payload
                        })
                    })
                })
            )
        })
    }
}

export function unsubscribeFromCalcTemplateEditUpdates() {
    return (dispatch) => {
        CALC_TEMPLATE_EDIT_ACTIONS.map(action => {
            return (
                dispatch({
                    type: action + SOCKET_UNSUBSCRIBE,
                    transport: 'socket',
                    promise: socket => socket.off(action.toLowerCase() + '/success'),
                })
            )
        })
    }
}

export function setOptionWeight(sheetId, calcTemplateId, optionGroupId, optionValueId, weight) {
    let json = {
        sheet_id: sheetId,
        calc_template_id: calcTemplateId,
        option_group_id: optionGroupId,
        option_id: optionValueId,
        weight: weight
    };
    return (dispatch) => {
        dispatch({
            type: SET_OPTION_WEIGHT + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => set_option_weight(socket, json),
        })
    }
}

export function setItemWeight(sheetId, calcTemplateId, itemId, weight) {
    let json = {
        sheet_id: sheetId,
        calc_template_id: calcTemplateId,
        item_id: itemId,
        weight: weight
    };
    return (dispatch) => {
        dispatch({
            type: SET_ITEM_WEIGHT + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => set_item_weight(socket, json),
        })
    }
}

export function changeCalcTemplatePercentages(sheetId, calcTemplateId) {
    let json = {
        sheet_id: sheetId,
        calc_template_id: calcTemplateId
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_CALC_TEMPLATE_PERCENTAGES + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_calc_template_percentages(socket, json),
        })
    }
}

export function changeCalcTemplateUseWeights(sheetId, calcTemplateId) {
    let json = {
        sheet_id: sheetId,
        calc_template_id: calcTemplateId
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_CALC_TEMPLATE_USE_WEIGHTS + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_calc_template_use_weights(socket, json),
        })
    }
}

export function changeNotAssessableReduceWeights(sheetId, calcTemplateId, notAssessableReduceWeights) {
    let json = {
        sheet_id: sheetId,
        calc_template_id: calcTemplateId,
        reduce_weights: notAssessableReduceWeights
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_NOT_ASSESSABLE_REDUCE_WEIGHTS + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_not_assessable_reduce_weights(socket, json),
        })
    }
}

export function setScoreDisplay(sheetId, calcTemplateId, score_display) {
    let json = {
        sheet_id: sheetId,
        calc_template_id: calcTemplateId,
        score_display: score_display
    };

    return (dispatch) => {
        dispatch({
            type: SET_SCORE_DISPLAY + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => set_score_display(socket, json),
        })
    }
}

export function changeItemActive(sheetId, calcTemplateId, itemId) {
    let json = {
        sheet_id: sheetId,
        calc_template_id: calcTemplateId,
        item_id: itemId,
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_ITEM_ACTIVE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_item_active(socket, json),
        })
    }
}

export function changeOptionActive(sheetId, calcTemplateId, optionId) {
    let json = {
        sheet_id: sheetId,
        calc_template_id: calcTemplateId,
        option_id: optionId,
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_OPTION_ACTIVE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_option_active(socket, json),
        })
    }
}

export function changeOptionCategoryNull(sheetId, calcTemplateId, optionId) {
    let json = {
        sheet_id: sheetId,
        calc_template_id: calcTemplateId,
        option_id: optionId,
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_OPTION_CATEGORY_NULL + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_option_category_null(socket, json),
        })
    }
}

export function changeOptionSheetNull(sheetId, calcTemplateId, optionId) {
    let json = {
        sheet_id: sheetId,
        calc_template_id: calcTemplateId,
        option_id: optionId,
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_OPTION_SHEET_NULL + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_option_sheet_null(socket, json),
        })
    }
}


export function fetchReportableSheetMetaData() {
    return (dispatch) => {
        dispatch({
            type: FETCH_REPORTABLE_SHEET_METADATA + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => fetch_reportable_sheet_metadata(socket).then(response => {
                dispatch({
                    type: FETCH_REPORTABLE_SHEET_METADATA,
                    payload: response
                })
            }),
        })
    }
}

export function subscribeToBulkEditUpdates() {
    return (dispatch) => {
        dispatch({
            type: BULK_EDIT_SCORE_UPDATE + SOCKET_SUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.on(BULK_EDIT_SCORE_UPDATE.toLowerCase(), (response) => {
                dispatch({
                    type: BULK_EDIT_SCORE_UPDATE,
                    payload: response
                })
            })
        })
    }
}

export function unsubscribeFromBulkEditUpdates() {
    return (dispatch) => {
        dispatch({
            type: BULK_EDIT_SCORE_UPDATE + SOCKET_UNSUBSCRIBE,
            transport: 'socket',
            promise: socket => socket.off(BULK_EDIT_SCORE_UPDATE.toLowerCase())
        })
    }
}

export function fetchResultsForSheet(sheetId, selectedMonths = null) {
    let json = {
        sheet_id: sheetId,
        monthrange: selectedMonths
    };
    return (dispatch) => {
        dispatch({
            type: FETCH_RESULTS_FOR_SHEET + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => fetch_results_for_sheet(socket, json).then(response => {
                dispatch({
                    type: FETCH_RESULTS_FOR_SHEET,
                    payload: response
                })
            }),
        })
    }
}

export function fetchReportMetaData() {
    return (dispatch) => {
        dispatch({
            type: FETCH_REPORT_METADATA + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => fetch_report_metadata(socket).then(response => {
                dispatch({
                    type: FETCH_REPORT_METADATA,
                    payload: response
                })
            }),
        })
    }
}

export function fetchReport(reportId) {
    return (dispatch) => {
        dispatch({
            type: FETCH_REPORT + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => fetch_report(socket, reportId).then(response => {
                dispatch({
                    type: FETCH_REPORT,
                    payload: response
                })
            }),
        });
    }
}

export function createReportTemplate(reportTemplateName, userName) {
    let json = {reportTemplateName: reportTemplateName, creator: userName};
    return (dispatch) => {
        dispatch({
            type: CREATE_REPORT_TEMPLATE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => create_report_template(socket, json).then(response => {
                dispatch({
                    type: CREATE_REPORT_TEMPLATE,
                    payload: response
                })
            }),
        })
    }
}

export function subscribeToReportTemplateEdits() {
    return (dispatch) => {
        REPORT_TEMPLATE_EDIT_ACTIONS.map(action => {
            return (
                dispatch({
                    type: action + SOCKET_SUBSCRIBE,
                    transport: 'socket',
                    promise: socket => socket.on(action.toLowerCase() + '/success', (payload) => {
                        dispatch({
                            type: action,
                            payload: payload
                        })
                    })
                })
            )
        })
    }
}

export function unsubscribeFromReportTemplateEdits() {
    return (dispatch) => {
        REPORT_TEMPLATE_EDIT_ACTIONS.map(action => {
            return (
                dispatch({
                    type: action + SOCKET_UNSUBSCRIBE,
                    transport: 'socket',
                    promise: socket => socket.off(action.toLowerCase() + '/success')
                })
            )
        })
    }
}

export function addReportItem(reportId, itemId, sheetId, index) {
    let json = {
        report_id: reportId,
        item_id: itemId,
        sheet_id: sheetId,
        index: index
    };
    return (dispatch) => {
        dispatch({
            type: ADD_REPORT_ITEM + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => add_report_item(socket, json),
        })
    }
}

export function deleteReportItem(reportId, itemId) {
    let json = {
        report_id: reportId,
        item_id: itemId
    };
    return (dispatch) => {
        dispatch({
            type: DELETE_REPORT_ITEM + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => delete_report_item(socket, json),
        })
    }
}

export function moveReportItem(reportId, reportItemId, newIndex) {
    let json = {
        report_id: reportId,
        report_item_id: reportItemId,
        new_index: newIndex
    };
    return (dispatch) => {
        dispatch({
            type: MOVE_REPORT_ITEM + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => move_report_item(socket, json),
        })
    }
}

export function fetchCalibrationMetaData() {
    return (dispatch) => {
        dispatch({
            type: FETCH_CALIBRATION_METADATA + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => fetch_calibration_metadata(socket).then(response => {
                dispatch({
                    type: FETCH_CALIBRATION_METADATA,
                    payload: response
                })
            }),
        })
    }
}

export function fetchCalibration(calibrationId) {
    return (dispatch) => {
        dispatch({
            type: FETCH_CALIBRATION + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => fetch_calibration(socket, calibrationId).then(response => {
                dispatch({
                    type: SET_CURRENT_CALIBRATION,
                    payload: response
                })
            }),
        })
    }
}

export function createNewCalibration(sheetId) {
    return (dispatch) => {
        dispatch({
            type: CREATE_NEW_CALIBRATION + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => create_new_calibration(socket, sheetId).then(response => {
                dispatch({
                    type: SET_CURRENT_CALIBRATION,
                    payload: response
                })
            }),
        })
    }
}

export function fetchCalibrationResult(calibrationId) {
    return (dispatch) => {
        dispatch({
            type: GET_CALIBRATION_RESULT + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_calibration_result(socket, calibrationId).then(response => {
                dispatch({
                    type: SET_CURRENT_RESULT,
                    payload: response
                })
            }),
        })
    }
}

export function fetchCalibrationResults(calibrationId) {
    return (dispatch) => {
        dispatch({
            type: GET_CALIBRATION_RESULTS + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_calibration_results(socket, calibrationId).then(response => {
                dispatch({
                    type: GET_CALIBRATION_RESULTS,
                    payload: response
                })
            }),
        })
    }
}

export function subscribeToCalibrationUpdates() {
    return (dispatch) => {
        CALIBRATION_ACTIONS.map(action => {
            return (
                dispatch({
                    type: action + SOCKET_SUBSCRIBE,
                    transport: 'socket',
                    promise: socket => socket.on((action + SOCKET_SUCCESS).toLowerCase(), (response) => {
                        // A bit strange perhaps, but all other handlers depend on SET_CURRENT_CALIBRATION
                        // whereas for these we need to emit the specific actions for the reducer.
                        if ([ATTACH_FILE_TO_CALIBRATION, DELETE_CALIBRATION_FILE].includes(action)) {
                            dispatch({
                                type: action,
                                payload: response
                            })
                        } else {
                            dispatch({
                                type: SET_CURRENT_CALIBRATION,
                                payload: response
                            });
                        }
                    }),
                })
            )
        });
    }
}

export function unsubscribeFromCalibrationUpdates() {
    return (dispatch) => {
        CALIBRATION_ACTIONS.map(action => {
            return (
                dispatch({
                    type: action + SOCKET_UNSUBSCRIBE,
                    transport: 'socket',
                    promise: socket => socket.off((action + SOCKET_SUCCESS).toLowerCase()),
                })
            )
        })
    }
}

export function addUsersToCalibration(calibrationId, userIds) {
    let json = {
        calibration_id: calibrationId,
        user_ids: userIds
    };
    return (dispatch) => {
        dispatch({
            type: ADD_USERS_TO_CALIBRATION + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => add_users_to_calibration(socket, json),
        })
    }
}

export function removeUserFromCalibration(calibrationId, userId) {
    let json = {
        calibration_id: calibrationId,
        user_id: userId
    };
    return (dispatch) => {
        dispatch({
            type: REMOVE_USER_FROM_CALIBRATION + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => remove_user_from_calibration(socket, json),
        })
    }
}

export function changeCalibrationName(calibrationId, newName) {
    let json = {
        calibration_id: calibrationId,
        new_name: newName
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_CALIBRATION_NAME + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_calibration_name(socket, json),

        })
    }
}

export function changeCalibrationDescription(calibrationId, newDescription) {
    let json = {
        calibration_id: calibrationId,
        new_description: newDescription
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_CALIBRATION_DESCRIPTION + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_calibration_description(socket, json),
        })
    }
}

export function setCalibrationResultCode(calibrationId, resultCodeId) {
    let json = {
        calibration_id: calibrationId,
        result_code_id: resultCodeId
    };
    return (dispatch) => {
        dispatch({
            type: SET_CALIBRATION_RESULT_CODE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => set_calibration_result_code(socket, json)
        })
    }
}

export function setCalibrationDeadlineDate(calibrationId, deadlineDate) {
    let json = {
        calibration_id: calibrationId,
        deadline_date: deadlineDate
    };
    return (dispatch) => {
        dispatch({
            type: SET_CALIBRATION_DEADLINE_DATE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => set_calibration_deadline_date(socket, json)
        })
    }
}

export function deleteCalibrationFile(calibrationId, fileId) {
    let json = {
        calibration_id: calibrationId,
        file_id: fileId
    };
    return (dispatch) => {
        dispatch({
            type: DELETE_CALIBRATION_FILE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => delete_calibration_file(socket, json)
        })
    };
}


export function setCalibrationResultToDone(calibrationId, calibrationResultId) {
    let json = {
        calibration_id: calibrationId,
        calibration_result_id: calibrationResultId,
    };
    return (dispatch) => {
        dispatch({
            type: SET_CALIBRATION_RESULT_TO_DONE + SOCKET_REQUEST,
            transport: 'socket',
            subscribeTo: socket => {
                socket.on('socket/calibration_result_done/error', (response) => {
                    // using this reducer action type as it is the same structure
                    dispatch({
                        type: SET_RESULT_CODE + '/ERROR',
                        payload: response,
                    });
                });
            },
            promise: socket => set_calibration_result_to_done(socket, json)
                .then(response => {
                    dispatch({
                        type: SET_CALIBRATION_RESULT_TO_DONE,
                        payload: response
                    })
                }),
        })
    }
}

export function closeCalibration(calibrationId) {
    return (dispatch) => {
        dispatch({
            type: CLOSE_CALIBRATION + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => close_calibration(socket, calibrationId),
        })
    }
}

export function saveCalibrationComment(calibrationResultId, itemId, comment, isPublic, commentId = null) {
    let json = {
        calibration_result_id: calibrationResultId,
        item_id: itemId,
        comment: comment,
        public: isPublic,
        comment_id: commentId
    };
    return (dispatch) => {
        dispatch({
            type: SAVE_CALIBRATION_COMMENT + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => save_calibration_comment(socket, json).then(response => {
                dispatch({
                    type: SAVE_CALIBRATION_COMMENT,
                    payload: response
                })
            }),
        })
    }
}

export function fetchCalibrationTableData(calibrationId) {
    return (dispatch) => {
        dispatch({
            type: FETCH_CALIBRATION_TABLE_DATA + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => fetch_calibration_table_data(socket, calibrationId).then(response => {
                dispatch({
                    type: FETCH_CALIBRATION_TABLE_DATA,
                    payload: response
                })
            }),
        })
    }
}

export function resetCurrentResult() {
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_RESULT,
            payload: null
        })
    }
}


export function resetSelectedSheets() {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_SHEETS,
            payload: []
        });
    }
}

export function resetSelectedReportTemplate() {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_REPORT_TEMPLATE,
            payload: {}
        });
    }
}

export function resetUserItemTableData() {
    return (dispatch) => {
        dispatch({
            type: RESET_ITEM_TABLE_DATA
        })
    }
}

export function resetResultReports() {
    return (dispatch) => {
        dispatch({
            type: RESET_RESULT_REPORTS
        })
    }
}

export function resetCurrentCalibration() {
    return (dispatch) => {
        dispatch({
            type: RESET_CURRENT_CALIBRATION
        })
    }
}

export function getSheetResultReportData(sheetIds, fromDate = null, toDate = null, filterItems = null, nestingItem = null) {
    let json = {
        sheet_ids: sheetIds,
        from_date: fromDate,
        to_date: toDate,
        filter_items: filterItems,
        nesting_item: nestingItem
    };
    return (dispatch) => {
        dispatch(fetchingReportsData());
        dispatch({
            type: GET_SHEET_RESULT_REPORT_DATA + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_sheet_result_report_data(socket, json).then(response => {
                dispatch({
                    type: GET_SHEET_RESULT_REPORT_DATA,
                    payload: response
                })
            }),
        })
    }
}

export function fetchingReportsData() {
    return (dispatch) => {
        dispatch({
            type: FETCHING_REPORTS_DATA
        })
    }
}

export function getReportTemplateReportData(reportId, fromDate = null, toDate = null, getMonthlyData = false) {
    let json = {
        report_id: reportId,
        from_date: fromDate,
        to_date: toDate,
        get_monthly_data: getMonthlyData
    };
    return (dispatch) => {
        dispatch({
            type: GET_REPORT_TEMPLATE_REPORT_DATA + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_report_template_report_data(socket, json).then(response => {
                dispatch({
                    type: GET_SHEET_RESULT_REPORT_DATA,
                    payload: response
                })
            })
        })
    }

}

export function exportRawReportAsExcel(sheetIds, fromDate = null, toDate = null, filterItems = null, allResults = false) {
    let json = {
        sheet_ids: sheetIds,
        from_date: fromDate,
        to_date: toDate,
        filter_items: filterItems,
        get_all_results: allResults
    };
    return (dispatch) => {
        dispatch({
            type: RESET_FILE_DOWNLOAD
        });
        dispatch({
            type: EXPORT_RAW_REPORT_AS_EXCEL + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => export_raw_report_as_excel(socket, json)
        })
    }
}

export function exportResultColumnsReportAsExcel(sheetIds, fromDate = null, toDate = null, filterItems = null) {
    let json = {
        sheet_ids: sheetIds,
        from_date: fromDate,
        to_date: toDate,
        filter_items: filterItems,
    };
    return (dispatch) => {
        dispatch({
            type: RESET_FILE_DOWNLOAD
        });
        dispatch({
            type: EXPORT_RESULT_COLUMNS_REPORT_AS_EXCEL + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => export_result_columns_report_as_excel(socket, json)
        })
    }
}

export function exportLocationReportAsExcel(sheetIds, fromDate = null, toDate = null, filterItems = null) {
    let json = {
        sheet_ids: sheetIds,
        from_date: fromDate,
        to_date: toDate,
        filter_items: filterItems
    };
    return (dispatch) => {
        dispatch({
            type: RESET_FILE_DOWNLOAD
        });
        dispatch({
            type: EXPORT_LOCATION_REPORT_AS_EXCEL + SOCKET_REQUEST,
            transport: 'socket',
            subscribeTo: socket => socket.on((EXPORT_LOCATION_REPORT_AS_EXCEL + SOCKET_SUCCESS).toLowerCase(), (response) => {
                dispatch({
                    type: EXPORT_LOCATION_REPORT_AS_EXCEL,
                    payload: response
                })
            }),
            promise: socket => export_location_report_as_excel(socket, json)
        })
    }
}

export function exportTextReportAsExcel(sheetIds, fromDate = null, toDate = null, filterItems = null) {
    let json = {
        sheet_ids: sheetIds,
        from_date: fromDate,
        to_date: toDate,
        filter_items: filterItems
    };
    return (dispatch) => {
        dispatch({
            type: RESET_FILE_DOWNLOAD
        });
        dispatch({
            type: EXPORT_TEXT_REPORT_AS_EXCEL + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => export_text_report_as_excel(socket, json)
        })
    }
}

export function exportReportAsExcel(sheetIds, fromDate = null, toDate = null, filterItems = null) {
    let json = {
        sheet_ids: sheetIds,
        from_date: fromDate,
        to_date: toDate,
        filter_items: filterItems
    };
    return (dispatch) => {
        dispatch({
            type: RESET_FILE_DOWNLOAD
        });
        dispatch({
            type: EXPORT_REPORT_AS_EXCEL + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => export_report_as_excel(socket, json)
        })
    }
}

export function exportReportAsPdf(sheetIds, fromDate = null, toDate = null, filterItems = null) {
    let json = {
        sheet_ids: sheetIds,
        from_date: fromDate,
        to_date: toDate,
        filter_items: filterItems
    };
    return (dispatch) => {
        dispatch({
            type: RESET_FILE_DOWNLOAD
        });
        dispatch({
            type: EXPORT_REPORT_AS_PDF + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => export_report_as_pdf(socket, json)
        })
    }
}

export function exportReportAsPowerpoint(sheetIds, fromDate = null, toDate = null, filterItems = null) {
    let json = {
        sheet_ids: sheetIds,
        from_date: fromDate,
        to_date: toDate,
        filter_items: filterItems
    };
    return (dispatch) => {
        dispatch({
            type: RESET_FILE_DOWNLOAD
        });
        dispatch({
            type: EXPORT_REPORT_AS_POWERPOINT + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => export_report_as_powerpoint(socket, json)
        })
    }
}

export function getCategoryScoreProgression(sheetIds, categoryName, toDate) {
    let json = {
        sheet_ids: sheetIds,
        category_name: categoryName,
        to_date: toDate
    };
    return (dispatch) => {
        dispatch({
            type: GET_CATEGORY_SCORE_PROGRESSION + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_category_score_progression(socket, json).then(response => {
                dispatch({
                    type: GET_CATEGORY_SCORE_PROGRESSION,
                    payload: response
                })
            })
        })
    }
}

export function getUserMetrics(date, timeframe = 'WEEK') {
    let json = {
        date: date,
        timeframe: timeframe
    };
    return (dispatch) => {
        dispatch({
            type: GET_USER_METRICS + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_user_metrics(socket, json).then(response => {
                dispatch({
                    type: GET_USER_METRICS,
                    payload: response
                })
            })
        })
    }
}


export function getSheetImportCompatibility(sheetId, filename, excelDimensions, excelSheetName = null) {
    let json = {
        sheet_id: sheetId,
        filename: filename,
        excel_dimensions: excelDimensions,
        excel_sheet_name: excelSheetName
    };
    return (dispatch) => {
        dispatch({
            type: GET_SHEET_IMPORT_COMPATIBILITY + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_sheet_import_compatibility(socket, json).then(response => {
                dispatch({
                    type: GET_SHEET_IMPORT_COMPATIBILITY,
                    payload: response
                })
            })
        })
    }
}

export function confirmImportExcelResults(sheetId, filename, excelDimensions, excelSheetName, resultCodeId = null, date) {
    let json = {
        sheet_id: sheetId,
        filename: filename,
        excel_dimensions: excelDimensions,
        excel_sheet_name: excelSheetName,
        result_code_id: resultCodeId,
        result_date: date
    };
    return (dispatch) => {
        dispatch({
            type: CONFIRM_IMPORT_EXCEL_RESULTS + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => create_results_from_excel_sheet(socket, json)
        })
    }
}

export function createExportMarker() {
    return (dispatch) => {
        dispatch({
            type: CREATE_EXPORT_MARKER + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => create_export_marker(socket)
        })
    }
}

export function getExportMarkerMetadata() {
    return (dispatch) => {
        dispatch({
            type: GET_EXPORT_MARKER_METADATA + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_export_marker_metadata(socket).then(response => {
                dispatch({
                    type: GET_EXPORT_MARKER_METADATA,
                    payload: response
                })
            }),
        })
    }
}

export function getExportMarkerResults(exportDate = null) {
    return (dispatch) => {
        dispatch({
            type: GET_EXPORT_MARKER_RESULTS + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_export_marker_results(socket, exportDate).then(response => {
                dispatch({
                    type: GET_EXPORT_MARKER_RESULTS,
                    payload: response
                })
            }),
        })
    }
}

export function downloadExportMarkerData(markerDate) {
    return (dispatch) => {
        dispatch({
            type: DOWNLOAD_EXPORT_MARKER_DATA + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => download_export_marker_data(socket, markerDate)
        })
    }
}

export function getTableDataForItem(date, timeframe, sheetIds, itemName, nestedItem = null) {
    let json = {
        date: date,
        timeframe: timeframe,
        sheet_ids: sheetIds,
        item_name: itemName,
        nested_item: nestedItem
    };
    return (dispatch) => {
        dispatch({
            type: GET_TABLE_DATA_FOR_ITEM + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_table_data_for_item(socket, json).then(response => {
                dispatch({
                    type: GET_TABLE_DATA_FOR_ITEM,
                    payload: response
                })
            })
        })
    }
}

export function setItemExample(resultId, sheetId, itemId, value, positive, reportable = false) {
    let json = {
        result_id: resultId,
        sheet_id: sheetId,
        item_id: itemId,
        value: value,
        positive: positive,
        reportable: reportable
    };

    return (dispatch) => {
        dispatch({
            type: SET_ITEM_EXAMPLE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => set_item_example(socket, json)
        })
    }
}

export function toggleItemExamplesReportable(itemExampleIds) {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_ITEM_EXAMPLES_REPORTABLE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => toggle_item_examples_reportable(socket, itemExampleIds)
        })
    }
}

export function deleteItemExamples(itemExampleIds) {
    return (dispatch) => {
        dispatch({
            type: DELETE_ITEM_EXAMPLES + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => delete_item_examples(socket, itemExampleIds),
        })
    }
}

export function getItemExamples(sheetIds = null, fromDate = null, toDate = null) {
    let json = {};
    if (sheetIds) json.sheet_ids = sheetIds;
    if (fromDate) json.from_date = fromDate;
    if (toDate) json.to_date = toDate;
    return (dispatch) => {
        dispatch({
            type: GET_ITEM_EXAMPLES + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_item_examples(socket, json).then(response => {
                dispatch({
                    type: GET_ITEM_EXAMPLES,
                    payload: response
                })
            })
        })
    }
}

export function setDisconnected(disconnected) {
    return (dispatch) => {
        dispatch({
            type: SET_DISCONNECTED,
            payload: disconnected
        })
    }
}

export function getItemPresentationMapper() {
    return (dispatch) => {
        dispatch({
            type: SET_ITEM_PRESENTATION_MAPPER + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => get_item_presentation_mapper(socket).then(response => {
                dispatch({
                    type: SET_ITEM_PRESENTATION_MAPPER,
                    payload: response
                })
            })
        })
    }
}

export function onPresentationTypeChange(sheetId, itemId, pType) {
    let json = {
        sheet_id: sheetId,
        item_id: itemId,
        ptype: pType,
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_ITEM_PRESENTATION_TYPE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => change_sheet_item_presentation_type(socket, json).then(response => {
                dispatch({
                    type: CHANGE_ITEM_PRESENTATION_TYPE,
                    payload: response
                })
            })
        })
    }
}

export function onChangeSheetItemIntMinimumValue(sheetId, itemId, value) {
    let json = {
        sheet_id: sheetId,
        item_id: itemId,
        value: value,
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_SHEET_ITEM_INT_MINIMUM_VALUE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => on_change_sheet_item_int_minimum_value(socket, json)
        })
    }
}

export function onChangeSheetItemIntMaximumValue(sheetId, itemId, value) {
    let json = {
        sheet_id: sheetId,
        item_id: itemId,
        value: value,
    };
    return (dispatch) => {
        dispatch({
            type: CHANGE_SHEET_ITEM_INT_MAXIMUM_VALUE + SOCKET_REQUEST,
            transport: 'socket',
            promise: socket => on_change_sheet_item_int_maximum_value(socket, json)
        })
    }
}
