import React from 'react';
import browserHistory from '../browserHistory';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dataCreators from '../actions/data';
import * as socketDataCreators from '../actions/socketActions';
import * as authCreators from '../actions/auth';
import LoadingSpinner from "../components/LoadingSpinner";
import GuestSurveyView from "../components/GuestSurveyComponents/GuestSurveyView";
import GuestSurveyHeader from "../components/GuestSurveyComponents/GuestSurveyHeader";
import StatusSnackbar from "../components/StatusSnackbar";


class CreateSurveyContainer extends React.Component {
    // This container handles the links shared
    // if all goes well it redirects to /survey/sheet_id/result_id
    // which is handled by the GuestSurveyContainer below
    // alternatively, if user is signed in and the token is valid
    // the backend will instead set resp.redirect to /desk?create_result=<sheet_id>
    // which will automatically create a result for the sheet using the normal strategy
    // so that it gets associated properly
    state = {invalid: null};
    componentDidMount(){
        let sheetId = this.props.match.params.sheet_id;
        this.props.createGuestResult(sheetId, this.props.token).then(resp => {
            browserHistory.push(resp.redirect);
        }).catch(() => {
            this.setState({invalid: true});
        });
    }

    render() {
        if (this.state.invalid === null){
            return (
                <React.Fragment>
                    <GuestSurveyHeader/>
                    <LoadingSpinner/>
                </React.Fragment>
                )
        }
        else if (this.state.invalid){
            return (
                <GuestSurveyView resultInvalid={true}/>
            )
        }
    }
}

export const GuestSurveyRedirect = connect(mapStateToProps, mapDispatchToProps)(CreateSurveyContainer);

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        currentResult: state.socket.currentResult,
        currentResultScores: state.socket.currentResultScores,
        currentCalcTemplate: state.socket.currentCalcTemplate,
        activeUsers: state.socket.activeUsers,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({...dataCreators, ...socketDataCreators, ...authCreators}, dispatch);
}

class GuestSurveyContainer extends React.Component {
    state = {
        loaded: false,
        resultFinished: false,
        snackbarOpen: false,
        snackbarVariant: null,
        snackbarMessage: null
    };

    componentDidMount() {
        this.props.fetchGuestResult(this.props.match.params.result_id, this.props.token);
        this.props.connectSocketUnauthenticated().then(resp => {
            if (resp){
                this.setState({loaded: true});

                this.props.joinRoomUnauthenticated('Guest', 'result/' + this.props.match.params.result_id);
                this.props.subscribeToResultUpdates();
                this.props.subscribeToItemExampleUpdates();
                this.props.subscribeToUserEvents();
                this.props.fetchDefaultCalcTemplate(this.props.match.params.result_id);
                this.props.fetchResultScores(this.props.match.params.result_id);
            }
        }).catch(err => {
            console.log('err connecting socket');
        });

    }

    componentWillUnmount() {
        this.props.leaveRoomUnauthenticated('Guest', 'result/' + this.props.match.params.result_id);
        this.props.resetCurrentResult();
        this.props.unsubscribeFromResultUpdates();
        this.props.unsubscribeFromItemExampleUpdates();
        this.props.unsubscribeFromUserEvents();
    }

    onClose = () => {
        browserHistory.push('/');
    };

    answerItem = (resultId, itemId, values) => {
        this.props.answerItem({
            result_id: resultId,
            item_id: itemId,
            answer: values,
        });
    };

    setItemExample = (resultId, itemId, value, positive) => {
        this.props.setItemExample(
            resultId,
            this.props.currentResult.sheet.sheet_id,
            itemId,
            value,
            positive,
            false
        )
    };

    finishResult = () => {
        let resultId = this.props.match.params.result_id;
        this.props.finishGuestResult(resultId, true).then(success => {
            this.setState({resultFinished: success});
            this.openSnackbar('success', this.props.t('resultFinished'));
        }).catch(errors => {
            if (errors.error_message){
                this.openSnackbar('error', errors.error_message);
            }

        });
    };

    openSnackbar = (variant, message) => {
        this.setState({
            snackbarOpen: true,
            snackbarVariant: variant,
            snackbarMessage: message
        });
    };

    closeSnackbar = () => {
        this.setState({
            snackbarOpen: false,
            snackbarVariant: null,
            snackbarMessage: null
        });
    };


    render() {
        return (
            <React.Fragment>
                {this.state.loaded ?
                    <React.Fragment>
                        <GuestSurveyHeader/>
                        <StatusSnackbar
                            open={this.state.snackbarOpen}
                            variant={this.state.snackbarVariant}
                            message={this.state.snackbarMessage}
                            onClose={this.closeSnackbar}
                        />
                        <GuestSurveyView
                            resultFinished={this.state.resultFinished}
                            sheet={this.props.currentResult ? this.props.currentResult.sheet : null}
                            result={this.props.currentResult}
                            currentResultScores={this.props.currentResultScores}
                            calcTemplate={this.props.currentCalcTemplate}
                            activeUsers={this.props.activeUsers}
                            onClose={this.onClose}
                            answerItem={this.answerItem}
                            setItemExample={this.setItemExample}
                            finishResult={this.finishResult}
                        />
                    </React.Fragment>
                    :
                    <LoadingSpinner/>
                }
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestSurveyContainer);