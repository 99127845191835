import React from 'react'
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbDown from '@mui/icons-material/ThumbDown';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import DescriptionPopover from './DescriptionPopover';
import {TEXT_ITEM_MAX_LENGTH} from "../../constants";
import Tooltip from 'utils/tooltipWithPlacement';
import {withTranslation} from "react-i18next";

const ThumbUpIcon = () => <ThumbUp style={{color: '#00ff76'}}/>;
const ThumbUpNeutralIcon = () => <ThumbUp style={{color: '#000'}}/>;
const ThumbDownIcon = () => <ThumbDown style={{color: '#ff625d'}}/>;
const ThumbDownNeutralIcon = () => <ThumbDown style={{color: '#000'}}/>;


const itemNameStyle = {marginLeft: '1%', textAlign: 'left', hyphens:'auto'};
const commentButtonSelectedStyle = {backgroundColor: '#cecece'};
const helperTextProps = {style: {textAlign: 'end'}};


class TextItemProduction extends React.PureComponent {
    constructor(props) {
        super(props);
        let radioValue = '';
        if (this.props.radioValue !== null) {
            radioValue = this.props.radioValue ? "positive" : "negative"
        }
        this.state = {
            editing: false,
            textValue: this.props.value ? this.props.value : "",
            radioValue: radioValue,
            error: null,
        };
    };

    static getDerivedStateFromProps(props, state) {
        let newState = Object.assign({}, state);
        if (state.editing) {
            return null
        }
        if ((props.value || props.value === '') && props.value !== newState.textValue) {
            newState.textValue = props.value.slice();
        }

        if ((props.radioValue ? 'positive' : 'negative') !== state.radioValue) {
            if (props.radioValue === null) newState.radioValue = '';
            else newState.radioValue = props.radioValue ? 'positive' : 'negative';
        }
        return newState
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.textLimit !== prevProps.textLimit) {
            this.checkTextLimit();
        }
    }

    checkTextLimit = () => {
        // Displays error message if answer is too long
        if (this.props.textLimit){
            if (this.state.textValue.length > this.props.textLimit) {
                this.setState({
                    error: true
                })
            }
            else if (this.state.error){
                this.setState({error: null})
            }
        }
    }

    onTextInput = (event) => {
        let newValue = event.target.value;
        if (this.props.textLimit){
            if (newValue.length > this.props.textLimit){
                if (newValue.length < this.state.textValue.length){
                    // check for the case when textLimit prop changes and turns previously OK input invalid
                    // bypassing that for removing the now extra characters
                }
                else {
                    this.setState({error: true});
                    return
                }

            };
        }
        this.setState({
            textValue: newValue,
            editing: true
        });
        this.checkTextLimit();
    };

    onTextChange = (event) => {
        if ((this.props.value === this.state.textValue) || this.state.error) return;
        if (this.props.onChange && !this.props.disabled) {
            this.props.onChange(this.props.resultId, this.props.itemId, this.state.textValue.trim());
        }
        this.setState({editing: false});
    };

    onRadioChange = (event, newRadioValue) => {
        if (this.props.onChange && this.props.onRadioChange && !this.props.disabled) {
            this.setState({radioValue: newRadioValue});
            this.props.onRadioChange(
                this.props.resultId,
                this.props.itemId,
                this.state.textValue,
                newRadioValue === "positive"
            )
        }
    };

    getHelperText = () => {
        if (!this.state.textValue.length) return " ";
        if (this.props.textLimit){
            const counter = `${this.state.textValue.length} / ${this.props.textLimit}`;
            if (this.state.error) {
                return `${this.props.t('textAnswerTooLong', {maxLength: this.props.textLimit})} ${counter}`
            }
            return counter
        }
        return this.state.textValue.length;
    };

    render() {
        // todo - should use the combined resultid/itemid id everywhere for consistency and for sure no duplicates
        let id = this.props.itemId;
        if (this.props.resultRefInId) {
            id = `${this.props.resultId}/${this.props.itemId}`;
        }
        const {t} = this.props;
        return (
            <div id={id} className="row small-gutters display-flex" style={{alignItems: 'center'}}>
                <div className="col-sm-2 col-xs-12">
                    <Tooltip placement="right"
                                        title={this.props.itemDescription !== null ? this.props.itemDescription : ""}>
                        <h4 style={itemNameStyle}>{this.props.itemName}
                        {this.props.itemRequired.length > 0 &&
                        <span style={{color: 'red'}}> *</span>
                        }
                        </h4>
                    </Tooltip>
                </div>

                {this.props.avatarContainer &&
                <div
                    className={'col-sm-2 col-xs-12'}
                    id={'itemAvatarContainer' + this.props.itemId}
                    style={{display: 'inline-flex'}}
                >
                </div>
                }
                <div className={`col-sm-${this.props.avatarContainer ? '1' : '3'}`}
                     style={{display: 'flex', justifyContent: 'end'}}>
                    <div style={{marginRight: '20px'}}>
                    </div>
                </div>

                <div className={"col-sm-5 col-xs-12"} style={{textAlign: 'left'}}>
                    <React.Fragment>
                        <TextField
                            variant="standard"
                            multiline
                            fullWidth
                            value={this.state.textValue}
                            onChange={this.onTextInput}
                            onBlur={this.onTextChange}
                            disabled={this.props.disabled}
                            error={Boolean(this.state.error)}
                            FormHelperTextProps={helperTextProps}
                            helperText={this.getHelperText()}
                        />
                    </React.Fragment>
                </div>
                <div className="col-sm-2 col-xs-12" style={{textAlign: 'left'}}>
                    {this.props.showRadioButtons &&
                    <RadioGroup
                        style={{paddingLeft: '10px', flexDirection: 'row'}}
                        name={"treasure"}
                        value={this.state.radioValue}
                        disabled={this.props.disabled}
                        onChange={this.onRadioChange}
                    >
                        <Tooltip title= {t('positiveFreeText')}>
                            <Radio
                                value="positive"
                                icon={<ThumbUpNeutralIcon/>}
                                checkedIcon={<ThumbUpIcon/>}
                            />
                        </Tooltip>
                        <Tooltip title= {t('negativeFreeText')}>
                            <Radio
                                value="negative"
                                icon={<ThumbDownNeutralIcon/>}
                                checkedIcon={<ThumbDownIcon/>}
                            />
                        </Tooltip>
                    </RadioGroup>
                    }
                </div>
                {this.props.showCommentButton &&
                <div className="col-xs-1">
                    <Tooltip title={this.props.commentButtonSelected ? t('closeComments') : t('openComments')}>
                        <Badge
                            variant={'dot'}
                            color={'primary'}
                            overlap="circular"
                            badgeContent={
                                this.props.comments.length
                            }
                        >
                            <IconButton
                                style={this.props.commentButtonSelected ? commentButtonSelectedStyle : null}
                                onClick={(event) => {
                                    this.props.onCommentButtonClick(event, id)
                                }}
                                size="large">
                                <ModeCommentIcon/>
                            </IconButton>
                        </Badge>
                    </Tooltip>
                </div>
                }
            </div>
        );
    }
}

TextItemProduction.defaultProps = {
    itemDescription: "",
    itemRequired: [],
    disabled: false,
    showCommentButton: false,
    showRadioButtons: true,
    itemScore: 120,
    itemWeight: {weight: 4},
    comments: [],
    avatarContainer: false,
    radioValue: null,
    commentButtonSelected: false,
    resultRefInId: false,
    textLimit: null
};

TextItemProduction.propTypes = exact({
    resultId: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    itemName: PropTypes.string.isRequired,
    itemDescription: PropTypes.string,
    itemRequired: PropTypes.array,
    onChange: PropTypes.func,
    onRadioChange: PropTypes.func,
    radioValue: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.shape({text: PropTypes.string, radio: PropTypes.string}), PropTypes.string]),
    textLimit: PropTypes.number,
    disabled: PropTypes.bool,
    showCommentButton: PropTypes.bool,
    showRadioButtons: PropTypes.bool,
    onCommentButtonClick: PropTypes.func,
    itemWeight: PropTypes.object,
    itemScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    comments: PropTypes.arrayOf(PropTypes.object),
    avatarContainer: PropTypes.bool,
    commentButtonSelected: PropTypes.bool,
    resultRefInId: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    tReady: PropTypes.bool.isRequired,
});
export default withTranslation(['sheetManagement'])(TextItemProduction);
