import React from 'react';

import OptionItem from './OptionItem';
import OptionItemProduction from './OptionItemProduction';
import TextItem from './TextItem';
import TextItemProduction from './TextItemProduction';
import {
    EDIT_CATEGORIES, EDIT_CATALOG_MODES, PROD_CATALOG_MODES,
    RADIO_GROUP, DROP_DOWN, HIGHLIGHT_COLOR, ERROR_COLOR, TEXT, CALIB_PRESENTATION_MODE,
    PROD_FILL_CATALOG_ITEM_ORDER, CALIB_FILL_MODE_ITEM_ORDER, DATE_ITEM, INT_ITEM
} from '../../constants';
import DateItem from "./DateItem";
import DateItemProduction from "./DateItemProduction";
import IntItem from "./IntItem";
import IntItemProduction from "./IntItemProduction";


export function sortCatalogItems(items){
    return items.sort(function(x, y){
        if(x.path < y.path) return -1;
        if(x.path > y.path) return 1;
        return 0;
        }
    )
}

export function sortCatalogItemsByOrder(items){
    return items.sort(function (x, y){
        if (x.order > y.order) return 1;
        if (x.order < y.order) return -1;
        return 0;
    })
}

export function getCurrentLevelItems(items){
    if (!items || items.length <= 0) return [];
    items = sortCatalogItems(items);

    let level = items[0].path.split("/").length;
    let returnItems = items.filter(item => item.path.split("/").length === level);
    returnItems = sortCatalogItems(returnItems);
    /*
    console.groupCollapsed("getCurrentLevelItems returnItems");
        console.table(returnItems);
    console.groupEnd();
    console.groupCollapsed("getCurrentLevelItems items");
        console.table(items);
    console.groupEnd();
    */
    return returnItems;
}

export function getCategories(items){
    return items.filter(item => item.isCategory);
}

export function getParentCategories(items){
    return items.filter(item => item.isCategory && item.path.split("/").length === 2)
}

export function getCategoriesForItemAvatars(item, items){
    let level = item.path.split("/").length;
    let categories = [];

    for (let i = 0; i < level; i++){
        let category = getCategoryForItem(item, items);
        if (!category.name) continue;

        // Turn name into initials
        let label = "";
        let categoryName = category.name.split(" ");

        for (let i = 0; i < categoryName.length; i++){
            if (i > 2) break;
            label += categoryName[i][0]
        }

        label = label.toUpperCase();

        categories.push({
            id: category.id,
            label: label,
            color: category.color,
            name: category.name,
            description: category.description
        });
        item = category;
    }
    // List gets reversed since loop starts with item and works its way upwards
    // through the different categories until root level
    categories = categories.reverse();
    return categories;
}

export function getCategoryForItem(givenItem, items){
    let itemPath = givenItem.path.split("/");
    // Remove item order number from path to get the parent category path
    itemPath.pop();
    let categoryPath = itemPath.join("/");
    let category = items.filter(item => item.path === categoryPath)[0];

    return category || {};
}

export function getCategoryChildren(categoryPath, items){
    let re = new RegExp(`^${categoryPath}.+`);
    return items.filter(item => item.path.match(re));
}

export function getOptionForItem(itemOptionId, catalogOptions){
    return catalogOptions.find(option => option.option_group_id === itemOptionId);
}

export function getItemViewById(itemId, catalog, options = {}){
    let itemObject = catalog.items.filter(item => item.id === itemId)[0];
    return getItemView(itemObject, catalog, options);
}


export function getItemView(item, catalog, options = {}) {
    const defaultOptions = {
        viewMode: EDIT_CATEGORIES,
        category: null,
        onMenuButtonClick: () => null,
        modifyItem: () => null,
        onChange: () => null,
        onRemoveAnswer: () => null,
        onTextItemRadioChange: () => null,
        textItemRadioValue: null,
        value: null,
        highlightItem: false,
        showCommentButton: false,
        showRadioButtons: true,
        onCommentButtonClick: () => null,
        resultId: null,
        displayErrors: false,
        itemError: null,
        itemWeight: {},
        itemScore: 0,
        comments: null,
        commentButtonSelected: false,
        displayPercentages: true,
        allowedAnswers: null,
        itemPresentationMapper: {},
        onPresentationTypeChange: () => null,
        onChangeSheetItemIntMaximumValue: () => null,
        onChangeSheetItemIntMinimumValue: () => null
    };
    options = Object.assign({}, defaultOptions, options);
    const {
        viewMode,
        category,
        onMenuButtonClick,
        modifyItem,
        onChange,
        onRemoveAnswer,
        onTextItemRadioChange,
        textItemRadioValue,
        value,
        highlightItem,
        showCommentButton,
        showRadioButtons,
        onCommentButtonClick,
        resultId,
        displayErrors,
        itemError,
        itemWeight,
        itemScore,
        comments,
        commentButtonSelected,
        displayPercentages,
        allowedAnswers,
        itemPresentationMapper,
        onPresentationTypeChange,
        onChangeSheetItemIntMaximumValue,
        onChangeSheetItemIntMinimumValue
    } = options;

    const divStyle = {
        backgroundColor: highlightItem ? HIGHLIGHT_COLOR : null,
        borderRadius: '25px',
        border: item.error ? `2px solid ${ERROR_COLOR}` : null,
        marginTop: item.error ? '15px' : null,
        marginBottom: item.error ? '15px' : null,
    };

    let itemOption = getOptionForItem(item.option, catalog.options);
    if (!itemOption) {
        if (item.type) itemOption = {type: item.type};
        else {
            console.groupCollapsed("Malformed item object:");
                console.table(item);
            console.groupEnd();
        }
    }

    switch (itemOption.type){
        case "OPTIONS":
        case DROP_DOWN:
        case RADIO_GROUP:
            if (EDIT_CATALOG_MODES.includes(viewMode)) {
                return (
                    <OptionItem
                        key={item.id}
                        itemId={item.id}
                        itemName={item.name}
                        itemOption={itemOption}
                        itemRequired={item.required}
                        multiselect={item.multiselect}
                        optionOverrides={item.optionOverrides}
                        optionDescriptions={item.optionDescriptions}
                        onItemLabelChange={modifyItem}
                        showMenuButton={!catalog.locked}
                        onMenuButtonClick={onMenuButtonClick}
                        onPresentationTypeChange={onPresentationTypeChange}
                        disabled={catalog.locked}
                        category={category}
                    />
                );
            }
            else if (PROD_CATALOG_MODES.includes(viewMode)){
                return (
                     <div
                        key={item.id}
                        style={divStyle}
                    >
                        <OptionItemProduction
                            key={item.id}
                            itemId={item.id}
                            itemName={item.name}
                            itemDescription={item.description}
                            itemRequired={item.required}
                            itemOption={itemOption}
                            itemWeight={itemWeight}
                            multiselect={item.multiselect}
                            itemScore={itemScore}
                            avatarContainer={
                                viewMode === PROD_FILL_CATALOG_ITEM_ORDER || viewMode === CALIB_FILL_MODE_ITEM_ORDER
                            }
                            optionOverrides={item.optionOverrides}
                            optionDescriptions={item.optionDescriptions}
                            onChange={onChange}
                            onRemoveAnswer={onRemoveAnswer}
                            value={value}
                            disabled={viewMode === CALIB_PRESENTATION_MODE}
                            showCommentButton={showCommentButton}
                            onCommentButtonClick={onCommentButtonClick}
                            comments={comments}
                            resultId={resultId}
                            commentButtonSelected={commentButtonSelected}
                            resultRefInId={viewMode === CALIB_PRESENTATION_MODE}
                            displayPercentages={displayPercentages}
                            allowedAnswers={allowedAnswers}
                        />
                         {(displayErrors && itemError) &&
                            <div className="row small-gutters">
                                <div className="col-sm-6 pull-right">
                                    <h5 style={{whiteSpace: 'pre-line', margin: 0, textAlign: 'start', color: 'red'}}>{itemError}</h5>
                                </div>
                            </div>
                         }
                     </div>
                );
            }
            break;
        // #TODO Implement slider production component
        // case SLIDER:
        //     if (EDIT_CATALOG_MODES.includes(viewMode)) {
        //         return (
        //             <SliderItem
        //                 key={item.id}
        //                 itemId={item.id}
        //                 itemName={item.name}
        //                 itemOption={itemOption}
        //                 onItemLabelChange={() => null}
        //                 showMenuButton={!catalog.locked}
        //                 onMenuButtonClick={onMenuButtonClick}
        //                 disabled={catalog.locked}
        //                 category={category}
        //             />
        //         );
        //     }
        //     break;

        case TEXT:
            if (EDIT_CATALOG_MODES.includes(viewMode)) {
                return (
                    <TextItem
                        key={item.id}
                        itemId={item.id}
                        itemName={item.name}
                        itemRequired={item.required}
                        onItemLabelChange={modifyItem}
                        showMenuButton={!catalog.locked}
                        onMenuButtonClick={onMenuButtonClick}
                        disabled={catalog.locked}
                        category={category}
                    />
                );
            }
            else if (PROD_CATALOG_MODES.includes(viewMode)){
                return (
                     <div
                        key={item.id}
                        style={divStyle}
                    >
                         <TextItemProduction
                            key={item.id}
                            itemId={item.id}
                            itemName={item.name}
                            itemDescription={item.description}
                            itemRequired={item.required}
                            itemWeight={itemWeight}
                            avatarContainer={
                                viewMode === PROD_FILL_CATALOG_ITEM_ORDER || viewMode === CALIB_FILL_MODE_ITEM_ORDER
                            }
                            onChange={onChange}
                            onRadioChange={onTextItemRadioChange}
                            radioValue={textItemRadioValue}
                            disabled={viewMode === CALIB_PRESENTATION_MODE}
                            value={value}
                            textLimit={allowedAnswers && allowedAnswers[0]}
                            showCommentButton={showCommentButton}
                            showRadioButtons={showRadioButtons}
                            onCommentButtonClick={onCommentButtonClick}
                            comments={comments}
                            resultId={resultId}
                            commentButtonSelected={commentButtonSelected}
                            resultRefInId={viewMode === CALIB_PRESENTATION_MODE}
                         />
                        {(displayErrors && itemError) &&
                            <div className="row small-gutters">
                                <div className="col-sm-6 pull-right">
                                    <h5 style={{whiteSpace: 'pre-line', margin: 0, textAlign: 'start', color: 'red'}}>{itemError}</h5>
                                </div>
                            </div>
                        }
                     </div>
                );
            }
            break;
        case DATE_ITEM:
            if (EDIT_CATALOG_MODES.includes(viewMode)) {
                return (
                    <DateItem
                        key={item.id}
                        itemId={item.id}
                        itemName={item.name}
                        itemRequired={item.required}
                        onItemLabelChange={modifyItem}
                        showMenuButton={!catalog.locked}
                        onMenuButtonClick={onMenuButtonClick}
                        disabled={catalog.locked}
                        category={category}
                    />
                );
            }
            else if (PROD_CATALOG_MODES.includes(viewMode)) {
                return (
                    <div
                        key={item.id}
                        style={divStyle}
                    >
                        <DateItemProduction
                            key={item.id}
                            itemId={item.id}
                            itemName={item.name}
                            itemDescription={item.description}
                            itemRequired={item.required}
                            itemWeight={itemWeight}
                            avatarContainer={
                                viewMode === PROD_FILL_CATALOG_ITEM_ORDER || viewMode === CALIB_FILL_MODE_ITEM_ORDER
                            }
                            onChange={onChange}
                            disabled={viewMode === CALIB_PRESENTATION_MODE}
                            value={value}
                            resultId={resultId}
                            resultRefInId={viewMode === CALIB_PRESENTATION_MODE}
                        />
                        {(displayErrors && itemError) &&
                        <div className="row small-gutters">
                            <div className="col-sm-6 pull-right">
                                <h5 style={{
                                    whiteSpace: 'pre-line',
                                    margin: 0,
                                    textAlign: 'start',
                                    color: 'red'
                                }}>{itemError}</h5>
                            </div>
                        </div>
                        }
                    </div>
                );
            }
            break;
        case INT_ITEM:
            if (EDIT_CATALOG_MODES.includes(viewMode)) {
                return (
                    <IntItem
                        key={item.id}
                        itemId={item.id}
                        itemName={item.name}
                        itemRequired={item.required}
                        value={0}
                        presentationType={item.presentationType}
                        maximumValue={item.maximumValue}
                        minimumValue={item.minimumValue}
                        onItemLabelChange={modifyItem}
                        showMenuButton={!catalog.locked}
                        onMenuButtonClick={onMenuButtonClick}
                        disabled={catalog.locked}
                        category={category}
                        itemPresentationMapper={itemPresentationMapper}
                        onPresentationTypeChange={onPresentationTypeChange}
                        onChangeSheetItemIntMaximumValue={onChangeSheetItemIntMaximumValue}
                        onChangeSheetItemIntMinimumValue={onChangeSheetItemIntMinimumValue}
                    />
                );
            }
            else if (PROD_CATALOG_MODES.includes(viewMode)) {
                return (
                    <div
                        key={item.id}
                        style={divStyle}
                    >
                        <IntItemProduction
                            key={item.id}
                            itemId={item.id}
                            itemName={item.name}
                            itemDescription={item.description}
                            itemRequired={item.required}
                            itemWeight={itemWeight}
                            itemScore={itemScore}
                            avatarContainer={
                                viewMode === PROD_FILL_CATALOG_ITEM_ORDER || viewMode === CALIB_FILL_MODE_ITEM_ORDER
                            }
                            onChange={onChange}
                            onRemoveAnswer={onRemoveAnswer}
                            disabled={viewMode === CALIB_PRESENTATION_MODE}
                            value={value}
                            showCommentButton={showCommentButton}
                            onCommentButtonClick={onCommentButtonClick}
                            comments={comments}
                            commentButtonSelected={commentButtonSelected}
                            displayPercentages={displayPercentages}
                            resultId={resultId}
                            resultRefInId={viewMode === CALIB_PRESENTATION_MODE}
                            presentationType={item.presentationType}
                            maximumValue={item.maximumValue}
                            minimumValue={item.minimumValue}
                        />
                        {(displayErrors && itemError) &&
                        <div className="row small-gutters">
                            <div className="col-sm-6 pull-right">
                                <h5 style={{
                                    whiteSpace: 'pre-line',
                                    margin: 0,
                                    textAlign: 'start',
                                    color: 'red'
                                }}>{itemError}</h5>
                            </div>
                        </div>
                        }
                    </div>
                );
            }
            break;
        default:
            console.groupCollapsed("CatalogItems - fn getItemView() - unspecified itemOption type");
                console.groupCollapsed("Item");
                    console.table(item);
                console.groupEnd();
                console.groupCollapsed("Option");
                    console.table(itemOption);
                console.groupEnd();
            console.groupEnd();
            return false;
    }
}
