import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {requiredIf} from '../../utils/misc';
import {withTranslation} from "react-i18next";
import {ITEM_NAME_MAX_LENGTH} from "../../constants";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider, deDE, enUS} from '@mui/x-date-pickers';

export const LangueLocalMapper = {
    "de": deDE.components.MuiLocalizationProvider.defaultProps.localeText,
    "en": enUS.components.MuiLocalizationProvider.defaultProps.localeText
}

class DateItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            itemName: "",
            currentName: this.props.itemName ? this.props.itemName : "",
            locale: LangueLocalMapper[this.props.i18n.language]
        }
    };

    componentDidUpdate() {
        this.setState({locale: LangueLocalMapper[this.props.i18n.language]})
        if (this.props.itemName !== this.state.itemName && this.props.itemName !== null) {
            let name = this.props.itemName ? this.props.itemName.slice() : '';
            this.setState({
                itemName: name,
                currentName: name
            });
        }
    }

    onItemNameChange = (event) => {
        this.setState({currentName: event.target.value});
    };

    onNameChange = (event) => {
        if (this.props.itemName === this.state.currentName) return;
        this.props.onItemLabelChange(this.props.itemId, {name: this.state.currentName});
    };

    render() {
        const {t} = this.props;
        const locale = this.state.locale;
        const nameError = this.state.currentName && this.state.currentName.length >= ITEM_NAME_MAX_LENGTH;
        return (
            <div className="row small-gutters display-flex"
                 style={{alignItems: 'center', width: '100%', paddingBottom: '0'}}>
                {(!this.props.disabled || this.props.showMenuButton) &&
                    <div className="col-xs-1" style={{display: 'flex', alignItems: 'center'}}>
                        <React.Fragment>
                            <IconButton
                                style={{marginLeft: '-12px'}}
                                onClick={(e) => this.props.onMenuButtonClick(e, this.props.itemId)}
                                size="large">
                                <MoreVertIcon/>
                            </IconButton>
                        </React.Fragment>
                    </div>
                }
                {this.props.category &&
                    <div
                        className="col-xs-1"
                        id={'itemAvatarContainer' + this.props.itemId}
                        style={{display: 'inline-flex'}}
                    >

                    </div>
                }
                <div
                    className={(!this.props.disabled || this.props.showMenuButton) ?
                        (this.props.category ? "row col-xs-10" : "row col-xs-11") :
                        "row col-xs-12"}
                >
                    <div className="col-sm-6 col-xs-12">
                        <TextField
                            variant="standard"
                            error={nameError}
                            label={nameError ? t('fieldNameError') : t('fieldName')}
                            helperText={nameError ? t('fieldNameTooLong', {maxLength: ITEM_NAME_MAX_LENGTH}) : null}
                            fullWidth
                            disabled={this.props.disabled}
                            value={this.state.currentName}
                            onChange={this.onItemNameChange}
                            onBlur={this.onNameChange}
                            InputProps={this.props.itemRequired.length > 0 ?
                                {
                                    endAdornment:
                                        <InputAdornment
                                            disableTypography
                                            position='end'
                                            style={{color: 'red'}}
                                        >
                                            *
                                        </InputAdornment>
                                }
                                :
                                null
                            }
                        />
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={this.props.i18n.language}
                                          localeText={locale}>
                        <MobileDatePicker
                            hideTabs={true}
                            renderInput={(props) => (
                                <div className="col-sm-6 col-xs-12">
                                    <TextField
                                        {...props}
                                        variant="standard"
                                        fullWidth
                                        helperText={null}
                                        margin={'normal'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position={'end'}>
                                                    <CalendarIcon/>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            )}
                            ampm={false}
                            variant={'inline'}
                            placeholder={t('common:chooseDate')}
                            onChange={() => null}
                            onClose={() => true}
                        />
                    </LocalizationProvider>
                </div>

            </div>
        );
    }
}

DateItem.defaultProps = {
    showMenuButton: false,
    disabled: false,
    itemName: "",
    itemRequired: [],
};

DateItem.propTypes = exact({
    itemId: PropTypes.string.isRequired,
    itemName: PropTypes.string,
    itemRequired: PropTypes.array,
    onItemLabelChange: PropTypes.func.isRequired,
    onMenuButtonClick: requiredIf(PropTypes.func, props => props.showMenuButton),
    showMenuButton: PropTypes.bool,
    disabled: PropTypes.bool,
    category: PropTypes.object,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    tReady: PropTypes.bool.isRequired,
});

export default withTranslation(['sheetManagement', 'common'])(DateItem);
