import React from 'react';
import {
    Popover,
    Paper,
    Button
} from "@mui/material";

import {useTranslation} from "react-i18next";


export default function UserPopover(props){
    const {
        open,
        onClose,
        anchor,
        user,
        currentProject,
        onLogoutClick,
    } = props;
    const { t } = useTranslation(['projectManagement', 'common']);

    return (
        <Popover
            open={open}
            onClose={onClose}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
        >
            <Paper
                style={{padding: 20}}
            >
                <h4 style={{fontWeight: 'bolder'}}>{user.name}</h4>
                <h5 style={{margin: '5px'}}>{user.email}</h5>
                <h5 style={{margin: '5px'}}>
                    {currentProject ?
                        `${t('roleInThisProject')}: ${user.role}`
                        :
                        t('youHaveNotBeenAssignedToAProject')
                    }
                </h5>
                <div style={{textAlign: 'center'}}>
                    <Button color='primary' onClick={onLogoutClick}>{t('common:logout')}</Button>
                </div>
            </Paper>
        </Popover>
    )
}
