import React from 'react';
import browserHistory from '../../browserHistory';
import HomepageFooter from "./HomepageFooter";
import withStyles from '@mui/styles/withStyles';
import './homepage-styles.scss';
import {
    Button,
    Paper
} from '@mui/material';
import {useTranslation, withTranslation} from "react-i18next";
import HomepageHeader from "./HomepageHeader";

const CssButton = withStyles({
    root: {
        backgroundColor: 'rgb(233,82,41)',
        color: "white",
        '&:disabled': {
            backgroundColor: 'rgba(233,82,41,0.39)',
            color: "white"
        },
        "&:hover": {
            backgroundColor: 'rgb(233,82,41)'
        }
    }
})(Button);


export const AnimatedCheckmark = () => (
    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
    </svg>
);


export const RegistrationConfirmedPanel = (props) => {
    const { registrationEmail } = props;
    const { t } = useTranslation(['homepage', 'common']);
    return (
        <div className="text-center" style={{textAlign: 'center'}}>
            <AnimatedCheckmark/>
            <React.Fragment>
                <h1><b>{t('registrationEmailTitle')}</b></h1>
                <hr style={{
                    borderTop: "5px solid rgba(233, 82, 41, 1)",
                    width: "20%",
                    marginBottom: '50px'
                }}/>
                <p>
                    {t('registrationConfirmationBody1', {email: registrationEmail})}
                    <br/>
                    {t('registrationConfirmationBody2')}
                    <br/><br/>
                    {t('registrationConfirmationBody3')}
                </p>
            </React.Fragment>
            <hr style={{
                borderTop: "5px solid rgba(233, 82, 41, 1)",
                width: "20%",
                marginBottom: '50px'
            }}/>
        </div>
    )
};

export const AccountConfirmedPanel = () => {
    const { t } = useTranslation(['homepage', 'common']);
    return (
        <Paper className='homepage-paper'>
            <form>
                <div className="text-center" style={{textAlign: 'center'}}>
                    <AnimatedCheckmark/>
                    <h1><b>{t('registerConfirmationHeading')}</b></h1>

                    <p>
                        {t('registrationConfirmationThanks')}<br/>
                        {t('registrationConfirmationWishes')}
                    </p>
                    <hr style={{
                        borderTop: "5px solid rgba(233, 82, 41, 1)",
                        width: "20%",
                        marginBottom: '50px'
                    }}/>
                    <div>
                        <CssButton
                            variant="contained"
                            style={{
                                margin: "12px 10px 16px",
                                padding: "16px 24px",
                                width: "40%",
                                borderRadius: '0px'
                            }}
                            onClick={() => {
                                browserHistory.push("/login")
                            }}
                        >
                            {t('common:login')}
                        </CssButton>
                    </div>
                </div>
            </form>
        </Paper>
    )
};


class ConfirmationComponent extends React.Component {

    render() {
        return (
            <React.Fragment>
                <HomepageHeader logoOnly={true}/>
                <div className='homepage-container'>
                    <img
                        src={require('../../images/2.jpg')}
                        alt="Tansparent background"
                        className='homepage-transparent-image'
                    />
                    <AccountConfirmedPanel/>
                </div>
                <div className='octo-uimaterial'>
                    <HomepageFooter/>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation(['homepage'])(ConfirmationComponent);