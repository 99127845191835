import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime';
import de from 'dayjs/locale/de';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import LocaleData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import i18n from '../i18n';

export function changeDayjsLocale(locale){
    if (locale === 'de'){
        return dayjs.locale('de-german', de);
    }
    if (locale === 'en'){
        return dayjs.locale('en');
    }
}

changeDayjsLocale(i18n.language);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(LocaleData);
dayjs.tz.guess()
export default dayjs;

