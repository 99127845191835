import React from 'react';

/* component styles */
import './styles.css';

export default class Footer extends React.PureComponent {
    render() {
        return (
            <footer className="footer" style={{backgroundColor: this.props.color, bottom: 0}}>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            DuMont Process
                </div>
            </footer>
        )
    }
}