import React from 'react';

import {
    AppBar,
    Toolbar,
    Button
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useTranslation} from "react-i18next";
import {
    PersonAdd as RegisterIcon,
    Language as LanguageIcon
} from '@mui/icons-material';

import AppLogo from '../../images/Logo Octoscore.svg';
import browserHistory from "../../browserHistory";


export default function GuestSurveyHeader(){
    const { i18n, t } = useTranslation('common');
    const classes = makeStyles(theme => ({
        appbar: {
            backgroundColor: "#fefefe"
        },
        appLogo: {
            maxWidth: '200px'
        },
        appLogoButton: {
            background: 'none',
            border: 'none'
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        navButton: {
            borderRadius: '0px',
            color: theme.palette.getContrastText('#fefefe'),
            backgroundColor: '#fff',
            '&:hover': {
                backgroundColor: '#E95229',
                color: '#fff'
            },
        },
        navButtonRaised: {
            borderRadius: '0px',
            color: theme.palette.getContrastText('#E95229'),
            backgroundColor: '#E95229',
            '&:hover': {
                backgroundColor: '#fefefe',
                color: theme.palette.getContrastText('#fefefe')
            },
        }
    }))();

    return (
        <AppBar className={classes.appbar}>
            <Toolbar className={classes.toolbar}>
                <button
                    onClick={() => browserHistory.push('/')}
                    className={classes.appLogoButton}
                >
                    <img
                        src={AppLogo}
                        alt={'app logo'}
                        className={classes.appLogo}
                    />
                </button>

                <div>
                    <Button
                        variant={'outlined'}
                        className={classes.navButtonRaised}
                        startIcon={<RegisterIcon/>}
                        onClick={() => browserHistory.push('/register')}
                    >
                        {t('register')}
                    </Button>
                    <Button
                        className={classes.navButton}
                        onClick={() => {
                            if (i18n.language === 'en'){
                                return i18n.changeLanguage('de');
                            }
                            i18n.changeLanguage('en');
                        }}

                    >
                        <LanguageIcon/>
                    </Button>
                </div>
            </Toolbar>
        </AppBar>
    )
}
