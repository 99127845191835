export function join_room(socket, json){
    return socket.emit('join_room', json);
}

export function leave_room(socket, json){
    return socket.emit('leave_room', json);
}

export function join_room_unauthenticated(socket, json){
    return socket.emit('join_room_unauthenticated', json);
}

export function leave_room_unauthenticated(socket, json){
    return socket.emit('leave_room_unauthenticated', json);
}

export function get_sheets(socket, sheetIds){
    return socket.emit('get_sheets', sheetIds);
}

export function create_sheet(socket, sheetName){
    return socket.emit('create_sheet', sheetName)
}

export function delete_sheet(socket, sheetId){
    return socket.emit('delete_sheet', sheetId);
}

export function copy_sheet(socket, json){
    return socket.emit('copy_sheet', json);
}

export function toggle_sheet_in_production(socket, sheetId){
    return socket.emit('toggle_sheet_in_production', sheetId);
}

export function change_sheet_order(socket, json){
    return socket.emit('change_sheet_order', json);
}

export function change_sheet_name(socket, json){
    return socket.emit('rename_sheet', json);
}

export function change_sheet_description(socket, json){
    return socket.emit('change_sheet_description', json);
}

export function change_sheet_color(socket, json){
    return socket.emit('change_sheet_color', json);
}

export function add_sheet_item(socket, json) {
    return socket.emit('add_sheet_item', json);
}

export function add_sheet_items(socket, json) {
    return socket.emit('add_sheet_items', json);
}

export function modify_sheet_item(socket, json){
    return socket.emit('modify_sheet_item', json);
}

export function delete_sheet_item(socket, json) {
    return socket.emit('delete_sheet_item', json);
}

export function change_sheet_item_option(socket, json) {
    return socket.emit('change_sheet_item_option', json);
}

export function change_sheet_item_category(socket, json){
    return socket.emit('change_sheet_item_category', json);
}

export function change_category_order(socket, json){
    return socket.emit('change_category_order', json);
}

export function change_item_order(socket, json){
    return socket.emit('change_item_order', json);
}

export function add_option_group(socket, json){
    return socket.emit('add_option_group', json);
}

export function delete_option_group(socket, json){
    return socket.emit('delete_option_group', json);
}

export function add_option_values(socket, json){
    return socket.emit('add_option_values', json)
}

export function delete_option_value(socket, json){
    return socket.emit('delete_option_value', json);
}

export function change_option_value_name(socket, json){
    return socket.emit('change_option_value_name', json)
}

export function change_option_type(socket, json){
    return socket.emit('change_option_presentation', json);
}

export function get_option_dependencies(socket, sheetId){
    return socket.emit('get_sheet_option_dependencies', sheetId);
}

export function add_option_dependency(socket, json){
    return socket.emit('add_option_dependency', json)
}

export function delete_option_dependency(socket, json){
    return socket.emit('delete_option_dependency', json)
}

export function add_result_code(socket, json){
    return socket.emit('add_result_code', json);
}

export function delete_result_code(socket, json){
    return socket.emit('delete_result_code', json);
}

export function modify_result_code(socket, json){
    return socket.emit('modify_result_code', json);
}

export function change_sheet_show_attendees(socket, json){
    return socket.emit('change_sheet_show_attendees', json);
}

export function change_sheet_update_date_on_completion(socket, json){
    return socket.emit('change_sheet_update_date_on_completion', json);
}

export function lock_sheet(socket, sheetId){
    return socket.emit('lock_sheet', sheetId);
}

export function export_sheet_as_excel(socket, sheetId){
    return socket.emit('export_sheet_as_excel', sheetId);
}

export function delete_result_file(socket, json){
    return socket.emit('delete_result_file', json);
}

export function change_result_attendees(socket, json){
    return socket.emit('change_result_attendees', json);
}

export function answer_item(socket, json){
    return socket.emit('answer_item', json);
}

export function remove_answer(socket, json){
    return socket.emit('remove_answer', json);
}

export function delete_result(socket, resultId){
    return socket.emit('delete_result', resultId)
}

export function fetch_result_scores(socket, resultId){
    return socket.emit('fetch_result_scores', resultId)
}

export function fetch_allowed_answers(socket, resultId){
    return socket.emit('fetch_allowed_answers', resultId)
}

export function change_result_creator(socket, json){
    return socket.emit('change_result_creator', json);
}

export function change_result_date(socket, json){
    return socket.emit('change_result_date', json);
}

export function set_result_code(socket, json){
    return socket.emit('set_result_code', json);
}

export function finish_guest_result(socket, json){
    return socket.emit('finish_guest_result', json);
}
export function toggle_sheet_public(socket, json){
    return socket.emit('toggle_sheet_public', json);
}

export function start_calibration_from_result(socket, json){
    return socket.emit('start_calibration_from_result', json);
}

export function export_result(socket, json){
    return socket.emit('export_result', json);
}

export function get_calc_templates(socket, sheetId){
    return socket.emit('get_calc_templates', sheetId);
}

export function get_default_calc_template(socket, resultId){
    return socket.emit('get_default_calc_template', resultId);
}

export function set_default_calc_template(socket, json){
    return socket.emit('set_default_calc_template', json);
}

export function create_calc_template(socket, json){
    return socket.emit('create_calc_template', json);
}

export function copy_calc_template(socket, json){
    return socket.emit('copy_calc_template', json);
}

export function delete_calc_template(socket, json){
    return socket.emit('delete_calc_template', json);
}

export function lock_calc_template(socket, json){
    return socket.emit('lock_calc_template', json);
}

export function set_option_weight(socket, json){
    return socket.emit('set_option_weight', json);
}

export function set_item_weight(socket, json){
    return socket.emit('set_item_weight', json);
}

export function change_calc_template_percentages(socket, json) {
    return socket.emit('change_calc_template_percentages', json);
}

export function change_calc_template_use_weights(socket, json) {
    return socket.emit('change_calc_template_use_weights', json);
}

export function change_not_assessable_reduce_weights(socket, json) {
    return socket.emit('change_not_assessable_reduce_weights', json)
}

export function set_score_display(socket, json) {
    return socket.emit('set_score_display', json);
}

export function change_item_active(socket, json) {
    return socket.emit('change_item_active', json);
}

export function change_option_active(socket, json) {
    return socket.emit('change_option_active', json);
}

export function change_option_category_null(socket, json) {
    return socket.emit('change_option_category_null', json);
}

export function change_option_sheet_null(socket, json) {
    return socket.emit('change_option_sheet_null', json);
}

export function fetch_results_for_sheet(socket, json){
    return socket.emit('fetch_results_for_sheet', json);
}

export function fetch_reportable_sheet_metadata(socket){
    return socket.emit('fetch_reportable_sheet_metadata');
}

export function fetch_report_metadata(socket) {
    return socket.emit('fetch_report_metadata');
}

export function fetch_report(socket, reportId){
    return socket.emit('fetch_report', reportId);
}

export function create_report_template(socket, json){
    return socket.emit('create_report_template', json);
}

export function add_report_item(socket, json){
    return socket.emit('add_report_item', json);
}

export function delete_report_item(socket, json){
    return socket.emit('delete_report_item', json);
}

export function move_report_item(socket, json){
    return socket.emit('move_report_item', json);
}

export function fetch_calibration_metadata(socket){
    return socket.emit('fetch_calibration_metadata');
}

export function fetch_calibration(socket, calibrationId){
    return socket.emit('fetch_calibration', calibrationId);
}

export function create_new_calibration(socket, sheetId){
    return socket.emit('create_new_calibration', sheetId);
}

export function get_calibration_result(socket, calibrationId){
    return socket.emit('get_calibration_result', calibrationId);
}

export function get_calibration_results(socket, calibrationId){
    return socket.emit('get_calibration_results', calibrationId);
}

export function fetch_calibration_table_data(socket, calibrationId){
    return socket.emit('fetch_calibration_table_data', calibrationId);
}

export function add_users_to_calibration(socket, json){
    return socket.emit('add_users_to_calibration', json);
}

export function remove_user_from_calibration(socket, json){
    return socket.emit('remove_user_from_calibration', json);
}

export function change_calibration_name(socket, json){
    return socket.emit('change_calibration_name', json);
}

export function change_calibration_description(socket, json){
    return socket.emit('change_calibration_description', json);
}

export function set_calibration_result_code(socket, json){
    return socket.emit('set_calibration_result_code', json);
}

export function set_calibration_deadline_date(socket, json){
    return socket.emit('set_calibration_deadline_date', json);
}

export function delete_calibration_file(socket, json){
    return socket.emit('delete_calibration_file', json);
}

export function set_calibration_result_to_done(socket, json){
    return socket.emit('calibration_result_done', json);
}

export function close_calibration(socket, calibrationId){
    return socket.emit('close_calibration', calibrationId);
}

export function save_calibration_comment(socket, json){
    return socket.emit('save_calibration_comment', json);
}

export function get_sheet_result_report_data(socket, json){
    return socket.emit('get_sheet_result_report_data', json);
}

export function get_report_template_report_data(socket, json){
    return socket.emit('get_report_template_report_data', json);
}

export function export_result_columns_report_as_excel(socket, json){
    return socket.emit('export_result_columns_report_as_excel', json);
}

export function export_raw_report_as_excel(socket, json){
    return socket.emit('export_raw_report_as_excel', json);
}

export function export_location_report_as_excel(socket, json){
    return socket.emit('export_location_report_as_excel', json);
}

export function export_text_report_as_excel(socket, json){
    return socket.emit('export_text_report_as_excel', json);
}

export function export_report_as_excel(socket, json){
    return socket.emit('export_report_as_excel', json);
}

export function export_report_as_pdf(socket, json){
    return socket.emit('export_report_as_pdf', json);
}

export function export_report_as_powerpoint(socket, json){
    return socket.emit('export_report_as_powerpoint', json);
}

export function get_category_score_progression(socket, json){
    return socket.emit('get_category_score_progression', json);
}

export function get_user_metrics(socket, json){
    return socket.emit('get_user_metrics', json);
}

export function get_table_data_for_item(socket, json){
    return socket.emit('get_table_data_for_item', json);
}

export function get_sheet_import_compatibility(socket, json){
    return socket.emit('get_sheet_import_compatibility', json);
}

export function create_results_from_excel_sheet(socket, json){
    return socket.emit('create_results_from_excel_sheet', json);
}

export function create_export_marker(socket){
    return socket.emit('create_export_marker');
}

export function get_export_marker_metadata(socket){
    return socket.emit('get_export_marker_metadata');
}

export function get_export_marker_results(socket, exportDate){
    return socket.emit('get_export_marker_results', exportDate);
}

export function download_export_marker_data(socket, exportDate){
    return socket.emit('download_export_marker_data', exportDate);
}

export function set_item_example(socket, json){
    return socket.emit('set_item_example', json);
}

export function toggle_item_examples_reportable(socket, itemExampleIds){
    return socket.emit('toggle_item_examples_reportable', itemExampleIds);
}

export function delete_item_examples(socket, itemExampleIds){
    return socket.emit('delete_item_examples', itemExampleIds);
}

export function get_item_examples(socket, json){
    return socket.emit('get_item_examples', json);
}

export function get_item_presentation_mapper(socket) {
    return socket.emit('get_item_presentation_mapper');
}

export function change_sheet_item_presentation_type(socket, json) {
    return socket.emit('on_change_sheet_item_presentation_type', json);
}

export function on_change_sheet_item_int_maximum_value(socket, json) {
    return socket.emit("on_change_sheet_item_int_maximum_value", json)
}

export function on_change_sheet_item_int_minimum_value(socket, json) {
    return socket.emit("on_change_sheet_item_int_minimum_value", json)
}
