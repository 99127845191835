import { combineReducers } from 'redux';
import auth from './auth';
import data from './data';
import socket from './socketReducer';

const rootReducer = combineReducers({
    auth,
    data,
    socket,
});

export default rootReducer;
