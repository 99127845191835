import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import HomePaper from "./HomePaper";
import {Typography} from "@mui/material";
import {withTranslation} from "react-i18next";

const textStyle = {textAlign: 'center'};

export class AppRoutesView extends React.Component {
    getRows = (pathArray, itemsPerRow = 3) => {
        const { t } = this.props;
        let splicedPaths = Object.assign([], pathArray);
        splicedPaths = splicedPaths.filter(
            path => this.props.userPrivilege >= path.privilege
        );
        let rowArray = [];
        // creating 2d array with paths
        // splicedPaths.length = amount of rows
        // splicedPaths[i] -> [{path}, {path}, ...].length -> itemsPerRow
        while (splicedPaths.length > 0){
            rowArray.push(splicedPaths.splice(0, itemsPerRow));
        }
        rowArray = rowArray.map((row, index) => {
            return (
                <div key={'row' + index}>
                    {row.map(path => {
                        let tName = t(path.name);
                        return (
                            <HomePaper
                                key={tName}
                                headline={tName}
                                browserAddress={path.link}
                                symbol={path.icon}
                            />
                        )
                    })}
                </div>
            )
        });
        return rowArray;
   };

    render() {
        const { t } = this.props;
        return (
            <div>
                <div style={textStyle}>
                    <Typography variant="h4" style={textStyle}>
                            {t("helloUser", {
                                "userName": this.props.userName
                            })}
                    </Typography>
                    {this.getRows(this.props.applicationRoutes)}
                </div>
            </div>
        );
    }
}
AppRoutesView.defaultProps = {
    userName: null,
    userPrivilege: 0,
    applicationRoutes: []
};

AppRoutesView.propTypes = exact({
    userName: PropTypes.string,
    userPrivilege: PropTypes.number,
    applicationRoutes: PropTypes.arrayOf(PropTypes.object),
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    tReady: PropTypes.bool.isRequired,
});

export default withTranslation('appRoutes')(AppRoutesView);