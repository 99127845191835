import React, {Suspense} from 'react';
import {withTranslation} from "react-i18next";
import LoadingSpinner from '../../components/LoadingSpinner';

export default function loadableComponent(LazyComponent) {
    class LoadableComponent extends React.Component {
        render() {
            const {t, i18n, tReady, ...restProps} = this.props;
            return (
                <Suspense
                    fallback={<LoadingSpinner text={t('viewIsBeingLoaded')}/>}
                >
                    <LazyComponent {...restProps}/>
                </Suspense>
           )
        }
    }
    return withTranslation('actionResponses')(LoadableComponent);
}