import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import withStyles from '@mui/styles/withStyles';
import SpeedDial from '@mui/material/SpeedDial';
import Badge from '@mui/material/Badge';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CalibrationIcon from '@mui/icons-material/ThumbsUpDown';
import CheckIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import BackIcon from '@mui/icons-material/ArrowBack';
import ErrorIcon from '@mui/icons-material/WarningOutlined';
import ExportIcon from "@mui/icons-material/CloudDownload";
import PDFIcon from "@mui/icons-material/PictureAsPdf";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {withTranslation} from "react-i18next";

const speedDialStyle = {position: 'fixed', bottom: '5%', right: '5%'};
const doneButtonProps = {style: {backgroundColor: '#00b75c'}};
const styles = theme => ({
    badge: {
        top: -18,
        right: -18
    },
});


class FillCatalogSpeedDial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null
        };

    }

    onOpen = () => {
        if (!(this.props.hidden)) {
            this.setState({open: true});
        }
    };

    onClose = () => {
        this.setState({open: false});
    };

    onClick = () => {
        this.setState({open: !this.state.open})
    };

    handleClose = () => {
        this.setState({anchorEl: null})
    };

    onResultCodeClicked = (event, resultCodeId) => {
        event.preventDefault();
        this.setState({anchorEl: null, open: false});
        this.props.onResultCodeSet(resultCodeId);
    };

    render() {
        const {t} = this.props;
        let speedDialActions = [
            {
                icon: <CalibrationIcon/>, name: t('startCalibration'),
                onClick: () => {this.props.onStartCalibrationClick()}

            },
            {
                name: t('exportExcel'), icon: <ExportIcon/>,
                onClick: () => {this.props.onExportResult('xls')}
            },
            {
                name: t('exportPdf'), icon: <PDFIcon/>,
                onClick: () => {this.props.onExportResult('pdf')}
            },
            {
                icon: <BackIcon/>, name: t('common:back'),
                onClick: () => {
                    this.setState({open: false});
                    this.props.onBackClick();
                }
            }
        ]
        return (
            <React.Fragment>
                <SpeedDial
                    ariaLabel="SpeedDial navigation"
                    style={speedDialStyle}
                    icon={
                        <SpeedDialIcon
                            icon={this.props.errorAmount ?
                                <Badge color='error' badgeContent={this.props.errorAmount} classes={{badge: this.props.classes.badge}}>
                                    <EditIcon/>
                                </Badge>
                                :
                                <EditIcon/>
                            }
                        />
                    }
                    buttonprops={this.props.errorAmount ? {} : doneButtonProps}
                    direction={'up'}
                    hidden={this.props.hidden}
                    // onBlur={this.onClose}
                    onClick={this.onClick}
                    // onFocus={this.onOpen}
                    onClose={this.onClose}
                    onMouseEnter={window.matchMedia('(hover:hover)').matches ? this.onOpen : null}
                    // onMouseLeave={this.onClose}
                    open={this.state.open}
                >
                    <SpeedDialAction
                        key={"showErrors/Abschliessen"}
                        tooltipTitle={this.props.errorAmount ? t('goToMissingField') : t('common:complete')}
                        onClick={
                            this.props.errorAmount ?
                                this.props.onErrorButtonClick
                                :
                                (e) => this.setState({anchorEl: e.currentTarget})
                        }
                        icon={this.props.errorAmount ?
                            <Badge color='error' badgeContent={this.props.errorAmount}>
                                <ErrorIcon/>
                            </Badge>
                            :
                            <CheckIcon/>
                        }
                    />

                    {speedDialActions.map((action) => {
                        return (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={action.onClick}
                                buttonprops={(action.name === t('common:complete') || action.name === t('startCalibration')) && this.props.errorAmount !== 0 ?
                                    {disabled: true}
                                    :
                                    {disabled: false}
                                }
                            />
                        )
                    })}
                </SpeedDial>
                <Menu
                    id="long-menu"
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    transformOrigin={{vertical: 'center', horizontal: 'center'}}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: 300,
                            width: 200,
                        },
                    }}
                >
                    {this.props.resultCodes && this.props.resultCodes.map(resultCode => (
                        <MenuItem
                            key={resultCode.result_code_id}
                            style={{backgroundColor: resultCode.color}}
                            onClick={(event) => {
                                this.onResultCodeClicked(event, resultCode.result_code_id)
                            }}
                        >
                            {resultCode.name}
                        </MenuItem>
                    ))}
                </Menu>
            </React.Fragment>
        )
    }
}

FillCatalogSpeedDial.defaultProps = {
    errorAmount: null,
};

FillCatalogSpeedDial.propTypes = exact({
    classes: PropTypes.object.isRequired,
    onBackClick: PropTypes.func.isRequired,
    onStartCalibrationClick: PropTypes.func.isRequired,
    onResultCodeSet: PropTypes.func.isRequired,
    onErrorButtonClick: PropTypes.func,
    onExportResult: PropTypes.func.isRequired,
    errorAmount: PropTypes.number,
    resultCodes: PropTypes.array,
    onDisplayErrorsClick: PropTypes.func,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    tReady: PropTypes.bool.isRequired,
});
export default withStyles(styles)(withTranslation(['sheetManagement','common'])(FillCatalogSpeedDial));
