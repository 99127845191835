import io from 'socket.io-client';
import {AUTHENTICATION_FAILED, SOCKET_ADDRESS} from "./constants";
import {setDisconnected} from "./actions/socketActions";


export default class SocketClient {
    socket;

    connect(token) {
        this.socket = io.connect(SOCKET_ADDRESS, {
            transports: ['websocket'],
            pingTimeout: 120000,
            pingInterval: 60000
        });
        return new Promise((resolve, reject) => {
            this.socket.on('connect', () => {
                this.socket.emit('verify_token', token, (auth) => {
                    if (auth) {resolve(true);}
                    else {reject(AUTHENTICATION_FAILED); console.log("Could not connect socket")}
                });

                if (!this.socket.hasListeners('request_token')){
                    this.socket.on('request_token', () => {
                        this.socket.emit('verify_token', token, (auth) => {
                            if (auth) resolve(true);
                            else reject(AUTHENTICATION_FAILED);
                        });
                    })
                }

            });
            this.socket.on('connect_error', (error) => this.socket.io.opts.transports = ["polling", "websocket"]);
        })
    }

    connectUnauthenticated(){
         this.socket = io.connect(SOCKET_ADDRESS, {
            transports: ['websocket'],
            pingTimeout: 120000,
            pingInterval: 60000
        });
        return new Promise((resolve, reject) => {
            this.socket.on('connect', () => {
                resolve(true);
            });
            this.socket.on('connect_error', (error) => this.socket.io.opts.transports = ["polling", "websocket"]);
        });
    }

    disconnect() {
        return new Promise((resolve) => {
            this.socket.disconnect(() => {
                this.socket = null;
                resolve();
            });
        });
    }

    emit(event, data) {
        return new Promise((resolve, reject) => {
            if (!this.socket) return reject('No socket available.');

            let responseFunc = (response) => {
                if (response && response.error) {
                    console.error(response.error);
                    return reject(response.error);
                }
                return resolve(response);
            };

            // Even if data is undefined the server will receive it as an argument
            // therefore only emit data argument when it's needed so backend doesn't complain
            // about undeclared arguments in functions without arguments
            if (data) {
                return this.socket.emit(event, data, responseFunc);
            }
            return this.socket.emit(event, responseFunc);
        });
    }

    on(event, func) {

        return new Promise((resolve, reject) => {
            if (!this.socket) return reject('No socket available.');
            if (this.socket.hasListeners(event)) return resolve();
            this.socket.on(event, func);
            resolve();
        })
    }

    off(event, func = null) {
        return new Promise((resolve, reject) => {
            if (!this.socket) return reject('No socket available.');

            if (func) this.socket.off(event, func);
            else this.socket.off(event);
            resolve();
        })
    }
}
