import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../actions/auth';


function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

class NotFound extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.unauthorized ?
                    <h1>Unauthorized</h1>
                    :
                    <h1>404 - Not Found</h1>
                }
            </React.Fragment>
        );
    }
}
NotFound.defaultProps = {
    unauthorized: false
};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
