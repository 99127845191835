import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
    Snackbar,
    Alert
} from "@mui/material";

class StatusSnackbar extends React.Component {
    onClose = () => {
        if (!this.props.persist){
            this.props.onClose();
        }
    };

    render() {
        return (
            <Snackbar
                open={this.props.open}
                onClose={this.onClose}
                anchorOrigin={this.props.anchorOrigin}
                autoHideDuration={this.props.autoHideDuration}
            >
                <Alert
                    onClose={this.onClose}
                    variant={'filled'}
                    severity={this.props.variant}
                >
                    <span dangerouslySetInnerHTML={{__html: this.props.message}}/>
                </Alert>
            </Snackbar>

        )
    }
}
StatusSnackbar.defaultProps = {
    anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
//    autoHideDuration: 5000,
    persist: false,
};

StatusSnackbar.propTypes = exact({
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
    message: PropTypes.string.isRequired,
    classes: PropTypes.object,
    persist: PropTypes.bool,
    anchorOrigin: PropTypes.object,
    autoHideDuration: PropTypes.number,
});
export default StatusSnackbar;
