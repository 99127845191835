import React from 'react';
import Link from '@mui/material/Link';
import {withTranslation} from "react-i18next";

class HomepageFooter extends React.PureComponent {

    render() {
        const {t} = this.props;
        return (
            <div style={{backgroundColor:'white'}} className='octo-uimaterial'>
                <footer className="octo-footer">

                    <div className="container text-center text-md-left">

                        <div className="row">
                            <div className="col-md-4 mx-auto">
                                <i className="fa fa-twitter"> </i>
                                <i className="fa fa-facebook"> </i>
                                <br/>
                                <h4>Copyright DuMont Process GmbH</h4>
                            </div>
                            <div className="col-md-2 mx-auto">
                                <h5><b>{t('about')}</b></h5>
                                <hr/>
                                <h5>
                                    Team
                                </h5>
                                <h5>
                                    <a href={'http://dumont-process.de'}>DuMont Process</a>
                                </h5>
                            </div>
                            <div className="col-md-2 mx-auto">
                                <h5><b>{t('common:more')}</b></h5>
                                <hr/>
                                <h5>
                                    <Link href="https://octoscore.de/impressum">{t('common:legal')}</Link>
                                </h5>
                                <h5>
                                    <Link href="https://octoscore.de/privacy">{t('common:privacy')}</Link>
                                </h5>
                            </div>
                            <div className="col-md-4 mx-auto">
                            </div>
                        </div>

                    </div>

                </footer>
            </div>

        );

    }
}

export default withTranslation(['homepage', 'common'])(HomepageFooter);
