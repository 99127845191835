import {
    RECEIVE_PROTECTED_DATA,
    RECEIVE_USERS,
    RECEIVE_PROJECTS,
    RECEIVE_CATALOGS,
    PUT_WORKBENCH_CATALOG,
    PUT_RESULT,
    PUT_TREASURE,
    RECEIVE_ROLES,
    RECEIVE_USERS_FAILURE,
    GET_SCORECARD_DATA,
    CHANGE_PROJECT,
    RENAME_PROJECT,
    GET_SHEET_TYPES,
    PUT_SHEET_TYPE,
    RECEIVE_PARTNERS,
    RECEIVE_PARTNER,
    GET_SHEET_METADATA,
    GET_RESULT_METADATA,
    CREATE_PROJECT,
    MODIFY_PROJECT,
    DELETE_PARTNER,
    MODIFY_PARTNER,
    FILE_UPLOAD_SUCCESS,
    SAVE_RESULT_META_FILTERS,
    RESET_DATA_REDUX_STATE,
    NEW_RESULT_CREATED,
    JOIN_MAILING_LIST_SUCCESS,
    JOIN_MAILING_LIST_FAILURE,
    RECEIVE_PROJECT_USERS,
    ASSIGN_USER_TO_PROJECT,
    CREATE_USER,
    DELETE_USER,
    UPDATE_USER_PERSONAL_INFO,
    REMOVE_USER_FROM_PROJECT,
    PRICING_INQUIRY_SUCCESS,
    PRICING_INQUIRY_FAILURE,
    SET_USER_LANGUAGE,
    CHANGE_PROJECT_RETENTION_DAYS,
    TOGGLE_BACKGROUND,
    BULK_ADD_USERS,
    RECEIVE_ASSOCIATED_USERS,
    GET_HISTORIC_RAW_RESULTS,
    GET_RAW_RESULTS,
    TOGGLE_PROJECT_ATTENDEE_EMAILS
} from '../constants';
import {createReducer} from '../utils/misc';
import {initialState} from "./initialState";

export default createReducer(initialState, {
    [RESET_DATA_REDUX_STATE]: (state) => {
        return {...initialState}
    },
    [SET_USER_LANGUAGE]: (state, language) => {
        return {
            ...state,
            user: {
                ...state.user,
                language: language
            }
        }
    },
    [PRICING_INQUIRY_SUCCESS]: (state, payload) => {
        let successMsg = payload;
        if (payload.message){
            successMsg = payload.message;
        }
        return {
            ...state,
            pricingInquirySuccess: true,
            pricingInquiryMessage: successMsg
        }

    },
    [PRICING_INQUIRY_FAILURE]: (state, err) => {
        let errMsg = err;
        if (err.response && err.response.data){
            errMsg = err.response.data.message ? err.response.data.message : err.response.data
        }
        return {
            ...state,
            pricingInquirySuccess: false,
            pricingInquiryMessage: errMsg
        }
    },
    [JOIN_MAILING_LIST_SUCCESS]: (state) => {
        return {...state, joinMailingListSuccess: true}
    },
    [JOIN_MAILING_LIST_FAILURE]: (state) => {
        return {...state, joinMailingListSuccess: false}
    },
    [FILE_UPLOAD_SUCCESS]: (state) =>
        Object.assign({}, state, {
            fileUploadSuccess: true,
        }),

    [RECEIVE_PROTECTED_DATA]: (state, payload) =>
        Object.assign({}, state, {
            user: payload,
            loaded: true,
        }),
    [RECEIVE_USERS]: (state, payload) =>
        Object.assign({}, state, {
            users: payload.response,
        }),

    [RECEIVE_USERS_FAILURE]: (state, payload) =>
        Object.assign({}, state, {
            users: [],
        }),

    [RECEIVE_PROJECTS]: (state, payload) =>
        Object.assign({}, state, {
            projects: payload.response,
        }),
    [RECEIVE_ASSOCIATED_USERS]: (state, payload) =>
        Object.assign({}, state, {
            associatedUsers: payload,
        }),
    [RECEIVE_PROJECT_USERS]: (state, payload) => {
        return {
            ...state,
            user: {
                ...state.user,
                currentProject: {
                    ...state.user.currentProject,
                    project_users: payload
                }
            }
        }
    },
    [ASSIGN_USER_TO_PROJECT]: (state, payload) => {
        let tempProjectUsers = Object.assign([], state.user.currentProject.project_users);
        let user_ids = Array.isArray(payload.user_id) ? payload.user_id : [payload.user_id];
        tempProjectUsers = tempProjectUsers.map(user => {
            if (!user_ids.includes(user.user_id)) return user
            return {
                ...user,
                role: payload.role
            }
        })

        return {
            ...state,
            user: {
                ...state.user,
                currentProject: {
                    ...state.user.currentProject,
                    project_users: tempProjectUsers
                }
            }
        }
    },
    [REMOVE_USER_FROM_PROJECT]: (state, payload) => {
        let tempProjectUsers = Object.assign([], state.user.currentProject.project_users);
        tempProjectUsers = tempProjectUsers.filter(u => u.user_id !== payload.user_id);
        return {
            ...state,
            user: {
                ...state.user,
                currentProject: {
                    ...state.user.currentProject,
                    project_users: tempProjectUsers
                }
            }
        }
    },
    [BULK_ADD_USERS]: (state, payload) => {
        let tempProjectUsers = Object.assign([], state.user.currentProject.project_users);
        tempProjectUsers.push(...payload)
        return {
            ...state,
            user: {
                ...state.user,
                currentProject: {
                    ...state.user.currentProject,
                    project_users: tempProjectUsers
                }
            }
        }
    },
    [CREATE_USER]: (state, payload) => {
        let tempProjectUsers = Object.assign([], state.user.currentProject.project_users);
        if (payload.data && 'email' in payload.data) {
            tempProjectUsers.push(payload.data);
        } else if (payload.payload && 'email' in payload.payload) {
            tempProjectUsers.push(payload.payload);
        }
        return {
            ...state,
            user: {
                ...state.user,
                currentProject: {
                    ...state.user.currentProject,
                    project_users: tempProjectUsers
                }
            }
        }
    },
    [DELETE_USER]: (state, userId) => {
        let tempProjectUsers = Object.assign([], state.user.currentProject.project_users);
        tempProjectUsers = tempProjectUsers.filter(u => u.user_id !== userId);
        return {
            ...state,
            user: {
                ...state.user,
                currentProject: {
                    ...state.user.currentProject,
                    project_users: tempProjectUsers
                }
            }
        }
    },
    [UPDATE_USER_PERSONAL_INFO]: (state, userObj) => {
        let isCurrentUser = state.user.user_id === userObj.user_id;
        let user = state.user;
        let newUserObj = isCurrentUser ? {...user, ...userObj} : {...user};
        let tempProjectUsers = Object.assign([], state.user.currentProject.project_users);
        let userIndex = tempProjectUsers.findIndex(u => u.user_id === userObj.user_id);
        tempProjectUsers[userIndex] = {
            ...tempProjectUsers[userIndex],
            ...userObj
        };
        return {
            ...state,
            user: {
                ...newUserObj,
                currentProject: {
                    ...state.user.currentProject,
                    project_users: tempProjectUsers
                }
            }
        }
    },
    [CHANGE_PROJECT_RETENTION_DAYS]: (state, newRetentionDays) => {
        return {
            ...state,
            user: {
                ...state.user,
                currentProject: {
                    ...state.user.currentProject,
                    retention_days: newRetentionDays
                }
            }
        }
    },
    [TOGGLE_PROJECT_ATTENDEE_EMAILS]: (state, enabled) => {
        return {
            ...state,
            user: {
                ...state.user,
                currentProject: {
                    ...state.user.currentProject,
                    attendee_emails: enabled
                }
            }
        }
    },

    [RECEIVE_ROLES]: (state, payload) =>
        Object.assign({}, state, {
            roles: payload.response,
        }),

    [RECEIVE_CATALOGS]: (state, payload) =>
        Object.assign({}, state, {
            workbenchCatalogs: payload.response,
        }),

    [PUT_WORKBENCH_CATALOG]: (state, payload) => {
        let givenCatalog = payload.response;
        let tempCatalogs = state.workbenchCatalogs.slice();
        let catalogFound = false;


        // Now updating an existing catalog, if it's there.
        tempCatalogs = tempCatalogs.map((catalog) => {
            if (catalog.sheet_id !== givenCatalog.sheet_id) {
                return catalog;
            }

            catalogFound = true;
            return givenCatalog;
        });

        // If the catalog was not found, add the new one.
        if (catalogFound === false) {
            tempCatalogs.push(givenCatalog)
        }
        return {
            ...state, workbenchCatalogs: tempCatalogs
        }
    },

    [PUT_RESULT]: (state, payload) => {
        let givenResult = payload.result;
        let tempResults = state.results.slice();
        let resultFound = false;

        // Now updating an existing result, if it's there.
        tempResults = tempResults.map((result) => {
            if (result.id !== givenResult.id) {
                return result;
            }

            resultFound = true;
            return givenResult;
        });

        // If the result was not found, add the new one.
        if (resultFound === false) {
            tempResults.push(givenResult)
        }
        return {
            ...state, results: tempResults
        }
    },

    [PUT_TREASURE]: (state, payload) => {
        let givenTreasure = payload.treasure;
        let tempTreasures = state.treasures.slice();
        let treasureFound = false;

        // Now updating an existing result, if it's there.
        tempTreasures = tempTreasures.map((treasure) => {
            if (treasure.itemId !== givenTreasure.itemId) {
                return treasure;
            }

            treasureFound = true;
            return givenTreasure;
        });

        // If the result was not found, add the new one.
        if (treasureFound === false) {
            tempTreasures.push(givenTreasure)
        }
        return {
            ...state, treasures: tempTreasures
        }
    },
    [GET_SCORECARD_DATA]: (state, payload) => {

        return {...state, scorecardData: payload.response}
    },
    [CREATE_PROJECT]: (state, payload) => {
        return {
            ...state,
            projects: [
                ...state.projects,
                payload.project
            ],
            user: payload.user,
        }
    },
    [MODIFY_PROJECT]: (state, newProject) => {
        let tempProjects = Object.assign([], state.projects);
        let modifiedProject = tempProjects.find(project => project.project_id === newProject.project_id);
        tempProjects[tempProjects.indexOf(modifiedProject)] = newProject;
        if (state.user.currentProject.project_id === newProject.project_id){
            return {...state, projects: tempProjects, user: {...state.user, currentProject: newProject}}
        }
        return {...state, projects: tempProjects}
    },
    [CHANGE_PROJECT]: (state, payload) => {
        return {
            ...state,
            user: payload.response,
            projectChanged: true
        }
    },
    [RENAME_PROJECT]: (state, payload) => {
        let oldProject = payload.oldProject;
        let tempProjects = state.projects.slice();
        tempProjects = tempProjects.filter((project) => project.name !== oldProject.name);
        tempProjects.push(payload.response);
        return {...state, projects: tempProjects}
    },
    [GET_SHEET_TYPES]: (state, payload) => {
        return {...state, sheetTypes: payload.response}
    },
    [GET_SHEET_METADATA]: (state, payload) => {
        return {...state, sheetMetaData: payload.response}
    },
    [PUT_SHEET_TYPE]: (state, payload) => {
        let givenSheetType = payload.response;
        let tempSheetTypes = state.sheetTypes.slice();
        let sheetTypeFound = false;
        tempSheetTypes = tempSheetTypes.map( (sheetType) => {
            if (sheetType.name !== givenSheetType.name) {
                return sheetType;
            }

            sheetTypeFound = true;
            return givenSheetType;
        });

        if (sheetTypeFound === false) {
            tempSheetTypes.push(givenSheetType)
        }
        return {...state, sheetTypes: tempSheetTypes}

    },
    [RECEIVE_PARTNERS]: (state, payload) => {
        return {...state, partners: payload}
    },
    [RECEIVE_PARTNER]: (state, receivedPartner) => {
        let tempPartners = Object.assign([], state.partners);
        tempPartners = tempPartners.filter(partner => partner.partner_id !== receivedPartner.partner_id);
        return {...state, partners: [...tempPartners, receivedPartner]}
    },
    [MODIFY_PARTNER]: (state, newPartner) => {
        let tempPartners = Object.assign([], state.partners);
        let modifiedPartner = tempPartners.find(partner => partner.partner_id === newPartner.partner_id);
        tempPartners[tempPartners.indexOf(modifiedPartner)] = newPartner;
        return {...state, partners: tempPartners}
    },
    [DELETE_PARTNER]: (state, deletedPartnerId) => {
        let tempPartners = Object.assign([], state.partners);
        tempPartners = tempPartners.filter(partner => partner.partner_id !== deletedPartnerId);
        return {...state, partners: tempPartners}
    },
    [NEW_RESULT_CREATED]: (state, newResultId) => {
        return {...state, newResultId: newResultId}
    },
    [GET_RESULT_METADATA]: (state, payload) => {
        // Only overwrites start and end date if they're null in state
        // in other words if no date range has been selected yet.
        // otherwise the min and max date will be set to the selected date range
        // as that will be the start/end of the queried results.
        return {...state, resultMetaData: {
            ...payload.response,
            start_date: state.resultMetaData.start_date ? state.resultMetaData.start_date : payload.response.start_date,
            end_date: state.resultMetaData.end_date ? state.resultMetaData.end_date : payload.response.end_date,
        }}
    },
    [SAVE_RESULT_META_FILTERS]: (state, payload) => {
        return {...state, resultMetaFilters: payload}
    },
    [TOGGLE_BACKGROUND]: (state, payload) => {
        return {...state, toggleBackground: payload}
    },
    [GET_HISTORIC_RAW_RESULTS]: (state, payload) => {
        return {...state, historicRawResults: payload}
    },
    [GET_RAW_RESULTS]: (state, payload) => {
        return {...state, rawResults: payload}
    }
});

