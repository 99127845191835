import React, {useEffect, useRef} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as socketActions from "../actions/socketActions";
import * as actionCreators from '../actions/data';
import * as authCreators from '../actions/auth';
import {IconButton, List, ListItem, Paper, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import Smiley from "@mui/icons-material/InsertEmoticon";
import Team from "@mui/icons-material/Group";
import Score from "@mui/icons-material/ShowChart";
import TeamScore from "@mui/icons-material/Grade";
import PropTypes from 'prop-types';
import * as echarts from "echarts";
import {ChevronRight, BarChart} from "@mui/icons-material";
import {Calendar, Views, momentLocalizer} from 'react-big-calendar'
import moment from 'moment-with-locales-es6';
// eslint-disable-next-line
moment.locale('de');

import {
    Timeline,
    TimelineConnector, TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";


let allViews = Object.keys(Views).map(k => Views[k])
const localizer = momentLocalizer(moment)
const charts = []

const events = [
    {
        id: 0,
        title: 'All Day Event very long title',
        allDay: true,
        start: new Date(2015, 3, 0),
        end: new Date(2015, 3, 1),
    },
    {
        id: 1,
        title: 'Long Event',
        start: new Date(2015, 3, 7),
        end: new Date(2015, 3, 10),
    },

    {
        id: 2,
        title: 'DTS STARTS',
        start: new Date(2016, 2, 13, 0, 0, 0),
        end: new Date(2016, 2, 20, 0, 0, 0),
    },

    {
        id: 3,
        title: 'DTS ENDS',
        start: new Date(2016, 10, 6, 0, 0, 0),
        end: new Date(2016, 10, 13, 0, 0, 0),
    },

    {
        id: 4,
        title: 'Some Event',
        start: new Date(2015, 3, 9, 0, 0, 0),
        end: new Date(2015, 3, 10, 0, 0, 0),
    },
    {
        id: 5,
        title: 'Conference',
        start: new Date(2015, 3, 11),
        end: new Date(2015, 3, 13),
        desc: 'Big conference for important people',
    },
    {
        id: 6,
        title: 'Meeting',
        start: new Date(2015, 3, 12, 10, 30, 0, 0),
        end: new Date(2015, 3, 12, 12, 30, 0, 0),
        desc: 'Pre-meeting meeting, to prepare for the meeting',
    },
    {
        id: 7,
        title: 'Lunch',
        start: new Date(2015, 3, 12, 12, 0, 0, 0),
        end: new Date(2015, 3, 12, 13, 0, 0, 0),
        desc: 'Power lunch',
    },
    {
        id: 8,
        title: 'Meeting',
        start: new Date(2015, 3, 12, 14, 0, 0, 0),
        end: new Date(2015, 3, 12, 15, 0, 0, 0),
    },
    {
        id: 9,
        title: 'Happy Hour',
        start: new Date(2015, 3, 12, 17, 0, 0, 0),
        end: new Date(2015, 3, 12, 17, 30, 0, 0),
        desc: 'Most important meal of the day',
    },
    {
        id: 10,
        title: 'Dinner',
        start: new Date(2015, 3, 12, 20, 0, 0, 0),
        end: new Date(2015, 3, 12, 21, 0, 0, 0),
    },
    {
        id: 11,
        title: 'Planning Meeting with Paige',
        start: new Date(2015, 3, 13, 8, 0, 0),
        end: new Date(2015, 3, 13, 10, 30, 0),
    },
    {
        id: 11.1,
        title: 'Inconvenient Conference Call',
        start: new Date(2015, 3, 13, 9, 30, 0),
        end: new Date(2015, 3, 13, 12, 0, 0),
    },
    {
        id: 11.2,
        title: "Project Kickoff - Lou's Shoes",
        start: new Date(2015, 3, 13, 11, 30, 0),
        end: new Date(2015, 3, 13, 14, 0, 0),
    },
    {
        id: 11.3,
        title: 'Quote Follow-up - Tea by Tina',
        start: new Date(2015, 3, 13, 15, 30, 0),
        end: new Date(2015, 3, 13, 16, 0, 0),
    },
    {
        id: 12,
        title: 'Late Night Event',
        start: new Date(2015, 3, 17, 19, 30, 0),
        end: new Date(2015, 3, 18, 2, 0, 0),
    },
    {
        id: 12.5,
        title: 'Late Same Night Event',
        start: new Date(2015, 3, 17, 19, 30, 0),
        end: new Date(2015, 3, 17, 23, 30, 0),
    },
    {
        id: 13,
        title: 'Multi-day Event',
        start: new Date(2015, 3, 20, 19, 30, 0),
        end: new Date(2015, 3, 22, 2, 0, 0),
    },
    {
        id: 14,
        title: 'Today',
        start: new Date(new Date().setHours(new Date().getHours() - 3)),
        end: new Date(new Date().setHours(new Date().getHours() + 3)),
    },
    {
        id: 15,
        title: 'Point in Time Event',
        start: new Date(),
        end: new Date(),
    },
    {
        id: 16,
        title: 'Video Record',
        start: new Date(2015, 3, 14, 15, 30, 0),
        end: new Date(2015, 3, 14, 19, 0, 0),
    },
    {
        id: 17,
        title: 'Dutch Song Producing',
        start: new Date(2015, 3, 14, 16, 30, 0),
        end: new Date(2015, 3, 14, 20, 0, 0),
    },
    {
        id: 18,
        title: 'Itaewon Halloween Meeting',
        start: new Date(2015, 3, 14, 16, 30, 0),
        end: new Date(2015, 3, 14, 17, 30, 0),
    },
    {
        id: 19,
        title: 'Online Coding Test',
        start: new Date(2015, 3, 14, 17, 30, 0),
        end: new Date(2015, 3, 14, 20, 30, 0),
    },
    {
        id: 20,
        title: 'An overlapped Event',
        start: new Date(2015, 3, 14, 17, 0, 0),
        end: new Date(2015, 3, 14, 18, 30, 0),
    },
    {
        id: 21,
        title: 'Phone Interview',
        start: new Date(2015, 3, 14, 17, 0, 0),
        end: new Date(2015, 3, 14, 18, 30, 0),
    },
    {
        id: 22,
        title: 'Cooking Class',
        start: new Date(2015, 3, 14, 17, 30, 0),
        end: new Date(2015, 3, 14, 19, 0, 0),
    },
    {
        id: 23,
        title: 'Go to the gym',
        start: new Date(2015, 3, 14, 18, 30, 0),
        end: new Date(2015, 3, 14, 20, 0, 0),
    },
]


const useStyles = makeStyles({
    paper: {
        background: 'rgba(255,255,255,0.1)',
        borderRadius: '10px',
        color: '#fff',
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingLeft: '22px',
        paddingRight: '22px',
        overflow: 'auto'
    },
    scoreContainer: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fontSize: 80
    },
    paperHeading: {
        borderBottom: "2px solid rgba(0,0,0,.12)",
        boxShadow: "0 1px 0 0 rgb(255 255 255 / 12%)",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        root: {
            colorPrimary: '#fff'
        }
    },
    root: {
        height: 350,
        overflow: 'auto',
        "& :hover": {
            backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0.4), rgba(255,255,255,0))',
        }
    },
    secondaryTail: {
        backgroundColor: '#fff',
    },
})

const ColoredDateCellWrapper = ({children}) =>
    React.cloneElement(React.Children.only(children), {
        style: {
            backgroundColor: 'lightblue',
        },
    })

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        user: state.data.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({...actionCreators, ...authCreators, ...socketActions}, dispatch);
}

function Chart({options, height}) {
    const myChart = useRef(null)

    useEffect(() => {
        const chart = echarts.init(myChart.current)
        chart.clear();
        chart.setOption(options)
        charts.push(chart);
        window.onresize = function () {
            charts.map(chart => chart.resize())
        };
    }, [options])

    return (

        <div
            ref={myChart}
            style={{
                height: height,
            }}
        ></div>
    )
}

Chart.propTypes = {
    options: PropTypes.any,
}

function getPieChart(value, percent) {
    return ({
        series: [{
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            color: 'rgba(58,233,213,0.8)',
            animationDuration: 5000,
            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: [
                        [1, 'transparent']
                    ]
                }
            },
            pointer: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            progress: {
                show: true,
                roundCap: true,
                overlap: false
            },

            detail: {
                valueAnimation: true,
                formatter: percent ? '{value}%' : '{value}',
                fontSize: 16,
                offsetCenter: ['0%', '0%'],
                color: '#fff',
            },
            title: {
                show: false
            },
            label: {
                show: false
            },
            labelLine: {
                show: false
            },
            data: [{
                value: value
            }
            ],
            roundCap: 2
        }]
    })
}


function getLineChart(data) {
    return (
        {
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], 130];
                }
            },
            xAxis: {
                type: 'category',
                // boundaryGap: [0, 0],
                axisPointer: {
                    snap: false,
                    lineStyle: {
                        color: '#0E8174',
                        width: 2
                    },
                    label: {
                        show: true,
                        formatter: function (params) {
                            return echarts.format.formatTime('yyyy-MM-dd', params.value);
                        },
                        color: '#fff',
                        backgroundColor: '#0E8174'
                    },
                    handle: {
                        show: true,
                        color: '#fff'
                    }
                },
                axisLabel: {
                    color: '#fff'
                },
                splitLine: {
                    show: false
                }
            },
            yAxis: {
                type: 'value',
                axisTick: {
                    inside: true
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    inside: true,
                    color: '#fff',
                    formatter: '{value}\n'
                },
                z: 10
            },
            grid: {
                top: 110,
                left: 15,
                right: 15,
                height: 160
            },
            dataZoom: [{
                type: 'inside',
                throttle: 50
            }],
            series: [
                {
                    type: 'line',
                    smooth: true,
                    symbol: 'circle',
                    symbolSize: 5,
                    sampling: 'average',
                    itemStyle: {
                        color: '#3AE9D5CC'
                    },
                    animationDelayUpdate: 4000,
                    animationDuration: 5000,
                    stack: 'a',
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgba(58,233,213,0.8)'
                        }, {
                            offset: 1,
                            color: 'rgba(58,77,233,0.3)'
                        }])
                    },
                    data: data
                }
            ]
        }
    )
}

function getRadarChart() {
    return (
        {
            tooltip: {
                trigger: 'item'
            },
            animationDuration: 10000,
            radar: {
                name: {
                    textStyle: {
                        color: '#fff',
                        fontSize: 16
                    },
                },
                indicator: [{
                    text: 'Gesprächseröffnung',
                    max: 100,
                },
                    {
                        text: 'Bedarfsermittlung',
                        max: 100
                    },
                    {
                        text: 'Produktpräsentation und Angebot',
                        max: 100
                    },
                    {
                        text: 'Abschluss',
                        max: 100,
                    },
                    {
                        text: 'Verabschiedung',
                        max: 100
                    },
                    {
                        text: 'Generelle Soft Skills',
                        max: 100
                    },
                    {
                        text: 'Verkäuferskills',
                        max: 100
                    },
                    {
                        text: 'No-Gos - Tabus',
                        max: 100
                    },
                    {
                        text: 'Einhaltung von Arbeitsanweisungen',
                        max: 100
                    }
                ],
                splitArea: {
                    show: true,
                    areaStyle: {
                        color: ['rgba(255,255,255,0)', 'rgba(255,255,255,0)'],
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: '#153269'
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: '#113865',
                        width: 1,
                    }
                },
            },
            series: [{
                name: "\b",
                type: 'radar',
                symbolSize: 0,
                data: [{
                    value: [93.8, 55, 67.8, 83.8, 54.2, 90.6, 81.3, 100, 100],
                    itemStyle: {
                        normal: {
                            color: 'transparent'
//                            shadowColor: '#fff',
//                            shadowBlur: 10,
                        },
                    },
                    areaStyle: {
                        color: {
                            type: 'radial',
                            x: 0.45,
                            y: 0.5,
                            r: 0.7,
                            colorStops: [{
                                offset: 0,
                                color: '#F84C0B'
                            }, {
                                offset: 0.5,
                                color: '#fff308'
                            }, {
                                offset: 1,
                                color: '#07f612'
                            }],

                            global: false
                        }
                    }
                }]
            }]
        }
    )
}

export function YourDashboard(props) {
    const {t} = useTranslation(['common', 'yourDashboard']);
    const classes = useStyles();
    const user = useSelector(state => state.data.user);
    const backgroundColor = useSelector(state => state.data.backgroundColor)
    const token = useSelector(state => state.auth.token);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionCreators.toggleBackground(true));
        return () => {
            dispatch(actionCreators.toggleBackground(false))
        }
    }, [])


    return (
        <div>
            <div>
                <Typography style={{color: '#fff', margin: '20px'}} variant="h4">{t('yourDashboard:title')}</Typography>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2} className={classes.scoreContainer}>
                            <Grid xs={4}>
                                <Chart options={getPieChart(80, true)} height='100px'/>
                            </Grid>
                            <Grid xs={4}>
                                <Typography variant={"h5"} color='#fff' style={{textAlign: 'center'}}>
                                    {t('yourDashboard:yourQualityScore')}
                                </Typography>
                            </Grid>
                            <Grid xs={4} style={{textAlign: "right"}}>
                                <Score className={classes.icon}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2} className={classes.scoreContainer}>
                            <Grid xs={4}>
                                <Chart options={getPieChart(72, true)} height='100px'/>
                            </Grid>
                            <Grid xs={4}>
                                <Typography variant={"h5"} color='#fff' style={{textAlign: 'center'}}>
                                    {t('yourDashboard:teamQualityScore')}
                                </Typography>
                            </Grid>
                            <Grid xs={4} style={{textAlign: "right"}}>
                                <TeamScore className={classes.icon}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2} className={classes.scoreContainer}>
                            <Grid xs={4}>
                                <Chart options={getPieChart(60, false)} height='100px'/>
                            </Grid>
                            <Grid xs={4}>
                                <Typography variant={"h5"} color='#fff' style={{textAlign: 'center'}}>
                                    {t('yourDashboard:yourCurrentNPS')}
                                </Typography>
                            </Grid>
                            <Grid xs={4} style={{textAlign: "right"}}>
                                <Smiley className={classes.icon}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2} className={classes.scoreContainer}>
                            <Grid xs={4}>
                                <Chart options={getPieChart(45, false)} height='100px'/>
                            </Grid>
                            <Grid xs={4}>
                                <Typography variant={"h5"} color='#fff' style={{textAlign: 'center'}}>
                                    {t('yourDashboard:teamNPS')}
                                </Typography>
                            </Grid>
                            <Grid xs={4} style={{textAlign: "right"}}>
                                <Team className={classes.icon}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <div className={classes.paperHeading}>
                            <Typography variant={"button"} autoCapitalize={true} color='#fff'>
                                {t('yourDashboard:skillStatus')}
                            </Typography>
                            <IconButton aria-label="switch" color="primary" size="large">
                                <BarChart/>
                            </IconButton>
                        </div>
                        <Chart options={getRadarChart()} height='350px'/>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <div className={classes.paperHeading}>
                            <Typography variant={"button"} autoCapitalize={true} color='#fff'>
                                {t('yourDashboard:todoList')}
                            </Typography>
                        </div>
                        <div>
                            <List className={classes.root}>
                                <ListItem button>
                                    <ChevronRight/>
                                    {t('yourDashboard:365speech')}
                                </ListItem>
                                <ListItem button>
                                    <ChevronRight/>
                                    {t('yourDashboard:cross')}
                                </ListItem>
                                <ListItem button>
                                    <ChevronRight/>
                                    {t('yourDashboard:greeting')}
                                </ListItem>
                            </List>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <div className={classes.paperHeading}>
                            <Typography variant={"button"} autoCapitalize={true} color='#fff'>
                                {t('yourDashboard:qshistory')}
                            </Typography>
                        </div>
                        <Chart options={getLineChart([['2021-04-01', 60], ['2021-04-05', 70], ['2021-04-10', 80],
                            ['2021-04-16', 55], ['2021-04-20', 67]])} height='350px'/>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <div className={classes.paperHeading}>
                            <Typography variant={"button"} autoCapitalize={true} color='#fff'>
                                {t('yourDashboard:calendar')}
                            </Typography>
                        </div>
                        <Calendar
                            events={events}
                            views={allViews}
                            step={60}
                            showMultiDayTimes
                            //    max={dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours')}
                            defaultDate={new Date(2015, 3, 1)}
                            components={{
                                timeSlotWrapper: ColoredDateCellWrapper,
                            }}
                            localizer={localizer}
                            style={{height: 530}}
                            messages={{
                                date: t('yourDashboard:date'),
                                time: t('yourDashboard:time'),
                                event: t('yourDashboard:event'),
                                allDay: t('yourDashboard:allDay'),
                                week: t('yourDashboard:week'),
                                work_week: t('yourDashboard:work_week'),
                                day: t('yourDashboard:day'),
                                month: t('yourDashboard:month'),
                                previous: t('yourDashboard:previous'),
                                next: t('yourDashboard:next'),
                                yesterday: t('yourDashboard:yesterday'),
                                tomorrow: t('yourDashboard:tomorrow'),
                                today: t('yourDashboard:today'),
                                agenda: t('yourDashboard:agenda'),
                                noEventsInRange: t('yourDashboard:noEventsInRange'),
                                showMore: function (e) {
                                    return '+' + e + t('yourDashboard:noEventsInRange:more')
                                }
                            }}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <div className={classes.paperHeading}>
                            <Typography variant={"button"} autoCapitalize={true} color='#fff'>
                                {t('yourDashboard:career')}
                            </Typography>
                        </div>
                        <Timeline align="alternate" style={{height: '500px'}}>
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Typography variant="body2">
                                        01.04.2020
                                    </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot/>
                                    <TimelineConnector/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={classes.paper}>
                                        <Typography variant="h6" component="h1">
                                            Initialtest
                                        </Typography>
                                        <Typography>durch Nico Schmitt</Typography>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Typography variant="body2">
                                        02.04.2020
                                    </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot/>
                                    <TimelineConnector/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={classes.paper}>
                                        <Typography variant="h6" component="h1">
                                            Telefoniefreigabe
                                        </Typography>
                                        <Typography>durch Hans Trainer</Typography>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Typography variant="body2">
                                        14.04.2020
                                    </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot/>
                                    <TimelineConnector className={classes.secondaryTail}/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={classes.paper}>
                                        <Typography variant="h6" component="h1">
                                            Silent Monitoring
                                        </Typography>
                                        <Typography>durch Hans Trainer</Typography>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Typography variant="body2">
                                        17.04.2020
                                    </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={classes.paper}>
                                        <Typography variant="h6" component="h1">
                                            Coaching
                                        </Typography>
                                        <Typography>durch Coach Cox</Typography>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(YourDashboard);
