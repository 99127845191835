// https://stackoverflow.com/a/41309189
import {SOCKET_FAILURE} from "../constants";

const debug = process.env.NODE_ENV === 'development';
export default function socketMiddleware(socket) {
    return ({dispatch, getState}) => next => action => {
        if (typeof action === 'function') {
            return action(dispatch, getState);
        }

        // Socket middleware
        // promise: (socket) => socket.emit('event', data)
        // type: ACTION_TYPE_CONST
        // transport: 'socket' for this middleware to be used
        // new action types REQUEST, SUCCESS, and FAILURE are created for easier program control and error handling
        // I.E if an action 'SOCKET/CONNECT' fails the action 'SOCKET/CONNECT/FAILURE' is created.

        const {promise, type, types, transport, subscribeTo, uncontrolled, ...rest} = action;

        if (transport !== 'socket' || !promise) {
            // If action should not use socket transport or action.promise is malformed move on.
            // if (debug) {
            //     console.info("sM - ", type);
            // }
            return next(action);
        }

        if (subscribeTo){
            subscribeTo(socket);
        }

        if (uncontrolled){
            return promise(socket);
        }

        return promise(socket)
            .then((result) => {
                // if (debug) {
                //     console.info("sM - ", type, result);
                // }
                return next({...rest, result, type: type});
            })
            .catch((error) => {
                return next({...rest, error, type: type + SOCKET_FAILURE});
            })
    };
}
