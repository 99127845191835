import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import CircularProgress from '@mui/material/CircularProgress';

class LoadingSpinner extends React.Component {
    state = {
        show: !Boolean(this.props.delayDuration),
        text: this.props.text
    };

    componentDidMount(){
        if (this.props.delayDuration){
            this.timeout = setTimeout(() => {
                this.setState({show: true})
            }, this.props.delayDuration)
        }
        if (this.props.animateText) {
            this.interval = setInterval(() => {
                this.setState({text: this.state.text + '.'})
            }, 500)
        }
    }

    componentWillUnmount(){
        if (this.props.delayDuration) clearTimeout(this.timeout);
        if (this.props.animateText) clearInterval(this.interval);
    }

    render(){
        return (
            this.state.show ?
                <React.Fragment>
                    <div style={{textAlign: 'center', width: '100%'}}>
                        <div style={this.props.divStyle}>
                            <CircularProgress style={this.props.circularProgressStyle}/>
                        </div>
                        <h3>{this.state.text}</h3>
                    </div>
                </React.Fragment>
                :
                null
        )
    }
}
LoadingSpinner.defaultProps = {
    divStyle: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: '25%',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '75px'
    },
    circularProgressStyle: {
        width: '75px',
        height: '75px'
    },
    delayDuration: null,
    animateText: true,
    text: "",
};
LoadingSpinner.propTypes = exact({
    divStyle: PropTypes.object,
    circularProgressStyle: PropTypes.object,
    delayDuration: PropTypes.number,
    animateText: PropTypes.bool,
    text: PropTypes.string,
});
export default LoadingSpinner;
