import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import {RADIO_GROUP, DROP_DOWN, STANDARD, STARS} from '../../constants';
import Badge from '@mui/material/Badge';
import Tooltip from 'utils/tooltipWithPlacement';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import DescriptionPopover from "./DescriptionPopover";
import {sortCatalogItemsByOrder} from "./CatalogItems";
import {withTranslation} from "react-i18next";
import {Autocomplete, Rating} from '@mui/material';
import {TextField} from "@mui/material";
import {Clear as DeleteIcon} from "@mui/icons-material";

const itemNameStyle = {marginLeft: '1%', textAlign: 'left', hyphens: 'auto'};
const commentButtonSelectedStyle = {backgroundColor: '#cecece'};


class IntItemProduction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? parseInt(this.props.value) : "",
            inputValue: this.props.value ? parseInt(this.props.value) : "",
            inputProps: {
                inputProps: {
                    max: this.props.maximumValue, min: this.props.minimumValue,
                    step: 'any'
                }
            }
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value != this.props.value) {
            let value = this.props.value ? this.props.value : ""
            this.setState({value: value})
        }
    }

    onChange = (event, newValue, reason) => {
        if (this.props.disabled) {
            return
        }
        if (reason === 'clear') {
            this.setState({value: "", inputValue: ""});
            this.props.onRemoveAnswer(this.props.resultId, this.props.itemId);
        } else if (newValue && this.props.onChange) {
            let value = parseInt(newValue)
            let goSignal = true;
            if (this.props.maximumValue && value > this.props.maximumValue) {
                goSignal = false
            }
            if (this.props.minimumValue && value < this.props.minimumValue) {
                goSignal = false
            }

            if (goSignal) {
                this.setState({value: newValue, inputValue: newValue});
                this.props.onChange(this.props.resultId, this.props.itemId, newValue);
            }
        }
    };

    onInputChange = (event, newValue) => {
        let value = parseInt(newValue)
        let goSignal = true;
        if (this.props.maximumValue && value > this.props.maximumValue) {
            goSignal = false
        }
        if (this.props.minimumValue && value < this.props.minimumValue) {
            goSignal = false
        }

        if (goSignal) {
            this.setState({value: value})
        }
    };

    render() {
        const {t} = this.props;
        let id = this.props.itemId;
        if (this.props.resultRefInId) {
            id = `${this.props.resultId}/${this.props.itemId}`;
        }

        return (
            <div id={id} className="row small-gutters display-flex" style={{alignItems: 'center'}}>
                <div className="col-sm-2 col-xs-12">
                    <Tooltip
                        title={this.props.itemDescription !== null ?
                            this.props.itemDescription : ""} placement="right"
                    >
                        <h4 style={itemNameStyle}>{this.props.itemName}
                            {this.props.itemRequired.length > 0 &&
                                <span style={{color: 'red'}}> *</span>
                            }
                        </h4>
                    </Tooltip>
                </div>
                {this.props.avatarContainer &&
                    <div
                        className={'col-sm-2 col-xs-12'}
                        id={'itemAvatarContainer' + this.props.itemId}
                        style={{display: 'inline-flex'}}
                    >
                    </div>
                }
                <div className={`col-sm-${this.props.avatarContainer ? '1' : '3'}`}
                     style={{display: 'flex', justifyContent: 'end'}}>
                    <div style={{marginRight: '20px'}}>
                        <h5 style={{
                            margin: 0,
                            color: '#8300ff'
                        }}>{typeof this.props.itemScore === 'number' && this.props.itemWeight
                        && !this.props.itemWeight.deactivated ? "G: " + this.props.itemWeight.weight : '\u00A0'}</h5>

                        <h5 style={{margin: 0, color: '#497993'}}>{typeof this.props.itemScore === 'number' ?
                            "S: " + this.props.itemScore.toString() + (this.props.displayPercentages ? "%" : "") : '\u00A0'}
                        </h5>
                    </div>
                </div>
                <div
                    className={"col-sm-7 col-xs-12"}
                    style={{textAlign: 'left'}}
                >
                    {this.props.presentationType === STANDARD &&
                        <React.Fragment>
                            <TextField
                                style={{width: '65px'}}
                                variant="standard"
                                inputRef={n => this.textField = n}
                                InputProps={this.state.inputProps}
                                id={'number'}
                                type={'number'}
                                value={this.state.value}
                                onChange={event => {
                                    event.preventDefault();
                                    this.onInputChange(event, event.target.value)
                                }}
                                onBlur={event => {
                                    event.preventDefault();
                                    this.onChange(event, event.target.value, null)
                                }}
                                disabled={this.props.disabled}
                            />
                        </React.Fragment>
                    }
                    {this.props.presentationType === STARS &&
                        <React.Fragment>
                            <Rating style={{top: '8px'}} size="large" onChange={event => {
                                event.preventDefault();
                                this.onChange(event, event.target.value, null)
                            }}
                                    value={parseInt(this.props.value)}
                                    max={this.state.maximumValue ? parseInt(this.state.maximumValue) : 5}/>
                        </React.Fragment>
                    }
                    {this.props.value &&
                        <IconButton
                            onClick={() => {
                                this.props.onRemoveAnswer(this.props.resultId, this.props.itemId)
                            }}
                            size="large">
                            <DeleteIcon/>
                        </IconButton>
                    }
                </div>
                {this.props.showCommentButton &&
                    <div className="col-xs-1">
                        <Badge
                            variant={'dot'}
                            color={'primary'}
                            overlap="circular"
                            badgeContent={this.props.comments.length}
                        >
                            <IconButton
                                style={this.props.commentButtonSelected ? commentButtonSelectedStyle : null}
                                onClick={(event) => {
                                    this.props.onCommentButtonClick(event, id)
                                }}
                                size="large">
                                <ModeCommentIcon/>
                            </IconButton>
                        </Badge>
                    </div>
                }
            </div>
        );
    }
}

IntItemProduction.defaultProps = {
    showCommentButton: false,
    disabled: false,
    itemWeight: null,
    itemScore: null,
    itemRequired: [],
    comments: [],
    avatarContainer: false,
    commentButtonSelected: false,
    resultRefInId: false,
    displayPercentages: true,
    value: null
};

IntItemProduction.propTypes = exact({
    resultId: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    itemName: PropTypes.string.isRequired,
    itemDescription: PropTypes.string,
    itemRequired: PropTypes.array,
    onChange: PropTypes.func,
    onRemoveAnswer: PropTypes.func,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    showCommentButton: PropTypes.bool,
    onCommentButtonClick: PropTypes.func,
    itemWeight: PropTypes.object,
    itemScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    comments: PropTypes.arrayOf(PropTypes.object),
    avatarContainer: PropTypes.bool,
    commentButtonSelected: PropTypes.bool,
    resultRefInId: PropTypes.bool,
    presentationType: PropTypes.string.isRequired,
    maximumValue: PropTypes.number,
    minimumValue: PropTypes.number,
    t: PropTypes.func,
    i18n: PropTypes.object,
    tReady: PropTypes.bool,
    displayPercentages: PropTypes.bool,
});

export default withTranslation(['sheetManagement', 'common'])(IntItemProduction);
