import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl'
import ListItemPure from './ListItemPure';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles({
    select: {
        color: props => props.color,
        "& .MuiSelect-icon": {
            fill: props => props.color
        }
    }
});


export const DropDown = (props) => {
    const [, setValues] = useState([]);
    const {select} = useStyles(props);

    const handleChange = (event) => {
        event.preventDefault();
        setValues(event.target.values);
        props.onChange(event.target.value, ...props.args);
    };

    return (
        <FormControl style={props.style} variant={props.variant}>
            <Select
                autoWidth
                style={props.selectStyle}
                className={select}
                multiple={props.multiple}
                value={props.valueKey && props.value ? props.value[props.valueKey] : props.value}
                key={props.value}
                onChange={handleChange}
                disabled={props.disabled}
                margin={props.margin}
                disableUnderline={props.disableUnderline}
            >
                {props.values.map((value) => {
                    return (
                        <ListItemPure
                            type={"MenuItem"}
                            emitEvent={true}
                            key={props.idKey ? value[props.idKey] : value}
                            value={props.valueKey && value ? value[props.valueKey] : value}
                        >
                            {props.labelKey ? value[props.labelKey] : value}
                        </ListItemPure>
                    )
                })}
            </Select>
        </FormControl>
    );

}

DropDown.defaultProps = {
    args: [],
    multiple: false,
    values: [],
    onChange: () => null,
    disabled: false,
    margin: "none",
    disableUnderline: false,
    color: '#000',
    variant: "standard"
};

DropDown.propTypes = {
    args: PropTypes.array,
    multiple: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    values: PropTypes.array,
    onChange: PropTypes.func,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    idKey: PropTypes.string,
    disabled: PropTypes.bool,
    margin: PropTypes.string,
    disableUnderline: PropTypes.bool,
    color: PropTypes.string,
    selectStyle: PropTypes.object,
    variant: PropTypes.string
};

export default DropDown
