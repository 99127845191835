import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import i18n from '../i18n';


// convenience function, instead of having to render a dialog and keep track of its state
// whenever a confirmation is needed in a separate component, this function can be called like so
// getDialogConfirmation().then((confirmed) => if(confirmed) yadayada)
// Could also fully use the promise structure and reject() onCancel instead
// Then we could use it like getDialogConfirmation().then(onOkClickFunc, onNoClickFunc)
// Though 99% of the time no special action should be taken when dialog is cancelled
// so it would just be a bunch of empty functions passed to avoid the unhandled rejection error
export function getDialogConfirmation(title, text, okText=null, cancelText=null){
    okText = okText || i18n.t('common:okay');
    cancelText = cancelText || i18n.t('common:cancel');
    return new Promise((resolve, reject) => {
        let containerDiv = document.createElement('div');
        const unmount = () => ReactDOM.unmountComponentAtNode(containerDiv);
        const onOk = () => {
            unmount();
            resolve(true);
        };
        const onCancel = () => {
            unmount();
            resolve(false);
        };

        ReactDOM.render(
            <DialogSimple
                open
                dialogTitle={title}
                dialogText={text}
                okText={okText}
                cancelText={cancelText}
                onOkClick={onOk}
                onCancelClick={onCancel}
            />,
            containerDiv
        );
    })
}

export default class DialogSimple extends React.PureComponent {

  render() {
    const actions = [
      <Button
        color="primary"
        key={"cancel"}
        onClick={this.props.onCancelClick}
      >
          {this.props.cancelText}
      </Button>,
      <Button
        color="primary"
        key={"ok"}
        onClick={this.props.onOkClick}
      >
          {this.props.okText}
      </Button>,
    ];

    if (this.props.onThirdActionClick){
        actions.push(
            <Button
                color="primary"
                key={"thirdAction"}
                onClick={this.props.onThirdActionClick}
            >
                {this.props.thirdActionText}
            </Button>
        );
    }

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.onCancelClick}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
            <DialogTitle id={"dialog-title"}>{this.props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description" style={{whiteSpace: 'pre-line'}}>
                    {this.props.dialogText}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{textAlign: 'center'}}>
                {actions}
            </DialogActions>
        </Dialog>
      </div>
    );
  }
}