import React from 'react';
import {bindActionCreators} from "redux";
import * as actionCreators from "../../actions/data";
import {connect} from "react-redux";


function mapStateToProps(state) {
    return {
        token: state.auth.token,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({...actionCreators}, dispatch);
}

class RedisDashboardView extends React.Component {

    componentDidMount() {
        let html = "";

        this.props.redirectToRQDashboard(this.props.token).then(resp => {
            html = resp;
        }).catch(err => console.log("err: ", err));

        let containerElement = document.getElementById("rq-root");
        console.log(html);
        containerElement.appendChild(html)
    }

    render(){

        return (

            <div id={"rq-root"}>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RedisDashboardView);
