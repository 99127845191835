import 'babel-polyfill';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './bootstrap/css/bootstrap.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.css';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import {routeConfig} from "./routes";
import {renderRoutes} from 'react-router-config';
import configureStore from "./store/configureStore";
import SocketClient from './SocketClient';

import history from './browserHistory';
import './i18n';
import LoadingSpinner from "./components/LoadingSpinner";

const socket = new SocketClient();
const store = configureStore(socket);
const rootElement = document.getElementById('root');

ReactDOM.render(
    <Suspense fallback={<LoadingSpinner/>}>
        <Provider store={store}>
            <Router history={history}>
                {renderRoutes(routeConfig)}
            </Router>
        </Provider>
    </Suspense>,
    rootElement
);

registerServiceWorker();
