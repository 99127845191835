import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import {
    CheckBoxOutlineBlank as UncheckedIcon,
    CheckBox as CheckedIcon
} from "@mui/icons-material";
import {
    TextField,
    Checkbox, Chip
} from "@mui/material";
import { Autocomplete } from '@mui/material';
import {useTranslation} from "react-i18next";
import {requiredIf, sortObjectArrayByName} from "../../utils/misc";


export default function SheetAttendeePicker(props){
    const {
        selectedAttendees,
        values,
        onChange,
        disabled
    } = props;
    const { t } = useTranslation('desk');
    const [value, setValue] = useState(selectedAttendees);
    const onValueChange = (event, newValue, reason) => {
        //console.log('onValueChange', event, newValue, reason)
        setValue(newValue);
        if (reason === "clear") {
            triggerChange([]);
        }
    };

    const triggerChange = (forcedValue=null) => {
        //console.log('triggerChange')
        if (forcedValue) onChange(forcedValue);
        else onChange(value)
    };

    const onChipDelete = (userId) => {
        //console.log('onChipDelete')
        let newValue = value.filter(u => u.user_id !== userId);
        // Have to pass the value to triggerChange explicitly as state update
        // is not synchronous and won't finish in time, and React hooks does not allow
        // for callbacks like ClassComponent.setState does.
        setValue(newValue);
        triggerChange(newValue);
    };

    // update value if selectedAttendee prop changes.
    useEffect(() => {
        setValue(selectedAttendees);
    }, [selectedAttendees]);


    return (
        <Autocomplete
            multiple
            disableCloseOnSelect
            disabled={disabled}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
            renderOption={(props, option, {selected}) => (
                <li {...props}>
                    <Checkbox
                        color="primary"
                        icon={<UncheckedIcon fontSize="small"/>}
                        checkedIcon={<CheckedIcon fontSize="small"/>}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </li>
            )}
            id={'sheet-attendee-picker'}
            options={sortObjectArrayByName(values, 'name')}
            renderInput={(params) => {
                // This is a bit hacky, but the API provides no way to differentiate between
                // deleting a user via the chip delete button / de-selecting a user in the open popover list.
                // both actions trigger onChange with 'remove-option' as the reason.
                // since we only want to trigger backend call onClose this is the only way to get around it.
                // It also makes it possible to style the Chip:s according to user color.
                params.InputProps.startAdornment = [];
                sortObjectArrayByName(value, 'name').forEach(user => {
                    params.InputProps.startAdornment.push(
                        <Chip
                            color={"primary"}
                            key={user.user_id}
                            label={user.name}
                            style={{color: '#fff', backgroundColor: user.color}}
                            onDelete={() => onChipDelete(user.user_id)}
                        />
                    )
                })
                return <TextField {...params} variant="outlined" label={t('attendees')}/>
            }}
            value={value}
            onChange={onValueChange}
            onClose={() => triggerChange()}
        />
    );
}

SheetAttendeePicker.defaultProps = {
    selectedAttendees: [],
    values: [],
    disabled: false
};

SheetAttendeePicker.propTypes = exact({
    disabled: PropTypes.bool,
    selectedAttendees: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func,
    values: PropTypes.arrayOf(PropTypes.object)
});
