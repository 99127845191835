import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import Avatar from '@mui/material/Avatar';
import Tooltip from 'utils/tooltipWithPlacement';


class ItemCategoryAvatars extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.categoryAvatars.length === 0 &&
                    <Avatar style={{backgroundColor: this.props.sheetColor}}>/</Avatar>
                }
                {this.props.categoryAvatars.map(item => {
                    let descriptionNode = (
                        <div style={{whiteSpace: 'pre-line'}}>
                            <h4 style={{fontWeight: 'bolder'}}>{item.name}</h4>
                            <h4>{item.description}</h4>
                        </div>
                    );

                    return (
                        <Tooltip key={item.id} title={descriptionNode}>
                            <Avatar style={{backgroundColor: item.color}}>{item.label}</Avatar>
                        </Tooltip>
                    )
                })}
            </React.Fragment>
        )
    }
}

ItemCategoryAvatars.defaultProps = {
    categoryAvatars: [],
    sheetColor: "#a6a6a6",
};

ItemCategoryAvatars.propTypes = exact({
    categoryAvatars: PropTypes.array.isRequired,
    sheetColor: PropTypes.string,
});

export default ItemCategoryAvatars;