import React from 'react'
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import TextField from '@mui/material/TextField';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import Tooltip from 'utils/tooltipWithPlacement';
import {withTranslation} from "react-i18next";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from '../../utils/dayjsWithLocale';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider, deDE, enUS} from '@mui/x-date-pickers';
import InputAdornment from "@mui/material/InputAdornment";
import {LangueLocalMapper} from "./DateItem";

const itemNameStyle = {marginLeft: '1%', textAlign: 'left', hyphens: 'auto'};


class DateItemProduction extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : "",
            locale: LangueLocalMapper[this.props.i18n.language]
        };
    };

    componentDidUpdate() {
        this.setState({locale: LangueLocalMapper[this.props.i18n.language]})
    }

    onChange = (newDate) => {
        let correctDate = dayjs(newDate).format('YYYY-MM-DD')
        this.setState({value: correctDate})
    };

    onDateSelectDone = () => {
        if (!this.state.value) return
        let dateString = dayjs(this.state.value).format('YYYY-MM-DD');
        if (this.props.onChange && !this.props.disabled) {
            this.props.onChange(this.props.resultId, this.props.itemId, dateString)
        }
    };

    render() {
        // todo - should use the combined resultid/itemid id everywhere for consistency and for sure no duplicates
        let id = this.props.itemId;
        const locale = this.state.locale;
        if (this.props.resultRefInId) {
            id = `${this.props.resultId}/${this.props.itemId}`;
        }
        const {t} = this.props;
        return (
            <div id={id} className="row small-gutters display-flex" style={{alignItems: 'center'}}>
                <div className="col-sm-2 col-xs-12">
                    <Tooltip placement="right"
                             title={this.props.itemDescription !== null ? this.props.itemDescription : ""}>
                        <h4 style={itemNameStyle}>{this.props.itemName}
                            {this.props.itemRequired.length > 0 &&
                                <span style={{color: 'red'}}> *</span>
                            }
                        </h4>
                    </Tooltip>
                </div>

                {this.props.avatarContainer &&
                    <div
                        className={'col-sm-2 col-xs-12'}
                        id={'itemAvatarContainer' + this.props.itemId}
                        style={{display: 'inline-flex'}}
                    >
                    </div>
                }
                <div className={`col-sm-${this.props.avatarContainer ? '1' : '3'}`}
                     style={{display: 'flex', justifyContent: 'end'}}>
                    <div style={{marginRight: '20px'}}>
                    </div>
                </div>

                <div className={"col-sm-5 col-xs-12"} style={{textAlign: 'left'}}>
                    <React.Fragment>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={this.props.i18n.language}
                                              localeText={locale}>
                            <MobileDatePicker
                                hideTabs={true}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        variant="standard"
                                        fullWidth
                                        helperText={null}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position={'end'}>
                                                    <CalendarIcon/>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}
                                variant={'inline'}
                                placeholder={t('common:chooseDate')}
                                value={this.state.value}
                                onChange={this.onChange}
                                onClose={this.onDateSelectDone}
                            />
                        </LocalizationProvider>
                    </React.Fragment>
                </div>
            </div>
        );
    }
}

DateItemProduction.defaultProps = {
    itemDescription: "",
    itemRequired: [],
    disabled: false,
    itemScore: 120,
    itemWeight: {weight: 4},
    avatarContainer: false,
    resultRefInId: false,
};

DateItemProduction.propTypes = exact({
    resultId: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    itemName: PropTypes.string.isRequired,
    itemDescription: PropTypes.string,
    itemRequired: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.shape({text: PropTypes.string, radio: PropTypes.string}), PropTypes.string]),
    disabled: PropTypes.bool,
    itemWeight: PropTypes.object,
    itemScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    avatarContainer: PropTypes.bool,
    resultRefInId: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    tReady: PropTypes.bool.isRequired,
});
export default withTranslation(['sheetManagement'])(DateItemProduction);
