import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {requiredIf} from '../../utils/misc';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {ITEM_NAME_MAX_LENGTH, STANDARD, STARS} from '../../constants';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {withTranslation} from "react-i18next";
import {Rating, Select} from '@mui/material';
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";


class IntItem extends React.Component {

    constructor(props) {
        super(props);
        this.arsch = React.createRef();
        this.state = {
            itemName: "",
            currentName: this.props.itemName ? this.props.itemName : "",
            value: this.props.value,
            minimumValue: this.props.minimumValue || '',
            maximumValue: this.props.maximumValue || '',
            inputProps: {
                inputProps: {max: this.props.maximumValue, min: this.props.minimumValue, step: 'any'}
            }
        };
    };

    componentDidUpdate(prevProps) {
        if (this.props.itemName !== this.state.itemName && this.props.itemName !== null) {
            let name = this.props.itemName;
            this.setState({
                itemName: name,
                currentName: name,
            });
        }

        if (prevProps.maximumValue !== this.props.maximumValue) {
            this.setState({maximumValue: this.props.maximumValue})
        }
        if (prevProps.minimumValue !== this.props.minimumValue) {
            this.setState({minimumValue: this.props.minimumValue})
        }
    }

    onValueChange = (event) => {
        this.setState({value: event.target.value})
    };

    onMinimumChange = (event) => {
        this.setState({minimumValue: event.target.value, inputProps: {inputProps: {min: this.state.maximumValue,
                max: event.target.value, step: 'any'}}})
    };

    onMaximumChange = (event) => {
        this.setState({maximumValue: event.target.value, inputProps: {inputProps: {min: this.state.minimumValue,
                max: event.target.value, step: 'any'}}})
    };

    onMinimumBlur = (event) => {
        this.props.onChangeSheetItemIntMinimumValue(this.props.itemId, event.target.value)
    }

    onMaximumBlur = (event) => {
        this.props.onChangeSheetItemIntMaximumValue(this.props.itemId, event.target.value)
    }

    onItemNameChange = (event) => {
        this.setState({currentName: event.target.value})
    };

    onNameChange = () => {
        if (this.props.itemName === this.state.currentName || this.props.currentName === null) return;
        this.props.onItemLabelChange(this.props.itemId, {name: this.state.currentName});
    };

    onPresentationTypeChange = (event) => {
        this.props.onPresentationTypeChange(this.props.itemId, event.target.value);
    }

    getIntField = () => {
        const {t} = this.props;

        if (this.props.maximumValue) {
            this.state.inputProps['max'] = this.props.maximumValue;
        }

        if (this.props.minimumValue) {
            this.state.inputProps['min'] = this.props.minimumValue;
        }

        return (
            <React.Fragment>
                {this.props.presentationType === STANDARD &&
                    <React.Fragment>
                        <TextField
                            style={{width: '65px'}}
                            variant="standard"
                            inputRef={n => this.textField = n}
                            InputProps={this.state.inputProps}
                            id={'number'}
                            type={'number'}
                            value={this.state.value}
                            onChange={this.onValueChange}
                            onBlur={this.onValueChange}
                            disabled={this.props.disabled}
                        />
                    </React.Fragment>
                }
                {this.props.presentationType === STARS &&
                    <Rating onChange={this.onValueChange}  value={this.state.value ? parseInt(this.state.value) : 0}
                            max={this.state.maximumValue ? parseInt(this.state.maximumValue) : 5} />
                }
            </React.Fragment>
        )
    };

    getRootClassName = () => {
        if ((!this.props.disabled || this.props.showMenuButton)) {
            if (this.props.category) return "row col-xs-10";
            return "row col-xs-11";
        }
        return "row col-xs-12";
    };


    render() {
        const {t} = this.props;
        const nameError = this.state.currentName && this.state.currentName.length >= ITEM_NAME_MAX_LENGTH;
        return (
            <div className="row small-gutters display-flex"
                 style={{alignItems: 'center', width: '100%', paddingBottom: '0'}}>
                {(!this.props.disabled || this.props.showMenuButton) &&
                    <div className="col-xs-1" style={{display: 'flex', alignItems: 'center'}}>
                        <IconButton
                            style={{marginLeft: '-12px'}}
                            onClick={(event) => this.props.onMenuButtonClick(event, this.props.itemId)}
                            size="large">
                            <MoreVertIcon/>
                        </IconButton>
                    </div>
                }
                {this.props.category &&
                    <div
                        className="col-xs-1"
                        id={'itemAvatarContainer' + this.props.itemId}
                        style={{display: 'inline-flex'}}
                    >

                    </div>
                }
                <div className={this.getRootClassName()}>
                    <div className="col-sm-6 col-xs-10">
                        <TextField
                            variant="standard"
                            error={nameError ? nameError : false}
                            label={nameError ? t('fieldNameError') : t('fieldName')}
                            helperText={nameError ? t('fieldNameTooLong', {maxLength: ITEM_NAME_MAX_LENGTH}) : null}
                            fullWidth
                            disabled={this.props.disabled}
                            value={this.state.currentName}
                            onChange={this.onItemNameChange}
                            onBlur={this.onNameChange}
                            InputProps={this.props.itemRequired.length > 0 ?
                                {
                                    endAdornment:
                                        <InputAdornment
                                            disableTypography
                                            position='end'
                                            style={{color: 'red'}}
                                        >
                                            *
                                        </InputAdornment>
                                }
                                :
                                null
                            }
                        />
                    </div>
                    <div className="col-sm-2 col-xs-2" style={{display: 'flow-root', top: '15px'}}>
                        {this.getIntField()}
                    </div>
                    <div className="col-sm-1"
                         style={{top: '10px', height: '32px'}}>
                        <Divider variant="middle" orientation="vertical"/>
                    </div>
                    <div className="col-sm-1 col-xs-2" style={{display: 'flow-root'}}>
                        <TextField
                            variant="standard"
                            id={'min.'}
                            type={'number'}
                            label="Min."
                            style={{width: '45px'}}
                            disabled={this.props.disabled}
                            value={this.state.minimumValue}
                            onChange={this.onMinimumChange}
                            onBlur={this.onMinimumBlur}
                        />
                    </div>
                    <div className="col-sm-1 col-xs-2" style={{display: 'flow-root'}}>
                        <TextField
                            variant="standard"
                            id={'max.'}
                            type={'number'}
                            label="Max."
                            style={{width: '45px'}}
                            disabled={this.props.disabled}
                            value={this.state.maximumValue}
                            onChange={this.onMaximumChange}
                            onBlur={this.onMaximumBlur}
                        />
                    </div>
                    <div className="col-sm-1 col-xs-8" style={{display: 'flow-root'}}>
                        <FormControl style={{minWidth: '100%'}}>
                            <InputLabel id="blub">Darstellung</InputLabel>
                            <Select variant="standard" label="Darstellung" value={this.props.presentationType}
                                    onChange={this.onPresentationTypeChange}>
                                {this.props.itemPresentationMapper['INT'].map(presentationType => {
                                    return <MenuItem key={presentationType} value={presentationType}>{presentationType}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        );
    }
}

IntItem.defaultProps = {
    showMenuButton: false,
    disabled: false,
    itemRequired: [],
    value: null,
    presentationType: ""
};

IntItem.propTypes = exact({
    itemId: PropTypes.string.isRequired,
    itemName: PropTypes.string,
    itemRequired: PropTypes.array,
    value: PropTypes.number.isRequired,
    onItemLabelChange: PropTypes.func.isRequired,
    presentationType: PropTypes.string.isRequired,
    maximumValue: PropTypes.number,
    minimumValue: PropTypes.number,
    showMenuButton: PropTypes.bool,
    onMenuButtonClick: requiredIf(PropTypes.func, props => props.showMenuButton),
    disabled: PropTypes.bool,
    category: PropTypes.object,
    itemPresentationMapper: PropTypes.object.isRequired,
    onPresentationTypeChange: PropTypes.func.isRequired,
    onChangeSheetItemIntMaximumValue: PropTypes.func.isRequired,
    onChangeSheetItemIntMinimumValue: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    tReady: PropTypes.bool.isRequired,
});
export default withTranslation(['sheetManagement', 'common'])(IntItem);
