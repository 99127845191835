/* eslint camelcase: 0 */
import axios from 'axios';


export function set_user_language(json, token){
    return axios.post('/api/setlanguage', json, tokenConfig(token));
}

export function send_pricing_inquiry(json){
    return axios.post('/api/send_pricing_inquiry', json);
}

export function send_feedback(json){
    return axios.post('/api/send_feedback', json);
}

export function join_mailing_list(email){
    return axios.post('/api/subscribe_to_email_list', {email});
}

const tokenConfig = (token) => ({
    headers: {
        'Authorization': token, // eslint-disable-line quote-props
    },
});

export function request_password_reset(email){
    return axios.post('/api/request-password-reset', {email});
}

export function verify_reset_password_token(verification_token){
    return axios.get(`/api/verify-reset-password-token/${verification_token}`);
}

export function change_password(json){
    return axios.post('/api/reset-password', json);
}

export function validate_token(token) {
    return axios.post('/api/is_token_valid', {
        token,
    });
}

export function get_github_access() {
    window.open(
        '/github-login',
        '_blank' // <- This is what makes it open in a new window.
    );
}

export function register_user(first_name, last_name, email, password){
    return axios.post('/api/user/register', {
        first_name, last_name, email, password
    })
}

export function bulk_add_users(project_id, csv_file, token){
    return axios.post(`/api/bulk_add_users/${project_id}`, csv_file, {
        headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
        },
    });
}

export function create_user(project_id, first_name, last_name, email, password, role, token) {
    return axios.put('/api/create_user', {
        project_id,
        first_name,
        last_name,
        email,
        password,
        role
    }, tokenConfig(token));
}


export function delete_user(id, token) {
    return axios.delete('/api/delete_user/'+id,
        tokenConfig(token));
}

export function reset_user_password(userId, token){
    return axios.put(`/api/reset_user_password/${userId}`, '', tokenConfig(token));
}

export function change_user_password(json, token){
    return axios.put('/api/change_user_password', json, tokenConfig(token));
}

export function get_token(name, password) {
    return axios.post('/api/get_token', {
        name,
        password,
    });
}

export function resend_confirmation_email(token){
    return axios.post('/api/user/resend_confirmation_email', '', tokenConfig(token))
}

export function has_github_token(token) {
    return axios.get('api/has_github_token', tokenConfig(token));
}

export function download_file(filename, token){
    return axios.get('/api/download_file/' + filename)
}

export function upload_file(file, token) {
    return axios.post('/api/upload/file', file, {
        headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function attach_file_to_result(resultId, file, token) {
    return axios.post(`/api/attach_file_to_result/${resultId}`, file, {
        headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
        },
    });
}

export function attach_file_to_calibration(calibrationId, file, token) {
    return axios.post(`/api/attach_file_to_calibration/${calibrationId}`, file, {
        headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
        },
    });
}

export function data_about_user(token) {
    return axios.get('/api/user', tokenConfig(token));
}

export function getUsers(token) {
    return axios.get('/api/users', tokenConfig(token));
}

export function put_project(project, token) {
    return axios.put('/api/project', project, tokenConfig(token))
}

export function create_project(json, token) {
    return axios.put('/api/create_project', json, tokenConfig(token))
}

export function modify_project(json, token){
    return axios.put('/api/modify_project', json, tokenConfig(token))
}

export function getProjects(token) {
    return axios.get('/api/projects', tokenConfig(token));
}

export function getProjectUsers(projectId, token) {
    return axios.get(`/api/project/users/${projectId}`, tokenConfig(token));
}

export function getAssociatedUsers(userId, token) {
    return axios.get(`/api/user/associated/${userId}`, tokenConfig(token));
}

export function get_workbench_sheet(id, token) {
    return axios.get('/api/workbench_sheet/'+id, tokenConfig(token));
}

export function get_sheet_metadata(json, token) {
    return axios.put('/api/sheets/get_metadata', json, tokenConfig(token));
}

export function getRoles(token) {
    return axios.get('/api/roles', tokenConfig(token));
}

export function getScorecardData(project) {
    return axios.get('/api/scorecard/'+project)
}

export function changeCurrentProject(projectId, token) {
    return axios.put('/api/user/current_project', {project_id: projectId}, tokenConfig(token));
}

export function get_sheet_types(projectName, token) {
    return axios.get('/api/sheet_type/'+projectName, tokenConfig(token));
}

export function put_sheet_type(projectName, name, token) {
    return axios.put('/api/sheet_type', {projectName: projectName, name: name}, tokenConfig(token));
}

export function get_partners(token) {
    return axios.get('/api/partners/', tokenConfig(token))
}

export function create_partner(partnerName, token){
    return axios.put(`/api/partner/create/${partnerName}`, {}, tokenConfig(token))
}

export function modify_partner(json, token){
    return axios.put('/api/partner/modify', json, tokenConfig(token))
}

export function delete_partner(partnerId, token){
    return axios.delete(`/api/partner/delete/${partnerId}`, tokenConfig(token))
}

export function add_partner_to_project(partnerId, projectId, token){
    return axios.put('/api/project/partner/add', {partner_id: partnerId, project_id: projectId}, tokenConfig(token))
}

export function remove_partner_from_project(partnerId, projectId, token){
    return axios.put('/api/project/partner/remove', {partner_id: partnerId, project_id: projectId}, tokenConfig(token))
}

export function create_new_result(sheetId, token) {
    return axios.post('/api/results/create', {sheet_id: sheetId}, tokenConfig(token));
}

export function get_result_metadata(json, token) {
    return axios.put('/api/results/get_metadata', json, tokenConfig(token));
}

export function create_guest_result(sheetId, token=null) {
    if (token) {
        return axios.post(`/api/survey/${sheetId}`, '', tokenConfig(token));
    }
    return axios.post(`/api/survey/${sheetId}`);
}

export function get_guest_result(resultId, token) {
    return axios.get(`/api/guest-result/${resultId}`, tokenConfig(token));
}

export function get_result(resultId, token) {
    return axios.get(`/api/result/${resultId}`, tokenConfig(token));
}

export function invite_user_to_project(json, token){
    return axios.post('/api/user/invite', json, tokenConfig(token));
}

export function assign_user_to_project(json, token){
    return axios.put('/api/assign_user_to_project', json, tokenConfig(token));
}

export function remove_user_from_project(json, token){
    return axios.put('/api/remove_user_from_project', json, tokenConfig(token));
}

export function change_user_color(json, token){
    return axios.put('/api/change_user_color', json, tokenConfig(token));
}

export function change_user_personal_info(user_id, first_name, last_name, email, token){
    return axios.put('/api/user/change_personal_info', {
        user_id, first_name, last_name, email
    }, tokenConfig(token));
}

export function change_project_retention_days(project_id, new_retention_days, token){
    return axios.put('/api/project/retention_days', {
        project_id, new_retention_days
    }, tokenConfig(token));
}

export function toggle_project_attendee_emails(project_id, enabled, token){
    return axios.put('/api/project/attendee_emails', {
        project_id, enabled
    }, tokenConfig(token));
}

export function generate_result_scores(token) {
    return axios.post('/api/results/generate', {}, tokenConfig(token));
}

export function redirect_to_rq_dashboard(token) {
    return axios.get('/rqq', tokenConfig(token));

}

export function get_historic_raw_results (json, token) {
    return axios.post('/api/reports/historic_raw_results', json, tokenConfig(token))
}

export function get_raw_results (json, token) {
    return axios.post('/api/reports/raw_results', json, tokenConfig(token))
}
