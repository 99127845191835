import React from 'react';

// There is no dynamic way to get a list of languages available to i18n lib
// so define that here ourselves instead, which is ok since we know which languages we have.
export const LANGUAGES = [{value: 'de', display: 'Deutsch'}, {value: 'en', display: 'English'}];

const LanguageContext = React.createContext({
    changeLanguage: () => null,
    languages: LANGUAGES,
    currentLanguage: null,
});
export default LanguageContext
