import jwtDecode from 'jwt-decode';

import {createReducer} from '../utils/misc';
import {
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGIN_USER_REQUEST,
    LOGOUT_USER,
    REGISTER_USER_FAILURE,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
} from '../constants/index';

const initialState = {
    token: localStorage.getItem('token'),
    registerError: false,
    userName: null,
    isAuthenticated: false,
    isAuthenticating: false,
    status: null,
    statusText: null,
    isRegistering: false,
    isRegistered: false,
    registerStatusText: null,
    registrationEmail: null,
    loginError: null,
};

export default createReducer(initialState, {
    [LOGIN_USER_REQUEST]: (state) =>
        Object.assign({}, state, {
            isAuthenticating: true,
            statusText: null,
        }),
    [LOGIN_USER_SUCCESS]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: true,
            token: payload.token,
            userName: jwtDecode(payload.token).name,
            statusText: 'Sie wurden erfolgreich eingeloggt.',
        }),
    [LOGIN_USER_FAILURE]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: false,
            token: null,
            userName: null,
            statusText: payload.status === 403 ? 'Falscher Login Name oder Passwort' :
                'Verbindung zum Server nicht möglich.',
            status: payload.status,
            loginError: payload.errorMessage
        }),
    [LOGOUT_USER]: (state) =>
        Object.assign({}, state, {
            isAuthenticated: false,
            token: null,
            userName: null,
            statusText: 'Sie wurden erfolgreich ausgeloggt',
            status: 200
        }),
    [REGISTER_USER_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            registrationEmail: payload.response.registrationEmail,
        })
    },
    [REGISTER_USER_REQUEST]: (state) =>
        Object.assign({}, state, {
            isRegistering: true,
        }),
    [REGISTER_USER_FAILURE]: (state, payload) => {
        return Object.assign({}, state, {
            registerError: payload.message
        })
    },
});
