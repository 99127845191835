import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Paper from '@mui/material/Paper';
import Portal from '@mui/material/Portal';
import {withTranslation} from "react-i18next";

class DescriptionPopover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopover: false,
            anchor: null,
            persist: false
        }
    }

    handleClick = (event) => {
        this.setState({persist: !this.state.persist});
        if (window.innerWidth < 991) {
            // this.setState({anchor: event.currentTarget});
            this.timerID = setTimeout(() => {
                this.setState({
                    anchor: this.state.showPopover ? null : event.currentTarget,
                    showPopover: !this.state.showPopover
                });
            }, 400);

        } else {
            this.handleOpen(event);
        }

    };

    handleOpen = (event) => {
        this.setState({anchor: event.currentTarget});
        this.setState({
            showPopover: true,
        });
    };

    handleClose = () => {
        clearTimeout(this.timerID);
        if (!this.state.persist) {
            this.setState({
                showPopover: false,
                anchor: null
            });
        }
    };

    createMarkup = () => {
        const {t} = this.props;
        return {__html: this.props.description ? this.props.description : ("<em>" + t('descriptionAlt') + "</em>")}
    };

    setPosition = () => {
        let childElementBorders = this.node.getBoundingClientRect();
        return {
            top: childElementBorders.top + (window.pageYOffset) - 25,
            left: childElementBorders.left + window.pageXOffset,
        };
    };

    render() {
        return (
            <React.Fragment>
                <div
                    ref={(n) => this.node = n}
                    style={this.props.divStyle}
                    onClick={this.handleClick}
                    onMouseEnter={this.handleOpen}
                >
                    {this.props.children}
                </div>

                {this.state.showPopover &&
                <Portal container={document.getElementById('body')} onMouseLeave={this.handleClose}

                >
                    <Paper

                        style={{
                            padding: '10px',
                            position: 'absolute',
                            maxWidth: '40%',
                            zIndex: 10,
                            ...this.setPosition()
                        }}
                    >
                        {/* todo: sanitize description input */}
                        <h4 style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={this.createMarkup()}/>
                    </Paper>
                </Portal>
                }
            </React.Fragment>
        )
    }
}

DescriptionPopover.defaultProps = {
    description: null,
    divStyle: {},
};

DescriptionPopover.propTypes = exact({
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    description: PropTypes.string,
    divStyle: PropTypes.object,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    tReady: PropTypes.bool.isRequired,
});

export default withTranslation('sheetManagement')(DescriptionPopover);
