import React from 'react';
import PropTypes from 'prop-types';

import './styles/app.css';

import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
    responsiveFontSizes,
} from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DayjsUtils from "@date-io/dayjs";
import * as dataCreators from '../../actions/data';
import * as socketDataCreators from '../../actions/socketActions';
import * as authCreators from '../../actions/auth';
import browserHistory from '../../browserHistory';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {renderRoutes} from 'react-router-config';


/* application components */
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import StatusSnackbar from '../../components/StatusSnackbar';
import {AUTHENTICATION_FAILED} from "../../constants";
import {FeedbackButton} from "../../components/FeedbackButton";
import {withTranslation} from "react-i18next";
import AuthContext from "../../contexts/AuthContext";
import LanguageContext, {LANGUAGES} from "../../contexts/LanguageContext";
import {changeLanguage} from "../../i18n";
import background from "images/blur-bg-blurred.jpg"

function mapStateToProps(state) {
    return {
        resetDone: state.data.resetDone,
        token: state.auth.token,
        user: state.data.user,
        userName: state.auth.userName,
        socketStatus: state.socket.status,
        room: state.socket.room,
        roomOptions: state.socket.roomOptions,
        projectChangeRequired: state.socket.projectChangeRequired,
        projectChanged: state.data.projectChanged,
        isFetching: state.data.isFetching,
        roles: state.data.roles,
        showBackground: state.data.toggleBackground,
        disconnected: state.socket.disconnected,
        projects: state.data.projects
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({...dataCreators, ...socketDataCreators, ...authCreators}, dispatch);
}

export class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadError: false,
            loaded: false,
            snackbarOpen: false,
            snackbarMessage: "",
            snackbarVariant: "info",
            snackbarPersist: false,
        };
    }

    componentDidMount() {
        this.props.connectSocket(this.props.token).then(authenticated => {
            if (authenticated) {
                this.fetchVitalData();
                this.props.loginUserSuccess(this.props.token);
            }
        }).catch(error => {
            console.warn("connectSocket error: ", error);
            if (error === AUTHENTICATION_FAILED) {
                this.setState({loadError: true});
            }
        });
        this.props.subscribeToTokenExpired();
        this.props.subscribeToStatusUpdates();
        this.props.subscribeToFileDownload();
        this.props.fetchProjects(this.props.token);

        this.props.subscribeTo('disconnect', (reason) => {
            this.props.setDisconnected(true);
        });

        if (this.props.location.search.includes("email_confirmed")){
            this.props.statusUpdate('success', this.props.t('emailConfirmed'));
        }
    }

    componentDidUpdate(prevProps) {
        const {t} = this.props;
        if (prevProps.socketStatus !== this.props.socketStatus) {
            this.openSnackbar(this.props.socketStatus);
            if (this.props.socketStatus.redirect) {
                browserHistory.push(this.props.socketStatus.redirect)
            }
            if (this.props.socketStatus.refresh) {
                window.location.reload()
            }
        }

        if (this.props.projectChangeRequired) {
            this.changeProject(this.props.projectChangeRequired);
        }

        if (this.props.projectChanged && (!prevProps.projectChanged !== this.props.projectChanged)) {
            this.props.socketEmit('current_project_changed');
            this.props.fetchProjects(this.props.token);

            this.setState({loaded: false}, () => {
                this.props.resetDataReduxState().then(done => {
                    this.fetchVitalData();
                });
                this.props.resetSocketReduxState();
            });
        }

        if (this.props.disconnected !== prevProps.disconnected) {
            if (this.props.disconnected) {

                this.setState({
                    snackbarOpen: true,
                    snackbarPersist: true,
                    snackbarVariant: 'warning',
                    snackbarMessage: t('connectionToServerWasLost', {reason: "Websocket connection closed."})
                });

            } else {
                // When a room is joined its id is stored in the redux state
                // On disconnect client gets kicked out of the room server-side
                // So if client was in a room before disconnect, request to join it again.
                if (this.props.room) {
                    this.props.joinRoom(this.props.userName, this.props.room, this.props.roomOptions)
                }
                this.setState({
                    snackbarOpen: true,
                    snackbarPersist: false,
                    snackbarVariant: 'success',
                    snackbarMessage: t('connectionToServerRestored')
                });
            }
        }
    }

    getCurrentLanguage = () => {
        let i18n_language = null
        if (this.props.i18n.language.includes("-")) {
            i18n_language = this.props.i18n.language.split("-")[0]
        } else {
            i18n_language = this.props.i18n.language
        }
        return LANGUAGES.find(obj => {
                return obj.value === i18n_language
            }) &&
            LANGUAGES.find(obj => {
                return obj.value === i18n_language
            }).display
    };
    getLanguageNames = () => (LANGUAGES && LANGUAGES.map(language => language.display));
    changeLanguage = (newLanguage, callback = null) => {
        let language = LANGUAGES.find(language => language.display === newLanguage).value
        changeLanguage(language, callback && callback);
        // if user is logged in, save the new language setting in the backend
        if (this.props.user) {
            this.props.setUserLanguage(
                this.props.user.user_id,
                language,
                this.props.token
            )
        }
    };


    fetchVitalData = () => {
        this.props.fetchVitalData(this.props.token).then(done => {
            this.setState({loaded: done})
        }).catch(error => {
            console.warn("could not fetch vital data, error: ", error)
        });
    };

    openSnackbar = (socketStatus) => {
        const {t} = this.props;
        let variant = socketStatus.type.toLowerCase();
        let message = socketStatus.message;
        if (socketStatus.translation_key) {
            message = t(`statusMessages:${socketStatus.translation_key}`, socketStatus.data && socketStatus.data);
        }
        this.setState({
            snackbarOpen: true,
            snackbarMessage: message,
            snackbarVariant: variant
        })
    };

    closeSnackbar = () => {
        this.setState({snackbarOpen: false});
    };

    onFeedbackSendClick = (email, feedbackText, userMetaData) => {
        this.props.sendFeedback(email, feedbackText, userMetaData, this.props.userName)
    };

    getMuiTheme(project) {
        let theme = createTheme({
            props: {
                MuiTextField: {
                    variant: "standard"
                },
                MuiSelect: {
                    variant: "standard"
                },
            },
            typography: {
                useNextVariants: true,
                h4: {
                    fontSize: '2rem',
                    color: 'rgba(0, 0, 0, 0.54)',
                }
            },
            palette: {
                default: {
                    main: project ? project.ui_primary_color : '#333333'
                },
                primary: {
                    main: project ? project.ui_header_background : '#002f5d'
                },
                secondary: {
                    main: project ? project.ui_text_color : '#FFFFFF'
                },
                text: {
                    alternate: project ? project.ui_text_color : '#FFFFFF'
                },
            },
        });
        theme = responsiveFontSizes(theme);
        return theme;
    }

    changeProject = (projectId) => {
        this.props.changeProject(projectId, this.props.token);
    };

    static propTypes = {
        children: PropTypes.node,
    };

    render() {
        const {t} = this.props;
        // when key prop of a component is changed, ALL of it's children are remounted
        // doing this with the main container so the child containers remounts and fetches the appropriate data
        // when the current project of the user changes.
        let user = this.props.user;
        // Edge cases for when a user is not assigned to a project
        const userHasProject = Boolean(user && user.currentProject);
        const project = userHasProject ? user.currentProject : null;
        const projectId = userHasProject ? user.currentProject.project_id : null;


        // todo - do these checks in reducers instead
        let loaded = false;
        if (this.state.loaded && this.props.user && Object.keys(this.props.roles).length > 0) {
            loaded = true;
        }
        if (this.state.loadError) loaded = true;
        if (loaded && (!userHasProject && !browserHistory.location.pathname.includes('project-management'))) {
            browserHistory.push('/project-management/create-project');
            // TODO - THIS SHOULD BE MOVED TO LOGIN PROMISE RESOLVE.

        }

        const currentRoutes = this.props.route.routes;
        let theme = this.getMuiTheme(project);

        let languageContextValues = {
            changeLanguage: this.changeLanguage,
            languages: this.getLanguageNames(),
            currentLanguage: this.getCurrentLanguage()
        };
        return (
            <div key={projectId}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={DayjsUtils}>
                            <LanguageContext.Provider value={languageContextValues}>
                                <div style={this.props.showBackground ? {
                                        position: 'relative', minHeight: '100vh',
                                        backgroundImage: `url(${background})`, height: '100%', backgroundPosition: "center",
                                        backgroundRepeat: 'no-repeat', backgroundSize: "cover"
                                    } :
                                    {position: 'relative', minHeight: '100vh', backgroundColor: '#fff'}}>
                                    <React.Fragment>
                                        <Header
                                            project={project ? project : {}}
                                            projects={this.props.projects}
                                            user={user}
                                            userPrivilege={user ? this.props.roles[user.role] : 0}
                                            onChangeProject={this.changeProject}
                                            onLogout={this.props.logoutAndRedirect}
                                        />
                                    </React.Fragment>
                                    <div className="container-fluid">
                                        <AuthContext.Provider value={{loaded: loaded}}>
                                            {renderRoutes(currentRoutes)}
                                        </AuthContext.Provider>
                                    </div>
                                    <Footer color={userHasProject ? project.ui_header_background : "#002f5d"}/>
                                    <StatusSnackbar
                                        open={this.state.snackbarOpen}
                                        variant={this.state.snackbarVariant}
                                        message={this.state.snackbarMessage}
                                        persist={this.state.snackbarPersist}
                                        onClose={this.closeSnackbar}
                                    />
                                    <FeedbackButton
                                        t={t}
                                        user={user}
                                        onSendClick={this.onFeedbackSendClick}
                                    />
                                </div>
                            </LanguageContext.Provider>
                        </LocalizationProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['actionResponses', 'statusMessages'])(App));
