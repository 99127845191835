import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';


function ListItemPure(props){
    const {onClick, valueToPass, emitEvent, type, id, ...otherProps} = props;
    const onItemClick = (event) => {
        if (emitEvent){
            return onClick(event, valueToPass);
        }
        onClick(valueToPass);
    };
    return (
        type === "ListItem" ?
            <ListItem id={id} onClick={onItemClick} {...otherProps}/>
            :
            <MenuItem id={id} onClick={onItemClick} {...otherProps}/>
    );
}


ListItemPure.defaultProps = {
    valueToPass: "",
    type: "ListItem",
    emitEvent: false,
    onClick: () => null
};

ListItemPure.propTypes = {
    valueToPass: PropTypes.any,
    type: PropTypes.oneOf(["MenuItem", "ListItem"]),
    emitEvent: PropTypes.bool,
    onClick: PropTypes.func,
};

export default ListItemPure;