import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actionCreators from '../actions/data';

import AppRoutesView from '../components/AppRoutesComponents/AppRoutesView';
import appPaths from '../appPaths';

function mapStateToProps(state) {
    return {
        data: state.data,
        token: state.auth.token,
        loaded: state.data.loaded,
        roles: state.data.roles,
        user: state.data.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

export class AppRoutesContainer extends React.Component {
    render() {
        return (
            <AppRoutesView
                applicationRoutes={appPaths}
                userPrivilege={this.props.roles[this.props.user.role]}
                userName={this.props.user.name}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutesContainer);